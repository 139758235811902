import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { FormikSearchBar } from "@evabot/eva-react-core-library/dist/atoms/SearchBar/FormikSearchBar";
import AeReturnActivityTag from "./AeReturnActivityTag";
import AeReturnActivityCard from "./AeReturnActivityCard";
import FilterListIcon from "./FilterListIcon";
import React, { useEffect, useState } from "react";
import AeReturnActivityOrderStatus from "./AeReturnActivityOrderStatus";
import { useAppSelector } from "hooks";
import AeReturnActivityFilters from "./AeReturnActivityFilters";
import AeReturnActivitySearchModal from "./AeReturnActivitySearchModal";
import { axiosHelper } from "utils";
import AeReturnActHistoryDetail from "./AeReturnActHistoryDetail";
import { useParams } from "react-router-dom";
import { getDateText } from "utils/constants";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import MuiAutocompleteSearch from "@evabot/eva-react-core-library/dist/atoms/MUIAutocompleteSearch/MuiAutocompleteSearch";
import NoActivity from "./NoActivity.png";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

interface AeReturnActHistoryRightSectionProps {}

const AeReturnActHistoryRightSection: React.FC<
  AeReturnActHistoryRightSectionProps
> = () => {
  const { id } = useParams();
  const [searchQuery, setSearchQuery] = useState(null);
  const [openSearchBarModal, setOpenSearchBarModal] = useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [activityHistory, setActivityHistory] = useState([]);
  const [options, setOptions] = useState([]);
  const [activtyData, setActivityData] = useState([]);
  const [searchValues, setSearchValues] = useState({
    name: "",
  });
  const [loading, setLoading] = useState(true);
  const [modalClose, setModalClose] = useState(false);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const orderStatusData = useAppSelector(
    (state) => state.aeReturnActivity.orderStatus
  );
  const orderStatusCount = Object.keys(orderStatusData)?.filter(
    (key) => orderStatusData[key]
  )?.length;
  const filterData = useAppSelector((state) => state.aeReturnActivity.filters);
  const filterCount = Object.keys(filterData)?.filter(
    (key) => filterData[key]
  )?.length;

  console.log(
    filterCount,
    filterData,
    "historyyy..... data",
    activityHistory,
    activtyData
  );

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const fetchHistory = async () => {
    try {
      const result = await axiosHelper({
        url: "activity/getActivityHistory",
      });
      setActivityHistory(result.data.data);
      setActivityData(result.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  useEffect(() => {
    if (activityHistory.length > 0) {
      let tempOpts = activityHistory.reduce(
        (accum, init) => [...accum, ...init.entries],
        []
      );
      let tempFinalOpts = [];
      for (let i = 0; i < tempOpts.length; i++) {
        let curEntries = tempFinalOpts.map((x) => x.value);
        if (!curEntries.includes(tempOpts[i].stakeholderName.trim())) {
          tempFinalOpts.push({
            key: "Name",
            value: tempOpts[i].stakeholderName.trim(),
          });
        }
      }
      console.log("tempOpts", tempOpts);
      setOptions(tempFinalOpts);
    }
  }, [activityHistory]);

  useEffect(() => {
    // if (!modalClose) {
    console.log(searchQuery, activtyData);
    if (
      searchQuery?.value?.length > 0 &&
      filterCount === 0 &&
      orderStatusCount === 0
    ) {
      if (!modalClose) {
        setLoading(true);
      }
      setActivityHistory([
        ...activtyData
          ?.map((history) => {
            return {
              ...history,
              entries: history?.entries?.filter(
                (entry) => entry?.stakeholderName === searchQuery.value
              ),
            };
          })
          .filter((history) => history?.entries?.length > 0),
      ]);
      setTimeout(() => {
        setLoading(false);
        setModalClose(false);
      }, 1000);
    } else {
      if (filterCount > 0 || orderStatusCount > 0) {
        console.log("historyyy filter called");
        if (!modalClose) setLoading(true);
        setActivityHistory([
          ...(searchQuery?.value?.length > 0
            ? [
                ...activtyData
                  ?.map((history) => {
                    return {
                      ...history,
                      entries: history?.entries?.filter(
                        (entry) => entry?.stakeholderName === searchQuery.value
                      ),
                    };
                  })
                  .filter((history) => history?.entries?.length > 0),
              ]
            : activtyData
          )
            ?.map((history) => {
              return {
                ...history,
                entries: history.entries.filter((entry) => {
                  if (
                    filterData?.weather &&
                    entry?.nudgeCategoryType.toLowerCase().includes("weather")
                  )
                    return entry;
                  if (
                    filterData?.places &&
                    entry?.nudgeCategoryType
                      .toLowerCase()
                      .includes("googleplaces")
                  )
                    return entry;
                  if (
                    filterData?.birthday &&
                    entry?.nudgeCategoryType.toLowerCase().includes("birthday")
                  )
                    return entry;
                  if (
                    filterData?.sports &&
                    entry?.nudgeCategoryType.toLowerCase().includes("espn")
                  )
                    return entry;
                  if (
                    filterData?.music &&
                    (entry?.nudgeCategoryType
                      .toLowerCase()
                      .includes("music") ||
                    entry?.nudgeCategoryType
                      .toLowerCase()
                      .includes("ticket"))
                  )
                    return entry;

                  if (filterData?.liked && entry?.interactionType === "like")
                    return entry;
                  if (filterData?.copied && entry?.interactionType === "copy") {
                    return entry;
                  }
                  if (filterData?.cake && entry?.orderType === "treatCake") {
                    return entry;
                  }
                  if (filterData?.flowers && entry?.orderType === "treatRose") {
                    return entry;
                  }
                  if (filterData?.gifts && entry?.orderType === "treatGift") {
                    return entry;
                  }
                  if (filterData?.meals && entry?.orderType === "treatPizza") {
                    return entry;
                  }
                  if (
                    filterData?.alcohol &&
                    entry?.orderType === "treatLiquor"
                  ) {
                    return entry;
                  }
                  if (filterData?.treatTea && entry?.orderType === "treatTea") {
                    return entry;
                  }
                  if (
                    filterData?.fanGearTickets &&
                    (entry?.orderType === "ticketSportsIcon" ||
                      entry?.orderType === "gearSportsIcon")
                  ) {
                    return entry;
                  }
                  if (
                    orderStatusData?.scheduled &&
                    entry.orderStatus === "Scheduled"
                  ) {
                    return entry;
                  }
                  if (
                    orderStatusData?.delivered &&
                    entry.orderStatus === "Delivered"
                  ) {
                    return entry;
                  }
                  if (
                    orderStatusData?.shipped &&
                    entry.orderStatus === "shipped"
                  ) {
                    return entry;
                  }
                  if (
                    orderStatusData?.packaging &&
                    (entry?.orderStatus === "packed" ||
                      entry?.orderStatus === "transferred ")
                  ) {
                    return entry;
                  }
                  if (
                    orderStatusData?.cancelled &&
                    entry?.orderStatus === "Cancelled"
                  ) {
                    return entry;
                  }
                }),
              };
            })
            .filter((history) => history?.entries?.length > 0),
        ]);
        setTimeout(() => {
          setModalClose(false);
          setLoading(false);
        }, 1000);
      } else {
        console.log("historyyy none called");
        setActivityHistory([...activtyData]);
        setModalClose(false);
      }
    }
    // } else {
    //   setModalClose(false);
    // }
  }, [
    searchQuery,
    filterCount,
    filterData,
    filterData?.weather,
    filterData?.places,
    filterData?.birthday,
    filterData?.sports,
    filterData?.music,
    filterData?.liked,
    filterData?.copied,
    activtyData,
    orderStatusCount,
    orderStatusData?.scheduled,
    orderStatusData?.delivered,
    orderStatusData?.shipped,
    orderStatusData?.packaging,
    orderStatusData?.cancelled,
    modalClose,
  ]);

  return (
    <Grid
      justifyContent={"center"}
      alignItems={activtyData?.length < 0 && "center"}
      py="24px"
      overflowY={"auto"}
    >
      <Flex flexDirection={"column"} width="770px">
        {!id && activtyData?.length > 0 && (
          <>
            <Flex mt="20px" mb="16px">
              <Text fontSize={"24px"} fontWeight={300}>
                Activity history
              </Text>
            </Flex>
            <Flex
              justifyContent={"space-between"}
              alignItems="center"
              className="demo"
            >
              <Flex position={"relative"}>
                <Flex
                  width="360px"
                  my={(openOrderModal || openFilterModal) && "5px"}
                >
                  <MuiAutocompleteSearch
                    // @ts-ignore
                    value={searchQuery}
                    setValue={setSearchQuery}
                    options={options}
                    screenSize={screenSize}
                    background="white"
                    placeholder="Search by name"
                  />
                </Flex>
              </Flex>
              <Flex position={"relative"}>
                {
                  <Flex>
                    <Grid
                      onMouseEnter={() => {
                        setOpenOrderModal(true);
                        if (openSearchBarModal) {
                          setOpenSearchBarModal(false);
                        }
                      }}
                      onMouseLeave={() => {
                        setOpenOrderModal(false);
                        if (openSearchBarModal) {
                          setOpenSearchBarModal(false);
                        }
                      }}
                    >
                      <Flex
                        background={"#fff"}
                        p="13px 20px"
                        border={"1px solid #EAECEE"}
                        borderRadius="8px"
                        fontSize={"14px"}
                        fontWeight={400}
                        cursor="pointer"
                        alignItems={"center"}
                        // mr="8px" - needs to be added back when filters are brought back
                      >
                        <Text mr="10px"> Order status</Text>
                        {orderStatusCount > 0 ? (
                          <Flex
                            width={"18px"}
                            height={"18px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            backgroundColor={"#DD015B"}
                            color="#fff"
                            borderRadius={"50%"}
                            fontSize="11px"
                            fontWeight={400}
                          >
                            {" "}
                            {orderStatusCount}
                          </Flex>
                        ) : (
                          <FilterListIcon />
                        )}
                      </Flex>
                      <AeReturnActivityOrderStatus
                        setOpenOrderModal={setOpenOrderModal}
                        initialValues={orderStatusData}
                        openOrderModal={openOrderModal}
                      />
                    </Grid>
                    {/* Removing filters temporarily for the current release */}
                    {/* <Grid
                      onMouseEnter={() => {
                        setOpenFilterModal(true);
                        if (openSearchBarModal) {
                          setOpenSearchBarModal(false);
                          setModalClose(true);
                          if (searchValues?.name?.length <= 0)
                            setSearchQuery("");
                        }
                      }}
                      onMouseLeave={() => {
                        setOpenFilterModal(false);
                        if (openSearchBarModal) {
                          setOpenSearchBarModal(false);
                          if (searchValues?.name?.length <= 0)
                            setSearchQuery("");
                        }
                        setModalClose(true);
                      }}
                    >
                      <Flex
                        background={"#fff"}
                        p="13px 20px"
                        border={"1px solid #EAECEE"}
                        borderRadius="8px"
                        fontSize={"14px"}
                        fontWeight={400}
                        cursor="pointer"
                        alignItems={"center"}
                      >
                        <Text mr="10px"> Filters</Text>
                        {filterCount > 0 ? (
                          <Flex
                            width={"18px"}
                            height={"18px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            backgroundColor={"#DD015B"}
                            color="#fff"
                            borderRadius={"50%"}
                            fontSize="11px"
                            fontWeight={400}
                          >
                            {" "}
                            {filterCount}
                          </Flex>
                        ) : (
                          <FilterListIcon />
                        )}
                      </Flex>

                      
                      <AeReturnActivityFilters
                        setOpenFilterModal={setOpenFilterModal}
                        initialValues={filterData}
                        openFilterModal={openFilterModal}
                      />
                    </Grid> */}
                  </Flex>
                }
              </Flex>
            </Flex>
          </>
        )}
        {!id ? (
          loading ? (
            <MemoRevenoidLoader />
          ) : activtyData?.length > 0 ? (
            <>
              {activityHistory?.length > 0 ? (
                <Flex
                  flexDirection={"column"}
                  backgroundColor="#fff"
                  borderRadius={"12px"}
                  my="12px"
                  p="32px 42px"
                >
                  {activityHistory?.length > 0 &&
                    activityHistory?.map((day, dayId) => {
                      return (
                        <>
                          <AeReturnActivityTag
                            key={dayId}
                            tagBgColor="#1B9788"
                            tagColor="#FFFFFF"
                            tagText={getDateText(day.date)}
                          />
                          {day?.entries?.map((entry, id) => {
                            return (
                              <>
                                <AeReturnActivityCard
                                  row={
                                    !(
                                      activityHistory?.length === dayId + 1 &&
                                      day?.entries?.length === id + 1
                                    )
                                  }
                                  largeRow={day?.entries?.length === id + 1}
                                  entry={entry}
                                />
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                </Flex>
              ) : (
                <Text
                  fontWeight={300}
                  fontSize={"24px"}
                  mt="24px"
                  mb="10px"
                  textAlign={"center"}
                  color="#57585C"
                >
                  You don’t have any activity history for this search
                </Text>
              )}
            </>
          ) : (
            <Flex
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
            >
              <img src={NoActivity} alt="" />
              <Text
                fontWeight={300}
                fontSize={"24px"}
                mt="24px"
                mb="10px"
                textAlign={"center"}
              >
                You don’t have any activity history
              </Text>
              <Text
                color="#57585C"
                fontWeight={400}
                fontSize={"14px"}
                textAlign={"center"}
              >
                When you engage with any stakeholder, then only
                <Text mt="5px">you could view details here</Text>
              </Text>
            </Flex>
          )
        ) : (
          <AeReturnActHistoryDetail />
        )}
      </Flex>
    </Grid>
  );
};

export default AeReturnActHistoryRightSection;
