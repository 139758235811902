import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function EyeIcon({ isAIVisibilityEnabled }) {
    return (
        <>
            {isAIVisibilityEnabled ? (
                <VisibilityIcon style={{ color: '#0094e3', cursor: 'pointer' }} />
            ) : (
                <VisibilityOffIcon style={{ color: '#c2c2c2', cursor: 'pointer' }} />
            )}
        </>
    );
}

export default EyeIcon;
