import * as React from "react";

  
  function AccountReportIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M1.5498 2.65C1.5498 1.73873 2.28854 1 3.1998 1H16.3998C17.3111 1 18.0498 1.73873 18.0498 2.65V23H3.1998C2.28854 23 1.5498 22.2613 1.5498 21.35V2.65Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
        <path d="M18.0498 11.9984C18.0498 11.3909 18.5423 10.8984 19.1498 10.8984H21.3498C21.9573 10.8984 22.4498 11.3909 22.4498 11.9984V21.3484C22.4498 22.2597 21.7111 22.9984 20.7998 22.9984H18.0498V11.9984Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
        <path d="M4.84961 5.39844H9.24961" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.84961 9.25H11.4496" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
  }

export default AccountReportIcon;
