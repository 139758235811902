import { configureStore } from "@reduxjs/toolkit";
import fieldMappingReducerSlice from "containers/FieldMapping/store/fieldMappingReducer";
import globalStateReducerSlice from "store/globalStateReducer";

import OpportunitySelectionReducerSlice from "containers/OpportunitySelectionComponent/store/OpportunitySelectionReducer";
import SlateEditorReducerSlice from "containers/SlateEdtitor/store/SlateEditorReducer";
import aeReturnUnlikeModalReducer from "components/AeReturnUnlikeModal/store/aeReturnUnlikeModalReducer";
import selectedNudgeReducerSlice from "containers/AEReturnDashboard/store/aeDashboardReducer";
import aeReturnProfileReducer from "containers/AEReturnProfile/store/aeReturnProfileReducer";
import aeLoginFormReducer from "containers/AESignupLayout/store/aeLoginFormReducer";
import AeFormReducer from "containers/AeForgotPassword/store/AeFormReducer";
import aeReturnActivityReducer from "containers/AeReturnActivityHistory/store/aeReturnActivityReducer";
import aeReturnAnalyticsReducer from "containers/AeReturnAnalytics/store/aeReturnAnalyticsReducer";
import stakeholdertagReducer from "containers/StakeholderTag/store/stakeholdertagReducer";
import memberListingReducer from "containers/MemberListing/store/memberListingReducer";
import emailTemplateReducer from "containers/EmailTemplate/store/emailTemplateReducer";
import prospectReportReducer from "containers/ProspectReport/store/prospectReportReducer";
import companyReportReducer from "containers/CompanyScoreReport/store/companyReportReducer";

const logger = (store) => {
  return (next) => {
    return (action) => {
      // console.log("Action Dispatched", action);
      const result = next(action);
      // console.log("Updated State", store.getState());
      return result;
    };
  };
};

const store = configureStore({
  reducer: {
    // You are free to call the LHS what you like, but it must have a reducer on the RHS.
    fieldMapping: fieldMappingReducerSlice,
    globalState: globalStateReducerSlice,
    opportunitySelection: OpportunitySelectionReducerSlice,
    slate: SlateEditorReducerSlice,
    aeReturnUnlikeModalData: aeReturnUnlikeModalReducer,
    aeReturnSelectedNudge: selectedNudgeReducerSlice,
    profileState: aeReturnProfileReducer,
    loginFormState: aeLoginFormReducer,
    aeFormState: AeFormReducer,
    aeReturnActivity: aeReturnActivityReducer,
    analyticsState: aeReturnAnalyticsReducer,
    stakeholderTagState:stakeholdertagReducer,
    memberListingState:memberListingReducer,
    emailTemplateState:emailTemplateReducer,
    prospectReportState : prospectReportReducer,
    companyScoreState:companyReportReducer
  },
  middleware: [logger],
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
