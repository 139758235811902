import Button from 'components/Elements/Button';
import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { axiosHelperCore } from "utils";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { TextField, Backdrop } from "@mui/material";
import MemoRevenoidLoader from 'components/RevenoidLoader/RevenoidLoader';

function PipelinerForm({ isDualMode, mode, isFieldsAdded, setIsFieldsAdded, setShowForm, setConnectionText }) {

    const [showMessage, setShowMessage] = useState(false);
    const [listData, setListData] = useState([]);
    const [fields, setFields] = useState([]);
    const [accountFields, setAccountFields] = useState([]);
    const [pipelinerData, setPipelinerData] = useState({ tagField: "", callPrep: "", prospectScore: "", accountScore: "", accountSummary: "", functionId: "" });
    const [loading, setLoading] = useState(false);
    const [IsError, setIsError] = useState(false);

    const getFilteredOptions = (currentField) => {
        return fields?.filter(f =>
            f.value !== pipelinerData.callPrep &&
            f.value !== pipelinerData.prospectScore &&
            f.value !== pipelinerData.functionId
        
        ).map(f => ({ label: f.label, value: f.value }));
    };

    const getFilteredAccountOptions = (currentField) => {
        return accountFields?.filter(f =>
            f.value !== pipelinerData.accountScore &&
            f.value !== pipelinerData.accountSummary
        ).map(f => ({ label: f.label, value: f.value }));
    };

    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 999 }),
        menu: (base) => ({
            ...base,
            maxHeight: 400,
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: 400,
            overflowY: 'auto',
        }),
    };


    useEffect(() => {
        getList();
        getData();
        setShowForm(true);
    }, [])

    const cancel = () => {
        setIsFieldsAdded(true);
        setShowForm(false);
    }

    const validateFields = () => {
        const fields = [
            pipelinerData?.callPrep,
            pipelinerData?.prospectScore,
            pipelinerData?.accountScore,
            pipelinerData?.accountSummary
        ];

        console.log("fieldsfields", fields);

        const normalizedFields = fields.map(field =>
            (field !== undefined && field !== null && field.trim() !== '') ? field.trim().toLowerCase() : field
        );

        const filteredFields = normalizedFields.filter(field => field !== undefined && field !== null && field !== '');

        const uniqueFields = new Set(filteredFields);

        if (uniqueFields.size !== filteredFields.length) {
            return false;
        }

        return true;
    }


    const getList = async () => {
        setLoading(true);
        const [result, result1]: any[] = await Promise.all([
            axiosHelperCore({
                url: `pipeliner/getFields`,
                method: "POST",
                JSONData: { entityName: "Contact" }
            }),
            axiosHelperCore({
                url: `pipeliner/getFields`,
                method: "POST",
                JSONData: { entityName: "Account" }
            }),
        ]);
        setFields(result.data.fields);
        setAccountFields(result1.data.fields);
        setLoading(false);
    }

    const getData = async () => {

        const result: any = await axiosHelperCore({
            url: `pipeliner/getData`,
            method: "POST",
        });
        if (result?.data?.pipelinerData) {
            setPipelinerData(result?.data?.pipelinerData);
            if (result?.data?.pipelinerData && Object.keys(result?.data?.pipelinerData).length > 0) {
                setConnectionText("connected");
            }
        }
        else {
            setConnectionText("Connection Pending");
            setShowForm(true)
        }



    }

    const saveData = async () => {

        // if (!validateFields()) {
        //     setIsError(true);
        //     const div = document.getElementById('scrollableDiv');
        //     div.scrollTop = 0;
        //     return;
        // }

        setIsFieldsAdded(true);
        setShowForm(false);
        const result: any = await axiosHelperCore({
            url: `pipeliner/saveData`,
            method: "POST",
            JSONData: { pipelinerData: pipelinerData }
        });
        setConnectionText("connected");
        setShowForm(false);
    }

    if (isDualMode && !mode)
        return (<>  </>)


    return (
        <>
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}>
                <MemoRevenoidLoader />
            </Backdrop>}

            <div id='scrollableDiv' className='flex flex-col gap-[8px]' style={{ width: "90%", padding: "10px", margin: "0 auto", maxHeight: "40vh", scrollbarWidth: "none", overflowY: "auto" }}>
                {(!mode || mode === "all") && (
                    <div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div className='mb-1'>Prospect Fields</div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Tag</div>
                            <TextField
                                margin="normal"
                                fullWidth
                                style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                value={pipelinerData.tagField || ''}
                                onChange={(e) => setPipelinerData({ ...pipelinerData, tagField: e.target.value })}
                                variant="outlined"
                                inputProps={{ style: { padding: '10px', margin: 0 } }}
                            />
                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Call Prep</div>
                            <Select
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_callprep"
                                value={fields?.filter(f => f.value == pipelinerData.callPrep).map(f => ({ label: f.label, value: f.value }))}
                                options={getFilteredOptions(pipelinerData.callPrep)}
                                onChange={(selectedField) => {
                                    setPipelinerData((prevpipelinerData) => ({
                                        ...prevpipelinerData,
                                        callPrep: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Prospect Account Score</div>
                            <Select
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_prospect_score"
                                value={fields?.filter(f => f.value == pipelinerData.prospectScore).map(f => ({ label: f.label, value: f.value }))}
                                options={getFilteredOptions(pipelinerData.prospectScore)}
                                onChange={(selectedField) => {
                                    setPipelinerData((prevpipelinerData) => ({
                                        ...prevpipelinerData,
                                        prospectScore: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Department / Function</div>
                            <Select
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_prospect_score"
                                value={fields?.filter(f => f.value == pipelinerData.functionId).map(f => ({ label: f.label, value: f.value }))}
                                options={getFilteredOptions(pipelinerData.functionId)}
                                onChange={(selectedField) => {
                                    setPipelinerData((prevpipelinerData) => ({
                                        ...prevpipelinerData,
                                        functionId: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mb-1 mt-4'>Account Fields</div>

                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Account Score</div>
                            <Select
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_account_score"
                                value={accountFields?.filter(f => f.value == pipelinerData.accountScore).map(f => ({ label: f.label, value: f.value }))}
                                options={getFilteredAccountOptions(pipelinerData.accountScore)}
                                onChange={(selectedField) => {
                                    setPipelinerData((prevpipelinerData) => ({
                                        ...prevpipelinerData,
                                        accountScore: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Account Summary</div>
                            <Select
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_account_summary"
                                value={accountFields?.filter(f => f.value == pipelinerData.accountSummary).map(f => ({ label: f.label, value: f.value }))}
                                options={getFilteredAccountOptions(pipelinerData.accountSummary)}
                                onChange={(selectedField) => {
                                    setPipelinerData((prevpipelinerData) => ({
                                        ...prevpipelinerData,
                                        accountSummary: selectedField.value
                                    }));
                                }} />

                        </div>
                        {showMessage && <div className='m-3 text-[#38C088] text-sm'>
                            Settings successfully saved!
                        </div>}
                        <div className='mt-5 text-center flex flex-wrap space-x-2 justify-center'>
                            <Button variant={"salesforce"} text={isFieldsAdded ? "Save" : "Save"} onClick={saveData} disabled={false} />
                            <Button variant={"secondary"} text={"Cancel"} onClick={cancel} />

                        </div>
                    </div>)}


                {mode === "write" && (
                    <div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div className='mb-1'>Prospect Fields</div>
                        <div>
                            <div className='text-sm text-[#57585C] mt-3 mb-1'>Select Call Prep</div>
                            <Select
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_callprep"
                                value={fields?.filter(f => f.value == pipelinerData.callPrep).map(f => ({ label: f.label, value: f.value }))}
                                options={getFilteredOptions(pipelinerData.callPrep)}
                                onChange={(selectedField) => {
                                    setPipelinerData((prevpipelinerData) => ({
                                        ...prevpipelinerData,
                                        callPrep: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Prospect Account Score</div>
                            <Select
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_prospect_score"
                                value={fields?.filter(f => f.value == pipelinerData.prospectScore).map(f => ({ label: f.label, value: f.value }))}
                                options={getFilteredOptions(pipelinerData.prospectScore)}
                                onChange={(selectedField) => {
                                    setPipelinerData((prevpipelinerData) => ({
                                        ...prevpipelinerData,
                                        prospectScore: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mb-1 mt-4'>Account Fields</div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Account Score</div>
                            <Select
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_account_score"
                                value={accountFields?.filter(f => f.value == pipelinerData.accountScore).map(f => ({ label: f.label, value: f.value }))}
                                options={getFilteredAccountOptions(pipelinerData.accountScore)}
                                onChange={(selectedField) => {
                                    setPipelinerData((prevpipelinerData) => ({
                                        ...prevpipelinerData,
                                        accountScore: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Account Summary</div>
                            <Select
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_account_summary"
                                value={accountFields?.filter(f => f.value == pipelinerData.accountSummary).map(f => ({ label: f.label, value: f.value }))}
                                options={getFilteredAccountOptions(pipelinerData.accountSummary)}
                                onChange={(selectedField) => {
                                    setPipelinerData((prevpipelinerData) => ({
                                        ...prevpipelinerData,
                                        accountSummary: selectedField.value
                                    }));
                                }} />

                        </div>
                        {showMessage && <div className='m-3 text-[#38C088] text-sm'>
                            Settings successfully saved!
                        </div>}
                        <div className='mt-6 text-center flex flex-wrap space-x-2 justify-center'>
                            <Button variant={"salesforce"} text={isFieldsAdded ? "Save" : "Save"} onClick={saveData} />
                            <Button variant={"secondary"} text={"Cancel"} onClick={cancel} />

                        </div>
                    </div>)}

                {
                    mode === "read" &&
                    (<div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div>
                            <div className='mb-1'>Prospect Fields</div>
                            <div style={{ padding: "10px" }}>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Tag</div>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={pipelinerData.tagField || ''}
                                        onChange={(e) => setPipelinerData({ ...pipelinerData, tagField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                                <div className='mt-3'>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Department / Function</div>
                                    <Select
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        className="text-sm "
                                        menuPlacement='auto'
                                        placeholder="Select"
                                        name="field_prospect_score"
                                        value={fields?.filter(f => f.value == pipelinerData.functionId).map(f => ({ label: f.label, value: f.value }))}
                                        options={getFilteredOptions(pipelinerData.functionId)}
                                        onChange={(selectedField) => {
                                            setPipelinerData((prevpipelinerData) => ({
                                                ...prevpipelinerData,
                                                functionId: selectedField.value
                                            }));
                                        }} />

                                </div>
                            </div>
                        </div>
                    </div>)
                }

            </div>
        </>
    )
}

export default PipelinerForm;