import { useEffect, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import './faq.css';

function FaqContent() {

    const [openQuestionIndex, setOpenQuestionIndex] = useState({});

    const toggleQuestion = (groupIndex, questionIndex) => {
        setOpenQuestionIndex((prevState) => ({
            ...prevState,
            [`${groupIndex}-${questionIndex}`]:
                prevState[`${groupIndex}-${questionIndex}`] ? null : true,
        }));
    };

    const faqData = [
        {
            group: "Product Description and Signals",
            questions: [
                {
                    question: "What is the primary function of the product?",
                    answer: "The product is designed to provide sales teams with actionable insights through researching target accounts based on relevant buying signals, enabling more effective targeting and personalized outreach.",
                },
                {
                    question: "What are the key features of the product?",
                    answer: "The product offers features such as real-time insights of the target accounts and prospects, Account Scoring as well as crafting of outreach messaging through different channels based on custom frameworks and contexts, which help users identify high-potential accounts, optimize sales strategies and personalize their outreach efforts.",
                },
                {
                    question: "How can I insert the buying signals that are most relevant to my company?",
                    answer: "Any user that has admin access to the tool is able to insert the buying signals that are most relevant to your company as well as modify the product description.",
                },
            ],
        },
        {
            group: " Hot Accounts Feature",
            questions: [
                {
                    question: "What is the Hot Accounts feature?",
                    answer: "Hot Accounts feature is used to prioritize your accounts based on their account scores.",
                },
                {
                    question: "How does the tool identify the scores of Hot Accounts?",
                    answer: "Accounts scores are calculated by the tool depending on how many matches there are to the buying signals.",
                },
                {
                    question: "What actions should I take when a Hot Account is identified?",
                    answer: "Upon identifying a Hot Account, you can click on the account and all enriched prospects will be listed under the account. Once you select the prospect of your liking, you can view the research insights of the prospect to initiate personalized outreach messaging.",
                },
            ],
        },
        {
            group: "Insights",
            questions: [
                {
                    question: "What types of insights does the product provide?",
                    answer: "The product offers insights on the Account and prospect level as well.",
                },
                {
                    question: "How can I select the relevant insights to use in my messaging?",
                    answer: "You can select Insights by clicking on the toggle button adjacent to the insight.",
                },
                {
                    question: "How can I find the sources of these insights if I want to validate the data? ",
                    answer: "You can find the sources against each insight in the circled numbers where you can visit the source weblinks to verify the information if needed.",
                },
            ],
        },
        {
            group: "Call Preparation",
            questions: [
                {
                    question: "How does the product assist in preparing for calls?",
                    answer: "The product aids call preparation by providing focused call notes based on the account research and selected custom frameworks.",
                },
                {
                    question: "How can I view call notes on my CRM or dialer? ",
                    answer: "Our tool integrates with most CRMs to push call notes into custom fields viewable in each prospect page so you’re able to make calls while having easy access to call notes at the same time. Please check with your company’s admin that they have that setup through their dashboard. ",
                },
                {
                    question: "Are there frameworks available for call preparation?",
                    answer: "Yes, the product includes customizable call frameworks within the extension to assist in structuring your calls effectively and ensuring consistency in messaging.",
                },
            ],
        },
    ];

    return (
        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"} >
            <div className="faq-container">
                {faqData.map((group, groupIndex) => (
                    <div key={groupIndex} className="faq-group">
                        <div className="faq-group-header">{group.group}</div>
                        <div className="faq-group-content">
                            {group.questions.map((item, questionIndex) => (
                                <div key={questionIndex} className="faq-item">
                                    <div
                                        className="faq-question"
                                        onClick={() => toggleQuestion(groupIndex, questionIndex)}
                                    >
                                        {item.question}
                                        <span className="arrow">
                                            {openQuestionIndex[`${groupIndex}-${questionIndex}`]
                                                ? "▲"
                                                : "▼"}
                                        </span>
                                    </div>
                                    <div
                                        className={`faq-answer ${openQuestionIndex[`${groupIndex}-${questionIndex}`]
                                                ? "open"
                                                : ""
                                            }`}
                                        style={{
                                            maxHeight: openQuestionIndex[`${groupIndex}-${questionIndex}`]
                                                ? "150px"
                                                : "0px",
                                            transition: "max-height 0.3s ease",
                                        }}
                                    >
                                        <p>{item.answer}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </Flex>
    );
}

export default FaqContent;
