import React, { useEffect, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import Button from 'components/Elements/Button';
import DeleteIcon from 'assets/icons/DeleteIcon';
import { Tag } from '@evabot/eva-react-core-library/dist/molecules/Tag/Tag';
import { axiosHelperCore } from 'utils';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';

const ApolloSearchTableHeaderAndBody = ({ contacts, totalRecords, page, setPage, selectedContacts, setSelectedContacts, selectedFilter, setLoading }) => {
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [hideModal, setHideModal] = useState(true);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('success');


  const saveContacts = async () => {
    try {
      setLoading(true);
      const result = await axiosHelperCore({
        url: `rapport/user/saveApolloList`,
        method: "POST",
        JSONData: { contacts: Array.from(selectedContacts?.values()) }
      });
      setHideModal(true);
      setSelectedContacts(new Map());
      setLoading(false);
      setSnackbarMessage('Selected List uploaded Successfully.');
      setSnackbarType('success');
      setShowSnackBar(true);

    } catch (error) {
      setLoading(false);
      console.error("Error fetching data", error);
    }
  }

  useEffect(() => {
    let isAllChecked = true;
    contacts?.forEach(contact => {
      if (!selectedContacts.has(contact.id))
        isAllChecked = false;
    });
    setIsCheckedAll(isAllChecked);

  })

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const updatedContacts = new Map(selectedContacts);
      contacts?.forEach(contact => {
        if (contact.id !== undefined) {
          updatedContacts.set(contact.id, contact);
        }
      });
      setSelectedContacts(updatedContacts);
    } else {
      const updatedContacts = new Map(selectedContacts);

      contacts?.forEach(contact => {
        if (contact.id !== undefined && updatedContacts.has(contact.id)) {
          updatedContacts.delete(contact.id);
        }
      });
      setSelectedContacts(updatedContacts);
    }

  };

  // Handle selection for individual contact
  const handleSelectContact = (contact) => {
    const contactId = contact.id;
    const newSelectedContacts = new Map(selectedContacts);
    if (newSelectedContacts.has(contactId)) {
      newSelectedContacts.delete(contactId);
    } else {
      newSelectedContacts.set(contactId, contact);
    }
    setSelectedContacts(newSelectedContacts);
  };

  // Handle page change via dropdown
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= 100) {
      setPage(newPage);
    }
  };

  // Total pages calculation (adjusted for 25 records per page)
  const totalPages = Math.ceil(totalRecords / 25);

  const startRecord = (page - 1) * 25 + 1;
  const endRecord = Math.min(page * 25, totalRecords);

  const formatNumber = (number) => {
    if (number >= 1_000_000) {
      return `${(number / 1_000_000).toFixed(1)}M`;
    } else if (number >= 1_000) {
      return `${(number / 1_000).toFixed(1)}K`;
    }
    return number?.toLocaleString();
  };

  return (
    <>
      <SnackBar
          iconName={"info"}
          message={snackbarMessage}
          setShowSnackBar={setShowSnackBar}
          show={showSnackBar}
          timeout={4000}
          anchor={"top-right"}
          type={snackbarType}
      />
      <div className="flex flex-col w-full"> {/* Flex container to manage layout */}
        {/* Table container with scrollable body */}
        <div className="overflow-y-auto flex-grow" >
          <table className="w-full text-left border-collapse">
            <thead className="sticky top-0 bg-gray-100"> {/* Sticky header */}
              <tr className="hover:bg-gray-200 transition duration-200">
                <th className="px-4 py-2 text-sm font-bold text-gray-600"
                  style={{
                    position: 'sticky',
                    left: 0,
                    background: '#f3f4f6',
                    zIndex: 10,
                    width: '250px',
                    boxShadow: '8px 0px 16px 0px rgba(26, 26, 26, 0.08)',
                    borderRight: '1px solid transparent',
                  }}>
                  <input
                    type="checkbox"
                    checked={isCheckedAll}
                    onChange={handleSelectAll}
                    className="mr-8"
                  />
                  Name
                </th>
                {["Job Title", "Company", "Email", "Phone Number", "Social", "Location", "Company Industries"].map(header => (
                  <th key={header} className="px-4 py-2 text-sm font-bold text-gray-600 w-72">{header}</th>
                ))}
              </tr>
            </thead>
            <tbody className="overflow-x-auto">
              {contacts?.map((contact) => (
                <tr key={contact.id} className="hover:bg-gray-200 transition duration-200">
                  <td
                    className="px-4 py-2 text-sm text-gray-600"
                    style={{
                      position: 'sticky',
                      left: 0,
                      background: '#fff',
                      zIndex: 5,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '250px',
                      boxShadow: '8px 0px 16px 0px rgba(26, 26, 26, 0.08)',
                      borderRight: '1px solid transparent',
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedContacts.has(contact.id)}
                      onChange={() => handleSelectContact(contact)}
                      className="mr-8"
                    />
                    <span className="underline">{contact.name}</span>
                  </td>
                  <div className="overflow-x-auto contents">
                    {["title", "organization", "email", "phone_numbers", "linkedin_url", "location", "functions"].map((field, index) => (
                      <td
                        key={index}
                        className="text-sm text-gray-600 w-72"
                        title={
                          field === "location"
                            ? [contact.state, contact.country].filter(Boolean).join(', ')
                            : field === "organization" && contact.organization
                              ? contact.organization.name
                              : Array.isArray(contact[field])
                                ? (typeof contact[field][0] === 'object' && contact[field][0] !== null
                                  ? contact[field][0].raw_number
                                  : contact[field].join(', '))
                                : typeof contact[field] === 'object' && contact[field] !== null
                                  ? contact[field].name
                                  : contact[field]
                        }
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          borderRight: '1px solid transparent',
                        }}
                      >
                        {
                          field === "linkedin_url"
                            ? (
                              <a href={contact.linkedin_url} target="_blank" rel="noopener noreferrer">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="#0077B5"
                                  width="20px"
                                  height="20px"
                                >
                                  <path d="M19 0h-14c-2.762 0-5 2.238-5 5v14c0 2.762 2.238 5 5 5h14c2.762 0 5-2.238 5-5v-14c0-2.762-2.238-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.268c-.966 0-1.75-.784-1.75-1.75s.784-1.75 1.75-1.75 1.75.784 1.75 1.75-.784 1.75-1.75 1.75zm13.5 11.268h-3v-5.5c0-1.38-.036-3.158-1.927-3.158-1.928 0-2.222 1.506-2.222 3.058v5.6h-3v-10h2.881v1.367h.041c.401-.763 1.384-1.524 2.855-1.524 3.053 0 3.619 2.008 3.619 4.621v5.536z" />
                                </svg>
                              </a>
                            )
                            : field === "organization" && contact.organization
                              ? (
                                <>
                                  {contact.organization.logo_url && (
                                    <img src={contact.organization.logo_url} alt={`${contact.organization.name} logo`} className="inline-block mr-2 h-6 w-6" />
                                  )}
                                  {contact.organization.name}
                                </>
                              )
                              : field === "location"
                                ? [contact.state, contact.country].filter(Boolean).join(', ')
                                : Array.isArray(contact[field])
                                  ? (typeof contact[field][0] === 'object' && contact[field][0] !== null
                                    ? contact[field][0].raw_number
                                    : contact[field].join(', '))
                                  : typeof contact[field] === 'object' && contact[field] !== null
                                    ? contact[field].name
                                    : contact[field]
                        }
                      </td>
                    ))}
                  </div>
                </tr>
              ))}

            </tbody>
          </table>
        </div>

        {/* Pagination Controls - Sticky at the bottom */}
        <div className="sticky bottom-0 bg-white shadow-md p-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              {/* Previous arrow */}
              <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6 text-gray-600">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                </svg>
              </button>

              {/* Page dropdown */}
              <Select
                value={page}
                onChange={(e) => handlePageChange(e.target.value)}
                displayEmpty
                variant="outlined"
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  '& .MuiSelect-select': {
                    padding: '8px 25px',
                    width: "40px",
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#ccc',
                  },
                  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#007bff',
                    boxShadow: '0 0 5px rgba(0, 123, 255, 0.25)',
                  },
                  '& .MuiMenuItem-root': {
                    backgroundColor: 'white',
                  },
                  '& .Mui-selected': {
                    backgroundColor: '#e9f2ff',
                    fontWeight: 'bold',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      borderRadius: '8px',
                      backgroundColor: '#f9f9f9',
                      '&::-webkit-scrollbar': {
                        width: '6px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#aaa',
                        borderRadius: '10px',
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1',
                      },
                      '& .MuiMenuItem-root.Mui-selected': {
                        backgroundColor: '#e9f2ff !important',
                        color: "#000 !important",
                        borderRadius: "10px",
                        margin: "0px 5px"
                      },
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                {Array.from({ length: Math.min(totalPages, 100) }, (_, i) => i + 1).map((pageNumber) => (
                  <MenuItem key={pageNumber} value={pageNumber}>
                    {pageNumber}
                  </MenuItem>
                ))}
              </Select>

              {/* Next arrow */}
              <button onClick={() => handlePageChange(page + 1)} disabled={page === Math.min(totalPages, 100)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6 text-gray-600">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                </svg>
              </button>
              <span className="ml-4">{`${startRecord} - ${endRecord} of ${formatNumber(totalRecords)}`}</span>
            </div>
            <div>
              {selectedContacts.size > 0 && <Button size='xs' text='View Selected & Save' variant='primary' onClick={() => { setHideModal(false) }}></Button>}
            </div>
          </div>
        </div>
      </div>
      <ModalWrapper
        width={"90%"}
        height={"650px"}
        hideModal={hideModal}
        setHideModal={() => {
          setHideModal(true);
        }}
        bgcolor={"rgba(0, 0, 0, 0.8)"}
        padding="2%"
        overflow={"scroll"}
      >
        <div className="overflow-y-auto flex-grow" >
          <div style={{ textAlign: "center", fontSize: "20px" }} className='mb-4'>Selected Contacts</div>
          <div className='flex justify-center items-center gap-2'>

            {/* {"companyDomain":"","customEmployeeRange":{"min":"","max":""},"emailStatus":"Verified","employeeFilterOption":"predefined",
            "employeeRanges":"","jobTitle":"","location":"","name":"","revenueRange":{"min":"","max":""}} */}
            {selectedFilter?.name && <Tag text={"Name : " + selectedFilter?.name} color="white" bgColor="#4b5563a6"></Tag>}
            {selectedFilter?.jobTitle && <Tag text={"Job Title : " + selectedFilter?.jobTitle} color="white" bgColor="#4b5563a6"></Tag>}
            {selectedFilter?.companyDomain && <Tag text={"Company : " + selectedFilter?.companyDomain} color="white" bgColor="#4b5563a6"></Tag>}
            {selectedFilter?.location && <Tag text={"Location : " + selectedFilter?.location} color="white" bgColor="#4b5563a6"></Tag>}
            {(selectedFilter?.customEmployeeRange?.min || selectedFilter?.customEmployeeRange?.max) && <Tag text={"Employees : " + JSON.stringify(selectedFilter?.customEmployeeRange)} color="white" bgColor="#4b5563a6"></Tag>}
            {selectedFilter?.emailStatus && <Tag text={"Email Status : " + selectedFilter?.emailStatus} color="white" bgColor="#4b5563a6"></Tag>}
            {selectedFilter?.employeeFilterOption && <Tag text={"Employees : " + JSON.stringify(selectedFilter?.employeeFilterOption)} color="white" bgColor="#4b5563a6"></Tag>}
            {(selectedFilter?.employeeRanges?.min || selectedFilter?.employeeRanges?.max) && <Tag text={"Employees Range : " + JSON.stringify(selectedFilter?.employeeRanges)} color="white" bgColor="#4b5563a6"></Tag>}
            {(selectedFilter?.revenueRange?.min || selectedFilter?.revenueRange?.max) && <Tag text={"Revenue Range : " + JSON.stringify(selectedFilter?.revenueRange)} color="white" bgColor="#4b5563a6"></Tag>}
            {(selectedFilter?.organizationTradingStatus?.length>0) && <Tag text={"Organization Trading Status : " + JSON.stringify(selectedFilter?.organizationTradingStatus)} color="white" bgColor="#4b5563a6"></Tag>}
          </div>
          <table className="w-full text-left border-collapse my-3">
            <thead className="sticky top-0 bg-gray-100"> {/* Sticky header */}
              <tr className="hover:bg-gray-200 transition duration-200">
                <th className="px-4 py-2 text-sm font-bold text-gray-600"
                  style={{
                    position: 'sticky',
                    left: 0,
                    background: '#f3f4f6',
                    zIndex: 10,
                    width: '250px',
                    boxShadow: '8px 0px 16px 0px rgba(26, 26, 26, 0.08)',
                    borderRight: '1px solid transparent',
                  }}>&nbsp;Name
                </th>
                {["Job Title", "Company", "Email", "Phone Number", "Social", "Location", "Company Industries"].map(header => (
                  <th key={header} className="px-4 py-2 text-sm font-bold text-gray-600 w-72">{header}</th>
                ))}
              </tr>
            </thead>
            <tbody className="overflow-x-auto">
              {Array.from(selectedContacts?.entries()).map(([key, contact]) => (
                <tr key={contact.id} className="hover:bg-gray-200 transition duration-200">
                  <td
                    className="px-4 py-2 text-sm text-gray-600"
                    style={{
                      position: 'sticky',
                      left: 0,
                      background: '#fff',
                      zIndex: 5,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '250px',
                      boxShadow: '8px 0px 16px 0px rgba(26, 26, 26, 0.08)',
                      borderRight: '1px solid transparent',
                    }}
                  >
                    <div className='flex items-center gap-2'>
                      <a style={{ cursor: "pointer" }} onClick={() => { handleSelectContact(contact) }}><DeleteIcon></DeleteIcon></a>
                      <span className="underline">{contact.name}</span>
                    </div>
                  </td>
                  <div className="overflow-x-auto contents">
                    {["title", "organization", "email", "phone_numbers", "linkedin_url", "location", "functions"].map((field, index) => (
                      <td
                        key={index}
                        className="text-sm text-gray-600 w-72"
                        title={
                          field === "location"
                            ? [contact.state, contact.country].filter(Boolean).join(', ')
                            : field === "organization" && contact.organization
                              ? contact.organization.name
                              : Array.isArray(contact[field])
                                ? (typeof contact[field][0] === 'object' && contact[field][0] !== null
                                  ? contact[field][0].raw_number
                                  : contact[field].join(', '))
                                : typeof contact[field] === 'object' && contact[field] !== null
                                  ? contact[field].name
                                  : contact[field]
                        }
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          borderRight: '1px solid transparent',
                        }}
                      >
                        {
                          field === "linkedin_url"
                            ? (
                              <a href={contact.linkedin_url} target="_blank" rel="noopener noreferrer">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="#0077B5"
                                  width="20px"
                                  height="20px"
                                >
                                  <path d="M19 0h-14c-2.762 0-5 2.238-5 5v14c0 2.762 2.238 5 5 5h14c2.762 0 5-2.238 5-5v-14c0-2.762-2.238-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.268c-.966 0-1.75-.784-1.75-1.75s.784-1.75 1.75-1.75 1.75.784 1.75 1.75-.784 1.75-1.75 1.75zm13.5 11.268h-3v-5.5c0-1.38-.036-3.158-1.927-3.158-1.928 0-2.222 1.506-2.222 3.058v5.6h-3v-10h2.881v1.367h.041c.401-.763 1.384-1.524 2.855-1.524 3.053 0 3.619 2.008 3.619 4.621v5.536z" />
                                </svg>
                              </a>
                            )
                            : field === "organization" && contact.organization
                              ? (
                                <>
                                  {contact.organization.logo_url && (
                                    <img src={contact.organization.logo_url} alt={`${contact.organization.name} logo`} className="inline-block mr-2 h-6 w-6" />
                                  )}
                                  {contact.organization.name}
                                </>
                              )
                              : field === "location"
                                ? [contact.state, contact.country].filter(Boolean).join(', ')
                                : Array.isArray(contact[field])
                                  ? (typeof contact[field][0] === 'object' && contact[field][0] !== null
                                    ? contact[field][0].raw_number
                                    : contact[field].join(', '))
                                  : typeof contact[field] === 'object' && contact[field] !== null
                                    ? contact[field].name
                                    : contact[field]
                        }
                      </td>
                    ))}
                  </div>
                </tr>
              ))}

            </tbody>
          </table>
          <div>
            <Button size='xs' text='Save' variant='primary' onClick={saveContacts}></Button>
          </div>
        </div>

      </ModalWrapper>
    </>
  );
};

export default ApolloSearchTableHeaderAndBody;
