import * as React from "react";

  
  function DataRepositorytIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M22.9938 4.85938C22.9938 4.85938 22.9937 18.636 22.9937 19.7012C22.9937 21.5227 18.0716 22.9994 11.9998 22.9994C5.92801 22.9994 1.00588 21.5227 1.00588 19.7012C1.00588 18.68 1.00586 4.85938 1.00586 4.85938" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.9937 14.7422C22.9937 16.5637 18.0716 18.0404 11.9998 18.0404C5.92801 18.0404 1.00586 16.5637 1.00586 14.7422" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.9937 9.80469C22.9937 11.6262 18.0716 13.1029 11.9998 13.1029C5.92801 13.1029 1.00586 11.6262 1.00586 9.80469" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.9998 7.59636C18.0716 7.59636 22.9937 6.11971 22.9937 4.29818C22.9937 2.47665 18.0716 1 11.9998 1C5.92801 1 1.00586 2.47665 1.00586 4.29818C1.00586 6.11971 5.92801 7.59636 11.9998 7.59636Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
  }

export default DataRepositorytIcon;
