import * as React from "react";

function ExcelUploadIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0702 3.88189V3.15763L10.5581 3.66976L8.95912 5.26871C8.58429 5.64354 8.03626 5.64354 7.66143 5.26871C7.2866 4.89388 7.2866 4.34585 7.66143 3.97102L11.3513 0.281122C11.7262 -0.0937074 12.2742 -0.0937074 12.649 0.281122L16.3389 3.97102C16.7138 4.34585 16.7138 4.89388 16.3389 5.26871C16.2336 5.374 16.1403 5.44104 16.0448 5.48345C15.9507 5.52528 15.8396 5.54984 15.6901 5.54984C15.5406 5.54984 15.4294 5.52528 15.3353 5.48345C15.2399 5.44104 15.1465 5.374 15.0412 5.26871L13.4423 3.66976L12.9301 3.15763V3.88189V18.1495C12.9301 18.4503 12.8312 18.6777 12.6798 18.8291C12.5284 18.9805 12.301 19.0795 12.0002 19.0795C11.6994 19.0795 11.472 18.9805 11.3206 18.8291C11.1692 18.6777 11.0702 18.4503 11.0702 18.1495V3.88189ZM16.92 8.60977H19.38C21.3052 8.60977 22.7699 10.0744 22.7699 11.9997V20.6094C22.7699 22.5347 21.3052 23.9993 19.38 23.9993H4.62037C2.69511 23.9993 1.23047 22.5347 1.23047 20.6094V11.9997C1.23047 10.0744 2.69511 8.60977 4.62037 8.60977H7.08031C7.38112 8.60977 7.60849 8.70873 7.7599 8.86014C7.91131 9.01156 8.01027 9.23892 8.01027 9.53974C8.01027 9.84056 7.91131 10.0679 7.7599 10.2193C7.60849 10.3707 7.38112 10.4697 7.08031 10.4697H4.62037C4.18321 10.4697 3.79559 10.6167 3.51651 10.8958C3.23743 11.1749 3.0904 11.5625 3.0904 11.9997V20.6094C3.0904 21.0466 3.23743 21.4342 3.51651 21.7133C3.79559 21.9924 4.18321 22.1394 4.62037 22.1394H19.38C19.8171 22.1394 20.2048 21.9924 20.4838 21.7133C20.7629 21.4342 20.9099 21.0466 20.9099 20.6094V11.9997C20.9099 11.5625 20.7629 11.1749 20.4838 10.8958C20.2048 10.6167 19.8171 10.4697 19.38 10.4697H16.92C16.6192 10.4697 16.3919 10.3707 16.2404 10.2193C16.089 10.0679 15.9901 9.84056 15.9901 9.53974C15.9901 9.23892 16.089 9.01156 16.2404 8.86014C16.3919 8.70873 16.6192 8.60977 16.92 8.60977Z" fill="white"/>
        </svg>

    );
}

export default ExcelUploadIcon;
