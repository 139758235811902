import { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

// The filters are predefined as constants
const filters = [
  { label: 'Total', value: '291.0M' },
  { label: 'Net New', value: '290.8M' },
  { label: 'Saved', value: '204.1K' },
];

const jobTitleOptions = [
  "manager", "project manager", "teacher", "owner", "student",
  "director", "software engineer", "consultant", "account manager",
  "engineer", "professor", "sales manager", "sales", "partner",
  "associate", "president", "administrative assistant", "supervisor",
  "general manager", "realtor", "SDR Leader, CRO","Sales Development Director, VP or Head",
  "Sales Enablement Director, VP or Head","Revenue Operations Director, VP or Head","Sales Operations Director, VP or Head"
];

const employeeRanges = [
  "1-10",
  "11-20",
  "21-50",
  "51-100",
  "101-200",
  "201-500",
  "501-1000",
  "1001-2000",
  "2001-5000",
  "5001-10000",
];

const emailStatusOptions = ["verified", "guessed", "unavailable", "bounced", "pending_manual_fulfillment"];

const accordions = [
  { label: 'Name', filters: [] },
  { label: 'Job Titles', filters: [] },
  { label: 'Company', filters: [] },
  { label: 'Location', filters: [] },
  { label: '# Employees', filters: [] },
  { label: 'Email Status', filters: [] },
  { label: 'Revenue', filters: [] },
];

const FilterComponent = ({ selectedFilter, onFilterChange }) => {
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [nameInput, setNameInput] = useState('');
  const [selectedJobTitle, setSelectedJobTitle] = useState('');
  const [companyDomainInput, setCompanyDomainInput] = useState('');
  const [locationInput, setLocationInput] = useState('');
  const [selectedEmployeeRanges, setSelectedEmployeeRanges] = useState('');
  const [employeeFilterOption, setEmployeeFilterOption] = useState('predefined');
  const [customRange, setCustomRange] = useState({ min: '', max: '' });
  const [revenueRange, setRevenueRange] = useState({ min: '', max: '' });
  const [selectedEmailStatus, setSelectedEmailStatus] = useState('');
  const [organizationTradingStatus, setOrganizationTradingStatus] = useState([]);



  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setOrganizationTradingStatus((prevStatus) => [...prevStatus, value]);
    } else {
      setOrganizationTradingStatus((prevStatus) =>
        prevStatus.filter((status) => status !== value)
      );
    }
    console.log("organizationTradingStatusorganizationTradingStatus", organizationTradingStatus);
  }


  // Determine if an accordion should be opened based on active filters
  const hasActiveFilters = (label) => {
    switch (label) {
      case 'Name': return nameInput.trim() !== '';
      case 'Job Titles': return selectedJobTitle !== '';
      case 'Company': return companyDomainInput.trim() !== '';
      case 'Location': return locationInput.trim() !== '';
      case '# Employees':
        return selectedEmployeeRanges !== '' ||
          employeeFilterOption === 'custom' && (customRange.min || customRange.max);
      case 'Email Status': return selectedEmailStatus !== '';
      default: return false;
    }
  };

  // Handle accordion open/close
  const handleAccordionToggle = (accordionLabel) => {
    setExpandedAccordion((prev) =>
      prev === accordionLabel ? null : accordionLabel
    );
  };

  // Gather all filter values and call the provided onFilterChange function
  const applyFilters = () => {
    const filterValues = {
      name: nameInput,
      jobTitle: selectedJobTitle,
      companyDomain: companyDomainInput,
      location: locationInput,
      employeeFilterOption,
      employeeRanges: selectedEmployeeRanges,
      customEmployeeRange: customRange,
      emailStatus: selectedEmailStatus,
      revenueRange: revenueRange,
      organizationTradingStatus: organizationTradingStatus,
    };
    onFilterChange(filterValues);
  };

  const handleEmployeeRangeChange = (range) => {
    setSelectedEmployeeRanges(range);
  };

  // Switch between predefined, custom, and unknown employee filter options
  const handleEmployeeFilterOptionChange = (option) => {
    setEmployeeFilterOption(option);
    setSelectedEmployeeRanges("");
    setCustomRange({ min: '', max: '' });
  };

  return (
    <div className="flex flex-col p-4 bg-gray-100 w-full text-[0.875rem]">
      <div className="flex-grow overflow-x-auto">
        {accordions.map((accordion) => {
          const isOpen = expandedAccordion === accordion.label || hasActiveFilters(accordion.label);
          return (
            <div key={accordion.label} className="mb-4">
              <button
                className="flex justify-between w-full p-4 bg-white hover:bg-gray-200"
                onClick={() => handleAccordionToggle(accordion.label)}
              >
                <span>{accordion.label}</span>
                <span>{isOpen ? '-' : '+'}</span>
              </button>

              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${isOpen ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                  }`}
              >
                <div className="p-4 bg-gray-200">
                  {accordion.label === 'Name' && (
                    <input
                      type="text"
                      placeholder="Enter name"
                      value={nameInput}
                      onChange={(e) => setNameInput(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded"
                      style={{ fontSize: '0.75rem' }}
                    />
                  )}
                  {accordion.label === 'Job Titles' && (
                    <Autocomplete
                      freeSolo
                      options={jobTitleOptions}
                      value={selectedJobTitle}
                      onChange={(event, newValue) => setSelectedJobTitle(newValue || '')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Select or Add Job Title"}
                          variant="outlined"
                          className="w-full"
                          InputLabelProps={{
                            style: { fontSize: '0.75rem' },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { padding: '5px 5px', fontSize: '0.75rem', backgroundColor: "#fff" },
                          }}
                        />
                      )}
                    />
                  )}
                  {accordion.label === 'Company' && (
                    <input
                      type="text"
                      placeholder="Enter Company Domain"
                      value={companyDomainInput}
                      onChange={(e) => setCompanyDomainInput(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded"
                      style={{ fontSize: '0.75rem' }}
                    />
                  )}
                  {accordion.label === 'Location' && (
                    <input
                      type="text"
                      placeholder="Enter Location"
                      value={locationInput}
                      onChange={(e) => setLocationInput(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded"
                      style={{ fontSize: '0.75rem' }}
                    />
                  )}
                  {accordion.label === '# Employees' && (
                    <div className="flex flex-col space-y-2 mb-4">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          value="predefined"
                          checked={employeeFilterOption === 'predefined'}
                          onChange={() => handleEmployeeFilterOptionChange('predefined')}
                          className="mr-2"
                          style={{ fontSize: '0.75rem' }}
                        />
                        Predefined Range
                      </label>
                      {employeeFilterOption === 'predefined' &&
                        employeeRanges.map((range) => (
                          <div key={range} className="flex items-center">
                            <input
                              type="radio"
                              value={range}
                              checked={selectedEmployeeRanges === range}
                              onChange={() => handleEmployeeRangeChange(range)}
                              className="mr-2"
                              style={{ fontSize: '0.25rem', marginLeft: "15px" }}
                            />
                            <label style={{ fontSize: '0.75rem' }}>{range}</label>
                          </div>
                        ))}
                      <label className="flex items-center">
                        <input
                          type="radio"
                          value="custom"
                          checked={employeeFilterOption === 'custom'}
                          onChange={() => handleEmployeeFilterOptionChange('custom')}
                          className="mr-2"
                          style={{ fontSize: '0.75rem' }}
                        />
                        Custom Range
                      </label>
                      {employeeFilterOption === 'custom' && (
                        <div className="mt-4 flex space-x-2">
                          <input
                            type="number"
                            placeholder="Min"
                            value={customRange.min}
                            onChange={(e) => setCustomRange({ ...customRange, min: e.target.value })}
                            className="w-1/2 p-2 border border-gray-300 rounded"
                            style={{ fontSize: '0.75rem' }}
                          />
                          <input
                            type="number"
                            placeholder="Max"
                            value={customRange.max}
                            onChange={(e) => setCustomRange({ ...customRange, max: e.target.value })}
                            className="w-1/2 p-2 border border-gray-300 rounded"
                            style={{ fontSize: '0.75rem' }}
                          />
                        </div>
                      )}
                      <label className="flex items-center">
                        <input
                          type="radio"
                          value="unknown"
                          checked={employeeFilterOption === 'unknown'}
                          onChange={() => handleEmployeeFilterOptionChange('unknown')}
                          className="mr-2"
                          style={{ fontSize: '0.75rem' }}
                        />
                        # of Employees Unknown
                      </label>
                    </div>
                  )}
                  {accordion.label === 'Email Status' && (
                    <Autocomplete
                      freeSolo
                      options={emailStatusOptions}
                      value={selectedEmailStatus}
                      onChange={(event, newValue) => setSelectedEmailStatus(newValue || '')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Select or Add Email Status"}
                          variant="outlined"
                          className="w-full"
                          InputLabelProps={{
                            style: { fontSize: '0.75rem' },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { padding: '5px 5px', fontSize: '0.75rem', backgroundColor: "#fff" },
                          }}
                        />
                      )}
                    />
                  )}

                  {accordion.label === 'Revenue' && (
                    <div>
                      <div className="mt-2 flex space-x-2">
                        <select
                          value={revenueRange.min}
                          onChange={(e) => setRevenueRange({ ...revenueRange, min: e.target.value })}
                          className="w-1/2 p-2 border border-gray-300 rounded bg-white"
                          style={{ fontSize: '0.75rem' }}
                        >
                          <option value="" disabled>Min</option>
                          <option value="100000">0.1M</option>
                          <option value="500000">0.5M</option>
                          <option value="1000000">1M</option>
                          <option value="5000000">5M</option>
                          <option value="10000000">10M</option>
                          <option value="25000000">25M</option>
                          <option value="50000000">50M</option>
                          <option value="100000000">100M</option>
                          <option value="500000000">500M</option>
                          <option value="500000000">1B</option>
                          <option value="500000000">5B</option>
                          <option value="5000000000">10B</option>
                        </select>
                        <select
                          value={revenueRange.max}
                          onChange={(e) => setRevenueRange({ ...revenueRange, max: e.target.value })}
                          className="w-1/2 p-2 border border-gray-300 rounded bg-white"
                          style={{ fontSize: '0.75rem' }}
                        >
                          <option value="" disabled>Max</option>
                          <option value="100000">0.1M</option>
                          <option value="500000">0.5M</option>
                          <option value="1000000">1M</option>
                          <option value="5000000">5M</option>
                          <option value="10000000">10M</option>
                          <option value="25000000">25M</option>
                          <option value="50000000">50M</option>
                          <option value="100000000">100M</option>
                          <option value="500000000">500M</option>
                          <option value="500000000">1B</option>
                          <option value="500000000">5B</option>
                          <option value="5000000000">10B</option>
                        </select>
                      </div>
                    <hr style={{color:"#fff"}}></hr>
                      <div className="mt-4 mx-2 flex flex-col gap-2 items-start">
                        <div>
                          <label>
                            <input
                              checked={organizationTradingStatus.includes("private")}
                              onChange={handleCheckboxChange}
                              type="checkbox"
                              name="privateCompany"
                              value={"private"}
                            />{' '}Private Company</label>
                        </div>
                        <div>
                          <label>
                            <input
                              checked={organizationTradingStatus.includes("public")}
                              onChange={handleCheckboxChange}
                              type="checkbox"
                              name="publicCompany"
                              value={"public"}
                            />{' '}Public Company</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Sticky Buttons */}
      <div className="sticky bottom-0 bg-white shadow-md p-4">
        <div className="flex flex-row justify-between">
          <button
            onClick={() => {
              setNameInput('');
              setSelectedJobTitle('');
              setCompanyDomainInput('');
              setLocationInput('');
              setSelectedEmployeeRanges('');
              setEmployeeFilterOption('predefined');
              setCustomRange({ min: '', max: '' });
              setSelectedEmailStatus('');
              setExpandedAccordion(null);
              setRevenueRange({ max: "", min: "" })
              setOrganizationTradingStatus([])
            }}
            className="px-4 py-2 bg-gray-300 hover:bg-gray-400 disabled:opacity-50 rounded-lg"
          >
            Clear
          </button>
          <button onClick={applyFilters} className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg">
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;

