import React, { useState } from "react";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import {
  EditableDivContainer,
  EditableDivEndButton,
  EditableDivStartButton,
} from "./style";
// import SendIconButton from "../../Insights/Components/SendIconButton";

import { MentionsInput, Mention } from "react-mentions";
import { Backdrop, Box, IconButton, Skeleton, Typography } from "@mui/material";
import AIIconButton from "./AIIconButton";
import { useAppSelector } from "hooks";
import SendIconButton from "./SendIconButton";
import SmartText from "./SmartText";
import { axiosHelperCore } from "utils";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

interface TagEditorProps {
  onTagAdded: (responseData: {
    customData: string;
    repoIds: string[];
    query: string;
  }) => void;
  query: string;
  setQuery: (value: string) => void;
  customData: string;
  setCustomData: (value: string) => void;
  tagList: string[];
  setOriginalQuery?:(value: string) => void; 
  originalQuery?: string; 
}

const TagEditor: React.FC<TagEditorProps> = ({
  onTagAdded,
  query,
  setQuery,
  setCustomData,
  customData,
  tagList,
  setOriginalQuery,
  originalQuery
}) => {

    const userId = useAppSelector(
        (state) => state.profileState.profileData.userId
    );
    const [loading, setLoading] = useState(false);

//   const [generateCustomData, { isLoading }] = useGenerateCustomDataMutation();
//   const { data: tagList } = useGetDataRepositoryTagsQuery({ userId });

const generateCustomData = async (payload) => {
    try {
        setLoading(true);
        const obj =  payload;
        const response = await axiosHelperCore({
            url: `rapport/user/generateCustomData`,
            method: "POST",
            JSONData: obj
        });

        setLoading(false);
        return response;
    } catch (error) {
      console.error('Error calling ML API:', error);
    }
};

  const handleSubmit = async () => {
    const pattern = /@\{\s*([^\}]+)\s*\}/g;

    // Extract tags using matchAll
    const tags = [...query.matchAll(pattern)].map((match) => match[1]);

    const payload = {
      userId,
      query,
      tags,
    };

    const response = await generateCustomData({ payload });
    setCustomData(response?.data?.result?.customData);
    onTagAdded({ ...response?.data?.result, query });
    if (setOriginalQuery) {
        setOriginalQuery(query);
    }
  };

  const shouldShowCustomDataEnterMsg = () => {
    return (query && !customData) || (query != originalQuery);
  };

  return (
    <>
        {loading && 
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
            open={true}
        >
          <MemoRevenoidLoader />
        </Backdrop>}
      <EditableDivContainer>
        <EditableDivStartButton>
          <AIIconButton />
        </EditableDivStartButton>

        <MentionsInput
          placeholder="Add your prompt here with appropriate tags E.g: Pull data related to @Al in the case study"
          value={query}
          onChange={(evt) => setQuery(evt.target.value)}
          style={{
            width: "100%",
            control: {
              backgroundColor: "#fff",
              fontSize: 14,
              fontWeight: "normal",
              width: "100%",
            },

            "&multiLine": {
              control: {
                minHeight: 63,
                border: 'none !important',
                outline: 'none',
              },
              highlighter: {
                padding: "9px 0px",
                border: "none",
              },
              input: {
                padding: "9px 0px",
                border: "none",
                outline: 'none',
              },
            },

            suggestions: {
              maxHeight: "200px",
              overflow: "scroll",
              list: {
                backgroundColor: "white",
                border: "1px solid rgba(0,0,0,0.15)",
                fontSize: 14,
              },
              item: {
                padding: "5px 15px",
                borderBottom: "1px solid rgba(0,0,0,0.15)",
                "&focused": {
                  backgroundColor: "#cee4e5",
                },
              },
            },
          }}
        >
          <Mention
            markup="@{__display__}"
            trigger="@"
            data={(tagList || []).map((tag: string) => ({
              id: tag,
              display: tag,
            }))}
            onAdd={(val) => {
              console.log("onAdd called", val);
            }}
            style={{ backgroundColor: "#cee4e5" }}
            renderSuggestion={(
              _suggestion,
              _search,
              highlightedDisplay,
              _index,
              focused
            ) => (
              <div className={`user ${focused ? "focused" : ""}`}>
                {highlightedDisplay}
              </div>
            )}
          />
        </MentionsInput>

        <EditableDivEndButton>
          <SendIconButton disabled={loading} onClick={handleSubmit} />
        </EditableDivEndButton>
      </EditableDivContainer>
      {shouldShowCustomDataEnterMsg() && <Typography fontSize={"12px"} color={"red"} fontWeight={"600"} paddingBottom={"5px"} paddingTop={"10px"}>Click send icon to generate custom data</Typography>}
      {(customData || loading) && (
        <Box marginBlock={"10px"}>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"5px"}
            marginBottom={"10px"}
          >
            <Typography>
              <AIIconButton /> Custom Data
            </Typography>
          </Box>

          {loading ? (
            <Box>
              <Skeleton height={"20px"} width={"100%"} />
              <Skeleton height={"20px"} width={"50%"} />
            </Box>
          ):(

          <SmartText text={customData || ""} length={120} />
          )}
        </Box>
      )}
    </>
  );
};

export default TagEditor;