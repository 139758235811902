import { axiosHelperCore } from "utils";
import AccountReportTableHeader from "./AccountReportTableHeader";
import React, { useEffect, useRef, useState } from "react";
import DateRangePicker from "./component/CalenderComponent";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import Select from 'react-select';
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Backdrop } from "@mui/material";
import { getDatesBetweenDates } from "utils/constants";
import { useAppSelector } from "hooks";
import * as XLSX from "xlsx";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";


function AccountReportTable() {

    const [dateRange, setDateRange] = useState([]);
    const [memberData, setMemberData] = useState([]);
    const [accountData, setAccountData] = useState({});
    const [adminList, setAdminList] = useState([]);
    const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [loading, setLoading] = useState(false);
    const [noDataFound, setNoDataFound] = useState(false);
    const loggedInMemberDetails = useAppSelector((state) => state.profileState.profileData);
    const tableRef = useRef();

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99,
        }),
        control: (provided) => ({
            ...provided,
            zIndex: 99,
        }),
        valueContainer: (provided) => ({
            ...provided,
            zIndex: 99,
        }),
    };

    const fetchReport = async () => {
        setLoading(true);
        const sDate = startDate.getFullYear() + "-" + (("0" + (startDate.getMonth() + 1)).slice(-2)) + "-" + ("0" + startDate.getDate()).slice(-2);
        const eDate = endDate.getFullYear() + "-" + (("0" + (endDate.getMonth() + 1)).slice(-2)) + "-" + ("0" + endDate.getDate()).slice(-2);
        const dates = getDatesBetweenDates(sDate, eDate);
        dates.pop();
        setDateRange(dates);
        const organizationId = selectedAdmin?.value?.split("$$$")[1];

        const result = await axiosHelperCore({
            url: `rapport/report/getAccountReport`,
            method: "POST",
            JSONData: { startDate: sDate, endDate: eDate, organizationId: organizationId }
        });
        setAccountData(result?.data);
        setLoading(false);
        setNoDataFound(Object.keys(result?.data).length == 0);

    }

    const getMemberList = async (organizationId = "") => {
        const members = await axiosHelperCore({
            url: `rapport/user/getOranizationMemberList`,
            method: "POST",
            JSONData: { organizationId: organizationId ? organizationId : loggedInMemberDetails.organizationId }
        });
        setMemberData(members?.data?.data)
    }

    const fetchAdminMemberList = async () => {
        try {
            return axiosHelperCore({
                url: `rapport/user/fetchAdminMemberList`,
                method: "POST",
                JSONData: {}
            });
        }
        catch (error) {
            console.error("Error fetching company list", error);
        }
    };

    const fetchData = async () => {
        try {
            const [adminListResult] = await Promise.all([fetchAdminMemberList()]);
            let admins = []
            adminListResult?.data?.data.forEach(user => {
                admins.push({ value: user.email + "$$$" + user?.organizationId, label: user?.firstName + " " + user?.lastName + " <" + user?.email + ">", organizationId: user?.organizationId })
            });

            let defaultValue = admins.find(u => u?.organizationId === loggedInMemberDetails.organizationId)

            setSelectedAdmin(defaultValue ? { value: defaultValue?.value, label: defaultValue?.label } : admins[0])
            setAdminList(admins);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        if (startDate && endDate) {
            fetchReport();
        }
    }, [startDate, endDate])


    useEffect(() => {
        fetchReport();
    }, [memberData])

    useEffect(() => {
        fetchData();
        getMemberList();
    }, [])

    const exportToExcel = () => {
        var worksheet = XLSX.utils.table_to_sheet(tableRef.current);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet 1');
        XLSX.writeFile(wb, "account-report.xlsx");
        setLoading(false);
    }


    return (
        <>
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}>
                <MemoRevenoidLoader />
            </Backdrop>}
            <Flex justifyContent={"right"} marginBottom={"20px"} gap={"10px"} alignItems={"center"}>
                <Select
                    className="single-select"
                    placeholder="Select admin user"
                    name="memberList"
                    value={selectedAdmin}
                    options={adminList}
                    onChange={(selectedOption) => {
                        if (selectedOption) {
                            setSelectedAdmin(selectedOption);
                            getMemberList(selectedOption.value.split("$$$")[1]);
                        }
                    }}
                    styles={customStyles}
                />
                <DateRangePicker startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}></DateRangePicker>
                <Button size="s" variant="primary" borderRadius={"15px"} marginLeft={"10px"} onClick={exportToExcel}>
                    Export
                </Button>
            </Flex>
            {noDataFound ? <div className="p-2 bg-white">
                No data found
            </div> : <AccountReportTableHeader tableRef={tableRef} memberData={memberData} accountData={accountData} dateRange={dateRange}></AccountReportTableHeader>}
        </>
    )
}

export default AccountReportTable;