import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { useNavigate } from "react-router-dom";
import { AeSidebarTagCollapse } from "./AeSidebarTagCollapse";
import { useEffect } from "react";

export interface AeSidebarAdminSectionCollapseProps {
  activeTab:
  | "home"
  | "opportunities"
  | "stakeholders"
  | "teamMembers"
  | "myPlan"
  | "fieldMapping"
  | "emailTemplate"
  | "history"
  | "nudges"
  | "analytics";
}

export const AeSidebarAdminSectionCollapse: React.FC<
  AeSidebarAdminSectionCollapseProps
> = ({ activeTab }) => {
  const navigate = useNavigate();

  

  return (
    <Flex
      flexDirection={"column"}
      m="30px 10px 24px 10px"
      color="white"
      justifyContent={"space-between"}
      height="100%"
      overflow={"scroll"}
      style={{ scrollbarWidth: "none" }}
    >
      <Grid gridRowGap={"20px"} justifyContent="center">
        <AeSidebarTagCollapse
          active={activeTab === "nudges"}
          iconName={"aeSideHomeIcon"}
          onTagClick={() => navigate("/aeReturnDashboard")}
        />
        <AeSidebarTagCollapse
          active={activeTab === "opportunities"}
          iconName={"aeSideOppoIcon"}
          onTagClick={() => navigate("/aeReturnProfile")}
        />
        <AeSidebarTagCollapse
          active={activeTab === "teamMembers"}
          iconName={"adminSideTeamIcon"}
        />
        <Flex border="1px solid rgba(255, 255, 255, 0.1)" />
        <AeSidebarTagCollapse
          active={activeTab === "myPlan"}
          iconName={"adminSidePlanIcon"}
        />
        <AeSidebarTagCollapse
          active={activeTab === "fieldMapping"}
          iconName={"adminSideFieldIcon"}
        />
        <AeSidebarTagCollapse
          active={activeTab === "emailTemplate"}
          iconName={"aeSideEmailIcon"}
        />
      </Grid>
      {/* <Grid gridRowGap={"25px"} justifyContent="center">
        <Flex border="1px solid rgba(255, 255, 255, 0.1)" />
        <AeSidebarTagCollapse iconName={"aeSideHelpIcon"} />
      </Grid> */}
    </Flex>
  );
};
