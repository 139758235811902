import { Box, Button, ButtonBase, Typography, Switch, styled } from "@mui/material";


const SettingAdminBadge = styled(Box)({
    backgroundColor: '#8D68F6F6',
    color: '#fff',
    padding: '2px 10px',
    borderRadius: '30px',
    fontSize: '14px',
    lineHeight: '150%'
});


const Container = styled(Box)({
    width: "600px",
    height: "max-content",
    borderRadius: "8px",
});

const Container1 = styled(Box)({
    display: 'flex',
    justifyContent: "space-between",
    margin: '32px 32px 0px 32px'
});

const Container1Title = styled(Box)({
    fontSize: "16px",
    fontWeight: 600
});
const Container1Version = styled(Box)({
    borderRadius: "100px",
    backgroundColor: "#FFF",
    padding: "3px 10px",
    fontSize: "12px",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "18px",
});

const PitchContainer = styled(Box)({
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    margin: '20px 20px 12px 20px',
})

const SaveButton = styled(Button)({
    textTransform: 'capitalize',
    borderRadius: '27px',
    fontSize: '14px',
    padding: '0px 15px'
})

const SettingCard = styled(Box)({
    borderRadius: "8px",
    // margin: "6px 32px 6px 32px",
    backgroundColor: '#FFF'
})

const SettingContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column'
})

const SettingProfileContainer = styled(Box)({
    borderRadius: '8px',
    marginInline: '32px',
    marginTop: '18px',
    marginBottom: '6px',
    paddingBlock: '18px',
    background: '#fff'
})

const SettingPersonProfile = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '10px 20px',
})

const Flex = styled(Box)({
    display: 'flex',
})


const SettingTitle = styled(Box)({
    fontSize: '16px',
    fontWeight: '500',
    marginBottom: '4px',
    marginRight: '10px'
})

const TextMuted = styled(Typography)({
    fontSize: '14px',
    marginTop: '5px',
    color: '#57585C'
})


const WebsiteListContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px',
    marginTop: '15px'
})

const WebsiteContainer = styled(Box)({
    display: 'flex',
    gap: '9px',
    alignItems: 'center',
    marginRight: '15px'
})


const Text1 = styled(Typography)({
    color: '#57585C',
    fontSize: '10px',
    fontWeight: 400,
    marginTop: '8px',
    lineHeight: 'normal'
})

const Tags = styled(ButtonBase)({
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '140%',
    color: '#0094E3',
    padding: '5px 11px',
    borderRadius: '27px',
    border: '1px solid  #0094E3',
    display: 'flex',
    gap: '4px',
    '& > svg ': {
        display: 'none'
    },
    '&:hover': {
        backgroundColor: '#0094E3',
        color: '#fff',
    },
    '&:hover svg': {
        display: 'block'
    }
})

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
}));


export {
    SettingAdminBadge,
    Container,
    Container1,
    Container1Title,
    Container1Version,
    PitchContainer,
    SaveButton,
    SettingCard,
    SettingContainer,
    SettingProfileContainer,
    SettingPersonProfile,
    Flex,
    SettingTitle,
    TextMuted,
    WebsiteListContainer,
    WebsiteContainer,
    Text1,
    Tags,
    AntSwitch
}