import React, { useEffect, useState } from "react";
import MemberListingTableBody from "./MemberListingTableBody";
import MemberListingTableHeader from "./MemberListingTableHeader";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import userService from "services/userService";
import { Backdrop } from "@mui/material";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { ErrorMessage, Form, Formik } from 'formik';
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Dropdown } from "@evabot/eva-react-core-library/dist/atoms/Dropdown/Dropdown";
import * as yup from 'yup';
import randomstring from 'randomstring';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import Select from 'react-select';
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { useAppSelector } from "hooks";
import { setMemberListingData } from "./store/memberListingReducer";
import { useDispatch } from "react-redux";
import AddMemberForm from "./component/AddMemberForm";
import downloadImage from "../../assets/images/download.png";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";


const AddMemberSchema = yup.object().shape({
    organizationName: yup.string().when('addmembertype', {
        is: 'child',
        then: yup.string().required('Please select an organization.'),
        otherwise: yup.string(),
    }),
    firstName: yup.string().required("First Name is required."),
    lastName: yup.string(),
    email: yup
        .string()
        .trim()
        .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid email address.")
        .required("Email is required."),
    userType: yup.string().required("Please select a user type."),
    addmembertype: yup.string().required("Please select a member type."),
    menu: yup.array().min(1, "Please select at least one menu option."),
});

const MenuValue = [
    "analytics",
    "integration",
    "tag",
    "stakeholders",
    "history",
    "home",
];

const usertypeOptions = [
    { value: "admin", label: "Admin" },
    { value: "sdr", label: "SDR" },
    { value: "ae", label: "AE" }
];

const addmembertypeOptions = [
    { value: "owner", label: "Owner" },
    { value: "child", label: "Child" },
];

function MemberListingTable() {
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [appVersion, setAppVersion] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [AdminMemberList, setAdminMemberList] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState("");
    const [selectedMemberType, setSelectedMemberType] = useState("");
    const [password, setPassword] = useState('');
    const [hashPassword, setHashPassword] = useState('');
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [showSnackBarModal, setShowSnackBarModal] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [hideModal, setHideModal] = useState(true);
    const [showOrganizationDropdown, setShowOrganizationDropdown] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [email, setEmail] = useState('');
    const [copied, setCopied] = useState(false);
    const [copiedPassword, setCopiedPassword] = useState('');
    const [crmMessage, setCrmMessage] = useState("");
    const dispatch = useDispatch();

    const [appVersionCreteria, setAppVersionCreteria] = useState({ value: '=', label: '=' });


    const allMemberData = useAppSelector(
        (state) => state.memberListingState?.memberListingData
    );


    const data = [
        { id: 1, name: 'John Doe', email: 'john.doe@example.com' },
        { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com' },
        // Add more data as needed
    ];

    function convertToCSV(arr) {

        const array = [Object.keys(arr[0])].concat(arr);

        return array.map(row => {
            return Object.values(row).map(value => `"${value}"`).join(',');
        }).join('\n');
    }

    function downloadCSV(csv, filename) {
        const csvFile = new Blob([csv], { type: 'text/csv' });
        const downloadLink = document.createElement('a');
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }


    const exportData = () => {

        let exportedData = filteredMembers.map(d => ({
            userId: d?.userId || "N/A",
            organizationId: d?.organizationId || "N/A",
            firstName: d?.firstName || "N/A",
            lastName: d?.lastName || "N/A",
            email: d?.email || "N/A",
            appVersion: d?.appVersion || "N/A",
            userType: d?.userType || "N/A",
            menu: d?.menu || "N/A",
            integration: d?.integration[0]?.type || "N/A",
            userStatus: d?.isActive === false && d?.isDeleted === false ? "Pending" : (d?.isActive === true && d?.isDeleted === false ? "Active" : "Deactivated")

        }))

        const csv = convertToCSV(exportedData);
        console.log("filteredMembers", exportedData);
        downloadCSV(csv, 'memberData.csv');
    }

    const fetchReport = async () => {
        try {
            setLoading(true);
            userService.getAllMember()
                .then((response) => {
                    setMembers(response?.data);
                    dispatch(setMemberListingData(response?.data));
                    console.log("response?.dataresponse?.dataresponse?.data", response?.data);
                    setFilteredMembers(response?.data);
                    setLoading(false);
                    setSearchTerm("");
                })
        } catch (error) {
            console.error("Error fetching report:", error);
            setLoading(false);
        }

    };

    const fetchAdminMemberList = async () => {
        try {
            userService.fetchAdminMemberList()
                .then((response) => {
                    const adminMemberList = response?.data
                        .filter(item => item.organizationId)
                        .map(({ organizationId, firstName, lastName, email }) => ({
                            value: organizationId + "$$$" + email,
                            label: `${firstName} ${lastName} <${email}>`
                        }));
                    setAdminMemberList(adminMemberList);
                })
        }
        catch (error) {
            console.error("Error fetching company list", error);
        }
    };

    const applyFilterAndSearch = () => {
        if (members.length == 0)
            return;
        let filteredData = members;
        if (searchTerm.trim() !== "") {
            const searchTermLowerCase = searchTerm.toLowerCase().trim();
            filteredData = filteredData.filter(item =>
                item?.firstName?.toLowerCase().includes(searchTermLowerCase) || item?.lastName?.toLowerCase().includes(searchTermLowerCase) || item?.email?.toLowerCase().includes(searchTermLowerCase)
                || item?.organizationName?.includes(searchTermLowerCase)
            );
        }

        if (appVersion.trim() !== "") {
            if (appVersionCreteria.value == "=") {
                filteredData = filteredData.filter(item =>
                    item.appVersion != "N/A" && item.appVersion == appVersion
                );
            }
            else if (appVersionCreteria.value == "<=") {
                filteredData = filteredData.filter(item =>
                    item.appVersion != "N/A" && item.appVersion <= appVersion
                );
            }
            else {
                filteredData = filteredData.filter(item =>
                    item.appVersion != "N/A" && item.appVersion >= appVersion
                );
            }
        }

        setFilteredMembers(filteredData);
    }

    const handleClear = () => {
        setSearchTerm("");
        applyFilterAndSearch();
    }

    const handleAddMember = (value, selectedCompanyId) => {
        if (value?.addmembertype === 'child') {
            const orgId = value?.organizationName.split("$$$");
            let userId = "";
            const userIdList = allMemberData.filter(item => item.organizationId === orgId[0] && (item.userType === 'admin' || item.userType === 'superadmin'));
            if (userIdList.length > 0) {
                userId = userIdList[0].userId;
            }
        }
        try {
            console.log(value);
            const generatedPassword = randomstring.generate({
                length: 10,
                charset: 'alphanumeric',
            });
            setHashPassword(generatedPassword);
            setPassword(generatedPassword);

            const newValue = {
                password: generatedPassword,
                ...(value.addmembertype !== 'owner' ? { organizationId: selectedCompanyId.split("$$$")[0] } : { organizationId: "" }),
                ...value
            }

            newValue.menu = value.menu.map(item => item.value);

            newValue.email = newValue?.email?.toLowerCase();

            setEmail(newValue.email);
            userService.addUser(newValue)
                .then((response) => {
                    if (response?.message) {
                        setSnackbarMessage('Member Already Exist');
                        setSnackbarType('error');
                        setShowSnackBarModal(true);
                    } else if (response?.success === true && response?.user?.identityContact?.email) {
                        setCrmMessage(response?.CRMmessage);
                        setSnackbarMessage('Member has been added successfully.');
                        setShowSnackBar(true);
                        setSnackbarType('success');
                        setShowDetailsModal(true);
                        fetchReport();
                        fetchAdminMemberList();

                    } else {
                        setSnackbarMessage('Error adding Member. Please try again later');
                        setShowSnackBar(true);
                        setSnackbarType('error');
                        setHideModal(true);
                    }
                })
        } catch (error) {
            console.error("Error adding member", error)
        }
    }

    const handleCopyDetails = () => {
        navigator.clipboard.writeText(`Email: ${email}\nPassword: ${password}`);
        setCopied(true);
        setCopiedPassword(password);
    };

    useEffect(() => {
        fetchReport();
        fetchAdminMemberList();
    }, []);

    useEffect(() => {
        applyFilterAndSearch();
    }, [searchTerm, appVersion, appVersionCreteria]);

    const showClearIcon = searchTerm.length > 0;

    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            <Box>
                {loading && <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <MemoRevenoidLoader />
                </Backdrop>}
                <div>
                    <Flex justifyContent={"flex-end"} alignItems="center" marginBottom={"20px"} gap="5px">
                        <Text fontSize={"12px"} marginRight={"15px"} alignSelf={"center"}>Filter By : </Text>
                        <Flex>
                            {/* <Text fontWeight="400" fontSize="24px">Members List</Text> */}
                            <Select
                                styles={{
                                    control: (provided: any) => ({
                                        ...provided,
                                        minHeight: '40px', // Change this value to increase the height
                                    }),
                                    valueContainer: (provided: any) => ({
                                        ...provided,
                                        height: '40px', // Ensure this matches the control minHeight
                                        display: 'flex',
                                        alignItems: 'center',
                                    }),
                                    input: (provided: any) => ({
                                        ...provided,
                                        margin: '0px',
                                        padding: '0px', // Ensure the input has no additional padding/margin
                                    }),
                                    indicatorsContainer: (provided: any) => ({
                                        ...provided,
                                        height: '40px',
                                    }),
                                }}
                                value={appVersionCreteria}
                                options={[
                                    { value: '=', label: '=' },
                                    { value: '<=', label: '<=' },
                                    { value: '>=', label: '>=' }
                                ]}

                                onChange={(option) => { setAppVersionCreteria(option) }}
                            />

                            <TextField variant="outlined"
                                InputProps={{
                                    style: {
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "10px",
                                        background: "#FFFFFF",
                                        borderRadius: "6px",
                                        height: "43px",
                                        position: "relative",
                                        color: "#ffffff",
                                    }
                                }}
                                style={{ width: "200px" }} type="number" onChange={(e) => setAppVersion(e.target.value)} placeholder="app version"></TextField>
                        </Flex>
                        <Flex style={{ zIndex: "99" }} justifyContent={"space-between"} alignItems={"center"}>
                            <Flex marginRight={"20px"}>

                                <TextField
                                    placeholder="Name/ Email/ Organization"
                                    variant="outlined"
                                    style={{ width: "300px" }}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={showClearIcon ? handleClear : () => { }}>
                                                {showClearIcon ? <ClearIcon /> : <SearchIcon />}
                                            </IconButton>
                                        ),
                                        style: {
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "10px",
                                            background: "#FFFFFF",
                                            borderRadius: "6px",
                                            height: "43px",
                                            position: "relative",
                                            color: "#ffffff",
                                        }
                                    }}
                                />
                            </Flex>
                            <Flex justifyContent={"flex-end"}>
                                <Button
                                    size="m"
                                    variant="secondary"
                                    borderRadius={"15px"}
                                    fontSize={"14px"}
                                    iconName='add'
                                    onClick={() => { setHideModal(false); fetchAdminMemberList(); }}
                                >
                                    Add Member
                                </Button>
                            </Flex>
                            <Flex>
                                <a title="Download" onClick={exportData} style={{ cursor: "pointer" }}>
                                    <svg fill="rgb(0, 148, 227)"
                                        width="30px" viewBox="0 0 360 360"
                                        preserveAspectRatio="xMidYMid meet">

                                        <g transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)"
                                            fill="rgb(0, 148, 227)" stroke="none">
                                            <path d="M1794 3003 c-18 -9 -41 -32 -53 -53 -21 -37 -21 -46 -21 -599 0 -308
-3 -561 -7 -561 -4 0 -88 81 -188 179 -197 196 -216 208 -287 178 -77 -32
-114 -110 -85 -178 17 -42 598 -630 644 -653 40 -20 86 -20 121 -2 15 8 167
156 338 328 333 334 338 341 314 412 -14 44 -69 93 -113 101 -59 11 -96 -15
-273 -194 -94 -94 -173 -171 -177 -171 -4 0 -7 253 -7 563 0 543 -1 563 -20
595 -40 66 -119 89 -186 55z"/>
                                            <path d="M782 1561 c-18 -12 -41 -35 -52 -53 -19 -31 -20 -51 -20 -359 0 -322
0 -326 23 -359 12 -18 38 -41 57 -51 33 -18 81 -19 1065 -19 l1031 0 44 23
c79 40 80 46 80 407 0 352 -2 362 -69 407 -61 41 -154 21 -192 -41 -17 -28
-19 -54 -19 -268 l0 -238 -870 0 -870 0 0 230 c0 249 -5 275 -57 314 -39 29
-111 32 -151 7z"/>
                                        </g>
                                    </svg>


                                </a>
                            </Flex>
                        </Flex>
                    </Flex>
                </div>
                <MemberListingTableHeader />
                <MemberListingTableBody data={filteredMembers} refetch={fetchReport} />
            </Box>

            <ModalWrapper
                width="max-content"
                height={"auto"}
                overflow="scroll"
                hideModal={hideModal}
                setHideModal={() => {
                    setHideModal(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="2%"
            >
                <SnackBar
                    iconName={"info"}
                    message={snackbarMessage}
                    setShowSnackBar={setShowSnackBarModal}
                    show={showSnackBarModal}
                    timeout={4000}
                    anchor={"top-right"}
                    type={snackbarType}
                />
                {showDetailsModal ? (
                    <>
                        <Flex flexDirection="column" alignItems="center">
                            {/* <Text fontWeight="600" fontSize="24px" marginBottom={3}>Member Details</Text>
                            <Text>Email: <span style={{ fontWeight: "600" }}>{email}</span></Text>
                            <Text>Password: <span style={{ fontWeight: "600" }}>{hashPassword}</span></Text>
                            <Flex justifyContent={"space-between"} marginTop={3}>
                                <Button marginRight={2} variant="secondary" type="button" onClick={handleCopyDetails}>
                                    {copied ? 'Details Copied!' : 'Copy Details'}
                                </Button>
                                <Button onClick={() => { setShowDetailsModal(false); setHideModal(true); }}>Close</Button>
                            </Flex> */}
                            <Text fontWeight="600" fontSize="16px" marginBottom={3}>Use Token for login</Text>
                            <Button onClick={() => { setShowDetailsModal(false); setHideModal(true); }}>Close</Button>
                            {crmMessage && <Text fontSize="12px" marginTop={3} fontStyle={"italic"} display={"inherit"}>
                                <Icon name={"info"} size="50px" style={{ paddingRight: "10px" }}></Icon>
                                {crmMessage}
                            </Text>}
                        </Flex>
                    </>
                ) : (
                    <>
                        <Text fontWeight="400" fontSize="24px" marginBottom={4}>Add Member</Text>
                        <AddMemberForm
                            onSubmit={handleAddMember}
                            handleClose={() => { setHideModal(true); }}
                            adminMemberList={AdminMemberList}
                            tabView={false}
                        />
                        {/* <Formik
                            key={isModalOpen.toString()}
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                email: '',
                                menu: MenuValue,
                                organizationName: '',
                                userType: '',
                                addmembertype: '',
                            }}
                            onSubmit={(values) => {
                                console.log('Submitting edited data:', values);
                                handleAddMember(values);
                            }}
                            validationSchema={AddMemberSchema}

                        >
                            {({ setFieldValue, values }) => (
                                <Form>
                                    <Flex flexDirection="column">
                                        <Flex marginBottom={3}>
                                            <Flex padding={"5px 10px"} flexDirection="column">
                                                <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Member Type</Text>
                                                <Box width={"360px"}>
                                                    <Select
                                                        className="single-select"
                                                        placeholder="Select Member Type"
                                                        name="addmembertype"
                                                        options={addmembertypeOptions}
                                                        onChange={(addmembertype) => {
                                                            setFieldValue('addmembertype', addmembertype ? addmembertype.value : null);
                                                            setSelectedUserType(addmembertype.value);
                                                            setShowOrganizationDropdown(addmembertype.value === "child");

                                                            if (addmembertype.value === 'owner') {
                                                                setFieldValue('userType', 'admin');
                                                            }
                                                        }}
                                                        styles={{
                                                            control: (provided: Record<string, unknown>, state: any) => ({
                                                                ...provided,
                                                                width: "360px",
                                                            }),
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        name="addmembertype"
                                                        component="div"
                                                        className="custom-error-message"
                                                    />
                                                </Box>
                                            </Flex>
                                            {showOrganizationDropdown && (
                                                <Flex padding={"5px 10px"} flexDirection="column">
                                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Admin Name</Text>
                                                    <Box width={"360px"}>
                                                        <Select
                                                            className="single-select"
                                                            placeholder="Admin List"
                                                            name="organizationName"
                                                            options={AdminMemberList}
                                                            onChange={(organizationName) => {
                                                                setFieldValue('organizationName', organizationName ? organizationName.value : null);
                                                                setSelectedCompanyId(organizationName.value);
                                                            }}
                                                            styles={{
                                                                control: (provided: Record<string, unknown>, state: any) => ({
                                                                    ...provided,
                                                                    width: "360px",
                                                                }),
                                                            }}
                                                        />
                                                        <ErrorMessage
                                                            name="organizationName"
                                                            component="div"
                                                            className="custom-error-message"
                                                        />
                                                    </Box>
                                                </Flex>
                                            )}
                                        </Flex>
                                        <Flex>
                                            <Flex padding={"5px 10px"} flexDirection="column">
                                                <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>First Name</Text>
                                                <FormikInput
                                                    name="firstName"
                                                    placeholder="aereturn.profile.firstname"
                                                />
                                            </Flex>
                                            <Flex padding={"5px 10px"} flexDirection="column">
                                                <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Last Name</Text>
                                                <FormikInput
                                                    name="lastName"
                                                    placeholder="aereturn.profile.lastname"
                                                />
                                            </Flex>
                                        </Flex>
                                        <Flex>
                                            <Flex padding={"5px 10px"} flexDirection="column">
                                                <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Email Address</Text>
                                                <FormikInput
                                                    name="email"
                                                    type="email"
                                                    placeholder="aereturn.profile.email"
                                                />
                                            </Flex>
                                            <Flex padding={"5px 10px"} flexDirection="column">
                                                <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>User Role</Text>
                                                <Box width={"360px"}>
                                                    <Select
                                                        className="single-select"
                                                        placeholder="Select User Type"
                                                        name="userType"
                                                        options={usertypeOptions}
                                                        onChange={(userType) => {
                                                            if (typeof userType === 'string') {
                                                                setFieldValue('userType', userType);
                                                                setSelectedUserType(userType);
                                                            } else {
                                                                setFieldValue('userType', userType ? userType.value : null);
                                                                setSelectedUserType(userType?.value || '');
                                                            }
                                                        }}
                                                        value={values.addmembertype === 'owner' ? { value: 'admin', label: 'Admin' } : usertypeOptions.find((option) => option.value === values.userType) || null}
                                                        isDisabled={values.addmembertype === 'owner'}
                                                        styles={{
                                                            control: (provided: Record<string, unknown>, state: any) => ({
                                                                ...provided,
                                                                width: "360px",
                                                            }),
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        name="userType"
                                                        component="div"
                                                        className="custom-error-message"
                                                    />
                                                </Box>
                                            </Flex>
                                        </Flex>
                                        <Flex>
                                            <Flex padding={"5px 10px"} flexDirection="column">
                                                <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Menu</Text>
                                                <Box width={"360px"}>
                                                    <Dropdown
                                                        isMultiple
                                                        padding={"7px 0px"}
                                                        options={MenuValue}
                                                        name="menu"
                                                        checkboxLabel
                                                        variantType="primary"
                                                        defaultValue={MenuValue as any}
                                                    />
                                                </Box>
                                            </Flex>
                                        </Flex>

                                        <Flex justifyContent={"flex-end"} marginRight={1} padding={"5px 10px"}>
                                            <Button type="submit">Submit</Button>
                                            <Button variant="secondary" type="button" onClick={() => { setHideModal(true); setShowOrganizationDropdown(false); }} marginLeft={2}>
                                                Cancel
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Form>
                            )}
                        </Formik> */}
                    </>
                )}
            </ModalWrapper>
        </>
    );
}

export default MemberListingTable;
