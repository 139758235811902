import React, { useEffect, useRef, useState } from "react";
import ProspectReportTableBody from "./ProspectReportTableBody";
import ProspectReportTableHeader from "./ProspectReportTableHeader";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import Calendar from "react-calendar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import reportService from "services/reportService";
import ReactSelect from 'react-select';
import { Backdrop } from "@mui/material";
import { Form, Formik, ErrorMessage } from 'formik';
import userService from "services/userService";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { useDispatch } from "react-redux";
import { setprospectReportData } from "./store/prospectReportReducer";
import * as yup from 'yup';
import { useAppSelector } from "hooks";
import SearchBar from "components/TailwindComponents/SearchBar";
import Pagination from "components/Pagination/Pagination";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

const searchReportSchema = yup.object().shape({
    memberList: yup.string().required("Please select a Member"),
});



type Range<T> = [T, T];

function ProspectReportTable() {
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState([]);
    const [filteredReport, setFilteredReport] = useState([]);

    const [filterStatus, setFilterStatus] = useState("All");
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedDate, setSelectedDate] = useState<Range<Date>>([new Date(), new Date()]);
    const [openModal, setOpenModal] = useState(false);
    const [memberList, setMemberList] = useState([]);
    const [bothDatesSelected, setBothDatesSelected] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const dispatch = useDispatch();

    const loggedInMemberDetails = useAppSelector((state) => state.profileState.profileData);
    const prospectReportData = useAppSelector((state) => state.prospectReportState.prospectReportData);

    const isSuperAdminView = localStorage.getItem("superadminview");

    const loggedInMemberOrgId = loggedInMemberDetails.organizationId;
    const loggedInMemberRole = loggedInMemberDetails.role;
    const loggedInMemberUserId = loggedInMemberDetails.userId;

    const initialMemberReport = loggedInMemberUserId + "$$$" + loggedInMemberRole + "$$$" + loggedInMemberOrgId;
    const [selectedMember, setselectedMember] = useState("");
    const [searchString, setSearchString] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalDataCount, setTotalDataCount] = useState(0);

    const searchRef = useRef({});

    const itemPerPageOptions = [
        { value: 50, label: "Per page: 50" },
        { value: 100, label: "Per page: 100" }
    ];

    const [searchData, setSearchData] = useState({});


    const StatusValues = [{ label: "All", value: "All" }, { label: "Enriched", value: "Processed" }, { label: "Private LinkedIn", value: "Error" }, { label: "Queued", value: "Queued" }];

    const fetchReport = async (date, user = {}, page = 1, perPage = 10, search = {}) => {
        try {
            const searchObj = date == "-1" ? searchRef.current : { date: date, user: user, page: page, perPage: perPage, search }
            searchRef.current = searchObj;

            setLoading(true);
            if (loggedInMemberRole === 'superadmin' && isSuperAdminView) {
                reportService.getProspectReportSuperAdmin(searchObj)
                    .then((response) => {
                        setReport(response?.data?.data);
                        setFilteredReport(response?.data?.data);
                        dispatch(setprospectReportData(response?.data?.data));
                        setTotalDataCount(response?.data?.totalCount);
                        console.log(response.data);
                        setLoading(false);
                    })
            }

            if (loggedInMemberRole !== "superadmin" || (loggedInMemberRole === "superadmin" && !isSuperAdminView)) {
                reportService.getProspectReportAdmin(searchObj)
                    .then((response) => {
                        setReport(response?.data?.data);
                        setFilteredReport(response?.data?.data);
                        dispatch(setprospectReportData(response?.data?.data));
                        setTotalDataCount(response?.data?.totalCount);
                        console.log(response.data);
                        setLoading(false);
                    })
            }
        } catch (error) {
            console.error("Error fetching report:", error);
            setLoading(false);
        }

    };

    // const handleDateChange = (dates: Date | Date[]) => {
    //     const isRange = Array.isArray(dates) && dates.length === 2;
    //     if (isRange) {
    //         const [startDate, endDate] = dates;
    //         setSelectedDate([startDate, endDate]);
    //         setBothDatesSelected(true);
    //         setOpenModal(false);
    //         fetchReport([startDate, endDate], initialMemberReport);
    //     }
    // };

    const reFetch = () => {
        fetchReport("-1");
    }

    const handleSearchChange = (e) => {
        const searchValue = e?.target?.value;
        const searchTermLowerCase = searchValue.toLowerCase().trim();
        setSearchString(searchTermLowerCase);
    }

    const updateReportData = () => {
        let search = { searchString: searchString };

        if (selectedMember.toLowerCase() !== "all members") {
            const userId = selectedMember.split("$$$")[0];
            search["userId"] = userId;
        }

        if (filterStatus.toLowerCase() !== "all") {
            search["status"] = filterStatus.toLowerCase();
        }

        setSearchData(search);
        setCurrentPage(1);

    }




    // const applyFilterAndSearch = () => {
    //     let filteredData = report;

    //     if (filterStatus !== "All") {
    //         filteredData = filteredData.filter(item => item.status.toLowerCase() === filterStatus.toLowerCase());
    //     }

    //     if (searchTerm.trim() !== "") {
    //         const searchTermLowerCase = searchTerm.toLowerCase().trim();
    //         filteredData = filteredData.filter(item =>
    //             item?.name?.toLowerCase().includes(searchTermLowerCase) || item?.email?.toLowerCase().includes(searchTermLowerCase)
    //             || item?.ownerName?.includes(searchTermLowerCase)
    //         );
    //     }

    //     // if (selectedDate) {
    //     //     filteredData = filteredData.filter(item => {
    //     //         const itemDate = new Date(item.processedAt);
    //     //         return (
    //     //             itemDate.getDate() === selectedDate.getDate() &&
    //     //             itemDate.getMonth() === selectedDate.getMonth() &&
    //     //             itemDate.getFullYear() === selectedDate.getFullYear()
    //     //         );
    //     //     });
    //     // }

    //     setFilteredReport(filteredData);
    // }

    const handleClear = () => {
        setSearchTerm("");
        // applyFilterAndSearch();
    }

    const filterData = (data) => {
        setFilteredReport(data);
    }

    const fetchMemberList = async () => {
        try {
            userService.getAllMember()
                .then((response) => {
                    const filteredMembers = response.data.filter(({ organizationId }) =>
                        (loggedInMemberRole === 'superadmin' && isSuperAdminView === 'yes') || (loggedInMemberRole === 'superadmin' && organizationId === loggedInMemberOrgId && isSuperAdminView !== 'yes') ||
                        (loggedInMemberRole === 'admin' && organizationId === loggedInMemberOrgId)
                    );

                    const fetchmemberList = filteredMembers.map(({ organizationId, firstName, lastName, email, userId, userType }) => ({
                        value: `${userId}$$$${userType}$$$${organizationId}`,
                        label: `${firstName} ${lastName} <${email}>`
                    })
                    );

                    if (filteredMembers.length > 0) {
                        fetchmemberList.unshift({ value: "All Members", label: "All Members" });
                    }

                    setMemberList(fetchmemberList);
                });
        } catch (error) {
            console.error("Error fetching company list", error);
        }
    };


    useEffect(() => {

        if (loggedInMemberOrgId) {

            const currentDate = new Date();
            //  currentDate.setDate(currentDate.getDate() - 1);
            // setSelectedDate([currentDate, currentDate]);
            fetchReport([currentDate, currentDate], initialMemberReport, currentPage, itemsPerPage, searchData);
            memberList.length == 0 && fetchMemberList();

        }
    }, [loggedInMemberOrgId, currentPage, itemsPerPage, searchData]);


    useEffect(() => {
        updateReportData();
    }, [filterStatus, selectedMember, searchString]);


    // useEffect(() => {
    //     applyFilterAndSearch();
    // }, [filterStatus, searchTerm]);

    const showClearIcon = searchTerm.length > 0;

    const minDate = new Date(2023, 9, 1);
    const maxDate = new Date();

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (newItemsPerPage) => {
        console.log("newItemsPerPagenewItemsPerPage", newItemsPerPage);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(totalDataCount / itemsPerPage);

    return (
        <Box>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}
            >
                <MemoRevenoidLoader />
            </Backdrop>}
            <div>
                <Flex marginBottom={"-20px"} justifyContent={"space-between"} alignItems="center" gap="20px">
                    <Flex marginBottom={"40px"}>
                        {/* <Text fontWeight="400" fontSize="32px">Framework/Context</Text> */}
                        <SearchBar placeholder="Search via email or name" onChange={handleSearchChange} />
                    </Flex>
                    {/* <Flex justifyContent={"right"}>
                        <Box width={"200px"} alignSelf={"center"} marginLeft={"15px"}>
                            <Select
                                value={filterStatus}
                                onChange={(e) => setFilterStatus(e.target.value)}
                                variant="outlined"
                                size="medium"
                                fullWidth
                                style={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "10px",
                                    background: "#FFFFFF",
                                    borderRadius: "6px",
                                    height: "43px",
                                    position: "relative",
                                    color: "#ffffff",
                                }}

                            >
                                {StatusValues.map((status) => (
                                    <MenuItem key={status} value={status}>
                                        Status:&nbsp;{status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box marginLeft={"15px"}>
                            <TextField
                                placeholder="Name/ Email/ Owner Name"
                                variant="outlined"
                                style={{width:"300px"}}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={showClearIcon ? handleClear : () => { }}>
                                            {showClearIcon ? <ClearIcon /> : <SearchIcon />}
                                        </IconButton>
                                    ),
                                    style: {
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "10px",
                                        background: "#FFFFFF",
                                        borderRadius: "6px",
                                        height: "43px",
                                        position: "relative",
                                        color: "#ffffff",
                                    }
                                }}
                            />
                        </Box>
                    </Flex> */}
                    <Flex style={{ position: "relative", zIndex: "99" }} >
                        <Text fontSize={"12px"} marginRight={"15px"} marginBottom={"40px"} alignSelf={"center"}>Filter By : </Text>
                        <Formik
                            initialValues={{
                                memberList: '',
                            }}
                            onSubmit={(values) => {
                                // if (bothDatesSelected) {
                                //     fetchReport(selectedDate, values.memberList)
                                // } else {
                                //     setSnackbarMessage('Please select end date.');
                                //     setShowSnackBar(true);
                                //     setSnackbarType('error');
                                // }
                            }}
                            validationSchema={searchReportSchema}

                        >
                            {({ setFieldValue, values }) => (
                                <Form>
                                    <Flex flexDirection={"row"} gap="12px" marginBottom={"40px"}>
                                        <Flex flexDirection={"column"}>
                                            <Box width={"202px"}>
                                                <ReactSelect
                                                    className="single-select"
                                                    placeholder="Rep name: e.g. John Doe"
                                                    name="memberList"
                                                    options={memberList}
                                                    onChange={(memberList) => {
                                                        const selectedValue = memberList as { value: string };
                                                        setFieldValue('memberList', selectedValue ? selectedValue.value : null);
                                                        setselectedMember(selectedValue.value);

                                                    }}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            width: "202px",
                                                            height: "48px",
                                                            border: "6px",
                                                            fontSize: "12px"
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            width: "202px",
                                                            wordWrap: "break-word",
                                                        })
                                                    }}
                                                />
                                            </Box>
                                            <Flex>
                                                <ErrorMessage
                                                    name="memberList"
                                                    component="div"
                                                    className="custom-error-message"
                                                />
                                            </Flex>
                                        </Flex>
                                        <Flex>
                                            <Box width={"202px"}>
                                                <ReactSelect
                                                    className="single-select"
                                                    placeholder={"Select status"}
                                                    name="statusList"
                                                    options={StatusValues}
                                                    onChange={(statusList) => {
                                                        const selectedValue = statusList as { value: string };
                                                        setFieldValue('statusList', selectedValue ? selectedValue.value : null);
                                                        setFilterStatus(selectedValue.value);

                                                    }}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            width: "202px",
                                                            height: "48px",
                                                            border: "6px",
                                                            fontSize: "12px"
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            width: "202px"
                                                        })
                                                    }}
                                                />
                                            </Box>
                                        </Flex>
                                        {/* <Flex flexDirection={"column"}>
                                            <Flex className="d1"
                                                justifyContent={"space-between"}
                                                alignItems={"center"}
                                                px={"10px"}
                                                py={"4px"}
                                                background={"#FFFFFF"}
                                                borderRadius={"6px"}
                                                height={"48px"}
                                                alignSelf={"center"}
                                                position={"relative"}
                                                cursor="pointer"
                                                onClick={() => {
                                                    setOpenModal(!openModal);
                                                }}
                                            >
                                                <Text
                                                    color="#57585C"
                                                    fontSize={"12px"}
                                                    fontWeight={600}
                                                >
                                                    Date:{" "}
                                                    <Text as="span" fontWeight={400}>
                                                        {Array.isArray(selectedDate) && (
                                                            `${selectedDate[0].getDate()} ${selectedDate[0].toLocaleString('default', { month: 'short' })} - 
                                                            ${selectedDate[1].getDate()} ${selectedDate[1].toLocaleString('default', { month: 'short' })}`
                                                        )}
                                                    </Text>
                                                </Text>
                                                <ArrowDropDownIcon />
                                            </Flex>
                                            <Flex marginTop={"20px"}>
                                                &nbsp;
                                            </Flex>
                                        </Flex>
                                        {openModal && (
                                            <div className="d1" style={{ position: "absolute", top: "60px", right: 0, zIndex: "9999" }}>
                                                <Calendar
                                                    onChange={handleDateChange}
                                                    selectRange={true}
                                                    value={selectedDate}
                                                    minDate={minDate}
                                                    maxDate={maxDate}
                                                />
                                            </div>
                                        )} */}
                                    </Flex>
                                </Form>
                            )}

                        </Formik>
                    </Flex>
                </Flex>
                {/* <Flex justifyContent={"right"} alignItems="right" marginBottom={"20px"}>
                    <Flex style={{ position: "relative", zIndex: "99" }}>
                        <Text fontSize={"12px"} marginRight={"15px"} alignSelf={"center"}>Filter By : </Text>
                        <Formik
                            initialValues={{
                                memberList: memberList,
                            }} 
                            onSubmit={(values) => { 
                                if(bothDatesSelected){
                                    console.log(values.memberList);
                                    console.log(selectedDate);
                                    fetchReport(selectedDate, values.memberList)
                                }else{
                                    setSnackbarMessage('Please select end date.');
                                    setShowSnackBar(true);
                                    setSnackbarType('error');
                                }
                            }}
                        >
                            {({ setFieldValue, values }) => (
                                <Form>
                                    <Flex flexDirection={"row"} gap="12px">
                                        <Flex className="d1"
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                            px={"10px"}
                                            py={"4px"}
                                            background={"#FFFFFF"}
                                            borderRadius={"6px"}
                                            border="1px solid #cccccc"
                                            height={"43px"}
                                            alignSelf={"center"}
                                            position={"relative"}
                                            cursor="pointer"
                                            onClick={() => {
                                                setOpenModal(!openModal);
                                            }}
                                        >
                                            <Text
                                                color="#57585C"
                                                fontSize={"12px"}
                                                fontWeight={600}
                                            >
                                                Date:{" "}
                                                <Text as="span" fontWeight={400}>
                                                    {Array.isArray(selectedDate) && (
                                                        `${selectedDate[0].toDateString()} - ${selectedDate[1].toDateString()}`
                                                    )}
                                                </Text>
                                            </Text>
                                            <ArrowDropDownIcon />
                                        </Flex>
                                        {openModal && (
                                            <div className="d1" style={{ position: "absolute", top: "53px", left: 0 }}>
                                                <Calendar
                                                    onChange={handleDateChange}
                                                    selectRange={true}
                                                    value={selectedDate}
                                                    minDate={minDate}
                                                    maxDate={maxDate}
                                                />
                                            </div>
                                        )}
                                        <Flex>
                                            <Box width={"360px"}>
                                                <ReactSelect
                                                    className="single-select"
                                                    placeholder="Member List"
                                                    name="memberList"
                                                    options={memberList}
                                                    onChange={(value) => {
                                                        setFieldValue("memberList", value.value);
                                                    }}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            width: "360px",
                                                        }),
                                                    }}
                                                />
                                            </Box>
                                        </Flex>
                                        <Flex>
                                            <Button type="submit">Submit</Button>
                                        </Flex>
                                    </Flex>
                                </Form>
                            )}

                        </Formik>
                    </Flex>
                </Flex> */}
            </div>
            <ProspectReportTableHeader filterDataFunc={filterData} />
            <ProspectReportTableBody data={filteredReport} currentPage={currentPage} itemsPerPage={itemsPerPage} reFetch={reFetch} />
            <Box borderBottomLeftRadius={"8px"} borderBottomRightRadius={"8px"} background={"#fff"} padding={"10px 16px"}>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    itemPerPageOptions={itemPerPageOptions}
                />
            </Box>
        </Box>
    );
}

export default ProspectReportTable;
