import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { useAppSelector } from "hooks";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import stakeHolderTagService from "services/stakeholderTagService";
import { Backdrop } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

function ProspectProcessTableBodyField({ data, index, reFetch }) {

    const [loading, setLoading] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    const dispatch = useDispatch();

    const allStakeholderTagData = useAppSelector(
        (state) => state.stakeholderTagState?.stakeholderTagData
    );

    const getTagListByStatus = (status: any) => {
        setListStatus(status);
        stakeHolderTagService.getTagListByStatus(data.tagId, status).then((response) => {
            console.log("response", response);
            setTagStatusList(response.data);
            setTagListHide(false);
            //dispatch(setStakeholderTagData(updatedStakeholderTagData));
            //setHide(true);
        });
    }

    const deleteData = (obj) => {
        stakeHolderTagService.deleteTag(obj.tagId).then((response) => {
            if(response.success){
                setSnackbarMessage('List has been removed');
                setSnackbarType('success');
                setShowSnackBar(true);
                reFetch();
            }else{
                setSnackbarMessage('Error removing list');
                setSnackbarType('error');
                setShowSnackBar(true);
            }
            setHide(true);
        });
    }

    const exportData = () => {
        setLoading(true);
        stakeHolderTagService.getTagListByTagId(data.tagId).then((response) => {
            const filteredData = response.data.map(({ status, prospectEmail, prospectLinkedInUrl }) => ({
                status,
                prospectEmail,
                prospectLinkedInUrl,
            }));
            const filePath = data.tagId + '.xlsx';
            exportToExcel(filteredData, filePath);
        });
    };

    const removeAfterCsv = (inputString) => {
        let match = inputString.match(/^(.*\.csv).*$/);
        return match ? match[1] : inputString;
    }

    const exportToExcel = (data, filePath) => {
        const headers = ['Status', 'Prospect Email', 'Prospect LinkedIn Url'];
        const ws = XLSX.utils.json_to_sheet([], { header: headers });
        XLSX.utils.sheet_add_json(ws, data, { skipHeader: true, origin: 'A2' });
        const colWidths = headers.map((header) => ({ wch: Math.max(20, header.length) }));
        ws['!cols'] = colWidths;

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, filePath);
        setLoading(false);
    }

    const dateTag: any = new Date(data?.requestedOn);
    const dateProcessed: any = data?.processedAt ? new Date(data?.processedAt) : "";
    const [hide, setHide] = useState(true);
    const [tagListhide, setTagListHide] = useState(true);
    const [tagStatusList, setTagStatusList] = useState([]);
    const dateOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    const [listStatus,setListStatus]=useState("");


    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            {loading && <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <MemoRevenoidLoader />
            </Backdrop>}

            <ModalWrapper
                width="400px"
                height="max-content"
                hideModal={tagListhide}
                setHideModal={() => {
                    setTagListHide(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="16px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    justifyContent={"flex-start"}

                >
                    <Text textTransform={"capitalize"} marginBottom={"16px"} fontSize={"16px"} fontWeight={300}>
                        {" "}
                       {listStatus} Prospect List
                    </Text>
                    <Flex
                        padding={"10px 15px"}
                        fontSize="12px"
                        fontWeight={500}
                        position="sticky"
                        backgroundColor={"#F8F8F8"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        width="400px"
                    >
                        <Flex width="20%" justifyContent={"center"} alignItems={"center"}>
                            #
                        </Flex>
                        <Flex width="80%" justifyContent="left" alignItems="flex-start">
                            Prospect Email
                        </Flex>
                    </Flex>
                    <Box overflow={"scroll"} height={"500px"}>
                        {tagStatusList.map((tag, index) => (
                            <Flex
                                padding="15px"
                                fontSize="12px"
                                fontWeight={500}
                                position="sticky"
                                top="0" /* Add a 'top' value to specify when the element becomes sticky */
                                backgroundColor="#fff"
                                justifyContent="center"
                                alignItems="center"
                                width="400px"
                            >
                                <Flex width="20%" justifyContent="center" alignItems="center">
                                    {index + 1}
                                </Flex>
                                <Flex width="80%" justifyContent="left" alignItems="flex-start">
                                    {tag.prospectEmail}
                                </Flex>

                            </Flex>
                        ))}
                    </Box>
                </Flex>
            </ModalWrapper>

            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={hide}
                setHideModal={() => {
                    setHide(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="40px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text fontSize={"20px"} fontWeight={300}>
                        {" "}
                        Are you sure you want to remove this data ?
                    </Text>
                    <Flex mt="15px">
                        <Flex
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => deleteData(data)}
                        >
                            Yes
                        </Flex>
                        <Flex 
                            ml="16px"
                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => setHide(true)}
                        >
                            Cancel
                        </Flex>
                    </Flex>
                </Flex>
            </ModalWrapper>
            <Flex
                padding={"15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={index % 2 == 0 ? "#FFF" : "rgb(249, 249, 249)"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={index % 2 != 0 && "12px"}
                margin="0 16px"
            >
                <Flex width="5%" >
                    {index + 1}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} width="12%">
                    {removeAfterCsv(data?.tag) || "-"}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} width="12%"  flexDirection={"column"}>
                    <Flex style={{ "textTransform": "capitalize" }} mb={"5px"}>
                        {data?.ownerName || "-"}
                    </Flex>
                    <Flex style={{ "wordBreak": "break-word" }}>
                        {data?.ownerEmail || "-"}
                    </Flex>
                </Flex>
                <Flex width="10%" style={{ "wordBreak": "break-word" }}>
                    <Text>
                        {data?.status === 'processed' && <Tag
                            color="#22C96F"
                            bgColor="rgba(34, 201, 111, 0.1)"
                            text="Uploaded"
                        />}

                        {data?.status === 'error' && <Tag
                            color="#FF0000"
                            bgColor="rgba(255, 0, 0, 0.1)"
                            text={data?.status}
                        />}

                        {data?.status !== 'processed' && data?.status !== 'error' && <Tag
                            color="#2247C9"
                            bgColor="rgba(34,71,201,0.1)"
                            text={data?.status}
                        />}
                        {data?.status === ' ' && "-"}
                    </Text>
                </Flex>
                <Flex width="7%" style={{ "wordBreak": "break-word" }}>
                    {data?.totalQueued ? (<Text cursor="pointer" textDecoration="underline" color={"blue"} onClick={() => getTagListByStatus("queued")}>{data.totalQueued}</Text>) : (
                        <Text>-</Text>
                    )}
                </Flex>               
                <Flex width="9%" style={{ "wordBreak": "break-word" }}>
                    {data?.totalProcess ? (<Text cursor="pointer" textDecoration="underline" color={"blue"} onClick={() => getTagListByStatus("processed")}>{data.totalProcess}</Text>) : (
                        <Text>-</Text>
                    )}
                </Flex>
                <Flex width="7%" style={{ "wordBreak": "break-word" }}>
                    {data?.totalError ? (<Text cursor="pointer" textDecoration="underline" color={"blue"} onClick={() => getTagListByStatus("error")}>{data.totalError}</Text>) : (
                        <Text>-</Text>
                    )}
                </Flex>
                <Flex width="10%" style={{ "wordBreak": "break-word" }}>
                    {dateProcessed ? dateProcessed.toLocaleDateString('en-US', dateOptions) : "-"}
                </Flex>
                <Flex width="10%" style={{ "wordBreak": "break-word" }}>
                    {dateTag.toLocaleDateString('en-US', dateOptions) || "-"}
                </Flex>
                <Flex width="18%" gap="10px" justifyContent={"flex-end"}>
                    {data?.status === "processed" ? (
                            <Button
                                size="s"
                                variant="primary"
                                borderRadius={"15px"}
                                onClick={exportData}
                            >
                                Export
                            </Button>
                    ) : <Flex>
                        -
                    </Flex>
                    }
                    <Button
                        size="s"
                        variant="secondary"
                        borderRadius={"15px"}
                        onClick={() => setHide(false)}
                    >
                        Delete
                    </Button>
                </Flex>
            </Flex>
        </>
    );
}

export default ProspectProcessTableBodyField;
