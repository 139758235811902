import React, { useState, useEffect } from 'react';
import StepForm from './StepForm';
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import Tooltip from '@mui/material/Tooltip';
import { axiosHelperCore } from 'utils';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';

const SequenceStepList = ({ sequenceStepList, sequenceName, sequenceId, defaultEmail, defaultLinkedin, frameworkList, toneList, wordCountList, contextList, onBack, handleSequenceSteps, autoPersonaSelectionSetting }) => {

  const [formState, setFormState] = useState({});
  const [emailOptions, setEmailOptions] = useState([]);
  const [followUpOptions, setFollowUpOptions] = useState([]);
  const [linkedinOptions, setLinkedinOptions] = useState([]);
  const [callPrepOptions, setCallPrepOptions] = useState([]);
  const [usedEmailOptions, setUsedEmailOptions] = useState([]);
  const [usedCustomFieldValues, setUsedCustomFieldValues] = useState([]);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDetail, setErrorDetail] = useState('');
  const [showError, setShowError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  let isEmailCount = 0;
  let isFollowupCount = 0;

  useEffect(() => {
    const emailSteps = sequenceStepList.filter(step => step?.stepType.includes('email') && !step.isReply);
    const followUpSteps = sequenceStepList.filter(step => step?.stepType.includes('email') && step.isReply);

    const emailOpts = emailSteps.map((_, index) => ({
      value: `email-${index + 1}`,
      label: `Email ${index + 1}`
    }));

    const followUpOpts = followUpSteps.map((_, index) => ({
      value: `followUp-${index + 1}`,
      label: `FollowUp Email ${index + 1}`
    }));

    const linkedinOptions = [{ value: 'linkedinConnectionRequest', label: 'LinkedIn Connection Request' },
    { value: 'linkedinDirectMessage', label: 'LinkedIn Direct Message' },
    { value: 'linkedinInMail', label: 'LinkedIn InMail' }]

    const callPrepOptions = [{ value: 'call', label: 'Call' },]

    setEmailOptions(emailOpts);
    setFollowUpOptions(followUpOpts);
    setLinkedinOptions(linkedinOptions);
    setCallPrepOptions(callPrepOptions);


  }, [sequenceStepList]);

  useEffect(() => {
    // Check for duplicates in usedCustomFieldValues
    const hasDuplicates = (arr) => new Set(arr).size !== arr.length;
    setIsSaveButtonEnabled(!hasDuplicates(usedCustomFieldValues));
  }, [usedCustomFieldValues]);


  const handleFormChange = (stepId, sequenceId, formData) => {
    setFormState((prevState) => ({
      ...prevState,
      [stepId]: {
        ...formData,
        sequenceId,
      },
    }));

    // if (formData.type.includes('email') && !usedEmailOptions.includes(formData.type)) {
    //   setUsedEmailOptions((prev) => [...prev, formData.type]);
    // }
  };

  const validateSteps = () => {
    const fieldValues = new Set();
    const emailValues = new Set();
    const followUpValues = new Set();
    let errorDetails = '';

    for (const stepId in formState) {
      const stepData = formState[stepId];


      if (stepData.isActive) {
        if (!stepData.type || (stepData.type.includes('email') && (!stepData.emailSubject || !stepData.emailBody))) {
          errorDetails = "" //`Step "${stepData.stepName}" is missing subject or body content for email.`;
          const element = "id_" + stepId;
          return { element, errorDetails };
        }

        if ((stepData.type.includes('followUp') && !stepData.emailBody) || (stepData.type.includes('linkedin') && !stepData.linkedinBody)) {
          errorDetails ="" //`Step "${stepData.stepName}" is missing body content.`;
          const element = "id_" + stepId;
          return { element, errorDetails };
        }

        if (!stepData.type || (stepData.type.includes('linkedinInMail') && (!stepData.linkedinSubject || !stepData.linkedinBody))) {
          errorDetails = "" //`Step "${stepData.stepName}" is missing subject or body content for email.`;
          const element = "id_" + stepId;
          return { element, errorDetails };
        }

        if (!stepData.type || (stepData.type.includes('call') && !stepData.callprepBody)) {
          errorDetails = "" //`Step "${stepData.stepName}" is missing subject or body content for email.`;
          const element = "id_" + stepId;
          return { element, errorDetails };
        }

        if (stepData.type.includes('email')) {
          if (emailValues.has(stepData.type)) {
            errorDetails = `Step "${stepData.stepName}" has duplicate email type "${stepData.type}".`;
            const element = "id_" + stepId;
            return { element, errorDetails };
          }
          emailValues.add(stepData.type);
        }

        if (stepData.type.includes('followUp')) {
          if (followUpValues.has(stepData.type)) {
            errorDetails = `Step "${stepData.stepName}" has duplicate follow-up type "${stepData.type}".`;
            const element = "id_" + stepId;
            return { element, errorDetails };
          }
          followUpValues.add(stepData.type);
        }

        if (stepData.type && (fieldValues.has(stepData.emailSubject?.trim()) || fieldValues.has(stepData.callprepBody?.trim()) || fieldValues.has(stepData.emailBody?.trim()) || fieldValues.has(stepData.linkedinBody?.trim()) || fieldValues.has(stepData.linkedinSubject?.trim()))) {
          errorDetails = `Step "${stepData.stepName}" - Similar custom field name used in another step.`;
          alert(errorDetails);
          const element = "id_" + stepId;
          return { element, errorDetails };
        }

        if (stepData.stepName.includes('Connection Request') && stepData.type !== 'linkedinConnectionRequest') {
          errorDetails = `Step "${stepData.stepName}" must have type "LinkedIn Connection Request".`;
          const element = "id_" + stepId;
          return { element, errorDetails };
        }

        if (stepData.stepName.includes('Send Message') && stepData.type !== 'linkedinDirectMessage') {
          errorDetails = `Step "${stepData.stepName}" must have type "LinkedIn Direct Message".`;
          const element = "id_" + stepId;
          return { element, errorDetails };
        }

        if (stepData.emailSubject) fieldValues.add(stepData.emailSubject?.trim());
        if (stepData.emailBody) fieldValues.add(stepData.emailBody?.trim());
        if (stepData.linkedinBody) fieldValues.add(stepData.linkedinBody?.trim());
        if (stepData.linkedinSubject) fieldValues.add(stepData.linkedinSubject?.trim());
        if (stepData.callprepBody) fieldValues.add(stepData.callprepBody?.trim());

      }
    }
    return null;
  };

  const handleSubmit = () => {
    setIsSubmitted(true);

    const status = validateSteps();
    if (status && (status?.element || status?.errorDetails?.trim() != "")) {
      document.getElementById(status?.element).scrollIntoView({ behavior: 'smooth' });
      setErrorMessage("Please check all the errors");
      setErrorDetail(status?.element+"$$$"+status.errorDetails);
      return;
    }

    const formattedData = Object.keys(formState).map((stepId, index) => {
      const stepData = formState[stepId];
      let field1 = "";
      let field2 = "";
      let type = "";

      if (stepData.stepType === 'manual_email' || stepData.stepType === 'auto_email') {
        field1 = stepData?.emailSubject;
        field2 = stepData?.emailBody;
        type = stepData?.type;
      } else if (stepData.stepType === 'linkedin_send_message' || stepData.stepType === 'linkedin_view_profile' || stepData.stepType === 'linkedin_interact_with_post' || stepData.stepType === 'linkedin_send_connection_request' || stepData.stepType === 'linkedin_other') {
        field1 = stepData?.linkedinSubject || ''
        field2 = stepData?.linkedinBody;
        type = stepData?.type;
      }
      else if (stepData.stepType === 'call') {
        field1 = ''
        field2 = stepData?.callprepBody;
        type = stepData?.type;
      }

      // if (stepData.type.includes('email') || stepData.type.includes('followUp')) {
      //   field1 = stepData.emailSubject;
      //   field2 = stepData.emailBody;
      //   type = stepData.type;
      // } else if (stepData.type === 'linkedin') {
      //     field1 = stepData.linkedinBody;
      //     type = stepData.type;
      // }

      return {
        crmStepId: stepData.crmStepId,
        stepName: stepData.stepName,
        stepType: stepData.stepType,
        type,
        orderBy: stepData.orderBy,
        field1,
        field2,
        frameworkId: stepData.selectedFramework,
        frameworkName: frameworkList.filter(fw => fw.frameworkId).find(fw => fw.frameworkId === stepData.selectedFramework)?.name || '',
        framework: frameworkList.filter(fw => fw.frameworkId).find(fw => fw.frameworkId === stepData.selectedFramework)?.framework || '',
        contextId: stepData.selectedContext,
        contextName: contextList.filter(cx => cx.contextId).find(cx => cx.contextId === stepData.selectedContext)?.name || '',
        context: contextList.filter(cx => cx.contextId).find(cx => cx.contextId === stepData.selectedContext)?.context || '',
        isContextPublic : contextList.filter(cx => cx.contextId).find(cx => cx.contextId === stepData.selectedContext)?.isPublic,
        toneId: stepData?.type === "followUp" ?"": stepData.selectedTone,
        tone: stepData?.type === "followUp" ?"": (toneList.find(tone => tone.toneId === stepData.selectedTone)?.value || ''),
        wordId: stepData.selectedWordLimit,
        wordLimit: wordCountList.find(wc => wc.wordId === stepData.selectedWordLimit)?.value || '',
        activated: stepData?.isActive,
      };
    });
    console.log(formattedData);    

    handleSequenceSteps(formattedData);
  };

  useEffect(() => {
    let timer;
    if (errorMessage) {
      setShowError(true);
      timer = setTimeout(() => {
        setShowError(false);
        setErrorMessage('');
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [errorMessage]);

  if ([emailOptions, followUpOptions, linkedinOptions].every(option => option.length === 0)) {
    return <div>Loading...</div>;
  }
  return (
    <div className="sequence-step-list px-10">
      <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ fontSize: "24px" }}>{sequenceName}</div>
        <div style={{ justifyContent: "flex-end" }}><Button iconName="arrowLeft" onClick={onBack} variant="secondary" fontSize={"14px"} padding={"10px 10px"} size="xs">Back</Button></div>
      </div>
      <div className='border-b-2 space-y-6 my-5' style={{ flexGrow: "1" }}>
        {sequenceStepList.map((step, index) => {

          const isEmailStep = step.stepType.includes('email') && !step.isReply;
          const isFollowUpStep = step.stepType.includes('email') && step.isReply;
          const isLinkedInStep = step.stepType.includes('linkedin');
          const isCallStep = step.stepType.includes('call');

          let dropDownList;
          let defaultType;
          if (isEmailStep) {
            console.log('Setting emailOptions:', emailOptions);
            dropDownList = emailOptions;
            defaultType = emailOptions[isEmailCount]?.value;
            isEmailCount += 1;
          }

          if (isFollowUpStep) {
            console.log('Setting followUpOptions:', followUpOptions);
            dropDownList = followUpOptions;
            defaultType = followUpOptions[isFollowupCount]?.value;
            isFollowupCount += 1;
          }

          if (isLinkedInStep) {
            console.log('Setting linkedinOptions:', linkedinOptions);
            dropDownList = linkedinOptions;
            if (step?.stepType === 'linkedin_send_connection_request') {
              defaultType = 'linkedinConnectionRequest'
            }

            if (step?.stepType === 'linkedin_send_message') {
              defaultType = 'linkedinDirectMessage'
            }
          }

          if(isCallStep)
          {
            dropDownList = callPrepOptions;
            defaultType = callPrepOptions[0]?.value;
          }

          return (<StepForm
            errorMessage={errorDetail}
            isSubmitted={isSubmitted}
            key={step.stepId}
            orderBy={step.orderBy}
            stepId={step.stepId}
            sequenceId={sequenceId}
            stepName={step.stepName}
            stepType={step.stepType}
            defaultEmail={defaultEmail}
            defaultLinkedin={defaultLinkedin}
            frameworkList={frameworkList}
            toneList={toneList}
            wordCountList={wordCountList}
            contextList={contextList}
            onChange={handleFormChange}
            dropDownList={dropDownList}
            usedEmailOptions={usedEmailOptions}
            globalUsedCustomValues={usedCustomFieldValues}
            setGlobalUsedCustomValues={setUsedCustomFieldValues}
            defaultType={defaultType}
            isDisabled={step.stepType === 'task' || step.stepType === 'linkedin_view_profile' || step.stepType === 'linkedin_interact_with_post'}
            autoPersonaSelectionSettingStep={autoPersonaSelectionSetting}
          />
          )
        }
        )}
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Tooltip title={ ""} placement="left">
          <span>
            <Button
              onClick={handleSubmit}
              variant={"primary"}
           
              padding={"20px"}

            >
              Save and Activate Sequence Settings
            </Button>
          </span>
        </Tooltip>
      </div>
      {/* {showError && <div className="error-message" style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>} */}
      <SnackBar
        iconName={"info"}
        message={errorMessage}
        setShowSnackBar={setShowError}
        show={showError}
        timeout={8000}
        anchor={"top-right"}
        type={'error'}
      // bgColorr="#F44545"

      />
    </div>
  );
};

export default SequenceStepList;
