import * as React from "react";

  
  function ApolloSearchIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M15.1501 19.35H2.09072C1.47458 19.35 0.975098 18.8505 0.975098 18.2344V2.61563C0.975098 1.99948 1.47458 1.5 2.09072 1.5H17.7095C18.3256 1.5 18.8251 1.99948 18.8251 2.61563V15.675" stroke="white" stroke-width="1.5"/>
        <path d="M14.0487 6.38671H12.123L13.1215 8.12482L14.0487 6.38671ZM16.7252 16.1984L9.88869 4.64844L3.0752 16.1742H6.72444C7.21127 16.1742 7.69028 16.0494 8.11377 15.8127C8.57084 15.557 8.91595 15.1864 9.18575 14.7371C9.5011 14.2115 9.8074 13.6798 10.1178 13.151L10.9121 11.7976L9.88777 10.0543L9.43361 10.7966C8.91595 11.6757 8.42605 12.575 7.88968 13.4422C7.61988 13.8771 7.26787 14.288 6.74821 14.3938C6.66941 14.4094 6.58949 14.4185 6.50925 14.421C6.40219 14.425 6.29497 14.423 6.18899 14.423L9.88869 8.02413L14.6397 16.1984H16.7252Z" fill="white"/>
        <path d="M17.775 21.4484C19.5147 21.4484 20.925 20.0381 20.925 18.2984C20.925 16.5587 19.5147 15.1484 17.775 15.1484C16.0353 15.1484 14.625 16.5587 14.625 18.2984C14.625 20.0381 16.0353 21.4484 17.775 21.4484Z" stroke="white" stroke-width="1.5"/>
        <path d="M20.3999 20.3984L23.0249 22.4984" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
  }

export default ApolloSearchIcon;
