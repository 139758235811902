import * as React from "react";

function EmailTemplateIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M11.45 2H1V13.616H11.45V2Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
            <path d="M23 17.8438H1V22.5957H23V17.8438Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
            <path d="M22.9996 2H15.8496V6.22399H22.9996V2Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
            <path d="M22.9991 9.39844H15.8491V13.6224H22.9991V9.39844Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
        </svg>

    );
}

export default EmailTemplateIcon;
