import * as React from "react";

function InfoIcon(props) {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 20 20" fill="none"  {...props}>
      <path  style={props?.style} fill={props?.fill} d="M10 0C15.523 0 20 4.478 20 10C20 15.522 15.523 20 10 20C4.477 20 0 15.522 0 10C0 4.478 4.477 0 10 0ZM10.002 13.004C9.86822 12.9998 9.73495 13.0225 9.6101 13.0707C9.48526 13.119 9.37139 13.1919 9.27526 13.285C9.17913 13.3782 9.10269 13.4897 9.05049 13.6129C8.99829 13.7362 8.9714 13.8686 8.9714 14.0025C8.9714 14.1364 8.99829 14.2688 9.05049 14.3921C9.10269 14.5153 9.17913 14.6268 9.27526 14.72C9.37139 14.8131 9.48526 14.886 9.6101 14.9343C9.73495 14.9825 9.86822 15.0052 10.002 15.001C10.2613 14.9928 10.5073 14.884 10.6879 14.6976C10.8684 14.5113 10.9694 14.262 10.9694 14.0025C10.9694 13.743 10.8684 13.4937 10.6879 13.3074C10.5073 13.121 10.2613 13.0122 10.002 13.004ZM10 5C9.75491 5.00006 9.51838 5.09013 9.33533 5.25309C9.15227 5.41605 9.03543 5.64057 9.007 5.884L9 6L9.002 11.001L9.009 11.118C9.03791 11.361 9.15496 11.5851 9.33797 11.7476C9.52097 11.9101 9.75724 11.9999 10.002 11.9999C10.2468 11.9999 10.483 11.9101 10.666 11.7476C10.849 11.5851 10.9661 11.361 10.995 11.118L11.002 11.001L11 6L10.993 5.883C10.9643 5.63975 10.8474 5.41547 10.6644 5.25272C10.4813 5.08996 10.2449 5.00003 10 5Z" fill="white" />
    </svg>

    // <svg
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   {...props}
    // >
    //   <path
    //     d="M17.77 3.77L16 2L6 12L16 22L17.77 20.23L9.54 12L17.77 3.77Z"
    //     style={props?.style}
    //     fill={props?.fill}
    //   />
    // </svg>
  );
}

export default InfoIcon;
