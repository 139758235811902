import React, { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Image } from "@evabot/eva-react-core-library/dist/atoms/Image/Image";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import userService from "services/userService";
import { axiosHelperCore, CORE_API_URL } from "utils";
import { useAppSelector } from "hooks";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { useEffect, useState } from "react";
import { IntegrationCard } from "./components/IntegrationCard";
import SalesforceImage from "assets/icons/salesforce";
import OutreachIcon from "assets/icons/Outreach";
import SalesloftIcon from "assets/icons/Salesloft";
import HubspotIcon from "assets/icons/Hubspot";
import { json } from "stream/consumers";
import { Backdrop } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import PipelinerIcon from "assets/icons/Pipeliner";
import GongIcon from "assets/icons/gongIcon";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

const IntegrationContent = ({ userData, connector, setConnector }) => {

    const profileData = useAppSelector((state) => state.profileState.profileData);
    const [hideModal, setHideModal] = useState(true);
    const [deleteType, setDeleteType] = useState("");

    const integrationsConnected = connector?.connections?.map(c => c.type);
    const isIntegrationButtonEnabled = (connector?.dualMode?.enabled && connector?.connections?.length < 2 || !connector?.dualMode?.enabled && connector?.connections?.length < 1)
    const [readModeIntegration, setReadModeIntegration] = useState(null);
    const [writeModeIntegration, setWriteModeIntegration] = useState(null);

    const [Integrations, setIntegrations] = useState([]);
    const [loading, setLoading] = useState(false);

    const saveIntegrationMode = async (type, mode) => {
        try {
            const result = await axiosHelperCore({
                url: `rapport/user/updateIntegrationMode`,
                method: "POST",
                JSONData: { type: type, mode: mode }
            });
            setConnector(result?.data?.connector);
        } catch (error) {
            console.error("Error saving outreach field data:", error);
        } finally {
        }
    };

    useEffect(() => {
        if (connector) {
            setIntegrations(
                [
                    {
                        type: "salesforce", url: CORE_API_URL + "salesforce/getSalesforceAuth", icon: SalesforceImage(),
                        isConnected: integrationsConnected?.includes("salesforce"),
                        isEnabled: isIntegrationButtonEnabled,
                        isShowForm: false,
                        isFieldsAdded: connector?.connections?.find(c => c.type === "salesforce")?.fields?.dataSource,
                        connections: connector?.connections, mode: connector?.connections?.find(c => c.type === "salesforce")?.mode
                    },
                    {
                        type: "salesloft", url: CORE_API_URL + "salesloft/getSalesloftAuth",
                        icon: SalesloftIcon(),
                        isConnected: integrationsConnected?.includes("salesloft"),
                        isEnabled: isIntegrationButtonEnabled, isShowForm: false,
                        isFieldsAdded: Object.keys(connector?.connections?.find(c => c.type === "salesloft")?.fields?.tagName || {}).length > 0,
                        connections: connector?.connections, mode: connector?.connections?.find(c => c.type === "salesloft")?.mode
                    },
                    {
                        type: "outreach", url: CORE_API_URL + "outreach/getAuth", icon: OutreachIcon(),
                        isConnected: integrationsConnected?.includes("outreach"),
                        isEnabled: isIntegrationButtonEnabled, 
                        isShowForm: false,
                        isFieldsAdded: Object.keys(connector?.connections?.find(c => c.type === "outreach")?.fields?.tagName || {}).length > 0,
                        connections: connector?.connections,
                        mode: connector?.connections?.find(c => c.type === "outreach")?.mode
                    },
                    {
                        type: "hubspot", url: CORE_API_URL + "hubspot/getAuth",
                        icon: HubspotIcon(),
                        isConnected: integrationsConnected?.includes("hubspot"),
                        isEnabled: isIntegrationButtonEnabled,
                        isShowForm: false,
                        isFieldsAdded: Object.keys(connector?.connections?.find(c => c.type === "hubspot")?.fields?.listId || {}).length > 0,
                        connections: connector?.connections,
                        mode: connector?.connections?.find(c => c.type === "hubspot")?.mode
                    },
                    {
                        type: "pipeliner", 
                        url: "",
                        icon:  PipelinerIcon(),
                        isConnected: integrationsConnected?.includes("pipeliner"),
                        isEnabled: isIntegrationButtonEnabled,
                        isShowForm: false,
                        isFieldsAdded: Object.keys(connector?.connections?.find(c => c.type === "pipeliner")?.fields?.tagName || {}).length > 0,
                        connections: connector?.connections,
                        mode: connector?.connections?.find(c => c.type === "pipeliner")?.mode
                    }
                ]);
        }
    }, [connector])

    useEffect(() => {
        readModeIntegration && saveIntegrationMode(readModeIntegration.type, readModeIntegration.mode);
    }, [readModeIntegration])

    useEffect(() => {
        writeModeIntegration && saveIntegrationMode(writeModeIntegration.type, writeModeIntegration.mode);
    }, [writeModeIntegration])

    const disconnectIntegration = (type) => {
        setDeleteType(type);
        setHideModal(false);
    }

    const deleteIntegration = async () => {
        setLoading(true);
        setConnector(null);
        try {
            const result = await axiosHelperCore({
                url: `${deleteType}/deleteRefreshToken`,
                method: "POST",
                JSONData: { type: deleteType }
            });
            setHideModal(true);
            console.log("result?.data?.data?.connector", result?.data?.connector);
            setLoading(false);
            setConnector(result?.data?.connector);
        } catch (error) {
            console.error("Error saving outreach field data:", error);
        }
    }

    const dateFormat = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    }

    return (
        <>
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}>
                <MemoRevenoidLoader />
            </Backdrop>}

            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={hideModal}
                setHideModal={() => {
                    setHideModal(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="48px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text display={"flex"} mt="24px" fontSize={"24px"} fontWeight={300}>
                        {" "}
                        Are you sure you want to disconnect&nbsp;<Text textTransform={"capitalize"}> {deleteType}</Text>?
                    </Text>

                    <Text color={"rgb(87, 88, 92)"} textAlign={"center"} lineHeight={"1.5"} mt="24px" mb={"24px"} fontSize={"16px"} fontWeight={300}>
                        {" "}
                        All the prospect tags saved previously will be fully deleted from our system.<br></br> You can always connect the app and save the tags manually again.
                    </Text>

                    <Flex mt="15px">
                        <Flex

                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => deleteIntegration()}

                        >
                            Yes
                        </Flex>
                        <Flex
                            ml="16px"
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => setHideModal(true)}

                        >
                            Cancel
                        </Flex>

                    </Flex>
                </Flex>
            </ModalWrapper>

            <Flex flexDirection={"column"} alignItems={"center"} margin={"8px"} marginTop={"20px"} overflowY={"scroll"}>
                <Text fontSize={"24px"} fontWeight={"400"} color={"#050505"} marginBottom={"4px"}>Connect your preferred App</Text>
                <Text fontSize={"16px"} fontWeight={"400"} color={"#57585C"} marginBottom={"20px"}>
                    {
                        profileData.role === "sdr" ? "Kindly request the admin to connect your preferred app before initiating the automation for creating prospect profiles." : "Please connect your preferred app before initiating the automation for creating prospect profiles"
                    }

                </Text>
                <div style={{ marginTop: "20px" }}></div>

                {
                    profileData.role !== "sdr" &&
                    (
                        <Flex alignItems={"flex-start"} gap="30px" flexWrap={"wrap"} className="integration-panel" width={"95%"} height={"auto"} overflowY={"scroll"} style={{scrollbarWidth:"none"}} >
                            {
                                Integrations.sort((a, b) => {
                                    if (b.isConnected !== a.isConnected) {
                                        return b.isConnected - a.isConnected;
                                    } else if (a.mode === 'read' && b.mode !== 'read') {
                                        return -1;
                                    } else if (a.mode !== 'read' && b.mode === 'read') {
                                        return 1;
                                    } else {
                                        return 0;
                                    }
                                }).map(integration => (<IntegrationCard setWriteModeIntegration={setWriteModeIntegration} setReadModeIntegration={setReadModeIntegration} integration={integration} dualMode={connector?.dualMode} disconnectIntegration={disconnectIntegration} setConnector={setConnector}></IntegrationCard>))
                            }
                        </Flex>
                    )
                }

            </Flex>
        </>
    );
};

export default IntegrationContent;
