import React, { FC } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import DeloitteLogoIcon from "assets/icons/DeloitteLogo";
import AmerifirstLogoIcon from "assets/icons/AmerifirstLogo";
import ZendeskLogoIcon from "assets/icons/ZendeskLogo";
import SalesforceButton from "components/SalesforceButton/SalesforceButton";
import GoogleButton from "components/GoogleButton/Googlebutton";
import { useAppSelector } from "hooks";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { axiosHelper, axiosHelperCore } from "utils";

// declare var ChiliPiper: any;

const SignupLeftSection: FC = () => {
  const journey = useAppSelector((state) => state.globalState.journey);

  const triggerSalesforceCall = async () => {
    window.open(
      `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${
        process.env.REACT_APP_SALESFORCE_CLIENT_ID
      }&redirect_uri=${
        process.env.REACT_APP_SALESFORCE_REDIRECT_URL
      }&state=${encodeURIComponent(JSON.stringify(journey))}`,
      "_self"
    );
  };

  const triggerGoogleCall = async () => {
    const result = await axiosHelper({
      url: `google/auth`
    });
    if(result.data.success) {
      window.open(
        `${result.data.url}`,
        "_self"
      );
    }
  }

  const bookDemo = async () => {
    //@ts-ignore
    // ChiliPiper.submit("evabot", "rapport-intelligence-router", { map: true, domElement: "#root", lead: { firstName: 'Abhishek', email: 'abhishek@evabot.ai' } });
    window.open(
      "https://evabot.chilipiper.com/book/rapport-intelligence-queue",
      "targetWindow",
      `toolbar=no,
     location=no,
     status=no,
     menubar=no,
     scrollbars=yes,
     resizable=yes,
     width=800,
     height=550`
    );
  };

  return (
    <>
      <Flex flexDirection="column" alignItems={"center"}>
        {
          //@ts-ignore
          <Text
            marginBottom={24}
            fontSize="24px"
            fontWeight={400}
            color={"white"}
          >
            Get Started
          </Text>
        }
        {/* <Box width="320px" mb="22px">
          <SalesforceButton onClick={triggerSalesforceCall} />
        </Box> */}
        <Box width="320px" mb="22px">
          <GoogleButton onClick={triggerGoogleCall} />
        </Box>
        <Flex marginTop={16}>
          {
            //@ts-ignore
            <Text
              fontSize="12px"
              id="salesforce.account.prompt"
              color={"white"}
            />
          }
          <button
            style={{
              background: "none",
              marginLeft: "5px",
              color: "white",
              marginTop: "0",
              marginBottom: "5px",
              textDecoration: "underline",
              fontSize: "12px",
              border: "none",
              padding: "0",
              cursor: "pointer",
              outline: "inherit",
            }}
            onClick={bookDemo}
          >
            Book a demo
          </button>
        </Flex>
      </Flex>
      <Flex flexDirection={"column"}>
        <Flex justifyContent={"center"}>
          {
            //@ts-ignore
            <Text
              id="trustedbyteams"
              fontSize={12}
              color={"white"}
              height={"auto"}
            />
          }
        </Flex>
        <Flex justifyContent={"space-evenly"} mt={34} mb={50}>
          <DeloitteLogoIcon />
          <ZendeskLogoIcon />
          <AmerifirstLogoIcon />
        </Flex>
      </Flex>
    </>
  );
};

export default SignupLeftSection;
