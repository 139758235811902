import * as React from "react";

function EvaLogoNew(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="133" height="40" viewBox="0 0 133 40" fill="none">
      <path d="M11.3372 27.5556C11.2525 27.701 11.2004 27.8672 11.1907 28.0445L7.07422 28.8685L7.53878 23.7539L11.3372 27.5556Z" fill="#BD99FF"/>
      <path d="M12.0104 27.0392C11.9133 27.0656 11.8215 27.1045 11.7369 27.1542L7.80273 23.2166L11.5651 21.543C11.6814 21.6802 11.8351 21.7847 12.0104 21.8408V27.0392Z" fill="#BD99FF"/>
      <path d="M12.8604 27.1433C12.7722 27.0936 12.6764 27.0555 12.5752 27.0309V21.8487C12.7588 21.7962 12.9202 21.6911 13.0421 21.5508L16.3937 23.0383C16.3903 23.0702 16.3885 23.1025 16.3885 23.1353C16.3885 23.2767 16.4211 23.4105 16.4792 23.5296L12.8604 27.1433Z" fill="#BD99FF"/>
      <path d="M17.2898 22.2365C17.2991 22.2365 17.3084 22.2366 17.3177 22.2369L18.3098 19.5949C18.1974 19.5155 18.1004 19.4157 18.0244 19.3008L13.2803 20.8799C13.2807 20.8915 13.2809 20.9031 13.2809 20.9147C13.2809 20.9559 13.2784 20.9964 13.2734 21.0362L16.6288 22.5254C16.7933 22.3477 17.0285 22.2365 17.2898 22.2365Z" fill="#BD99FF"/>
      <path d="M17.8486 22.4295C17.982 22.5352 18.0847 22.678 18.1412 22.8421H22.7041L19.502 19.6445C19.3386 19.7401 19.1484 19.7949 18.9454 19.7949C18.9098 19.7949 18.8746 19.7932 18.8398 19.7899L17.8486 22.4295Z" fill="#BD99FF"/>
      <path d="M18.1481 23.4062C18.0691 23.6561 17.8839 23.859 17.6455 23.9617L18.0633 28.5515L22.7474 23.4062H18.1481Z" fill="#BD99FF"/>
      <path d="M17.0824 24.0109C17.0092 23.9937 16.9395 23.9675 16.8744 23.9336L13.2676 27.5353C13.3592 27.6861 13.4153 27.8603 13.4246 28.0467L17.5243 28.8655L17.0824 24.0109Z" fill="#BD99FF"/>
      <path d="M12.5752 29.1758C12.8948 29.0982 13.1603 28.885 13.3056 28.6016L17.2312 29.3856L12.5752 33.5701V29.1758Z" fill="#BD99FF"/>
      <path d="M17.8434 18.7644L13.0962 20.3445C12.97 20.1701 12.7875 20.0392 12.5752 19.9785V15.3086L17.8803 18.3989C17.8547 18.4922 17.841 18.5904 17.841 18.6919C17.841 18.7162 17.8418 18.7404 17.8434 18.7644Z" fill="#BD99FF"/>
      <path d="M12.0104 15.3086V19.9867C11.8077 20.0516 11.6339 20.1812 11.5133 20.3512L7.0258 18.8541C7.03194 18.8007 7.0351 18.7464 7.0351 18.6913C7.0351 18.5385 7.01077 18.3914 6.96582 18.2538L12.0104 15.3086Z" fill="#BD99FF"/>
      <path d="M6.84847 19.3906L11.3343 20.8871C11.3341 20.8957 11.334 20.9044 11.334 20.913C11.334 20.9502 11.3361 20.987 11.3401 21.0231L7.47098 22.7442L6.39355 19.8686C6.5805 19.7486 6.73694 19.5844 6.84847 19.3906Z" fill="#BD99FF"/>
      <path d="M5.65524 20.0838C5.37326 20.0838 5.11104 19.9983 4.89256 19.8516L1.90625 22.8405H6.90423L5.86534 20.0677C5.79683 20.0783 5.72667 20.0838 5.65524 20.0838Z" fill="#BD99FF"/>
      <path d="M4.49666 19.4499C4.38381 19.2743 4.30905 19.0713 4.28418 18.853L0 18.4609L1.34966 22.5997L4.49666 19.4499Z" fill="#BD99FF"/>
      <path d="M0.015625 17.8945L4.33378 18.2897C4.41476 18.0175 4.57579 17.7804 4.78923 17.6063L2.31339 12.6758L0.015625 17.8945Z" fill="#BD99FF"/>
      <path d="M11.7834 14.7846L6.68496 17.7614C6.58015 17.6427 6.45555 17.5423 6.31641 17.4654L7.98478 11.9258L11.7834 14.7846Z" fill="#BD99FF"/>
      <path d="M5.65513 17.2969C5.69567 17.2969 5.7358 17.2986 5.77544 17.302L7.43815 11.7812L2.7334 12.2522L5.2912 17.3458C5.40714 17.3139 5.52916 17.2969 5.65513 17.2969Z" fill="#BD99FF"/>
      <path d="M18.1658 17.9088L12.8037 14.7852L16.6118 11.9258L18.3694 17.7484C18.2953 17.7938 18.227 17.8477 18.1658 17.9088Z" fill="#BD99FF"/>
      <path d="M18.911 17.5879L17.1582 11.7812L21.8749 12.2523L19.1774 17.6119C19.1025 17.5958 19.0249 17.5875 18.9452 17.5875C18.9338 17.5875 18.9223 17.5876 18.911 17.5879Z" fill="#BD99FF"/>
      <path d="M19.9839 18.3172C19.9211 18.1439 19.8162 17.9909 19.6816 17.8703L22.296 12.6758L24.5996 17.8958L19.9839 18.3172Z" fill="#BD99FF"/>
      <path d="M19.9014 19.2447C19.9658 19.1337 20.0114 19.0103 20.0338 18.8791L24.6149 18.4609L23.2619 22.6005L19.9014 19.2447Z" fill="#BD99FF"/>
      <path d="M38.2547 14.7325H36.6933C36.6771 15.7734 36.6608 16.8143 36.6445 17.8552C36.6445 18.8961 36.6445 19.937 36.6445 20.9779H38.0595C39.3281 20.9779 40.2308 20.6689 40.7675 20.0508C41.3205 19.4328 41.5969 18.6765 41.5969 17.782C41.5969 15.749 40.4829 14.7325 38.2547 14.7325ZM31.4238 14.6349V13.4883H38.767C40.6699 13.4883 42.1337 13.8542 43.1583 14.5861C44.1992 15.318 44.7196 16.3345 44.7196 17.6356C44.7196 18.2211 44.5733 18.7904 44.2805 19.3433C44.004 19.8963 43.5568 20.3761 42.9387 20.7827C42.3207 21.1893 41.5075 21.4739 40.4991 21.6366C41.5238 21.7992 42.28 22.157 42.768 22.71C43.2722 23.263 43.6381 24.0681 43.8658 25.1252L44.6221 28.3211L46.5006 28.5163V29.6629C46.094 29.7768 45.7362 29.8499 45.4271 29.8825C45.1344 29.9313 44.7522 29.9557 44.2805 29.9557C43.386 29.9557 42.6866 29.8093 42.1825 29.5165C41.6945 29.2238 41.3855 28.7033 41.2554 27.9552L40.5479 24.5641C40.369 23.6533 40.06 23.0353 39.6209 22.71C39.198 22.3685 38.5556 22.1977 37.6936 22.1977H36.6445C36.6445 23.2874 36.6445 24.3364 36.6445 25.3448C36.6608 26.3369 36.6771 27.3209 36.6933 28.2967L38.767 28.5163V29.6629H31.4238V28.5163L33.473 28.2967C33.5056 27.2884 33.5218 26.2637 33.5218 25.2228C33.5218 24.1819 33.5218 23.141 33.5218 22.1001V21.0511C33.5218 20.0264 33.5218 19.0018 33.5218 17.9772C33.5218 16.9363 33.5056 15.8954 33.473 14.8545L31.4238 14.6349Z" fill="#401463"/>
      <path d="M53.0475 18.8077C52.6572 18.8077 52.2668 18.9378 51.8765 19.1981C51.5024 19.442 51.1771 19.8486 50.9007 20.4179C50.6404 20.9871 50.4778 21.7597 50.4127 22.7355H53.755C54.3405 22.7355 54.7308 22.6135 54.926 22.3696C55.1212 22.1256 55.2188 21.7841 55.2188 21.3449C55.2188 20.5968 55.0073 19.9869 54.5845 19.5152C54.1616 19.0436 53.6493 18.8077 53.0475 18.8077ZM53.0475 30.0056C51.909 30.0056 50.9088 29.7616 50.0468 29.2737C49.1848 28.7858 48.5098 28.0864 48.0219 27.1756C47.534 26.2486 47.29 25.1507 47.29 23.8821C47.29 22.581 47.5665 21.4669 48.1195 20.5399C48.6887 19.5965 49.4206 18.8809 50.3152 18.393C51.2259 17.8888 52.1936 17.6367 53.2183 17.6367C54.1779 17.6367 55.0155 17.84 55.7311 18.2466C56.4467 18.6532 56.9997 19.2225 57.39 19.9544C57.7966 20.6862 57.9999 21.5482 57.9999 22.5403C57.9999 23.0608 57.9511 23.5081 57.8535 23.8821H50.3883C50.4534 25.2808 50.8193 26.3299 51.4862 27.0292C52.1693 27.7286 53.0231 28.0783 54.0478 28.0783C54.7634 28.0783 55.3651 27.94 55.8531 27.6635C56.3573 27.3708 56.8127 26.9967 57.2193 26.5413L57.9267 27.1756C57.4551 28.0701 56.8045 28.7695 55.975 29.2737C55.1618 29.7616 54.186 30.0056 53.0475 30.0056Z" fill="#401463"/>
      <path d="M66.5576 19.0012V17.9766H71.1929V19.0012L69.8268 19.294L65.8257 29.7599H64.3865L59.9707 19.2208L58.7021 19.0012V17.9766H65.1182V19.0012L63.2398 19.294L65.9233 26.3688L68.3385 19.3184L66.5576 19.0012Z" fill="#401463"/>
      <path d="M77.4439 18.8077C77.0536 18.8077 76.6633 18.9378 76.2729 19.1981C75.8989 19.442 75.5736 19.8486 75.2971 20.4179C75.0369 20.9871 74.8742 21.7597 74.8092 22.7355H78.1514C78.7369 22.7355 79.1273 22.6135 79.3224 22.3696C79.5176 22.1256 79.6152 21.7841 79.6152 21.3449C79.6152 20.5968 79.4038 19.9869 78.9809 19.5152C78.558 19.0436 78.0457 18.8077 77.4439 18.8077ZM77.4439 30.0056C76.3054 30.0056 75.3052 29.7616 74.4432 29.2737C73.5812 28.7858 72.9063 28.0864 72.4183 27.1756C71.9304 26.2486 71.6865 25.1507 71.6865 23.8821C71.6865 22.581 71.9629 21.4669 72.5159 20.5399C73.0852 19.5965 73.817 18.8809 74.7116 18.393C75.6224 17.8888 76.5901 17.6367 77.6147 17.6367C78.5743 17.6367 79.4119 17.84 80.1275 18.2466C80.8431 18.6532 81.3961 19.2225 81.7864 19.9544C82.193 20.6862 82.3963 21.5482 82.3963 22.5403C82.3963 23.0608 82.3476 23.5081 82.25 23.8821H74.7848C74.8498 25.2808 75.2158 26.3299 75.8826 27.0292C76.5657 27.7286 77.4195 28.0783 78.4442 28.0783C79.1598 28.0783 79.7616 27.94 80.2495 27.6635C80.7537 27.3708 81.2091 26.9967 81.6157 26.5413L82.3232 27.1756C81.8515 28.0701 81.2009 28.7695 80.3715 29.2737C79.5583 29.7616 78.5824 30.0056 77.4439 30.0056Z" fill="#401463"/>
      <path d="M84.1688 29.664V28.6638L85.7545 28.3466C85.7708 27.7448 85.7789 27.0943 85.7789 26.3949C85.7789 25.6793 85.7789 25.0613 85.7789 24.5408V23.2478C85.7789 22.581 85.7708 22.0524 85.7545 21.6621C85.7545 21.2555 85.7383 20.8082 85.7057 20.3203L83.998 20.1007V19.1737L88.0722 17.6367L88.4625 17.8807L88.6333 19.7592C89.8856 18.3442 91.2681 17.6367 92.7806 17.6367C93.8378 17.6367 94.651 17.9864 95.2202 18.6858C95.8057 19.3851 96.0985 20.4911 96.0985 22.0036V24.5408C96.0985 25.0938 96.0985 25.72 96.0985 26.4193C96.0985 27.1187 96.1066 27.7692 96.1229 28.371L97.6111 28.6638V29.664H91.5852V28.6638L93.1466 28.3466C93.1628 27.7448 93.171 27.1024 93.171 26.4193C93.171 25.72 93.171 25.0938 93.171 24.5408V22.2232C93.171 21.2636 93.0408 20.6049 92.7806 20.2471C92.5204 19.873 92.0813 19.686 91.4632 19.686C90.6012 19.686 89.6904 20.0845 88.7309 20.8814V24.5408C88.7309 25.0775 88.7309 25.6956 88.7309 26.3949C88.7309 27.0943 88.739 27.753 88.7553 28.371L90.1946 28.6638V29.664H84.1688Z" fill="#401463"/>
      <path d="M105.035 30.0056C103.929 30.0056 102.929 29.7616 102.034 29.2737C101.156 28.7858 100.457 28.0783 99.936 27.1512C99.4155 26.2242 99.1553 25.1182 99.1553 23.8333C99.1553 22.5322 99.4237 21.4181 99.9604 20.4911C100.514 19.564 101.238 18.8565 102.132 18.3686C103.027 17.8807 103.994 17.6367 105.035 17.6367C106.076 17.6367 107.044 17.8807 107.938 18.3686C108.833 18.8565 109.557 19.564 110.11 20.4911C110.663 21.4019 110.939 22.5159 110.939 23.8333C110.939 25.1345 110.671 26.2486 110.134 27.1756C109.614 28.0864 108.906 28.7858 108.012 29.2737C107.133 29.7616 106.141 30.0056 105.035 30.0056ZM105.035 28.8101C105.913 28.8101 106.58 28.4035 107.036 27.5903C107.507 26.7771 107.743 25.5329 107.743 23.8577C107.743 20.5073 106.84 18.8321 105.035 18.8321C103.246 18.8321 102.352 20.5073 102.352 23.8577C102.352 25.5329 102.579 26.7771 103.035 27.5903C103.49 28.4035 104.157 28.8101 105.035 28.8101Z" fill="#401463"/>
      <path d="M112.614 29.6637V28.6635L114.224 28.3463C114.24 27.7445 114.248 27.094 114.248 26.3946C114.248 25.679 114.248 25.061 114.248 24.5405V23.3939C114.248 22.7271 114.24 22.1741 114.224 21.735C114.224 21.2796 114.207 20.8079 114.175 20.32L112.443 20.1004V19.1734L116.834 17.6364L117.224 17.8804L117.151 21.3202V24.5405C117.151 25.061 117.151 25.679 117.151 26.3946C117.151 27.094 117.159 27.7527 117.176 28.3707L118.664 28.6635V29.6637H112.614ZM115.688 15.3676C115.167 15.3676 114.728 15.2049 114.37 14.8797C114.012 14.5544 113.833 14.1396 113.833 13.6355C113.833 13.115 114.012 12.6921 114.37 12.3669C114.728 12.0416 115.167 11.8789 115.688 11.8789C116.208 11.8789 116.639 12.0416 116.981 12.3669C117.338 12.6921 117.517 13.115 117.517 13.6355C117.517 14.1396 117.338 14.5544 116.981 14.8797C116.639 15.2049 116.208 15.3676 115.688 15.3676Z" fill="#401463"/>
      <path d="M123.339 23.8818C123.339 25.3619 123.616 26.4677 124.169 27.1996C124.738 27.9152 125.453 28.2731 126.315 28.2731C126.738 28.2731 127.121 28.1918 127.462 28.0292C127.82 27.8503 128.153 27.5982 128.462 27.2729V20.2711C127.747 19.6531 127.039 19.344 126.34 19.344C125.527 19.344 124.819 19.71 124.217 20.4419C123.632 21.1738 123.339 22.3205 123.339 23.8818ZM128.755 29.932L128.56 28.3951C128.105 28.9156 127.6 29.314 127.047 29.5905C126.494 29.867 125.86 30.0052 125.144 30.0052C124.185 30.0052 123.331 29.7612 122.583 29.2733C121.851 28.7854 121.274 28.086 120.851 27.1752C120.428 26.2644 120.216 25.1829 120.216 23.9305C120.216 22.6457 120.452 21.5317 120.924 20.5884C121.412 19.645 122.054 18.9212 122.851 18.417C123.664 17.8965 124.551 17.6363 125.51 17.6363C126.128 17.6363 126.681 17.7502 127.169 17.9779C127.674 18.2056 128.145 18.5796 128.584 19.1001V14.0745L126.681 13.8305V12.8791L131.146 11.7812L131.512 12.0008L131.414 15.4407V28.3951L133 28.6635V29.6637L128.755 29.932Z" fill="#401463"/>
    </svg>

  );
}

export default EvaLogoNew;
