
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex';
import { ErrorMessage, Form, Formik, Field } from 'formik';
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { FormikTextArea } from "@evabot/eva-react-core-library/dist/atoms/FormikTextArea/FormikTextArea";
import { useEffect, useState } from "react";
import { axiosHelperCore } from "utils";
import GongQuestionHeader from "./GongQuestionHeader";
import GongQuestionBody from "./GongQuestionBody";
import * as yup from 'yup';
import AddIcon from "assets/icons/AddIcon";

export default function GongTable() {
    const [hideModal, setHideModal] = useState(true);
    const [hideDeleteModal, setHideDeleteModal] = useState(true);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [list, setList] = useState([]);

    const questionSchema = yup.object().shape({
        question: yup.string().required("Question is required."),
    });


    const editQuestion = (item) => {
        setSelectedQuestion(item);
        setHideModal(false);
        setIsEditMode(true);
    }

    const updateQuestionStatus = async (questionId, status) => {
        const result = await axiosHelperCore({
            url: 'question/updateStatus',
            method: 'POST',
            JSONData: { questionId: questionId, status: status }
        });
        getList();
    }

    const deleteQuestion = async (item) => {
        setHideDeleteModal(false);
        setSelectedQuestion(item);
    }

    const saveQuestion = async (values) => {
        const result = await axiosHelperCore({
            url: 'question/saveQuestion',
            method: 'POST',
            JSONData: { questionId: selectedQuestion?.questionId, question: values.question }
        });
        getList();
        setSelectedQuestion(null);
        setHideModal(true);
    }

    const getList = async () => {
        const result = await axiosHelperCore({
            url: 'question/getList',
            method: 'POST',
        });
        setList(result?.data?.data);
    }

    useEffect(() => {
        getList();
    }, [])

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                <div style={{fontSize:"20px"}}>Recommended Question Set</div>
                <Button size="xs" onClick={() => setHideModal(false)} variant="secondary" fontSize={"14px"} padding={"10px 10px"}>
                    <div style={{ display: "flex", alignItems: "center", gap:"3px" }}>
                        <AddIcon width="16px" height="16px"></AddIcon><span>Add Question</span>
                    </div>

                </Button>
            </div>

            <div>
                <GongQuestionHeader></GongQuestionHeader>
                {list.map((item, index) => (
                    <GongQuestionBody updateQuestionStatus={updateQuestionStatus} deleteQuestion={deleteQuestion} editQuestion={editQuestion} item={item} index={index}></GongQuestionBody>
                ))}

            </div>

            <ModalWrapper
                width="40%"
                height="max-content"

                hideModal={hideModal}
                setHideModal={() => {
                    setHideModal(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="24px"
            >

                <>
                    {/* <SnackBar
        iconName={"info"}
        message={snackbarMessageInModal}
        setShowSnackBar={setShowSnackBarInModal}
        show={showSnackBarInModal}
        timeout={4000}
        anchor={"top-right"}
        type={snackbarTypeInModal}
    /> */}
                    <Text fontWeight="400" fontSize="24px" marginBottom={4}>{isEditMode ? "Edit Question" : "Add Question"}</Text>
                    <Formik
                        initialValues={{
                            question: selectedQuestion?.question || "",
                        }}
                        onSubmit={async (values) => {
                            console.log('Submitting edited data:', values);
                            await saveQuestion(values);
                        }}
                        validationSchema={questionSchema}

                    >
                        {({ setFieldValue, values }) => (
                            <Form style={{ maxHeight: 600, width: "100%", overflowY: 'auto' }}>
                                <Flex flexDirection="column">
                                    <Flex padding={"0 10px"} flexDirection="column" >
                                        {/* <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Question</Text> */}
                                        <FormikTextArea marginBottom={0}
                                            name="question"
                                            placeholder="enter question"
                                            width={"92%"}
                                        // onChange={(event) => handleDescriptionChange(event, setFieldValue)}
                                        />
                                    </Flex>

                                    <Flex marginRight={1} marginTop={3} padding={"5px 10px"}>
                                        <Flex justifyContent={"left"}>
                                            <Button size="xs" type="submit">Save</Button>
                                        </Flex>
                                        <Flex justifyContent={"right"}>
                                            <Button size="xs" variant="secondary" type="button" onClick={() => { setHideModal(true); setIsEditMode(false); setSelectedQuestion(null); }} marginLeft={2}>
                                                Cancel
                                            </Button>
                                        </Flex>
                                    </Flex>

                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </>
            </ModalWrapper>

            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={hideDeleteModal}
                setHideModal={() => {
                    setHideDeleteModal(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="48px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text display={"flex"} mt="24px" fontSize={"24px"} fontWeight={300}>
                        {" "}
                        Are you sure you want to delete this question?
                    </Text>

                    <Flex mt="15px">
                        <Flex

                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={async () => {
                                const result = await axiosHelperCore({
                                    url: 'question/deleteQuestion',
                                    method: 'POST',
                                    JSONData: { questionId: selectedQuestion.questionId }
                                });
                                getList();
                                setHideDeleteModal(true);
                            }}

                        >
                            Yes
                        </Flex>
                        <Flex
                            ml="16px"
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => setHideDeleteModal(true)}

                        >
                            Cancel
                        </Flex>

                    </Flex>
                </Flex>
            </ModalWrapper>
        </>
    )
}
