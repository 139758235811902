import { Box } from '@evabot/eva-react-core-library/dist/atoms/Box/Box';
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import userService from 'services/userService';
import { Backdrop } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useAppSelector } from 'hooks';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import { axiosHelperCore } from 'utils';
import { AntSwitch, Container1Title, SettingCard, Tags, Text1, WebsiteContainer, WebsiteListContainer } from './subcomponent/Style';
import KeywordTagManager from './subcomponent/KeywordTagManager';
import { Box as Box1, Typography, TextareaAutosize, FormControl } from '@mui/material';
import { ToggleButton } from '@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton';
import MemoRevenoidLoader from 'components/RevenoidLoader/RevenoidLoader';

const websitesList = [
    { regex: /https:\/\/.*\.apollo\.io\/.*/, name: "Apollo", key: "apollo" },
    { regex: /https:\/\/.*\.outreach\.io\/.*/, name: "Outreach", key: "outreach" },
    { regex: /https:\/\/.*\.linkedin\.com\/.*/, name: "Linkedin", key: "linkedin" },
    { regex: /https:\/\/.*\.salesloft\.com\/.*/, name: "Salesloft", key: "salesloft" },
    { regex: /https:\/\/.*\.hubspot\.com\/.*/, name: "Hubspot", key: "hubspot" },
    { regex: /https:\/\/.*\.(salesforce|force)\.com\/.*/, name: "Salesforce", key: "salesforce" },
    { regex: /https:\/\/.*\.(frontspin)\.com\/.*/, name: "FrontSpin", key: "frontspin" },
    { regex: /^https:\/\/[a-zA-Z0-9.-]+\.app\.gong\.io\/?$/, name: "Gong", key: "gong" },
];

interface KeywordData {
    trackingKeywords: string[];
    hiringKeywords: string[];
    factKeywords: string[];
    trackingKeywordsUpdatedOn?: string;
    hiringKeywordsUpdatedOn?: string;
    factKeywordsUpdatedOn?: string;
    trackingKeywordsUpdatedBy?: string;
    hiringKeywordsUpdatedBy?: string;
    factKeywordsUpdatedBy?: string;
    trackingLimit?: number;
    hiringLimit?: number;
    factLimit?: number;
}

const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    };
};


const GeneralInfo = ({ integration, refetchData, userInfoData, userInfo}) => {

    const DELAY = 2000;
    const MAX_LENGTH_FOR_PERSONA = 150;
    const componentMount = useRef(false);
    const timerRef = useRef<number | ReturnType<typeof setTimeout>>(undefined);

    let userData = useAppSelector(
        (state) => state.memberListingState?.tabViewState?.isTabViewMemberData
    );

    const loggedInMemberDetails = useAppSelector((state) => state.profileState.profileData);

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [loading, setLoading] = useState(false);
    const [callPrepText, setCallPrepText] = useState(userInfoData?.callPrepCustomField);
    const [trackingKeywordsUpdatedOn, setTrackingKeywordsUpdatedOn] = useState("");
    const [hiringKeywordsUpdatedOn, setHiringKeywordsUpdatedOn] = useState("");
    const [factKeywordsUpdatedOn, setFactKeywordsUpdatedOn] = useState("");
    const [pitch, setPitch] = useState(userInfoData?.pitch);
    const [persona, setPersona] = useState(userInfoData?.persona);
    const [autoOpen, setAutoOpen] = useState(userInfoData?.autoOpen);
    const [autoProcessing, setAutoProcessing] = useState(userInfoData?.autoProcessing ?? false);
    const [isSequenceAutomationOn, setIsSequenceAutomationOn] = useState(userInfoData?.sequenceEnableAtAccount ?? false);
    const [isAutoPersonaSelectionSetting, setIsAutoPersonaSelectionSetting] = useState(userInfoData?.autoPersonaSelection ?? false);
    const [isProspectUseCaseEnabled, setIsProspectUseCaseEnabled] = useState(userInfoData?.prospectUseCaseEnabled ?? false);
    const [isUserUseCaseEnabled, setIsUserUseCaseEnabled] = useState(userInfoData?.userUseCaseEnabled ?? false);
    const [isDataRepositoryEnabled, setIsDataRepositoryEnabled] = useState(userInfoData?.dataRepositoryFeature ?? false);
    const [isSalesNavigatorEnabled, setIsSalesNavigatorEnabled] = useState(userInfoData?.salesNavigatorFeature ?? false);
    const [ssoOrganizationId, setSsoOrganizationId] = useState(userInfoData?.ssoOrganizationId);
    const [subscriptionAccountSettings, setSubscriptionAccountSettings] = useState(userInfoData?.subscriptionAccountLimit);
    const [subscriptionProspectSettings, setSubscriptionProspectSettings] = useState(userInfoData?.subscriptionProspectLimit);
    const [evabotTagField, setEvabotTagField] = useState(userInfoData?.evabotTagField);
    const [hasMounted, setHasMounted] = useState(false);
    const [keywordArray, setKeywordArray] = useState<KeywordData>({
        trackingKeywords: [],
        hiringKeywords: [],
        factKeywords: [],
    });


    const uniqueTypesSet = new Set();
    integration?.forEach(item => uniqueTypesSet.add(item.type));
    const uniqueTypesArray = Array.from(uniqueTypesSet);
    const label = "Keywords";
    const label2 = "Integration";

    const viewMemberOrgId = useAppSelector((state) => state.memberListingState.tabViewState.isOrgId);

    const countWords = (str) => {
        str = str.trim();
        let words = str.split(/\s+/);
        return words.length;
    };

    const fetchCompanyKeywords = async (orgId) => {
        try {
            const obj = {organizationId : orgId};
            userService.companyKeywords(obj)
                .then((response) => {
                    setKeywordArray(response?.data);
                })
        }
        catch (error) {
            console.error("Error fetching company list", error);
        }
    };

    const handleSaveCompanyKeywords = async (keywords) => {

        setLoading(true);
        const obj = {
            "organizationId": viewMemberOrgId,
            "keywords": keywords,
        };
        try {
            userService.saveCompanyKeywords(obj)
                .then((response) => {
                    fetchCompanyKeywords(viewMemberOrgId);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
        }

    }

    const handleDelete = (word) => {

        setLoading(true);
        const obj = {
            "organizationId": viewMemberOrgId,
            "keywords": keywordArray?.trackingKeywords?.filter(item => item !== word),
        };
        try {
            userService.saveCompanyKeywords(obj)
                .then((response) => {
                    fetchCompanyKeywords(viewMemberOrgId);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
        }
    }

    const handleHiringKeywordDelete = (word) => {

        setLoading(true);
        const obj = {
            "organizationId": viewMemberOrgId,
            "keywords": keywordArray?.hiringKeywords?.filter(item => item !== word),
        };
        try {
            userService.saveHiringKeywords(obj)
                .then((response) => {
                    fetchCompanyKeywords(viewMemberOrgId);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
        }
        
    }

    const handleFactKeywordDelete = (word) => {

        setLoading(true);
        const obj = {
            "organizationId": viewMemberOrgId,
            "keywords": keywordArray?.factKeywords?.filter(item => item !== word),
        };
        try {
            userService.saveFactKeywords(obj)
                .then((response) => {
                    fetchCompanyKeywords(viewMemberOrgId);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
        }
    }

    const handleFactSubmit = async (keywords) => {

        setLoading(true);
        const obj = {
            "organizationId": viewMemberOrgId,
            keywords
        };
        try {
            userService.saveFactKeywords(obj)
                .then((response) => {
                    fetchCompanyKeywords(viewMemberOrgId);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
        }
    }

    const handleJobSubmit = async (jobs) => {

        setLoading(true);
        const obj = {
            "organizationId": viewMemberOrgId,
            "keywords": jobs,
        };
        try {
            userService.saveHiringKeywords(obj)
                .then((response) => {
                    fetchCompanyKeywords(viewMemberOrgId);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
        }
    }


    const saveCallPrep = async( callPrepText) => {
        const obj = { organizationId: viewMemberOrgId, fieldName: callPrepText };
        try {

            const result = await userService.saveCallPrep(obj);

            if(result?.success){
                setSnackbarMessage('Call Prep Text updated!');
                setSnackbarType('success');
                setShowSnackBar(true);  
            }
        } catch (error) {
            console.error("Error fetching call prep", error);
        }
    }

    const changeKeywordLimit = async (type, limit) => {
        const result: any = await axiosHelperCore({ url: `rapport/user/updateKeywordLimit`, method: "POST", JSONData: { type: type, limit: limit, organizationId: viewMemberOrgId } });
        if (result?.data?.success) {

            if(result?.data?.result?.status === "error"){
                setSnackbarMessage(result?.data?.result?.message);
                setSnackbarType('error');
                setShowSnackBar(true);
            }else{

                setKeywordArray((prevKeywords) => ({
                    ...prevKeywords,
                    [type + "Limit"]: limit
                }));
                setSnackbarMessage('Keyword limit changed!');
                setSnackbarType('success');
                setShowSnackBar(true);
            }
        }
    }

    const submitForm = useCallback(async () => {

        if (countWords(pitch || "") <= 300 && pitch?.trim().length > 1 && countWords(persona?.trim()) <= MAX_LENGTH_FOR_PERSONA) {

          const obj = {
            userId: userData?.userId,
            pitch,
            persona: persona.trim()
          };
            try {
                userService.updateMemberPitch(obj)
                    .then((response) => {

                        if(response?.success){
                            setShowSnackBar(true);
                            setSnackbarType('success');
                            setSnackbarMessage('Persona/Pitch saved successfully');
                        }
                });
            } catch (error) {
                console.error("Error fetching member pitch/persona", error);
            }
        }
    }, [persona, pitch]);

    const handleAutoOpenSettingChange = async (websiteList) => {

        setLoading(true);
        const obj = {
            websiteList,
            autoProcessing: autoProcessing
        };
        try {
            userService.saveAutoOpenSetting(obj)
                .then((response) => {
                    setAutoOpen(response?.autoOpen);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
        }
    }
    
    const handleAutoProcessingSettingChange = async (checked) => {

        setLoading(true);
        const obj = {
            autoProcessing: checked,
            websitesList: autoOpen
        };
        try {
            userService.saveAutoOpenSetting(obj)
                .then((response) => {
                    setAutoProcessing(response?.autoProcessing);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
        }
    }

    const saveSequenceSettings = async (value) => {
        const result: any = await axiosHelperCore({
            url: `rapport/user/saveSequenceAccountSettings`,
            method: "POST",
            JSONData: { enabled: value, organizationId :  viewMemberOrgId}
        });

        if(result?.data?.success){
            setShowSnackBar(true);
            setSnackbarType('success');
            setSnackbarMessage('Sequence setting saved successfully');
            refetchData();
            userInfo();
        }
    };

    const saveAutoPersonaSelectionSettings = async (value) => {
        setLoading(true);
        const result: any = await axiosHelperCore({
            url: `rapport/user/saveAutoPersonaSelectionSettings`,
            method: "POST",
            JSONData: { enabled: value, organizationId :  viewMemberOrgId}
        });

        if(result?.data?.success){
            setShowSnackBar(true);
            setSnackbarType('success');
            setSnackbarMessage('Auto Persona Selection setting saved successfully');
            refetchData();
            userInfo();
        }
        setLoading(false);
    };

    const saveProspectUseCaseEnabledSettings = async (value) => {
        setLoading(true);
        const result: any = await axiosHelperCore({
            url: `rapport/user/saveUseCaseEnabledSettings`,
            method: "POST",
            JSONData: { enabledProspectUseCase: value, organizationId :  viewMemberOrgId}
        });

        if(result?.data?.success){
            setShowSnackBar(true);
            setSnackbarType('success');
            setSnackbarMessage('Prospect Use Case enabled setting saved successfully');
            refetchData();
            userInfo();
        }
        setLoading(false);
    };

    const saveUserUseCaseEnabledSettings = async (value) => {
        setLoading(true);
        const result: any = await axiosHelperCore({
            url: `rapport/user/saveUseCaseEnabledSettings`,
            method: "POST",
            JSONData: { enabledUserUseCase: value, organizationId :  viewMemberOrgId}
        });

        if(result?.data?.success){
            setShowSnackBar(true);
            setSnackbarType('success');
            setSnackbarMessage('User Use Case enabled setting saved successfully');
            refetchData();
            userInfo();
        }
        setLoading(false);
    };

    const saveDataRepositoryEnabledSettings = async (value) => {
        setLoading(true);
        const result: any = await axiosHelperCore({
            url: `rapport/user/saveDataRepositoryEnabledSettings`,
            method: "POST",
            JSONData: { enabledDataRepository: value, organizationId :  viewMemberOrgId}
        });

        if(result?.data?.success){
            setShowSnackBar(true);
            setSnackbarType('success');
            setSnackbarMessage('Content Library setting saved successfully');
            refetchData();
            userInfo();
        }
        setLoading(false);
    };

    const saveSalesNavigatorEnabledSettings = async (value) => {
        setLoading(true);
        const result: any = await axiosHelperCore({
            url: `rapport/user/saveSalesNavigatorEnabledSettings`,
            method: "POST",
            JSONData: { enabledSaleNavigator: value, organizationId :  viewMemberOrgId}
        });

        if(result?.data?.success){
            setShowSnackBar(true);
            setSnackbarType('success');
            setSnackbarMessage('Sales Navigator feature setting saved successfully');
            refetchData();
            userInfo();
        }
        setLoading(false);
    };

    const saveSsoAccountSettings = async (ssoOrganizationId) => {
        const result: any = await axiosHelperCore({
            url: `rapport/user/saveSsoAccountSettings`,
            method: "POST",
            JSONData: { ssoOrganizationId: ssoOrganizationId, organizationId :  viewMemberOrgId}
        });

        if(result?.data?.success){
            setShowSnackBar(true);
            setSnackbarType('success');
            setSnackbarMessage('SSO field saved successfully');
        }
    }

    const saveSubscriptionAccountSettings = async (subscriptionAccountSettings) => {
        const result: any = await axiosHelperCore({
            url: `rapport/user/saveSubscriptionAccountSettings`,
            method: "POST",
            JSONData: { accountLimit: subscriptionAccountSettings, organizationId :  viewMemberOrgId}
        });

        if(result?.data?.success){
            setShowSnackBar(true);
            setSnackbarType('success');
            setSnackbarMessage('Subscription account limit saved successfully');
        }
    }

    const saveSubscriptionProspectSettings = async (subscriptionProspectSettings) => {
        const result: any = await axiosHelperCore({
            url: `rapport/user/saveSubscriptionProspectSettings`,
            method: "POST",
            JSONData: { prospectLimit: subscriptionProspectSettings, organizationId :  viewMemberOrgId}
        });

        if(result?.data?.success){
            setShowSnackBar(true);
            setSnackbarType('success');
            setSnackbarMessage('Subscription prospect limit saved successfully');
        }
    }

    const saveEvabotTagField = async (evabotTagField) => {
        const result: any = await axiosHelperCore({
            url: `rapport/user/saveEvabotTagField`,
            method: "POST",
            JSONData: { evabotTagField: evabotTagField, organizationId :  viewMemberOrgId}
        });

        if(result?.data?.success){
            setShowSnackBar(true);
            setSnackbarType('success');
            setSnackbarMessage('Evabot tag saved successfully');
        }
    };

    const debouncedSaveSubscriptionAccountSettings = useCallback(debounce(saveSubscriptionAccountSettings, 300), []);
    const debouncedSaveSubscriptionProspectSettings = useCallback(debounce(saveSubscriptionProspectSettings, 300), []);
    const debouncedSaveSsoAccountSettings = useCallback(debounce(saveSsoAccountSettings, 300), []);
    const debouncedSaveEvabotTagField = useCallback(debounce(saveEvabotTagField, 300), []);
    const debouncedHandleSaveCallPrep = useCallback(debounce(saveCallPrep, 300), []);
    const debouncedPersonaAndPitch = useCallback(debounce(submitForm, 300), [submitForm]);

    useEffect(() => {
        if (!hasMounted) return;
        if(subscriptionAccountSettings || subscriptionAccountSettings === ''){
            debouncedSaveSubscriptionAccountSettings(subscriptionAccountSettings);
        }
        
    }, [subscriptionAccountSettings, debouncedSaveSubscriptionAccountSettings]);

    useEffect(() => {
        if (!hasMounted) return;
        if(subscriptionProspectSettings || subscriptionProspectSettings === ''){
            debouncedSaveSubscriptionProspectSettings(subscriptionProspectSettings);
        }
        
    }, [subscriptionProspectSettings, debouncedSaveSubscriptionProspectSettings]);

    useEffect(() => {
        if (!hasMounted) return;
        if(ssoOrganizationId || ssoOrganizationId === ''){
            debouncedSaveSsoAccountSettings(ssoOrganizationId);
        }
    }, [ssoOrganizationId, debouncedSaveSsoAccountSettings]);

    useEffect(() => {
        if (!hasMounted) return;
        if(evabotTagField || evabotTagField === ''){
            debouncedSaveEvabotTagField(evabotTagField);
        }
    }, [evabotTagField, debouncedSaveEvabotTagField]);

    useEffect(() => {
        if (!hasMounted) return;
        if(callPrepText || callPrepText === ''){
            debouncedHandleSaveCallPrep(callPrepText);
        }
    }, [callPrepText, debouncedHandleSaveCallPrep]);

    useEffect(() => {
        if (!hasMounted) return;
        if(persona || persona === ''){
            debouncedPersonaAndPitch();
        }
    }, [persona, debouncedPersonaAndPitch]);

    useEffect(() => {
        if (!hasMounted) return;
        if(pitch || pitch === ''){
            debouncedPersonaAndPitch();
        }
    }, [pitch, debouncedPersonaAndPitch]);

    useEffect(() => {
        const hiringdate = new Date(keywordArray?.hiringKeywordsUpdatedOn);
        const trackingdate = new Date(keywordArray?.trackingKeywordsUpdatedOn);
        const factdate = new Date(keywordArray?.factKeywordsUpdatedOn);

        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];


        const dayhiring = hiringdate.getDate();
        const daytracking = trackingdate.getDate();
        const dayfact = factdate.getDate();

        const monthhiring = monthNames[hiringdate.getMonth()];
        const monthtracking = monthNames[trackingdate.getMonth()];
        const monthfact = monthNames[factdate.getMonth()];

        const resulthiring = `${dayhiring} ${monthhiring}`;
        const resulttracking = `${daytracking} ${monthtracking}`;
        const resultfact = `${dayfact} ${monthfact}`;

        setTrackingKeywordsUpdatedOn(resulttracking);
        setHiringKeywordsUpdatedOn(resulthiring);
        setFactKeywordsUpdatedOn(resultfact);


    }, [keywordArray])


    useEffect(() => {
        if (viewMemberOrgId) {
            fetchCompanyKeywords(viewMemberOrgId);
        }
    }, [viewMemberOrgId]);

    useEffect(() => {
        userInfo();
        setHasMounted(true);
    },[]);

    return (
        <div>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            {loading &&
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <MemoRevenoidLoader />
                </Backdrop>}
            <Box padding={"40px"} backgroundColor="white" border="1px solid #ddd" boxShadow="0px 0px 8px rgba(0,0,0,0.1)" borderRadius="4px">
                <Flex flexDirection={"column"} gap="35px">
                    <Flex flexDirection={"row"}>
                        <Flex width={"25%"} opacity={0.7}>Integration Type</Flex>
                        <Flex width={"75%"}> {uniqueTypesArray.join(",") || "N/A"}</Flex>
                    </Flex>
                    <Flex flexDirection={"column"} border={"1px solid lightgrey"} borderRadius={"5px"}  padding={"15px"} gap={"15px"}>
                        <Flex flexDirection={"row"} alignItems={"center"}>
                            <Flex width={"25%"} opacity={0.7}>Tracking Keywords Limit</Flex>
                            <Flex width={"75%"} padding={"20px"}>
                                <select onChange={(e) => { changeKeywordLimit("tracking", e.target.value) }}
                                    value={keywordArray.trackingLimit}
                                    style={{ padding: "10px", border: "1px solid lightgrey", borderRadius: "7px" }}
                                >
                                    {Array.from({ length: 25 }, (_, i) => (
                                        <option key={i + 1} value={i + 1}>
                                            {i + 1}
                                        </option>
                                    ))}
                                </select>
                            </Flex>
                        </Flex>
                        <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                            <Flex width={"25%"} flexDirection={"column"}>
                                <Flex opacity={0.7} >Tracking Keywords</Flex>
                                <Flex><div style={{ fontSize: "12px", fontStyle: "italic", color: "#0094E3" }}>Updated By&nbsp;<span><b>{keywordArray?.trackingKeywordsUpdatedBy || "-"}</b></span>&nbsp;on&nbsp;<span><b>{trackingKeywordsUpdatedOn !== "NaN undefined" ? trackingKeywordsUpdatedOn : "-"}</b></span></div></Flex>
                            </Flex>
                            <Flex width={"75%"}>
                                <SettingCard>
                                    <Box1>
                                        <KeywordTagManager
                                            name='Keywords'
                                            defaultValue={keywordArray?.trackingKeywords || []}
                                            placeholder='e.g. growth challenges, acquisition etc.'
                                            onSubmit={handleSaveCompanyKeywords}
                                            loading={loading}
                                            MAX_LENGTH={keywordArray?.trackingLimit}
                                        />

                                        <Box1 sx={{ marginTop: '10px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                            {keywordArray?.trackingKeywords?.map(words => (
                                                <Tags key={words} disabled={loading}>
                                                    {words}
                                                    <svg onClick={() => handleDelete(words)} xmlns="http://www.w3.org/2000/svg" style={{ minWidth: 9 }} width={9} height={9} viewBox="0 0 9 9" fill="none">
                                                        <path d="M8.80065 0.206172C8.7375 0.142889 8.66249 0.0926821 8.5799 0.0584261C8.49732 0.02417 8.40879 0.00653721 8.31939 0.00653721C8.22999 0.00653721 8.14146 0.02417 8.05888 0.0584261C7.97629 0.0926821 7.90128 0.142889 7.83813 0.206172L4.5 3.53747L1.16187 0.199346C1.09867 0.136145 1.02364 0.086012 0.941068 0.0518081C0.858492 0.0176043 0.769989 6.65925e-10 0.68061 0C0.591231 -6.65925e-10 0.502727 0.0176043 0.420151 0.0518081C0.337576 0.086012 0.262546 0.136145 0.199346 0.199346C0.136145 0.262546 0.086012 0.337576 0.0518081 0.420151C0.0176043 0.502727 -6.65925e-10 0.591231 0 0.68061C6.65925e-10 0.769989 0.0176043 0.858492 0.0518081 0.941068C0.086012 1.02364 0.136145 1.09867 0.199346 1.16187L3.53747 4.5L0.199346 7.83813C0.136145 7.90133 0.086012 7.97636 0.0518081 8.05893C0.0176043 8.14151 0 8.23001 0 8.31939C0 8.40877 0.0176043 8.49727 0.0518081 8.57985C0.086012 8.66242 0.136145 8.73745 0.199346 8.80065C0.262546 8.86385 0.337576 8.91399 0.420151 8.94819C0.502727 8.9824 0.591231 9 0.68061 9C0.769989 9 0.858492 8.9824 0.941068 8.94819C1.02364 8.91399 1.09867 8.86385 1.16187 8.80065L4.5 5.46253L7.83813 8.80065C7.90133 8.86385 7.97636 8.91399 8.05893 8.94819C8.14151 8.9824 8.23001 9 8.31939 9C8.40877 9 8.49727 8.9824 8.57985 8.94819C8.66242 8.91399 8.73745 8.86385 8.80065 8.80065C8.86385 8.73745 8.91399 8.66242 8.94819 8.57985C8.9824 8.49727 9 8.40877 9 8.31939C9 8.23001 8.9824 8.14151 8.94819 8.05893C8.91399 7.97636 8.86385 7.90133 8.80065 7.83813L5.46253 4.5L8.80065 1.16187C9.06006 0.902469 9.06006 0.465577 8.80065 0.206172Z" fill="white" />
                                                    </svg>
                                                </Tags>
                                            ))}
                                        </Box1>
                                    </Box1>
                                </SettingCard>
                            </Flex>

                        </Flex>
                    </Flex>

                    <Flex flexDirection={"column"} border={"1px solid lightgrey"} borderRadius={"5px"}  padding={"15px"} gap={"15px"}>
                        <Flex flexDirection={"row"} alignItems={"center"}>
                            <Flex width={"25%"} opacity={0.7}>Hiring Keywords Limit</Flex>
                            <Flex width={"75%"} padding={"20px"}>
                                <select onChange={(e) => { changeKeywordLimit("hiring", e.target.value) }}
                                    value={keywordArray.hiringLimit}
                                    style={{ padding: "10px", border: "1px solid lightgrey", borderRadius: "7px" }}
                                >
                                    {Array.from({ length: 25 }, (_, i) => (
                                        <option key={i + 1} value={i + 1}>
                                            {i + 1}
                                        </option>
                                    ))}
                                </select>
                            </Flex>
                        </Flex>
                        <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                            <Flex width={"25%"} flexDirection={"column"}>
                                <Flex opacity={0.7}>Hiring Keywords</Flex>
                                <Flex><div style={{ fontSize: "12px", opacity: 1, fontStyle: "italic", color: "#0094E3" }}>Updated By&nbsp;<span style={{ fontWeight: 'bold' }}><b>{keywordArray?.hiringKeywordsUpdatedBy || "-"}</b></span>&nbsp;on&nbsp;<span><b>{hiringKeywordsUpdatedOn !== "NaN undefined" ? hiringKeywordsUpdatedOn : "-"}</b></span></div></Flex>
                            </Flex>
                            <Flex width={"75%"} flexDirection={"column"}>
                                <SettingCard>
                                    <Box1>
                                        <KeywordTagManager
                                            name='Job'
                                            defaultValue={keywordArray?.hiringKeywords || []}
                                            placeholder='SDR, Director of Sales'
                                            onSubmit={handleJobSubmit}
                                            loading={loading}
                                            MAX_LENGTH={keywordArray?.hiringLimit}
                                        />

                                        <Box1 sx={{ marginTop: '10px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                            {keywordArray?.hiringKeywords?.map(words => (
                                                <Tags key={words} disabled={loading}>
                                                    {words}
                                                    <svg onClick={() => handleHiringKeywordDelete(words)} xmlns="http://www.w3.org/2000/svg" style={{ minWidth: 9 }} width={9} height={9} viewBox="0 0 9 9" fill="none">
                                                        <path d="M8.80065 0.206172C8.7375 0.142889 8.66249 0.0926821 8.5799 0.0584261C8.49732 0.02417 8.40879 0.00653721 8.31939 0.00653721C8.22999 0.00653721 8.14146 0.02417 8.05888 0.0584261C7.97629 0.0926821 7.90128 0.142889 7.83813 0.206172L4.5 3.53747L1.16187 0.199346C1.09867 0.136145 1.02364 0.086012 0.941068 0.0518081C0.858492 0.0176043 0.769989 6.65925e-10 0.68061 0C0.591231 -6.65925e-10 0.502727 0.0176043 0.420151 0.0518081C0.337576 0.086012 0.262546 0.136145 0.199346 0.199346C0.136145 0.262546 0.086012 0.337576 0.0518081 0.420151C0.0176043 0.502727 -6.65925e-10 0.591231 0 0.68061C6.65925e-10 0.769989 0.0176043 0.858492 0.0518081 0.941068C0.086012 1.02364 0.136145 1.09867 0.199346 1.16187L3.53747 4.5L0.199346 7.83813C0.136145 7.90133 0.086012 7.97636 0.0518081 8.05893C0.0176043 8.14151 0 8.23001 0 8.31939C0 8.40877 0.0176043 8.49727 0.0518081 8.57985C0.086012 8.66242 0.136145 8.73745 0.199346 8.80065C0.262546 8.86385 0.337576 8.91399 0.420151 8.94819C0.502727 8.9824 0.591231 9 0.68061 9C0.769989 9 0.858492 8.9824 0.941068 8.94819C1.02364 8.91399 1.09867 8.86385 1.16187 8.80065L4.5 5.46253L7.83813 8.80065C7.90133 8.86385 7.97636 8.91399 8.05893 8.94819C8.14151 8.9824 8.23001 9 8.31939 9C8.40877 9 8.49727 8.9824 8.57985 8.94819C8.66242 8.91399 8.73745 8.86385 8.80065 8.80065C8.86385 8.73745 8.91399 8.66242 8.94819 8.57985C8.9824 8.49727 9 8.40877 9 8.31939C9 8.23001 8.9824 8.14151 8.94819 8.05893C8.91399 7.97636 8.86385 7.90133 8.80065 7.83813L5.46253 4.5L8.80065 1.16187C9.06006 0.902469 9.06006 0.465577 8.80065 0.206172Z" fill="white" />
                                                    </svg>
                                                </Tags>
                                            ))}
                                        </Box1>
                                    </Box1>
                                </SettingCard>
                            </Flex>

                        </Flex>
                    </Flex>
                    <Flex flexDirection={"column"} border={"1px solid lightgrey"} borderRadius={"5px"} padding={"15px"} gap={"15px"}>
                        <Flex flexDirection={"row"} alignItems={"center"}>
                            <Flex width={"25%"} opacity={0.7}>Fact Keywords Limit</Flex>
                            <Flex width={"75%"} padding={"20px"}>
                                <select onChange={(e) => { changeKeywordLimit("fact", e.target.value) }}
                                    value={keywordArray.factLimit}
                                    style={{ padding: "10px", border: "1px solid lightgrey", borderRadius: "7px" }}
                                >
                                    {Array.from({ length: 25 }, (_, i) => (
                                        <option key={i + 1} value={i + 1}>
                                            {i + 1}
                                        </option>
                                    ))}
                                </select>
                            </Flex>
                        </Flex>
                        <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                            <Flex width={"25%"} flexDirection={"column"}>
                                <Flex opacity={0.7}>Fact Keywords</Flex>
                                <Flex><div style={{ fontSize: "12px", opacity: 1, fontStyle: "italic", color: "#0094E3" }}>Updated By&nbsp;<span style={{ fontWeight: 'bold' }}><b>{keywordArray?.factKeywordsUpdatedBy || "-"}</b></span>&nbsp;on&nbsp;<span><b>{factKeywordsUpdatedOn !== "NaN undefined" ? hiringKeywordsUpdatedOn : "-"}</b></span></div></Flex>
                            </Flex>
                            <Flex width={"75%"} flexDirection={"column"}>
                                <SettingCard>
                                    <Box1>
                                        <KeywordTagManager
                                            name='Fact'
                                            defaultValue={keywordArray?.factKeywords || []}
                                            placeholder='uses Microsoft dynamics, offers employee benefits'
                                            onSubmit={handleFactSubmit}
                                            loading={loading}
                                            MAX_LENGTH={keywordArray?.factLimit}
                                        />

                                        <Box1 sx={{ marginTop: '10px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                            {keywordArray?.factKeywords?.map(words => (
                                                <Tags key={words} disabled={loading}>
                                                    {words}
                                                    <svg onClick={() => handleFactKeywordDelete(words)} xmlns="http://www.w3.org/2000/svg" style={{ minWidth: 9 }} width={9} height={9} viewBox="0 0 9 9" fill="none">
                                                        <path d="M8.80065 0.206172C8.7375 0.142889 8.66249 0.0926821 8.5799 0.0584261C8.49732 0.02417 8.40879 0.00653721 8.31939 0.00653721C8.22999 0.00653721 8.14146 0.02417 8.05888 0.0584261C7.97629 0.0926821 7.90128 0.142889 7.83813 0.206172L4.5 3.53747L1.16187 0.199346C1.09867 0.136145 1.02364 0.086012 0.941068 0.0518081C0.858492 0.0176043 0.769989 6.65925e-10 0.68061 0C0.591231 -6.65925e-10 0.502727 0.0176043 0.420151 0.0518081C0.337576 0.086012 0.262546 0.136145 0.199346 0.199346C0.136145 0.262546 0.086012 0.337576 0.0518081 0.420151C0.0176043 0.502727 -6.65925e-10 0.591231 0 0.68061C6.65925e-10 0.769989 0.0176043 0.858492 0.0518081 0.941068C0.086012 1.02364 0.136145 1.09867 0.199346 1.16187L3.53747 4.5L0.199346 7.83813C0.136145 7.90133 0.086012 7.97636 0.0518081 8.05893C0.0176043 8.14151 0 8.23001 0 8.31939C0 8.40877 0.0176043 8.49727 0.0518081 8.57985C0.086012 8.66242 0.136145 8.73745 0.199346 8.80065C0.262546 8.86385 0.337576 8.91399 0.420151 8.94819C0.502727 8.9824 0.591231 9 0.68061 9C0.769989 9 0.858492 8.9824 0.941068 8.94819C1.02364 8.91399 1.09867 8.86385 1.16187 8.80065L4.5 5.46253L7.83813 8.80065C7.90133 8.86385 7.97636 8.91399 8.05893 8.94819C8.14151 8.9824 8.23001 9 8.31939 9C8.40877 9 8.49727 8.9824 8.57985 8.94819C8.66242 8.91399 8.73745 8.86385 8.80065 8.80065C8.86385 8.73745 8.91399 8.66242 8.94819 8.57985C8.9824 8.49727 9 8.40877 9 8.31939C9 8.23001 8.9824 8.14151 8.94819 8.05893C8.91399 7.97636 8.86385 7.90133 8.80065 7.83813L5.46253 4.5L8.80065 1.16187C9.06006 0.902469 9.06006 0.465577 8.80065 0.206172Z" fill="white" />
                                                    </svg>
                                                </Tags>
                                            ))}
                                        </Box1>
                                    </Box1>
                                </SettingCard>
                            </Flex>

                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} flexDirection={"column"}>
                            <Flex opacity={0.7}>Pitch</Flex>
                        </Flex>
                        {/* <Flex width={"75%"} flexWrap={"wrap"}>
                            {pitch}
                        </Flex> */}
                        <Box mb="10px" marginX={'20px'} width={"75%"}>
                            <TextareaAutosize
                            style={{
                                width: "100%",
                                borderRadius: "6px",
                                padding: "16px",
                                border: "1px solid rgba(0, 0, 0, 0.20)",
                                fontFamily: "Inter ,sans-serif",
                            }}
                            value={pitch}
                            placeholder="A product pitch is a short description that highlights the key features and benefits of a product in order to persuade potential customers to buy it."
                            onChange={(e) => {
                                setPitch(e.target.value);
                                console.log(e.target.value);
                            }}
                            />
                            <Box1 sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                color: '#9F9F9F',
                                fontSize: '10px',
                                marginTop: '4px'
                            }}>
                                (Up to 300 words are allowed)
                            </Box1>

                            {(countWords(pitch || "") > 300) && (
                                <Typography color="rgb(238, 0, 4)" marginTop={"4px"}>
                                    {`Maximum 300 words are allowed`}
                                </Typography>
                            )}
                        </Box>

                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} flexDirection={"column"}>
                            <Flex opacity={0.7}>Persona</Flex>
                        </Flex>
                        <Box mb="10px" marginX={'20px'} width={"75%"}>
                            <TextareaAutosize
                                style={{
                                    width: "100%",
                                    borderRadius: "6px",
                                    padding: "16px",
                                    border: "1px solid rgba(0, 0, 0, 0.20)",
                                    fontFamily: "Inter ,sans-serif",
                                }}
                                value={persona}
                                placeholder="A persona is a detailed description of a fictional character representing a user type. It includes their goals, behaviors, and pain points to help address the needs of target customers. "
                                onChange={(e) => {
                                    setPersona(e.target.value);
                                    console.log(e.target.value);
                                }}
                            />
                            <Box1 sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                color: '#9F9F9F',
                                fontSize: '10px',
                                marginTop: '4px'
                            }}>
                                (Up to 300 words are allowed)
                            </Box1>
                            {(countWords(persona || "") > MAX_LENGTH_FOR_PERSONA) && (
                            <Typography color="rgb(238, 0, 4)" marginTop={"4px"}>
                                {`Maximum ${MAX_LENGTH_FOR_PERSONA} words are allowed`}
                            </Typography>
                            )}

                        </Box>

                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.7}>Call Prep Custom Field</Flex>
                        <Flex width={"75%"}>
                            <TextareaAutosize
                                style={{
                                    width: "100%",
                                    borderRadius: "6px",
                                    padding: "6px",
                                    border: "1px solid rgba(0, 0, 0, 0.20)",
                                    fontFamily: "Inter ,sans-serif",
                                }}
                                value={callPrepText}
                                onChange={(e) => setCallPrepText(e.target.value)}
                            />
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.7}>Evabot Tag Field</Flex>
                        <Flex width={"75%"}>
                            <TextareaAutosize
                                style={{
                                    width: "100%",
                                    borderRadius: "6px",
                                    padding: "6px",
                                    border: "1px solid rgba(0, 0, 0, 0.20)",
                                    fontFamily: "Inter ,sans-serif",
                                }}
                                value={evabotTagField}
                                onChange={(e) => setEvabotTagField(e.target.value)}
                            />
                        </Flex>
                        
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.7}>SSO Integration Field</Flex>
                        <Flex width={"75%"}>
                            <TextareaAutosize
                                style={{
                                    width: "100%",
                                    borderRadius: "6px",
                                    padding: "6px",
                                    border: "1px solid rgba(0, 0, 0, 0.20)",
                                    fontFamily: "Inter ,sans-serif",
                                }}
                                value={ssoOrganizationId}
                                onChange={(e) => setSsoOrganizationId(e.target.value)}
                            />
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.7}>Set Subscription Account Limit</Flex>
                        <Flex width={"75%"}>
                            <TextareaAutosize
                                style={{
                                    width: "100%",
                                    borderRadius: "6px",
                                    padding: "6px",
                                    border: "1px solid rgba(0, 0, 0, 0.20)",
                                    fontFamily: "Inter ,sans-serif",
                                }}
                                value={subscriptionAccountSettings}
                                onChange={(e) => setSubscriptionAccountSettings(e.target.value)}
                            />
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.7}>Set Subscription Prospect Limit</Flex>
                        <Flex width={"75%"}>
                            <TextareaAutosize
                                style={{
                                    width: "100%",
                                    borderRadius: "6px",
                                    padding: "6px",
                                    border: "1px solid rgba(0, 0, 0, 0.20)",
                                    fontFamily: "Inter ,sans-serif",
                                }}
                                value={subscriptionProspectSettings}
                                onChange={(e) => setSubscriptionProspectSettings(e.target.value)}
                            />
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.7}>Enable Sequence Feature for Account</Flex>
                        <ToggleButton
                            value={isSequenceAutomationOn}
                            name='isActive'
                            variant="tertiary"
                            onChange={(value) => { saveSequenceSettings(value); setIsSequenceAutomationOn(value) }} />
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.7}>Auto Open</Flex>
                        <SettingCard m={'6px 32px 6px 32px'}>
                            <FormControl size='small'>
                                <WebsiteListContainer>
                                {websitesList.map((website) => {
                                    return (
                                    <WebsiteContainer>
                                        <AntSwitch
                                        title={website?.name}
                                        checked={autoOpen?.includes(website?.key)}
                                        onChange={() => {
                                            if (autoOpen?.includes(website?.key)) {
                                            handleAutoOpenSettingChange(autoOpen?.filter(item => item !== website?.key))
                                            } else {
                                            handleAutoOpenSettingChange([...autoOpen, website?.key])
                                            }
                                        }} />

                                        <Typography fontSize={'12px'}>
                                        {website?.name}
                                        </Typography>
                                    </WebsiteContainer>)

                                })}

                                <WebsiteContainer>
                                    <AntSwitch
                                    title={'All websites'}
                                    checked={autoOpen?.length === websitesList?.length}
                                    onChange={() => {
                                        if (autoOpen?.length === websitesList?.length) {
                                        handleAutoOpenSettingChange([])
                                        } else {
                                        handleAutoOpenSettingChange(websitesList.map(x => x.key))
                                        }
                                    }} />

                                    <Typography fontSize={'12px'}>
                                        All
                                    </Typography>
                                </WebsiteContainer>

                                </WebsiteListContainer>

                            </FormControl>
                        </SettingCard>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.7}>Auto Processing</Flex>
                        {Boolean(autoOpen?.length) && <SettingCard m={'6px 32px 6px 32px'}>
                            <FormControl size="small" >
                                <Box1 sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <AntSwitch
                                    title={'Auto Processing'}
                                    checked={autoProcessing}
                                    onChange={() => {
                                    handleAutoProcessingSettingChange(!autoProcessing)
                                }} />

                                </Box1>
                                <Text1>
                                    When "ON" the extension can read the prospects' on your CRM and other pages like Outreach, LinkedIn etc and process the profiles automatically to save time.
                                </Text1>

                            </FormControl>
                        </SettingCard>}
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.7}>Auto Persona Selection</Flex>
                        <ToggleButton
                            value={isAutoPersonaSelectionSetting}
                            name='isActive'
                            variant="tertiary"
                            onChange={(value) => { saveAutoPersonaSelectionSettings(value); setIsAutoPersonaSelectionSetting(value) }} />
                    </Flex>
                    { (loggedInMemberDetails?.email === "ashish@evabot.ai" || loggedInMemberDetails?.email === "rohith@evabot.ai" || loggedInMemberDetails?.email === "ashish@revenoid.com" || loggedInMemberDetails?.email === "rohith@revenoid.com") &&
                    <>
                        <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                            <Flex width={"25%"} opacity={0.7}>Prospect Mapping Flow</Flex>
                            <ToggleButton
                                value={isProspectUseCaseEnabled}
                                name='isActive'
                                variant="tertiary"
                                onChange={(value) => { saveProspectUseCaseEnabledSettings(value); setIsProspectUseCaseEnabled(value) }} />
                        </Flex>
                        <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                            <Flex width={"25%"} opacity={0.7}>User Mapping Flow</Flex>
                            <ToggleButton
                                value={isUserUseCaseEnabled}
                                name='isActive'
                                variant="tertiary"
                                onChange={(value) => { saveUserUseCaseEnabledSettings(value); setIsUserUseCaseEnabled(value) }} />
                        </Flex>
                    </>
                    }
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.7}>Content Library feature ON/OFF</Flex>
                        <ToggleButton
                            value={isDataRepositoryEnabled}
                            name='isActive'
                            variant="tertiary"
                            onChange={(value) => { saveDataRepositoryEnabledSettings(value); setIsDataRepositoryEnabled(value) }} />
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.7}>Sales Navigator Feature</Flex>
                        <ToggleButton
                            value={isSalesNavigatorEnabled}
                            name='isActive'
                            variant="tertiary"
                            onChange={(value) => { saveSalesNavigatorEnabledSettings(value); setIsSalesNavigatorEnabled(value) }} />
                    </Flex>
                </Flex>
            </Box>
        </div>
    );
};

export default GeneralInfo;