import React, { FC, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import DeloitteLogoIcon from "assets/icons/DeloitteLogo";
import MetaLogoIcon from "assets/icons/MetaIcon";
import AmerifirstLogoIcon from "assets/icons/AmerifirstLogo";
import ZendeskLogoIcon from "assets/icons/ZendeskLogo";
import SalesforceButton from "components/SalesforceButton/SalesforceButton";
import GoogleButton from "components/GoogleButton/Googlebutton";
// import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import AELoginForm from "./AELoginForm";
import { axiosHelper, axiosHelperCore } from "utils";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import EvaLogo from "containers/AeForgotPassword/EvaLogo";
import EvaLogoNew from "containers/AeForgotPassword/EvaLogoNew";
interface AEMobileSignUp01Props {
    handleSnackbarError: (e) => any ;
    setShowSnackBar: (e) => any;
  showSnackBar: boolean,
  snackbarMessage: string
  }
  
  const AEMobileSignUp01: FC<AEMobileSignUp01Props> = ({handleSnackbarError , setShowSnackBar , showSnackBar , snackbarMessage}) => {
    const journey = useAppSelector((state) => state.globalState.journey);

    const triggerSalesforceCall = async () => {
      window.open(
        `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${
          process.env.REACT_APP_SALESFORCE_CLIENT_ID
        }&redirect_uri=${
          process.env.REACT_APP_SALESFORCE_REDIRECT_URL
        }&state=${encodeURIComponent(JSON.stringify(journey))}`,
        "_self"
      );
    };
  
    const triggerGoogleCall = async () => {
      const result = await axiosHelper({
        url: `google/auth/${encodeURIComponent(JSON.stringify(journey))}`
      });
      if(result.data.success) {
        window.open(
          `${result.data.url}`,
          "_self"
        );
      }
    }
  
    const bookDemo = async () => {
      //@ts-ignore
      // ChiliPiper.submit("evabot", "rapport-intelligence-router", { map: true, domElement: "#root", lead: { firstName: 'Abhishek', email: 'abhishek@evabot.ai' } });
      window.open(
        "https://evabot.chilipiper.com/book/rapport-intelligence-queue",
        "targetWindow",
        `toolbar=no,
       location=no,
       status=no,
       menubar=no,
       scrollbars=yes,
       resizable=yes,
       width=800,
       height=550`
      );
    };
    return (
        <>
        <SnackBar
        iconName={"info"}
        message={"Login Error"}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        subHeading={snackbarMessage}
        timeout={4000}
        anchor={"top-right"}
        type='error'
      />
        <Flex flexDirection="column" alignItems={"center"} width="100%" >

        <Grid mt={"48px"} width={"calc(100vw - 32px)"} >
         <Box textAlign={"center"} width={"100%"} mb={"35px"} >
        {EvaLogoNew()}
</Box>
          <Text
            marginBottom={24}
            fontSize="20px"
            fontWeight={300}
            color={"#050505"}
            lineHeight={"140%"}
            textAlign="center"
          >
            Get Started
          </Text>

          {/* <Box width="320px" mb="16px">
            <SalesforceButton onClick={triggerSalesforceCall} />
          </Box> */}
          <Box width="100%" mb="16px" border={"1px solid #0094E3"} borderRadius={"8px"} >
            <GoogleButton onClick={triggerGoogleCall} />
          </Box>

          <Text color="#818181" variant="bodyRegular" mb="14px" mt="24px">
            Or use your email to continue
          </Text>
          <AELoginForm handleSnackbarError={handleSnackbarError}/>
        </Grid>
      </Flex>
      <Flex flexDirection={"column"}>
        <Flex justifyContent={"center"}>
          {
            //@ts-ignore
            <Text
              id="trustedbyteams"
              fontSize={12}
              color={"white"}
              height={"auto"}
            />
          }
        </Flex>
        <Flex justifyContent={"space-evenly"} mt={34} mb={50}>
          <DeloitteLogoIcon />
          <ZendeskLogoIcon />
          <MetaLogoIcon />
          <AmerifirstLogoIcon />
        </Flex>
      </Flex>
      </>
    )
};

export default AEMobileSignUp01;