import * as React from "react";

function OtherIntegrationIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3.98523 17.0415C2.33653 17.0415 1 18.3767 1 20.0237C1 21.6708 2.33653 23.006 3.98523 23.006C5.63393 23.006 6.97046 21.6708 6.97046 20.0237C6.97046 18.3767 5.63393 17.0415 3.98523 17.0415ZM3.98523 17.0415V6.97656" stroke="white" stroke-width="1.5"/>
            <path d="M22.9998 20.0165C22.9998 21.6652 21.6632 23.0017 20.0145 23.0017C18.3658 23.0017 17.0293 21.6652 17.0293 20.0165C17.0293 18.3678 18.3658 17.0312 20.0145 17.0312C21.6632 17.0312 22.9998 18.3678 22.9998 20.0165Z" stroke="white" stroke-width="1.5"/>
            <path d="M3.98547 6.96628C5.63417 6.96628 6.9707 5.6311 6.9707 3.98407C6.9707 2.33703 5.63417 1.00185 3.98547 1.00185C2.33677 1.00185 1.00024 2.33704 1.00024 3.98407C1.00024 5.6311 2.33677 6.96628 3.98547 6.96628ZM3.98547 6.96628L3.98547 17.0313" stroke="white" stroke-width="1.5"/>
            <path d="M17.0357 3.98523C17.0357 2.33653 18.3709 1 20.0179 0.999999C21.6649 0.999999 23.0001 2.33653 23.0001 3.98523C23.0001 5.63393 21.6649 6.97046 20.0179 6.97046C18.3709 6.97046 17.0357 5.63393 17.0357 3.98523ZM17.0357 3.98523L6.9707 3.98523" stroke="white" stroke-width="1.5"/>
        </svg>
    );
}

export default OtherIntegrationIcon;
