import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import ManageProfilerContent from "./ManageProfilerContent";

function ManageProfilerRightSection() {
    return (
        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
            <ManageProfilerContent></ManageProfilerContent>
        </Flex>
    )
}

export default ManageProfilerRightSection;