import { FC, useCallback, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { useAppDispatch } from "hooks";
import { aeChangePasswordData } from "./store/AeFormReducer";
import { axiosHelper } from "utils";
import userService from "services/userService";

const AeChangePasswordRightSectionSchema = yup.object().shape({
  password: yup
    .string()
    .required("aereturn.profile.password.valid")
    .min(4, "aereturn.profile.password.min"),
  confirmPassword: yup
    .string()
    .required("aereturn.profile.password.valid")
    .oneOf([yup.ref("password"), "password"], "Passwords must match"),
});

const defaultValues = {
  password: "",
  confirmPassword: "",
};

const AeChangePasswordRightSection: FC = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userHash } = useParams();

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSubH, setSnackbarSubH] = useState('');
  const [snackbarType, setSnackbarType] = useState('success');
  const [isLoading, toggleIsLoading] = useState(false);


  const onSubmitHandler = useCallback(
    async (values: any) => {
      toggleIsLoading(true);
      dispatch(aeChangePasswordData(values));
      console.log(values);
      const result = await userService.updatePassword({ userHash: userHash, password: values.password });

      if (result.data.success) {
        setSnackbarMessage('Password is reset successfully');
        setSnackbarSubH('This popup will close and you will be redirected to the login page automatically.')
        setShowSnackBar(true);
      }
      else if ((!result.data.success) && result.data.error === 'LINK_EXPIRED') {
        setSnackbarMessage('Password reset link has expired!');
        setSnackbarSubH('Please reset your password from the forgot password page.')
        setSnackbarType('error');
        setShowSnackBar(true);
      }
      toggleIsLoading(false);
      setTimeout(() => {
        navigate('/aeLogin1');
      }, 3000);
    },
    [dispatch, userHash]
  );

  return (
    <Grid>
      <SnackBar
        iconName={"info"}
        message={snackbarMessage}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        subHeading={snackbarSubH}
        timeout={4000}
        anchor={"top-right"}
        type={snackbarType}
         
      // bgColorr="#F44545"
      />
      <Text variant="pageHeadingRegular" color={"#050505"} fontWeight="500">
        Set your new password
      </Text>

      <Formik
        initialValues={defaultValues}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validationSchema={AeChangePasswordRightSectionSchema}
      >
        {({ dirty, isValid, submitForm }) => {
          return (
            <Form>
              <Grid width={"300px"} gridRowGap="5px">
                <Text variant="bodyRegular" mt={"24px"} color="#050505">
                  New Password
                </Text>
                <FormikInput
                  width={"100%"}
                  name="password"
                  placeholder="aereturn.login.password.placeholder"
                  type={"password"}
                />

                <Text variant="bodyRegular" color="#050505">
                  Confirm Password
                </Text>
                <FormikInput
                  width={"100%"}
                  name="confirmPassword"
                  placeholder="aereturn.login.password.placeholder"
                  type={"password"}
                />

                <Flex
                  justifyContent={"space-between"}
                  alignItems="center"
                  mt="15px"
                >
                  <Box mr="-30px">
                    <Button
                      padding="13px 31px"
                      iconName="arrowRight"
                      iconAlign="right"
                      borderRadius={"118px"}
                      onClick={submitForm}
                      loading={isLoading}
                      disabled={!dirty || !isValid}
                    >
                      Submit
                    </Button>
                  </Box>
                </Flex>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default AeChangePasswordRightSection;
