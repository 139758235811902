import * as React from "react";
import RevenoidLoaderGif from '../../assets/RevenoidLoader.gif';

function RevenoidLoader() {
  return (
    <div style={{
        position:"fixed", top: "50%", left: "50%"
        }}>
        <img src={RevenoidLoaderGif} width="75px" height="75px" alt="Loading" />
    </div>
  );
}

const MemoRevenoidLoader = React.memo(RevenoidLoader);
export default MemoRevenoidLoader;
