import * as React from 'react';

function AccelDataLogo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="12" viewBox="0 0 60 12" fill="none">
            <g clip-path="url(#clip0_210_15401)">
            <path d="M9.09783 9.82891V9.22263C9.03494 9.27694 8.9981 9.30391 8.96758 9.33643C8.48678 9.84628 7.87461 9.99111 7.19806 9.98852C5.87957 9.98372 4.68574 8.71832 4.66639 7.25083C4.65188 6.15354 5.05602 5.27386 5.98191 4.65686C6.83969 4.08531 8.29252 4.13223 9.01447 4.9831C9.02601 4.99677 9.04275 5.00563 9.08667 5.03999V4.43999H10.4755C10.4814 4.48654 10.4945 4.54196 10.4945 4.59701C10.4956 6.28322 10.4952 7.9698 10.4952 9.65601C10.4952 9.74209 10.5123 9.8315 10.3732 9.83002C9.95822 9.82596 9.54328 9.82855 9.09783 9.82855V9.82891ZM6.06638 7.12337C6.05708 7.99788 6.67297 8.64258 7.52666 8.65256C8.43021 8.66327 9.07811 8.04443 9.08704 7.16253C9.09597 6.27657 8.4678 5.61893 7.60257 5.60822C6.70981 5.59714 6.07531 6.223 6.06601 7.12337H6.06638Z" fill="white"/>
            <path d="M28.5785 7.70998H24.4601C24.5751 8.14741 24.8251 8.44668 25.233 8.60222C25.9557 8.87746 26.6073 8.74224 27.228 8.19434C27.592 8.40271 27.96 8.6133 28.3404 8.83091C28.0739 9.22513 27.7442 9.52697 27.3215 9.70875C26.4108 10.1 25.4838 10.1011 24.5806 9.70653C23.4519 9.2133 22.8867 8.0835 22.9957 6.85357C23.0806 5.89741 23.5145 5.13817 24.3507 4.66675C25.9873 3.74421 28.222 4.43879 28.5711 6.64446C28.6247 6.98399 28.5785 7.33941 28.5785 7.71035V7.70998ZM24.4537 6.60714H27.1901C27.1216 5.9968 26.563 5.53645 25.8962 5.5298C25.134 5.52204 24.5408 5.95837 24.4534 6.60714H24.4537Z" fill="white"/>
            <path d="M36.7509 2.33594H37.6928V9.81895H36.7629V8.95663C36.7108 8.9958 36.684 9.00799 36.6694 9.02831C35.8898 10.1164 34.2792 10.2427 33.2309 9.54223C32.2495 8.88607 31.8313 7.93656 31.9675 6.78939C32.1107 5.582 32.7765 4.72892 33.9781 4.39567C35.0264 4.10491 35.9527 4.34653 36.6601 5.21993C36.6758 5.23914 36.6925 5.25725 36.7093 5.27535C36.7126 5.27867 36.7193 5.27904 36.7513 5.29013V2.33594H36.7509ZM36.7562 7.14629C36.7584 6.0357 35.9374 5.20257 34.8385 5.19998C33.7225 5.19739 32.8893 6.02018 32.8803 7.13262C32.8718 8.21624 33.7262 9.07523 34.8147 9.07708C35.9114 9.07893 36.7535 8.24099 36.7558 7.14629H36.7562Z" fill="white"/>
            <path d="M55.3326 9.81231H54.4126V8.91526C54.298 9.04679 54.2232 9.14063 54.1399 9.22672C53.3524 10.0429 52.0801 10.2065 51.0265 9.6298C50.0556 9.09815 49.4628 7.94654 49.5957 6.84962C49.7453 5.61193 50.5018 4.66649 51.6249 4.38312C52.6859 4.11526 53.6133 4.34137 54.3166 5.23102C54.3341 5.25282 54.3576 5.27019 54.4093 5.31895V4.43595H55.3326V9.81231ZM52.4697 5.19371C51.3607 5.19223 50.5227 6.02425 50.5189 7.13078C50.5152 8.21514 51.3659 9.06711 52.4552 9.07044C53.5482 9.07376 54.4201 8.2059 54.4082 7.12635C54.3963 6.02425 53.5645 5.19519 52.4697 5.19371Z" fill="white"/>
            <path d="M43.3928 4.43102H44.3168V9.80923H43.4002V8.91366C43.2893 9.04149 43.2183 9.12942 43.1405 9.21181C42.3668 10.0309 41.1123 10.2079 40.0476 9.64925C39.0659 9.13423 38.4608 7.99001 38.5792 6.8724C38.7105 5.63213 39.4794 4.66784 40.6248 4.3804C41.6713 4.11772 42.5886 4.34383 43.289 5.21316C43.3109 5.24013 43.337 5.26415 43.3928 5.32252V4.43065V4.43102ZM39.5199 7.01279C39.4317 8.0857 40.2039 8.98312 41.3207 9.06514C42.3527 9.14087 43.3433 8.41378 43.3876 7.19124C43.4241 6.18336 42.7419 5.30663 41.6504 5.20208C40.4927 5.09124 39.6152 5.85195 39.5199 7.01316V7.01279Z" fill="white"/>
            <path d="M16.5731 5.6824C16.1697 5.91701 15.7763 6.14607 15.383 6.37477C14.9316 5.63326 14.1672 5.5675 13.6384 5.73634C13.0139 5.93548 12.641 6.51332 12.663 7.2308C12.682 7.84854 13.1081 8.40273 13.686 8.56196C14.3737 8.75113 15.0008 8.51615 15.3882 7.90987C15.5832 8.01923 15.78 8.12933 15.9765 8.24016C16.1753 8.35211 16.374 8.46443 16.572 8.576C16.2508 9.33672 15.2393 10.032 14.0563 9.99324C12.3489 9.93672 11.4379 8.67243 11.2946 7.59952C11.0658 5.88597 12.1033 4.751 13.2261 4.41073C14.5591 4.00654 15.9858 4.54669 16.5731 5.68277V5.6824Z" fill="white"/>
            <path d="M21.2271 6.36862C20.7512 5.60051 19.9407 5.56431 19.4256 5.74978C18.8414 5.96 18.4878 6.52084 18.5057 7.20877C18.5221 7.83869 18.9269 8.37995 19.5105 8.55286C20.1855 8.75237 20.797 8.5281 21.2338 7.90704C21.6272 8.12909 22.022 8.35224 22.4146 8.57392C22.1344 9.45471 20.8029 10.0939 19.5495 9.96012C18.3241 9.82934 17.2691 8.75384 17.1277 7.56308C16.9271 5.87761 17.9601 4.73709 19.0788 4.40051C20.3984 4.00334 21.7954 4.53241 22.415 5.67588C22.0194 5.90643 21.6231 6.13808 21.2268 6.36899L21.2271 6.36862Z" fill="white"/>
            <path d="M29.6172 2H30.9937V9.8122H29.6172V2Z" fill="white"/>
            <path d="M46.5077 5.33487C46.1881 5.33487 45.8922 5.32933 45.5964 5.33746C45.4683 5.34078 45.42 5.30384 45.4252 5.17157C45.4345 4.9329 45.4278 4.69349 45.4278 4.43413H46.5074C46.5074 4.28856 46.5074 4.16295 46.5074 4.03733C46.5074 3.80346 46.5111 3.5696 46.5059 3.33573C46.5036 3.23376 46.5301 3.18277 46.6424 3.16208C46.9093 3.11332 47.1727 3.04755 47.4578 2.98438V4.42489H48.8943V5.33007H47.4559V5.54251C47.4559 6.4595 47.4552 7.37613 47.4559 8.29313C47.4563 8.80113 47.664 9.00618 48.1801 9.01024C48.4146 9.01209 48.649 9.01024 48.8954 9.01024V9.83044C48.3614 9.90212 47.8329 9.94165 47.3186 9.74694C46.8066 9.55335 46.5732 9.14399 46.5405 8.63266C46.504 8.06258 46.5129 7.48955 46.5081 6.918C46.5036 6.4015 46.507 5.88499 46.507 5.33487H46.5077Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_210_15401">
            <rect width="50.6683" height="8.00063" fill="white" transform="translate(4.66602 2)"/>
            </clipPath>
            </defs>
        </svg>

    )
}

const AccelDataLogoIcon = React.memo(AccelDataLogo);
export default AccelDataLogoIcon;