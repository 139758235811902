import { Scale } from '@mui/icons-material';
import * as React from 'react';

function SurveySparrowLogo() {
    return (
        <svg style={{transform: "scale(1.5)" }} xmlns="http://www.w3.org/2000/svg" width="60" height="12" viewBox="0 0 60 12" fill="none">
            <g clip-path="url(#clip0_210_15399)">
            <path d="M3.84841 5.73843C3.3821 5.61473 3.09035 5.32431 2.92211 4.90286C2.83556 4.68577 2.8414 4.23792 2.9333 4.12057C3.02082 4.13866 3.11807 4.16067 3.21581 4.17827C3.30819 4.1944 3.40107 4.20565 3.50172 4.20222C3.05778 3.96314 2.77235 3.6028 2.64641 3.12414C2.5759 2.8567 2.55694 2.58143 2.65127 2.29297C2.83702 2.3311 3.01742 2.36826 3.19781 2.40444C3.40642 2.44649 3.6155 2.48512 3.82362 2.52912C4.00353 2.56677 4.18149 2.61077 4.36092 2.65037C4.54083 2.68998 4.72123 2.72811 4.90211 2.76527C5.16274 2.81856 5.42337 2.87039 5.684 2.92319C5.95679 2.97844 6.22763 3.04298 6.50187 3.08894C7.06592 3.18281 7.57551 3.39696 7.98006 3.80522C8.29175 4.1196 8.5057 4.499 8.62921 4.93561C8.72743 5.28373 8.74542 5.63429 8.70506 5.98143C8.65449 6.41706 8.49111 6.82189 8.22659 7.17929C7.88281 7.6428 7.43304 7.9562 6.87677 8.09701C6.54709 8.18012 6.21061 8.18795 5.86878 8.12341C5.38544 8.03247 4.97602 7.81539 4.62836 7.47412C4.27729 7.12991 4.04972 6.71384 3.94469 6.23273C3.92087 6.12224 3.916 6.00783 3.89655 5.89635C3.88634 5.83915 3.86252 5.7839 3.84841 5.73892V5.73843ZM5.52889 5.39471C5.20942 5.43138 5.17928 5.70812 5.29938 5.83377C5.46957 6.01174 5.63878 6.19117 5.81529 6.3623C5.92469 6.4684 6.0448 6.46204 6.15518 6.35252C6.47707 6.03276 6.79654 5.71105 7.11697 5.38934C7.16268 5.34387 7.20498 5.29497 7.25069 5.2495C7.35426 5.14536 7.35961 5.00602 7.26236 4.90383C7.1656 4.80214 7.04258 4.79871 6.93171 4.90383C6.77222 5.05589 6.61711 5.21235 6.46297 5.37027C6.31272 5.52428 6.16636 5.68171 6.01416 5.84159C5.83036 5.67194 5.6699 5.52379 5.52889 5.39374V5.39471Z" fill="white"/>
            <path d="M9.2811 3.9046C9.49116 3.71148 9.70948 3.51004 9.93656 3.30078C9.95406 3.32669 9.9954 3.37803 10.0255 3.43572C10.2152 3.80291 10.3805 4.18085 10.4486 4.59203C10.5026 4.91864 10.5667 5.24671 10.5774 5.57575C10.5867 5.86031 10.5312 6.14731 10.4967 6.43235C10.4418 6.88314 10.2871 7.30215 10.0868 7.70649C9.79992 8.28538 9.40412 8.77577 8.90377 9.18011C8.37522 9.60744 7.77859 9.90275 7.11097 10.0216C6.55568 10.1203 5.99357 10.1418 5.43779 9.99711C5.24718 9.94724 5.05657 9.89932 4.85332 9.8475C4.48912 10.2279 4.12492 10.6083 3.74711 11.0028C3.47627 10.8322 3.21418 10.6669 2.93848 10.4939C3.52586 9.88612 4.09332 9.29892 4.6559 8.7171C4.87861 8.81049 5.08867 8.91267 5.30845 8.9865C5.48544 9.04615 5.67168 9.08184 5.85694 9.10922C6.04366 9.1366 6.23475 9.16105 6.42244 9.15127C7.05262 9.11802 7.63661 8.9337 8.15884 8.57042C8.61591 8.25262 8.98011 7.85024 9.24706 7.36033C9.45274 6.98337 9.60202 6.58147 9.65405 6.15757C9.70365 5.75226 9.71872 5.34156 9.63265 4.93282C9.56166 4.59595 9.45177 4.27717 9.29179 3.97452C9.28499 3.96132 9.28693 3.94323 9.28158 3.9046H9.2811Z" fill="white"/>
            <path d="M57.4206 7.32483C57.6268 6.55624 57.8325 5.78716 58.0401 5.01319H58.8181C58.4695 6.14065 58.1232 7.26029 57.7756 8.38286H57.0107C56.77 7.60547 56.5293 6.82759 56.2891 6.04971C56.2726 6.04775 56.2565 6.0458 56.24 6.04384C56.0042 6.82074 55.7679 7.59814 55.5301 8.3814H54.7657C54.4224 7.26322 54.0801 6.14652 53.7334 5.01661H54.5182C54.7219 5.77542 54.9291 6.54646 55.1474 7.35807C55.1853 7.29794 55.2131 7.2686 55.2237 7.23438C55.3672 6.76598 55.5072 6.29662 55.6502 5.82774C55.7231 5.58865 55.8004 5.35103 55.8768 5.11293C55.8875 5.07919 55.905 5.04741 55.9201 5.01172H56.665C56.8994 5.77738 57.1366 6.55086 57.3735 7.32434C57.389 7.32434 57.4046 7.32434 57.4206 7.32434V7.32483Z" fill="white"/>
            <path d="M38.8555 7.98307V9.86593H38.1631V5.01871H38.8137V5.44555C38.8643 5.42208 38.8954 5.41817 38.9075 5.40057C39.423 4.65104 40.6162 4.79723 41.1122 5.54724C41.4025 5.98679 41.5099 6.45469 41.4472 6.97295C41.3845 7.49121 41.1623 7.91707 40.7402 8.22753C40.2029 8.62308 39.3578 8.60987 38.9202 8.0173C38.9085 8.00116 38.9056 7.97818 38.8983 7.95863C38.8842 7.96694 38.8696 7.97525 38.8555 7.98356V7.98307ZM40.7504 6.61555C40.7285 6.53047 40.7023 6.35348 40.6376 6.19263C40.5087 5.87287 40.2637 5.6685 39.9238 5.61765C39.5572 5.56289 39.2464 5.69539 39.0388 6.00683C38.7758 6.40091 38.7514 6.82432 38.9537 7.25457C39.1871 7.75083 39.7575 7.9376 40.2219 7.67945C40.5953 7.47166 40.73 7.11963 40.7509 6.61555H40.7504Z" fill="white"/>
            <path d="M17.0576 4.37129C16.831 4.47152 16.6146 4.56784 16.3846 4.67002C16.2937 4.40307 16.1313 4.2207 15.8804 4.13221C15.5736 4.02415 15.2784 4.05691 15.0222 4.26177C14.7061 4.51406 14.7236 4.92964 15.0795 5.14428C15.345 5.30416 15.6416 5.41515 15.9314 5.52956C16.3127 5.68015 16.6735 5.85274 16.9297 6.19352C17.1728 6.51621 17.2521 6.88486 17.1923 7.26818C17.1033 7.83484 16.7731 8.22598 16.2217 8.39857C15.2867 8.69144 14.4017 8.32817 13.9835 7.41192C13.9476 7.33271 13.9315 7.24471 13.8994 7.14008C14.1352 7.04132 14.3643 6.945 14.6025 6.84526C14.674 7.09656 14.7664 7.3156 14.9512 7.48624C15.1296 7.65101 15.3251 7.76004 15.577 7.74781C15.7374 7.73999 15.8979 7.74928 16.0472 7.67105C16.283 7.54784 16.4318 7.36058 16.4478 7.09119C16.4663 6.78463 16.3204 6.55043 16.0559 6.41989C15.7292 6.25854 15.3849 6.13338 15.0499 5.98915C14.6881 5.83318 14.3716 5.62001 14.1975 5.24794C14.0784 4.99321 14.0467 4.73016 14.1041 4.4461C14.2111 3.91952 14.534 3.57679 15.0217 3.43451C15.7909 3.21058 16.688 3.41935 17.025 4.31506C17.0304 4.32973 17.0406 4.34293 17.0571 4.37129H17.0576Z" fill="white"/>
            <path d="M34.2305 7.14452C34.4697 7.04429 34.6968 6.94944 34.9326 6.85067C34.9608 6.93086 34.9817 6.99539 35.0055 7.05847C35.1261 7.37725 35.3464 7.60068 35.6591 7.72829C35.998 7.86666 36.5144 7.697 36.6987 7.38458C36.8805 7.07656 36.7886 6.65706 36.4692 6.47567C36.1677 6.30454 35.8395 6.17987 35.5181 6.0459C35.2414 5.931 34.9871 5.79264 34.7663 5.58191C34.3365 5.17317 34.2976 4.40995 34.6593 3.93179C34.9428 3.55678 35.326 3.38174 35.7928 3.36512C36.1531 3.35241 36.494 3.40277 36.7993 3.60518C37.0789 3.79097 37.2758 4.0413 37.3799 4.38062C37.1611 4.47694 36.9501 4.56983 36.7308 4.66664C36.6909 4.59575 36.6559 4.53072 36.6189 4.46765C36.4492 4.17723 36.1784 4.06771 35.8638 4.07944C35.5788 4.0902 35.3294 4.19727 35.1923 4.47596C35.0868 4.6906 35.1577 4.95755 35.3479 5.1057C35.6192 5.3174 35.943 5.41323 36.2572 5.53057C36.6291 5.66894 36.9812 5.83664 37.2369 6.15591C37.5029 6.48838 37.5773 6.8717 37.5195 7.28435C37.4344 7.8916 37.0546 8.26025 36.4998 8.42599C36.0977 8.54578 35.6766 8.5404 35.2715 8.36732C34.743 8.14144 34.4152 7.74687 34.2553 7.20123C34.2509 7.18608 34.2431 7.17239 34.2305 7.14354V7.14452Z" fill="white"/>
            <path d="M44.7924 8.38987H44.0723V7.98064C43.9366 8.10189 43.8306 8.20799 43.7134 8.29844C43.4188 8.52579 43.0852 8.53362 42.74 8.45588C42.3762 8.37423 42.0996 8.17034 41.9537 7.82614C41.6965 7.2184 41.8895 6.57498 42.544 6.32954C43.0244 6.14961 43.5068 6.17064 43.9818 6.36376C44.0027 6.37207 44.0275 6.37061 44.0499 6.37354C44.1088 6.15695 44.0173 5.90808 43.8467 5.76336C43.4616 5.43627 42.8863 5.51694 42.5975 5.88119C42.5756 5.90857 42.546 5.93009 42.5061 5.96724C42.3223 5.84208 42.1394 5.71789 41.9459 5.58588C42.0582 5.40009 42.2061 5.26515 42.3767 5.161C42.7691 4.92143 43.1975 4.88134 43.6449 4.9449C43.9818 4.99281 44.2716 5.12042 44.4953 5.38738C44.6344 5.55361 44.7195 5.74527 44.7506 5.95453C44.7793 6.14619 44.789 6.34225 44.7905 6.53635C44.7953 7.10155 44.7924 7.66724 44.7924 8.23244C44.7924 8.28035 44.7924 8.32827 44.7924 8.39085V8.38987ZM44.0893 7.00572C44.0533 6.97785 44.0285 6.95096 43.9974 6.93483C43.6711 6.76273 43.3273 6.72703 42.9811 6.84535C42.8003 6.90696 42.6476 7.02968 42.6072 7.23796C42.5649 7.45553 42.6257 7.64084 42.8139 7.766C43.0502 7.92344 43.2977 7.91268 43.5447 7.78996C43.8676 7.62959 44.0348 7.36068 44.0898 7.00572H44.0893Z" fill="white"/>
            <path d="M30.4478 6.84467H27.9854C27.9173 7.11358 28.143 7.50277 28.4313 7.67438C28.671 7.81666 28.9389 7.8279 29.2025 7.76141C29.4699 7.69394 29.6318 7.48174 29.7952 7.25195C29.996 7.35902 30.193 7.46365 30.4225 7.58588C30.3296 7.71545 30.2557 7.84306 30.1589 7.94915C29.8147 8.32758 29.3843 8.49871 28.8714 8.49333C28.0788 8.48502 27.5079 7.95111 27.3158 7.30866C27.1009 6.58847 27.2735 5.71085 27.8862 5.23415C28.2874 4.92221 28.7396 4.85768 29.2215 4.93737C29.6931 5.0156 30.0325 5.28793 30.2465 5.71085C30.4215 6.05701 30.4755 6.42908 30.4473 6.84516L30.4478 6.84467ZM28.0301 6.27018H29.7203C29.7237 5.88344 29.3469 5.5896 29.0323 5.56417C28.5062 5.52115 28.0472 5.84971 28.0301 6.27018Z" fill="white"/>
            <path d="M50.1836 6.70257C50.1992 6.19702 50.3392 5.74867 50.701 5.38491C51.0374 5.04657 51.4459 4.89647 51.9185 4.90674C52.609 4.92141 53.1025 5.24801 53.3958 5.86797C53.5771 6.25178 53.6141 6.65661 53.5382 7.081C53.4633 7.50001 53.2859 7.85203 52.9644 8.12534C52.6032 8.43239 52.1787 8.54044 51.7099 8.50035C51.355 8.47003 51.0433 8.34487 50.7802 8.10383C50.5001 7.84763 50.3144 7.53472 50.2468 7.15776C50.2201 7.00717 50.2045 6.85462 50.1836 6.70306V6.70257ZM52.8371 6.61994C52.8521 6.4229 52.7743 6.18675 52.6143 5.98336C52.3479 5.64551 51.9988 5.54137 51.6905 5.62546C51.3345 5.72325 51.0822 5.95109 50.9893 6.33001C50.8891 6.73728 50.9081 7.12304 51.1892 7.45698C51.4687 7.78896 51.8957 7.87941 52.2433 7.71073C52.6654 7.50587 52.8409 7.14407 52.8371 6.61945V6.61994Z" fill="white"/>
            <path d="M31.9724 8.18729C31.5231 7.12828 31.0782 6.07904 30.627 5.01514H31.4395C31.7283 5.74169 32.021 6.4785 32.3254 7.24415C32.3614 7.19966 32.3906 7.17717 32.4022 7.14735C32.6682 6.4697 32.9323 5.79107 33.1968 5.11293C33.2099 5.07919 33.2269 5.04692 33.2435 5.01172H34.0326C33.3567 6.63935 32.6862 8.25476 32.0181 9.86333H31.2474C31.4939 9.29275 31.7317 8.74222 31.9719 8.1868L31.9724 8.18729Z" fill="white"/>
            <path d="M19.9465 5.01172H20.654V8.37993H19.9771V7.94919C19.9674 7.94674 19.9577 7.94381 19.948 7.94136C19.9027 7.99417 19.859 8.04893 19.8118 8.10026C19.4612 8.47869 19.0309 8.56279 18.5466 8.44447C18.0983 8.33495 17.8658 8.02204 17.7696 7.59325C17.7346 7.43728 17.72 7.27398 17.719 7.11361C17.7136 6.46285 17.7166 5.8116 17.7171 5.16035C17.7171 5.11293 17.7214 5.0655 17.7243 5.01221H18.4148C18.4172 5.05866 18.4211 5.10559 18.4211 5.15204C18.4211 5.77053 18.4158 6.38902 18.4255 7.00751C18.4279 7.15175 18.4673 7.30038 18.515 7.43826C18.5938 7.66365 18.7829 7.75459 19.0051 7.78491C19.4705 7.84847 19.7398 7.582 19.8638 7.24415C19.9261 7.07499 19.9329 6.87942 19.9363 6.69509C19.9455 6.18661 19.9392 5.67812 19.9397 5.16915C19.9397 5.12173 19.9436 5.07381 19.9465 5.01221V5.01172Z" fill="white"/>
            <path d="M25.7619 8.3814H25.0369C24.5929 7.2686 24.147 6.15189 23.6934 5.01465H24.5122C24.7971 5.79204 25.0845 6.57726 25.3821 7.38937C25.4127 7.35318 25.4424 7.33216 25.4531 7.3038C25.6729 6.7254 25.8902 6.14603 26.1086 5.56714C26.1776 5.38379 26.2476 5.20093 26.3196 5.01172H27.1151C26.66 6.14554 26.2107 7.26322 25.7614 8.38188L25.7619 8.3814Z" fill="white"/>
            <path d="M9.94696 3.20814C9.84387 2.65077 9.58713 2.23909 9.06976 2.03521C8.54851 1.82986 8.08997 1.95845 7.6718 2.31879C7.3572 2.23176 7.03822 2.14326 6.71973 2.05477C7.27113 1.01042 9.19862 0.369928 10.423 1.95551H11.0833C11.0916 1.96578 11.0994 1.97556 11.1076 1.98583C10.7279 2.38528 10.3481 2.78522 9.94696 3.20765V3.20814Z" fill="white"/>
            <path d="M45.4902 5.0127H46.1637V5.49918C46.1744 5.50652 46.1851 5.51385 46.1958 5.52118C46.5473 4.99657 46.9961 4.75993 47.6322 5.00048C47.5568 5.24103 47.4877 5.46154 47.4163 5.68938C47.0312 5.56274 46.6952 5.61946 46.4316 5.94166C46.2872 6.11816 46.2109 6.32498 46.2094 6.55331C46.2055 7.10677 46.2079 7.65975 46.2079 8.21321C46.2079 8.26112 46.2079 8.30904 46.2079 8.37016H45.4902V5.01319V5.0127Z" fill="white"/>
            <path d="M48.7335 8.36908H48.0576V5.00087C48.2322 5.00087 48.4019 5.00087 48.5711 5.00087C48.6149 5.00087 48.6586 5.00087 48.732 5.00087V5.48198C48.7447 5.48589 48.7573 5.48931 48.77 5.49322C48.9669 5.1632 49.2416 4.94465 49.6258 4.90309C49.8057 4.88353 49.9895 4.88891 50.1674 5.01261C50.1037 5.22774 50.0396 5.44482 49.9725 5.67217C49.8553 5.65066 49.7522 5.61105 49.653 5.61888C49.5159 5.62963 49.3705 5.6531 49.2484 5.71226C48.9922 5.83645 48.8424 6.06135 48.7909 6.33906C48.7578 6.51703 48.7466 6.70087 48.7442 6.88177C48.7379 7.32474 48.7423 7.7677 48.7418 8.21116C48.7418 8.25859 48.7369 8.3065 48.7335 8.36859V8.36908Z" fill="white"/>
            <path d="M22.1275 5.00954V5.49406C22.1372 5.497 22.1465 5.49993 22.1562 5.50335C22.341 5.16551 22.6201 4.95771 22.9969 4.90637C23.259 4.87068 23.4297 4.91762 23.5595 5.02078C23.4963 5.22858 23.4331 5.43588 23.365 5.65883C23.2945 5.65443 23.2177 5.65883 23.1457 5.64319C22.5977 5.52047 22.1868 6.01233 22.1674 6.41471C22.1392 7.00582 22.1392 7.59889 22.1265 8.19098C22.1256 8.24623 22.1197 8.30148 22.1154 8.36748H21.4434V5.01052H22.128L22.1275 5.00954Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_210_15399">
            <rect width="56.2322" height="10" fill="white" transform="translate(2.58398 1)"/>
            </clipPath>
            </defs>
        </svg>

    )
}

const SurveySparrowLogoIcon = React.memo(SurveySparrowLogo);
export default SurveySparrowLogoIcon;