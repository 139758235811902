import React, { FC, useCallback, useEffect, useState } from 'react';
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex';
import { Text } from '@evabot/eva-react-core-library/dist/atoms/Text/Text';
import { Box } from '@evabot/eva-react-core-library/dist/atoms/Box/Box';
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import styled from 'styled-components';
import WarningIcon from 'assets/icons/WarningIcon';
import { CORE_API_URL } from 'utils';
import { debounce } from 'lodash';
import userService from 'services/userService';

interface AESignupRightSectionProps {
  setShowSnackBar: (e) => any;
  showSnackBar: boolean;
  snackbarMessage: string;
  showSsoLayout?: boolean;
}

const AESignupRightSection: FC<AESignupRightSectionProps> = ({ setShowSnackBar, showSnackBar, snackbarMessage, showSsoLayout }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorFound, setErrorFound] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loginUrl, setLoginUrl] = useState("");
  const [emailAddress, setEmailAddress] = useState('');

  const [screenSize, setScreenSize] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const updateDimension = () => {
    setScreenSize({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  const setSsoEmailCookie = (email: string) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + (30 * 24 * 60 * 60 * 1000));
    const cookie = `ssologinemail=${email};expires=${expires.toUTCString()};path=/;`;
    document.cookie = cookie;
  }

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmailAddress(emailValue);

    if (validateEmail(emailValue)) {
      setErrorFound(false);
      setErrorMessage('');
      setIsLoading(true);
      debouncedCheckEmailAccess(emailValue);
    } else {
      setLoginUrl('');
      setErrorFound(true);
      setErrorMessage('Invalid email address');
      setIsLoading(false);
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const checkEmailAccess = async (email) => {
    try {
      const response = await userService.checkEmailAccess({ email });
      if (response.message === 'success' && response.status === "200") {
        setLoginUrl(`${CORE_API_URL}workos/getAuth/${email}`);
        setSsoEmailCookie(email);
        setErrorFound(false);
        setErrorMessage('');
      } else {
        setLoginUrl('');
        setErrorFound(true);
        setErrorMessage(response.message);
      }
    } catch (error) {
      setLoginUrl('');
      setErrorFound(true);
      setErrorMessage('An error occurred while checking email access');
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedCheckEmailAccess = useCallback(debounce(checkEmailAccess, 1000), []);

  useEffect(() => {
    return () => {
      debouncedCheckEmailAccess.cancel();
    };
  }, [debouncedCheckEmailAccess]);

  return (
    <>
      <SnackBar
        iconName={"info"}
        message={"Login Error"}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        subHeading={snackbarMessage}
        timeout={6000}
        anchor={"top-right"}
        type='error'
      />
      <Flex height={"100%"} justifyContent={"space-between"} margin="0px 80px" flexDirection={"column"}>
        {!showSsoLayout && (
          <Flex flexDirection={"column"} gridRowGap={"0px"} justifyContent={"center"} width={"100%"} marginTop={"110px"}>
            <Flex flexDirection={"column"} gridRowGap={"8px"} textAlign={"center"} width={"70%"} margin={"0 auto"}>
              <Text color={"#050505"} fontSize={"24px"} lineHeight={"140%"} fontWeight={"600"}>
                Treat stakeholders as a person, not a persona
                <Text color={"#57585C"} fontSize={"14px"} fontWeight={"400"} lineHeight={"160%"}>
                  Unlock your creativity to stay on top of your stakeholders mind, build deeper visibility into deals, and accelerate decisions.
                  <Box width={"100%"} height={"auto"}>
                    <img src={"https://s3.amazonaws.com/eva-bot/img/a639edfd-c0cb-4341-b0be-d2d0d19963cc.gif"} style={{ width: "100%", height: "100%" }} loading='lazy' />
                  </Box>
                </Text>
              </Text>
            </Flex>
          </Flex>
        )}

        {showSsoLayout && (
          <Flex flexDirection={"column"} gridRowGap={"8px"} margin={"0 auto"} marginTop={"110px"}>
            <Box width={"100%"} mb={"35px"} display={"flex"} justifyContent={"center"}>
              <img width={"150px"} src='https://cdn.prod.website-files.com/675d3112f29bca783ec26954/677bbbd1dc9070223f695c12_Revenoid%20logo.png'></img>
            </Box>
            <Box background={"#fff"} borderRadius={"6px"} boxShadow={"0px 0px 16.517px 0px rgba(0, 0, 0, 0.25)"} padding={"30px 72px 30px 36px"} width={"450px"} ml={"50px"}>
              <Text color={"#050505"} fontSize={"26px"} fontWeight={"600"} lineHeight={"100%"} mb={4} textAlign={"center"}>Log In with SSO</Text>
              <Text color={"#050505"} variant="bodyRegular" mb={1}>Email</Text>
              <Input 
                width={screenSize.dynamicWidth > 576 ? "100%" : ""}
                placeholder="Enter your email"
                onChange={handleEmailChange}
                value={emailAddress}
              />
              {errorFound && (
                <ContainerInner1>
                  <WarningIcon width={55} />
                  {errorMessage}
                </ContainerInner1>
              )}
              <a href={loginUrl} style={{ display: 'inline-block', width: '100%' }}>
                <Button
                  fullSize
                  loading={isLoading}
                  variant="primary"
                  borderRadius={screenSize.dynamicWidth > 576 ? "118px" : "8px"}
                  fontSize={screenSize.dynamicWidth > 576 ? "14px" : "16px"}
                  type="submit"
                  disabled={!validateEmail(emailAddress) || !loginUrl}
                  iconName="arrowRight"
                  iconAlign="right"
                >
                  Log In
                </Button>
              </a>
            </Box>
          </Flex>
        )}
        <Flex marginBottom={"16px"} justifyContent={"center"} gridColumnGap={"16px"} alignItems={"center"} flexDirection={"row"}>
          <Text color={"#57585C"} fontSize={"12px"} fontWeight={"400"} lineHeight={"140%"}>Copyright © 2025 Revenoid, Inc. All rights reserved.</Text>
          <Text color={"#57585C"} fontSize={"12px"} fontWeight={"400"} lineHeight={"140%"}>|</Text>
          <a href="https://www.evabot.com/terms-of-service" style={{ textDecoration: "none", color: "#57585C", fontSize: "12px", fontWeight: "400", lineHeight: "140%" }}>Terms of services</a>
          <Text color={"#57585C"} fontSize={"12px"} fontWeight={"400"} lineHeight={"140%"}>|</Text>
          <a href="https://www.evabot.com/privacy-policy" style={{ textDecoration: "none", color: "#57585C", fontSize: "12px", fontWeight: "400", lineHeight: "140%"}}>Privacy policy</a>
        </Flex>
      </Flex>
    </>
  );
};

export default AESignupRightSection;

const ContainerInner1 = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  padding: '5px 12px',
  borderRadius: '3px',
  border: '1px solid #F7A300',
  background: '#FFF5D5',
  marginBottom: "10px"
});

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
`;
