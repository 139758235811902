import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';

export default function GongQuestionHeader() {
     return (
       <>
         <Flex
           padding={"10px 15px"}
           fontSize="12px"
           fontWeight={500}
           position="sticky"
           backgroundColor={"#F8F8F8"}
           alignItems={"center"}
         >
           <Flex padding={"0 5px"} width="5%" >
             #
           </Flex>
           {/* <Flex padding={"0 5px"} width="30%" >
             Template Id
           </Flex> */}
           <Flex padding={"0 5px"} width="40%" >
             Question
           </Flex>
           <Flex padding={"0 5px"} width="10%" >
             Added By
           </Flex>
           <Flex padding={"0 5px"} width="10%" >
             Added On
           </Flex>
           <Flex padding={"0 5px"} width="20%" >
             Activate/Deactivate
           </Flex>
           <Flex padding={"0 5px"} width="20%" >
             Actions
           </Flex>
         </Flex>
         {/* <Flex
           padding={"10px 15px"}
           fontSize="12px"
           fontWeight={500}
           position="sticky"
           backgroundColor={"#F8F8F8"}
           alignItems={"center"}
         >
           <Flex  width="5%" >
   
           </Flex>
           <Flex padding={"0 5px"} width="10%" >
             <TextField
               placeholder=""
               variant="outlined"
               style={{ width: "100%" }}
               value={searchNameOrEmail}
               onChange={(e) => setSearchNameOrEmail(e.target.value)}
               InputProps={{
                 style: {
                   justifyContent: "space-between",
                   alignItems: "center",
                   padding: "5px",
                   background: "#FFFFFF",
                   borderRadius: "6px",
                   height: "43px",
                   position: "relative",
                   color: "#ffffff",
                 }
               }}
             />
           </Flex>
           <Flex padding={"0 5px"} width="15%" >
             <TextField
               placeholder=""
               variant="outlined"
               style={{ width: "100%" }}
               value={searchType}
               onChange={(e) => setSearchType(e.target.value)}
               InputProps={{
                 style: {
                   justifyContent: "space-between",
                   alignItems: "center",
                   padding: "5px",
                   background: "#FFFFFF",
                   borderRadius: "6px",
                   height: "43px",
                   position: "relative",
                   color: "#ffffff",
                 }
               }}
             />
           </Flex>
           <Flex padding={"0 5px"} width="30%" >
             <TextField
               placeholder=""
               variant="outlined"
               style={{ width: "100%" }}
               value={searchFrameworkOrContextName}
               onChange={(e) => setSearchFrameworkOrContextName(e.target.value)}
               InputProps={{
                 style: {
                   justifyContent: "space-between",
                   alignItems: "center",
                   padding: "5px",
                   background: "#FFFFFF",
                   borderRadius: "6px",
                   height: "43px",
                   position: "relative",
                   color: "#ffffff",
                 }
               }}
             />
           </Flex>
           <Flex padding={"0 5px"} width="15%" >
             <TextField
               placeholder=""
               variant="outlined"
               style={{ width: "100%" }}
               value={searchFrameworkOrContextValue}
               onChange={(e) => setSearchFrameworkOrContextValue(e.target.value)}
               InputProps={{
                 style: {
                   justifyContent: "space-between",
                   alignItems: "center",
                   padding: "5px",
                   background: "#FFFFFF",
                   borderRadius: "6px",
                   height: "43px",
                   position: "relative",
                   color: "#ffffff",
                 }
               }}
             />
           </Flex>
           <Flex padding={"0 5px"} width="25%" >
   
           </Flex>
         </Flex> */}
       </>
     );
}