import * as React from 'react';

function HireezLogo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="12" viewBox="0 0 60 12" fill="none">
            <g clip-path="url(#clip0_210_15405)">
            <path d="M44.3842 3.40946H40.2601C40.4566 3.08486 40.6253 2.79484 40.8058 2.51213C40.999 2.21032 41.1771 1.89304 41.4093 1.62335C41.7586 1.21862 42.228 1.0368 42.7668 1.03721C44.3084 1.03843 45.85 1.04209 47.3916 1.03517C47.8725 1.03314 48.2396 1.21456 48.4597 1.64125C48.683 2.07405 48.6235 2.49017 48.3382 2.8827C47.3818 4.19939 46.4287 5.51812 45.4743 6.83604C45.1083 7.34124 44.7416 7.84563 44.376 8.35124C44.3455 8.39354 44.321 8.44072 44.2745 8.51638H48.152C48.0261 9.0708 48.0477 9.62766 47.7848 10.1129C47.5053 10.6291 47.0452 10.9134 46.4568 10.9143C44.0394 10.9171 41.6221 10.9045 39.2047 10.8968C39.179 10.8968 39.1529 10.8894 39.082 10.8789C40.8478 8.39191 42.5985 5.9257 44.3842 3.40986V3.40946Z" fill="white"/>
            <path d="M41.1536 1.0633C40.7885 1.60023 40.4498 2.12414 40.0847 2.62853C39.6923 3.17074 39.1348 3.42619 38.4726 3.43514C37.6519 3.44653 36.8307 3.43799 35.9868 3.43799C35.9089 3.88055 35.8323 4.31619 35.7471 4.80228H39.742C39.6853 4.90681 39.6605 4.9601 39.6295 5.00932C39.409 5.35873 39.1898 5.70936 38.9653 6.05633C38.495 6.78484 37.8181 7.14931 36.9554 7.16964C36.4668 7.18103 35.9778 7.18144 35.4892 7.17737C35.3555 7.17615 35.3026 7.21276 35.2826 7.35309C35.2288 7.73383 35.1542 8.11171 35.0837 8.51522H39.9241C39.8671 8.60633 39.8373 8.66084 39.8015 8.71087C39.4755 9.1693 39.1503 9.62813 38.821 10.0841C38.4192 10.6402 37.8678 10.9172 37.1857 10.9216C36.1604 10.9286 35.1351 10.9265 34.1102 10.9225C33.0796 10.9184 32.3852 10.0475 32.5698 9.03222C32.9145 7.13507 33.2471 5.23548 33.5755 3.33508C33.6688 2.79489 33.7878 2.2722 34.1636 1.84591C34.5976 1.35413 35.1379 1.05232 35.7956 1.04662C37.5541 1.03157 39.3125 1.04337 41.0709 1.04499C41.0892 1.04499 41.1076 1.05272 41.154 1.06289L41.1536 1.0633Z" fill="white"/>
            <path d="M31.3516 9.51231C30.7758 10.1981 30.0651 10.6317 29.2097 10.8136C28.001 11.0706 26.8698 10.8847 25.8738 10.1273C24.6268 9.17877 24.1761 7.68676 24.6313 6.10607C25.082 4.54084 26.2271 3.62562 27.8751 3.52962C29.1343 3.456 30.2591 3.77816 31.1393 4.72673C31.8622 5.50569 32.1189 6.44775 32.0607 7.50819H25.494C25.4072 8.30463 26.069 9.24873 27.0124 9.67868C28.0418 10.1477 29.5814 10.1119 30.5146 8.96725C30.788 9.14541 31.0635 9.32479 31.3516 9.51231ZM30.9995 6.57019C30.8455 5.45159 29.6979 4.45339 28.1233 4.48674C26.849 4.51399 25.6073 5.50691 25.5495 6.57019H30.9995Z" fill="white"/>
            <path d="M12.3156 10.8455H11.4023V0.996094H12.3144V4.64355C12.371 4.60247 12.4024 4.58416 12.4281 4.55976C13.704 3.37038 15.808 3.07669 17.4735 4.27909C18.2942 4.87174 18.7905 5.7109 18.8199 6.7453C18.8582 8.09291 18.8464 9.44215 18.8557 10.7906C18.8557 10.8028 18.8476 10.815 18.839 10.8394H17.9152C17.9152 10.7645 17.9152 10.6925 17.9152 10.6201C17.9152 9.49503 17.9176 8.36951 17.9144 7.2444C17.9119 6.40687 17.6703 5.669 16.9918 5.12394C15.9481 4.286 14.4725 4.24939 13.3535 5.03038C12.7761 5.43349 12.4924 6.01028 12.3893 6.68347C12.3425 6.99017 12.3221 7.30338 12.3197 7.61415C12.3111 8.61031 12.3164 9.60689 12.3164 10.6035C12.3164 10.6771 12.3164 10.7511 12.3164 10.8447L12.3156 10.8455Z" fill="white"/>
            <path d="M24.2753 3.52227V4.43587C24.0797 4.44847 23.8894 4.44441 23.7048 4.47573C22.9273 4.60711 22.3914 5.2315 22.3727 6.06211C22.3523 6.97651 22.3625 7.89214 22.3608 8.80695C22.3596 9.43052 22.3608 10.0541 22.3608 10.6777V10.8859H21.4346V10.6598C21.4346 9.12789 21.4346 7.59602 21.4346 6.06414C21.4346 4.59816 22.3702 3.60769 23.836 3.52349C23.977 3.51536 24.1193 3.52227 24.2757 3.52227H24.2753Z" fill="white"/>
            <path d="M19.5469 10.8868V3.53125H20.2853V10.8872H19.5469V10.8868Z" fill="white"/>
            <path d="M20.2889 0.996094V2.47061H19.5664V0.996094H20.2889Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_210_15405">
            <rect width="37.1928" height="9.9283" fill="white" transform="translate(11.4033 1)"/>
            </clipPath>
            </defs>
        </svg>

    )
}

const HireezLogoIcon = React.memo(HireezLogo);
export default HireezLogoIcon;