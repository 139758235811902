import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import SettingContent from "./SettingContent";

function SettingRightSection() {
    return (
        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
            <SettingContent></SettingContent>
        </Flex>
    )
}

export default SettingRightSection;