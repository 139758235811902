import React, { useEffect, useState } from "react";
import TeamManagementTableBody from "./TeamManagementTableBody";
import TeamManagementTableHeader from "./TeamManagementTableHeader";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import userService from "services/userService";
import { Backdrop } from "@mui/material";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { ErrorMessage, Form, Formik } from 'formik';
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import * as yup from 'yup';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import Select from 'react-select';
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { useAppSelector } from "hooks";
import { useDispatch } from "react-redux";
import { setMemberListingData } from "./store/memberListingReducer";
import SearchBar from "components/TailwindComponents/SearchBar";
import Pagination from "components/Pagination/Pagination";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

// const StatusValues = ["All Members", "Admin", "SDR", "AE"];

const StatusValues = [{ label: "All Members", value: "All Members" }, { label: "Admin", value: "Admin" }, { label: "SDR", value: "SDR" }, { label: "AE", value: "AE" }];

const AddMemberSchema = yup.object().shape({
    name: yup.string().required("Name is required."),
    email: yup
        .string()
        .trim()
        .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, "Invalid email address.")
        .required("Email is required."),
    userType: yup.string().required("Please select a user type."),
});

const MenuValue = [
    "analytics",
    "integration",
    "tag",
    "stakeholders",
    "history",
    "home",
];

const usertypeOptions = [
    { value: "admin", label: "Admin" },
    { value: "sdr", label: "SDR" },
    { value: "ae", label: "AE" }
];



function TeamManagementTable() {
    const [loading, setLoading] = useState(false);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [showSnackBarModal, setShowSnackBarModal] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [hideModal, setHideModal] = useState(true);
    const [showOrganizationDropdown, setShowOrganizationDropdown] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [filterStatus, setFilterStatus] = useState("All Members");
    const dispatch = useDispatch();


    const [searchString, setSearchString] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [searchData, setSearchData] = useState({});

    const itemPerPageOptions = [
        { value: 20, label: "Per page: 20" },
        { value: 50, label: "Per page: 50" }
    ];

    const userOrganizationId = useAppSelector(
        (state) => state.profileState.profileData.organizationId
    );

    const handleSearchChange = (e) => {
        const searchValue = e?.target?.value;
        const searchTermLowerCase = searchValue.toLowerCase().trim();
        setSearchString(searchTermLowerCase)
    }

    const handleClear = () => {
        setSearchTerm("");
    }

    const handleInvite = (value) => {
        const fullname = value.name.split(" ");
        const obj = { "firstName": fullname[0], "lastName": fullname[1] ?? '', "email": value.email.toLowerCase(), "menu": [], "userType": value.userType, "organizationId": userOrganizationId, "password": "1234567890", "refreshToken": "", "activeUser": false, isInviteEmailSend:1
     }
        try {
            userService.addUser(obj)
                .then((response) => {
                    console.log("responseresponseresponse",response);
                    if (response?.message) {
                        setSnackbarMessage('Member Already Exist');
                        setSnackbarType('error');
                        setShowSnackBarModal(true);
                    } else if (response?.success && response?.user?.identityContact?.email) {
                        setSnackbarMessage('Member has been added successfully.');
                        setShowSnackBar(true);
                        setSnackbarType('success');
                        setHideModal(true)
                        fetchReport(currentPage, itemsPerPage, searchData);
                    } else {
                        setSnackbarMessage('Error adding Member. Please try again later');
                        setShowSnackBar(true);
                        setSnackbarType('error');
                        setHideModal(true);
                        setShowOrganizationDropdown(false);
                    }
                })
        } catch (error) {
            console.error("Error adding member", error)
            setHideModal(true)
        }
    }

    const fetchReport = async (page = 1, perPage = 20, search = {}) => {
        try {
            setLoading(true);
            const obj = { "organizationId": userOrganizationId, "filter": false, page: page, perPage: perPage, search };
            userService.getAllMemberByOrgId(obj)
                .then((response) => {
                    dispatch(setMemberListingData(response?.data?.data));
                    let filterData = Array.isArray(response?.data?.data)
                        ? response?.data?.data?.filter(item => !(item.isActive && item.isDeleted))
                        : [];
                    setFilteredMembers(filterData);
                    setTotalDataCount(response?.data?.totalCount?.total || 0);
                    setLoading(false);
                })
        } catch (error) {
            console.error("Error fetching report:", error);
            setLoading(false);
        }

    };

    useEffect(() => {
        if(userOrganizationId){
            fetchReport(currentPage, itemsPerPage, searchData);
        }
    }, [userOrganizationId, currentPage, itemsPerPage, searchData]);

    // useEffect(() => {
    //     fetchReport();
    // }, [userOrganizationId])

    useEffect(() => {

        let search = { searchString: searchString };
        if (filterStatus.toLowerCase() !== "all members") {
            search["userType"]=filterStatus.toLowerCase();
        }

        setSearchData(search);
        setCurrentPage(1);

    }, [filterStatus, searchString])

    const showClearIcon = searchTerm.length > 0;

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (newItemsPerPage) => { 
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(totalDataCount / itemsPerPage);

    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            <Box>
                {loading && <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <MemoRevenoidLoader />
                </Backdrop>}
                <div>
                    <Flex justifyContent={"space-between"} alignItems="center" marginBottom={"20px"} gap="20px">
                        <Flex>
                            {/* <Text fontWeight="400" fontSize="32px">Team Management</Text> */}
                            <SearchBar placeholder="Search via name or email" onChange={handleSearchChange} />
                        </Flex>
                        <Flex style={{ zIndex: "99" }}>
                            <Box display={"flex"} marginRight={"15px"}>
                                <Text fontSize={"12px"} marginRight={"15px"} alignSelf={"center"}>Filter By : </Text>
                                <Box display={"flex"} marginRight={"15px"}>
                                    <Select
                                        placeholder="User Role: e.g. Admin"
                                        options={StatusValues}
                                        defaultValue={StatusValues[0]}
                                        onChange={(e) => setFilterStatus(e.value)}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                width: "202px",
                                                height: "48px",
                                                border: "6px",
                                                fontSize: "12px"
                                            }),
                                        }}

                                    />
                                </Box>
                            </Box>
                            <Flex justifyContent={"flex-end"}>
                                <Button
                                    size="m"
                                    variant="secondary"
                                    borderRadius={"9px"}
                                    padding={"10px 20px"}
                                    fontSize={"14px"}
                                    fontWeight={"500"}
                                    lineHeight={"normal"}
                                    onClick={() => { setHideModal(false); }}
                                >
                                    Invite New Member
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </div>
                <TeamManagementTableHeader />
                <TeamManagementTableBody data={filteredMembers} fetchList={fetchReport} currentPage={currentPage} itemsPerPage={itemsPerPage}/>
                <Box borderBottomLeftRadius={"8px"} borderBottomRightRadius={"8px"} background={"#fff"} padding={"10px 16px"}>
                    {totalDataCount > itemsPerPage && <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        itemPerPageOptions={itemPerPageOptions}
                    />}
                </Box>
            </Box>

            <ModalWrapper
                width="max-content"
                height="max-content"

                hideModal={hideModal}
                setHideModal={() => {
                    setHideModal(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="24px"
            >
                <SnackBar
                    iconName={"info"}
                    message={snackbarMessage}
                    setShowSnackBar={setShowSnackBarModal}
                    show={showSnackBarModal}
                    timeout={4000}
                    anchor={"top-right"}
                    type={snackbarType}
                />

                <>
                    <Text fontWeight="400" fontSize="24px" marginBottom={4}>Invite a new member</Text>
                    <Formik
                        key={isModalOpen.toString()}
                        initialValues={{
                            name: '',
                            email: '',
                            userType: '',
                        }}
                        onSubmit={(values) => {
                            console.log('Submitting edited data:', values);
                            handleInvite(values);
                        }}
                        validationSchema={AddMemberSchema}

                    >
                        {({ setFieldValue, values }) => (
                            <Form>
                                <Flex flexDirection="column">
                                    <Flex padding={"5px 10px"} marginBottom={3} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Select Role</Text>
                                        <Select
                                            className="single-select"
                                            placeholder="Select Role"
                                            name="userType"
                                            options={usertypeOptions}
                                            onChange={(selectedOption) => {
                                                setFieldValue("userType", selectedOption ? selectedOption.value : '');
                                            }}

                                            styles={{
                                                control: (provided: Record<string, unknown>, state: any) => ({
                                                    ...provided,
                                                    width: '360px',
                                                }),
                                            }}

                                        />
                                        <ErrorMessage
                                            name="userType"
                                            component="div"
                                            className="custom-error-message"
                                        />
                                    </Flex>
                                    <Flex padding={"5px 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Name</Text>
                                        <FormikInput
                                            name="name"
                                            placeholder="Name"
                                        />
                                    </Flex>
                                    <Flex padding={"5px 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Email Address</Text>
                                        <FormikInput
                                            name="email"
                                            type="email"
                                            placeholder="aereturn.profile.email"
                                        />
                                    </Flex>
                                    <Flex  marginRight={1} padding={"5px 10px"}>
                                        <Flex justifyContent={"left"}>
                                            <Button type="submit">Send Invite</Button>
                                        </Flex>
                                        <Flex justifyContent={"right"}>
                                            <Button variant="secondary" type="button" onClick={() => { setHideModal(true); }} marginLeft={2}>
                                                Cancel
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </>
            </ModalWrapper>
        </>
    );
}

export default TeamManagementTable;
