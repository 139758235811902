import apiService from "./apiService";

class userService {
    async saveRefreshToken(userId: String, code: String, type: String) {
        const obj = { userId: userId, code: code }
        if (type === "gong")
            return apiService.post(type + "/saveRefreshTokenDashboard", obj);
        else
            return apiService.post(type + "/saveRefreshToken", obj);
    }
    async deleteRefreshToken(userId: String, type: String) {
        const obj = { userId: userId }
        return apiService.post(type + "/deleteRefreshToken", obj);
    }

    async getUserList(type: string, userId: String) {
        const obj = { userId: userId }
        return apiService.post(type + "/getUserList", obj);
    }

    async getMenuOptions(obj) {
        return apiService.post("rapport/user/getMenuOptions", obj);
    }

    async getTotalCount(obj) {
        return apiService.post("rapport/user/getTotalCount", obj);
    }

    async getAllMember() {
        return apiService.post("rapport/user/getMemberList", {});
    }

    async getAllMemberByOrgId(obj) {
        return apiService.post("rapport/user/getOranizationMemberList", obj);
    }

    async updateUser(obj) {
        return apiService.post("rapport/user/updateMember", obj);
    }

    async updateTeamMember(obj) {
        return apiService.post("rapport/team/updateTeamMember", obj);
    }

    async fetchAdminMemberList() {
        return apiService.post("rapport/user/fetchAdminMemberList", {});
    }

    async resetPassword(obj) {
        return apiService.post("rapport/user/sendForgotPasswordLink", obj);
    }

    async addUser(obj) {
        return apiService.post("rapport/user/addMember", obj);
    }

    async deleteUser(obj) {
        return apiService.post("rapport/user/deleteMember", obj);
    }

    async addTagListByExcel(obj) {
        return apiService.post("crm/addTagListByExcel", obj);
    }

    async updateActivationStatus(obj) {
        return apiService.post("rapport/user/updateMemberDetails", obj);
    }

    async resendInvitation(obj) {
        return apiService.post("rapport/user/resendInvitation", obj);
    }

    async updateFrameworkOrContextStatus(obj) {
        return apiService.post("rapport/user/updateFrameworkOrContextStatus", obj);
    }

    async updateOrDeleteFramework(obj) {
        return apiService.post("rapport/user/updateOrDeleteFramework", obj);
    }

    async updateOrDeleteContext(obj) {
        return apiService.post("rapport/user/updateOrDeleteContext", obj);
    }

    async addMemberKey(obj) {
        return apiService.post("chromeExtension/addMemberKey", obj);
    }

    async companyKeywords(obj) {
        return apiService.post("chromeExtension/v1/getCompanyKeywords", obj);
    }

    async getCallPrep(obj) {
        return apiService.post("chromeExtension/v1/getCallPrepField", obj);
    }

    async saveCallPrep(obj) {
        return apiService.post("chromeExtension/v1/saveCallPrepField", obj);
    }


    async evaLogin(obj) {
        return apiService.post("rapport/user/evaLogin", obj);
    }

    async updatePassword(obj) {
        return apiService.post("rapport/user/updatePassword", obj);
    }

    async getAccountDetails(obj) {
        return apiService.post("rapport/user/getAccountDetails", obj);
    }

    async accountCallPrepSetting(obj) {
        return apiService.post("rapport/user/accountCallPrepSetting", obj);
    }

    async getSsoCallbackWorkOS(obj) {
        return apiService.get(`workos/callbackWorkOS?code=${obj?.code}&ssologinemail=${obj?.ssoLoginEmail}`);
    }

    async validateTokenAndLogin(jwtToken) {
        return apiService.post("/validateTokenAndLogin", jwtToken);
    }

    async checkEmailAccess(email) {
        return apiService.post("workos/checkEmailAccess", email);
    }

    async getUpdatedToken(obj) {
        return apiService.post("rapport/user/getUpdatedToken", obj);
    }

    async saveCompanyKeywords(obj) {
        return apiService.post("chromeExtension/v1/saveCompanyKeywords", obj);
    }

    async saveHiringKeywords(obj) {
        return apiService.post("chromeExtension/v1/saveHiringKeywords", obj);
    }

    async saveFactKeywords(obj) {
        return apiService.post("chromeExtension/v1/saveFactKeywords", obj);
    }

    async updateMemberPitch(obj) {
        return apiService.post("chromeExtension/addOrUpdateMemberPitch", obj);
    }

    async getUserInfoData(obj) {
        return apiService.post("rapport/user/getUserInfoData", obj);
    }

    async saveAutoOpenSetting(obj) {
        return apiService.post("chromeExtension/v1/saveAutoOpenSetting/", obj);
    }

    async fetchListFromApollo(obj) {
        return apiService.post("rapport/user/fetchListFromApollo", obj);
    }

    async updateAIVisibility(obj) {
        return apiService.post("rapport/user/updateAIVisibility", obj);
    }

}
export default new userService();