import React, { useCallback, useState, useEffect } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Info } from "@evabot/eva-react-core-library/dist/molecules/Info/Info";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Anchor } from "@evabot/eva-react-core-library/dist/atoms/Anchor/Anchor";
import FieldMappingOpportunitySection from "./FieldMappingOpportunitySection";
import FieldMappingLeadsSection from "./FieldMappingLeadsSection";
import FieldMappingAccountsSection from "./FieldMappingAccountsSection";
import FieldMappingUsersSection from "./FieldMappingUsersSection";
import { useNavigate, useParams } from "react-router-dom";
import { axiosHelper } from "utils";
import { setGlobalProgress } from "store/globalStateReducer";
import FieldMappingActivitySection from "./FieldMappingActivitySection";
import FieldMappingContactSection from "./FieldMappingContactSection";
import FieldMappingOpportunityStageSection from "./FieldMappingOpportunityStageSection";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { useAppSelector, useAppDispatch } from "hooks";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { checkObjectArray } from "utils/constants";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

const FieldMappingContent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fieldMappingData = useAppSelector((state) => state.fieldMapping);
  const globalProgress = useAppSelector((state) => state.globalState.progress);
  const [loading, setLoading] = useState(true);
  const [stonlyId,setStonlyId]=useState(null);

  const [allOpportunityFieldsData, setAllOpportunityFieldsData] = useState([]);
  const [allAccountFieldsData, setAllAccountFieldsData] = useState([]);
  const [allLeadFieldsData, setallLeadFieldsData] = useState([]);
  const [allContactFieldsData, setAllContactFieldsData] = useState([]);
  const [allUserFieldsData, setAllUserFieldsData] = useState([]);
  const [allActivityFieldsData, setAllActivityFieldsData] = useState([]);

  const [isAccountsCompleteFlag, toggleIsAccountsCompleteFlag] =
    useState(false);
  const [isActivityCompleteFlag, toggleIsActivityCompleteFlag] =
    useState(false);
  const [isContactCompleteFlag, toggleIsContactCompleteFlag] = useState(false);
  const [isLeadsCompleteFlag, toggleIsLeadsCompleteFlag] = useState(false);
  const [isOpportunityCompleteFlag, toggleIsOpportunityCompleteFlag] =
    useState(false);
  const [isStageCompleteFlag, toggleIsStageCompleteFlag] = useState(false);
  const [isUsersCompleteFlag, toggleIsUsersCompleteFlag] = useState(false);
  const [isAllStagesCompleted, toggleIsAllStagesCompleted] = useState(false);

  const fetchAllData = async (value) => {
    const result = await axiosHelper({
      url: `salesforce/getSalesforceFieldsFromCollection/${value}`,
      method: "GET",
      JSONData: {},
      urlOverride: false,
    });
    if (result.data?.success) {
      if (value === "Task") {
        setAllActivityFieldsData(result.data?.data?.fields);
      } else if (value === "Opportunity") {
        setAllOpportunityFieldsData(result.data?.data?.fields);
      } else if (value === "Account") {
        setAllAccountFieldsData(result.data?.data?.fields);
      } else if (value === "Contact") {
        setAllContactFieldsData(result.data?.data?.fields);
      } else if (value === "Lead") {
        setallLeadFieldsData(result.data?.data?.fields);
      } else if (value === "User") {
        setAllUserFieldsData(result.data?.data?.fields);
      }
    }
  };

  useEffect(() => {
    const items =localStorage.getItem('jwt_token');
    if (items) {
      setStonlyId(items);
      console.log("stonlyId :",items)
    }
  }, []);
  const submitFieldMapping = async () => {
    const result = await axiosHelper({
      url: `salesforce/updateFieldMapping`,
      method: "PUT",
      JSONData: {
        fields: fieldMappingData,
      },
      urlOverride: false,
    });
    if (result.data.success) {
      if(globalProgress<20) {
        dispatch(setGlobalProgress(20));
      }
      navigate("/opportunityLayout");
    }
  };

  useEffect(() => {
    fetchAllData("Opportunity");
    fetchAllData("Account");
    fetchAllData("Lead");
    fetchAllData("Contact");
    fetchAllData("User");
    fetchAllData("Task");
  }, []);

  const onSubmitOpportunity = useCallback(() => {
    toggleAccountsExpandedFlag(true);
  }, []);
  const [accountsExpandedFlag, toggleAccountsExpandedFlag] = useState(false);

  const onSubmitAccounts = useCallback(() => {
    toggleLeadsExpandedFlag(true);
  }, []);

  const [leadsExpandedFlag, toggleLeadsExpandedFlag] = useState(false);
  const onSubmitLeads = useCallback(() => {
    toggleContactExpandedFlag(true);
  }, []);

  const [usersExpandedFlag, toggleUsersExpandedFlag] = useState(false);
  const onSubmitUsers = useCallback(() => {
    toggleOpportunityStageExpandedFlag(true);
  }, []);

  const [contactExpandedFlag, toggleContactExpandedFlag] = useState(false);
  const onSubmitContact = useCallback(() => {
    toggleUsersExpandedFlag(true);
  }, []);

  const [opportunityStageExpandedFlag, toggleOpportunityStageExpandedFlag] =
    useState(false);
  const onSubmitStage = useCallback(() => {
    toggleActivityExpandedFlag(true);
  }, []);

  const [activityExpandedFlag, toggleActivityExpandedFlag] = useState(false);
  const onSubmitActivity = useCallback(() => {}, []);

  const { previous } = useParams();

  useEffect(() => {
    if (
      previous === "previous" &&
      checkObjectArray(fieldMappingData?.defaultState?.accounts) &&
      checkObjectArray(fieldMappingData?.defaultState?.opportunity) &&
      checkObjectArray(fieldMappingData?.defaultState?.leads) &&
      checkObjectArray(fieldMappingData?.defaultState?.contact) &&
      checkObjectArray(fieldMappingData?.defaultState?.users) &&
      checkObjectArray(
        fieldMappingData?.defaultState?.opportunityStage,
        true
      ) &&
      checkObjectArray(fieldMappingData?.defaultState?.activity)
    ) {
      toggleIsOpportunityCompleteFlag(true);
      toggleIsAccountsCompleteFlag(true);
      toggleIsLeadsCompleteFlag(true);
      toggleIsContactCompleteFlag(true);
      toggleIsUsersCompleteFlag(true);
      toggleIsStageCompleteFlag(true);
      toggleIsActivityCompleteFlag(true);
    }
  }, [
    fieldMappingData?.defaultState?.accounts,
    fieldMappingData?.defaultState?.activity,
    fieldMappingData?.defaultState?.contact,
    fieldMappingData?.defaultState?.leads,
    fieldMappingData?.defaultState?.opportunity,
    fieldMappingData?.defaultState?.opportunityStage,
    fieldMappingData?.defaultState?.users,
    previous,
  ]);

  useEffect(() => {
    if (
      isAccountsCompleteFlag &&
      isActivityCompleteFlag &&
      isContactCompleteFlag &&
      isLeadsCompleteFlag &&
      isOpportunityCompleteFlag &&
      isStageCompleteFlag &&
      isUsersCompleteFlag
    ) {
      toggleIsAllStagesCompleted(true);
    } else toggleIsAllStagesCompleted(false);
  }, [
    isAccountsCompleteFlag,
    isActivityCompleteFlag,
    isContactCompleteFlag,
    isLeadsCompleteFlag,
    isOpportunityCompleteFlag,
    isStageCompleteFlag,
    isUsersCompleteFlag,
  ]);

  useEffect(() => {
    if (
      allOpportunityFieldsData?.length > 0 &&
      allAccountFieldsData?.length > 0 &&
      allLeadFieldsData?.length > 0 &&
      allContactFieldsData?.length > 0 &&
      allUserFieldsData?.length > 0 &&
      allActivityFieldsData?.length > 0
    ) {
      setLoading(false);
    }
  }, [
    allAccountFieldsData?.length,
    allActivityFieldsData?.length,
    allContactFieldsData?.length,
    allLeadFieldsData?.length,
    allOpportunityFieldsData?.length,
    allUserFieldsData?.length,
  ]);

  return (
    <Box padding="32px 42px">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        marginBottom="20px"
      >
        <Flex flexDirection="column" gap="10px">
          {
            //@ts-ignore
            <Text
              id="fieldmapping.title"
              fontSize="24px"
              color="#050505"
              fontWeight="400"
            />
          }
          <Info locale="fieldmapping.infomessage" color="#1FA1FF" />
        </Flex>
        <Flex alignItems="baseline">
          {
            //@ts-ignore
            <Text fontSize="12px" fontWeight="400">
              Having troubles?
            </Text>
          }
          <Anchor text="Get Help" link="" id={stonlyId} />
        </Flex>
      </Flex>
      {loading ? (
        <Flex height="75vh" justifyContent={"center"}>
          <MemoRevenoidLoader />
        </Flex>
      ) : (
        <Grid gridGap={24}>
          <FieldMappingOpportunitySection
            onSubmit={onSubmitOpportunity}
            isOpportunityCompleteFlag={isOpportunityCompleteFlag}
            toggleIsOpportunityCompleteFlag={toggleIsOpportunityCompleteFlag}
            allSalesforceFields={allOpportunityFieldsData}
            initialValue={fieldMappingData?.defaultState.opportunity}
          />

          <FieldMappingAccountsSection
            accountsExpandedFlag={accountsExpandedFlag}
            toggleAccountsExpandedFlag={toggleAccountsExpandedFlag}
            allSalesforceFields={allAccountFieldsData}
            isAccountsCompleteFlag={isAccountsCompleteFlag}
            toggleIsAccountsCompleteFlag={toggleIsAccountsCompleteFlag}
            onSubmit={onSubmitAccounts}
            initialValue={fieldMappingData?.defaultState.accounts}
          />

          <FieldMappingLeadsSection
            allSalesforceFields={allLeadFieldsData}
            leadsExpandedFlag={leadsExpandedFlag}
            isLeadsCompleteFlag={isLeadsCompleteFlag}
            toggleIsLeadsCompleteFlag={toggleIsLeadsCompleteFlag}
            toggleLeadsExpandedFlag={toggleLeadsExpandedFlag}
            onSubmit={onSubmitLeads}
            initialValue={fieldMappingData?.defaultState?.leads}
          />

          <FieldMappingContactSection
            allContactFieldsData={allContactFieldsData}
            contactExpandedFlag={contactExpandedFlag}
            isContactCompleteFlag={isContactCompleteFlag}
            toggleIsContactCompleteFlag={toggleIsContactCompleteFlag}
            toggleContactExpandedFlag={toggleContactExpandedFlag}
            onSubmit={onSubmitContact}
            initialValue={fieldMappingData?.defaultState?.contact}
          />

          <FieldMappingUsersSection
            allUserFieldsData={allUserFieldsData}
            usersExpandedFlag={usersExpandedFlag}
            isUsersCompleteFlag={isUsersCompleteFlag}
            toggleIsUsersCompleteFlag={toggleIsUsersCompleteFlag}
            toggleUsersExpandedFlag={toggleUsersExpandedFlag}
            onSubmit={onSubmitUsers}
            initialValue={fieldMappingData?.defaultState?.users}
          />

          <>
            <FieldMappingOpportunityStageSection
              allOpportunityFieldsData={allOpportunityFieldsData}
              stageExpandedFlag={opportunityStageExpandedFlag}
              isStageCompleteFlag={isStageCompleteFlag}
              toggleIsStageCompleteFlag={toggleIsStageCompleteFlag}
              toggleStageExpandedFlag={toggleOpportunityStageExpandedFlag}
              onSubmit={onSubmitStage}
              initialValue={fieldMappingData?.defaultState?.opportunityStage}
            />
          </>

          <FieldMappingActivitySection
            activityExpandedFlag={activityExpandedFlag}
            allActivityFieldsData={allActivityFieldsData}
            isActivityCompleteFlag={isActivityCompleteFlag}
            toggleIsActivityCompleteFlag={toggleIsActivityCompleteFlag}
            toggleActivityExpandedFlag={toggleActivityExpandedFlag}
            onSubmit={onSubmitActivity}
            initialValue={fieldMappingData?.defaultState?.activity}
          />
          <Flex marginTop={36}></Flex>
          <Flex justifyContent={"flex-end"} paddingBottom="40px">
            <Button
              iconAlign="right"
              iconName="arrowRight"
              title="next"
              // onClick={submitFieldMapping}
              disabled={!isAllStagesCompleted}
              onClick={submitFieldMapping}
            />
          </Flex>
        </Grid>
      )}
      <Flex marginTop={36}></Flex>
    </Box>
  );
};

export default FieldMappingContent;
