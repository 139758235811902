import React, { useState } from "react";
import { TextField, Box, Typography, Chip, Tooltip } from "@mui/material";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";

function AddCustomDataComponent({ onBack, onSave, editData = null, pdfDataPreview = false }) {
    const [title, setTitle] = useState(editData?.title || "");
    const [tagInput, setTagInput] = useState("");
    const [tags, setTags] = useState(editData?.tags || []);
    const [data, setData] = useState(editData?.rawData || "");
    const [errors, setErrors] = useState({
        title: false,
        tags: false,
        data: false
    });

    const handleTagAdd = () => {
        const trimmedTag = tagInput.trim();
        if (trimmedTag && !tags.includes(trimmedTag)) {
            setTags((prevTags) => [...prevTags, trimmedTag]);
            setTagInput("");
        }
    };

    const handleTagDelete = (tagToDelete) => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
    };

    const validateFields = () => {
        const newErrors = {
            title: !title,
            tags: tags.length === 0,
            data: !data
        };
        setErrors(newErrors);
        return !(newErrors.title || newErrors.tags || newErrors.data);
    };

    const handleSave = () => {
        if (!validateFields()) {
            return;
        }

        const customDataObject = {
            title: title,
            tags: tags,
            rawData: data,
            repoId: editData?.repoId || null,
        };

        onSave([customDataObject]);
    };

    return (
        <Box>
            <Box marginBottom={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"} justifyItems={"center"} >
                <Typography variant="h6">
                    {pdfDataPreview ? "Preview Data" : editData ? "Edit Custom Data" : "Custom Data Setup"}
                </Typography>
                <Button size="xs" variant="secondary" onClick={onBack} iconName="arrowLeft">
                    Back
                </Button>
            </Box>
            <Box sx={{ backgroundColor: 'white', borderRadius: 2, padding: 2, boxShadow: 1, paddingY: 2 , paddingBottom:"40px"}}>
                <Box display="flex" flexDirection="column" gap={2}>
                    <label>Title*</label>
                    <TextField
                        style={{marginTop:"-8px"}}
                        placeholder="Input Custom Data title"
                        variant="outlined"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                        error={errors.title}
                        helperText={errors.title ? "Title is required" : ""}
                    />
                    <label>Tags*</label>
                    <Tooltip
                        style={{ marginTop: "-10px", marginBottom:"3px" }}
                        title="Add Tag and then press enter"
                        placement="top"
                        arrow
                    >
                        <TextField
                            value={tagInput}
                            placeholder="Add Tag and then press enter"
                            onChange={(e) => setTagInput(e.target.value)}
                            onKeyPress={(e) => e.key === "Enter" && handleTagAdd()}
                            fullWidth
                            multiline
                            margin="normal"
                            error={errors.tags}
                            helperText={errors.tags ? "At least one tag is required" : ""}
                            sx={{
                                "& .MuiInputBase-root": {
                                    display: "block",
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: "5px",
                                            marginRight: "8px",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        {tags.map((tag, index) => (
                                            <Chip
                                                key={index}
                                                label={tag}
                                                onDelete={() => handleTagDelete(tag)}
                                                style={{
                                                    border: "1px solid rgb(0, 148, 227)",
                                                    color: "rgb(0, 148, 227)",
                                                    backgroundColor: "white",
                                                    fontSize: "12px",
                                                }}
                                                sx={{
                                                    "& .MuiChip-deleteIcon": {
                                                        display: "none",
                                                    },
                                                    "&:hover .MuiChip-deleteIcon": {
                                                        display: "inline",
                                                        color: "white",
                                                    },
                                                    "&:hover": {
                                                        backgroundColor: "rgb(0, 148, 227) !important",
                                                        color: "white !important",
                                                        borderColor: "transparent",
                                                    },
                                                }}
                                            />
                                        ))}
                                    </Box>
                                ),
                            }}
                        />
                    </Tooltip>
                    <label style={{padding:"0", margin:"0"}}>Data*</label>
                    <TextField
                        style={{marginTop:"-8px"}}
                        placeholder="Input Custom Data"
                        variant="outlined"
                        multiline
                        rows={4}
                        value={data}
                        onChange={(e) => setData(e.target.value)}
                        fullWidth
                        error={errors.data}
                        helperText={errors.data ? "Data is required" : ""}
                    />
                    <Button
                        size="xs"
                        variant="primary"
                        fontSize={"14px"}
                        padding={"10px 50px"}
                        onClick={handleSave}
                        top={20}
                        alignSelf={"center"}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default AddCustomDataComponent;
