import React, { FC, useCallback, useMemo, useState, useEffect } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import Image from "./../../assets/images/ImageDef.jpeg";
import { Info } from "@evabot/eva-react-core-library/dist/molecules/Info/Info";
import { Anchor } from "@evabot/eva-react-core-library/dist/atoms/Anchor/Anchor";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Table } from "@evabot/eva-react-core-library/dist/atoms/Table/Table";
import { ImageTag } from "@evabot/eva-react-core-library/dist/molecules/ImageTag/ImageTag";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { EditField } from "@evabot/eva-react-core-library/dist/molecules/EditField/EditField";
import { Formik, Form } from "formik";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "hooks";
import { Backdrop } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { setGlobalProgress } from "store/globalStateReducer";
import { axiosHelper } from "../../utils";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

interface BudgetSetUpProps {}

export const BudgetSetupLayout: FC<BudgetSetUpProps> = () => {
  const [stonlyId, setStonlyId] = useState(null);
  const journey = useAppSelector((state) => state.globalState.journey);
  const currentlyImportedOpportunities = useAppSelector(
    (state) => state.opportunitySelection.importOpportunities
  );
  const dispatch = useAppDispatch();
  const globalProgress = useAppSelector((state) => state.globalState.progress);
  const [budgetOpportunities, setBudgetOpportunities] = useState([]);
  const [assignedToList, setAssignedToList] = useState([]);
  const [stakeHolderNames, setStakeHoldersNames] = useState([]);
  const [showBackDrop, setShowBackDrop] = useState(false);
  console.log(journey, "state");
  const navigate = useNavigate();
  const [showSnackBar, setShowSnackBar] = useState(false);
  // const [stopPropagtion,setStopPropagtion] =useState(false);

  useEffect(() => {
    const items = localStorage.getItem("jwt_token");
    if (items) {
      setStonlyId(items);
      console.log("stonlyId :", items);
    }
  }, []);

  useEffect(() => {
    axiosHelper({
      url: "salesforce/getBudgetData",
      method: "GET",
      JSONData: {},
      urlOverride: false,
    })
      .then((res) => {
        console.log(
          res.data.data
            .map((x) => x.opportunity)
            .reduce((accum, iter) => [...accum, ...iter], [])
        );
        let fetchedOpportunities = res.data.data
          .map((x) => x.opportunity)
          .reduce((accum, iter) => [...accum, ...iter], []);
        let currentlyImportedOpportunitiesIds =
          currentlyImportedOpportunities.map((x) => x.Id);
        fetchedOpportunities = fetchedOpportunities.filter((x) =>
          currentlyImportedOpportunitiesIds.includes(x.salesforceOpportunityId)
        );
        setBudgetOpportunities(fetchedOpportunities);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  useEffect(() => {
    axiosHelper({
      url: "salesforce/getOpportunityFieldValues",
      method: "GET",
      JSONData: {},
      urlOverride: false,
    })
      .then((result) => {
        // console.log("userData",result.data.data.userData);
        setAssignedToList(result.data.data.userData);
      })
      .catch((err) => {
        console.log("error while fetching assigned to data", err);
      });
  }, []);

  const tableTitle: string[] = [
    "Opportunity name",
    "Assigned to",
    "Opportunity stage",
    "Value",
    "#Stakeholder",
    "Recommended budget",
  ];

  useEffect(() => {
    setShowBackDrop(true);
    if (budgetOpportunities.length > 0 && assignedToList.length > 0) {
      let assignedNames = [];
      budgetOpportunities.forEach((item) => {
        let Id = item.members[0].userId;
        let obj = assignedToList.filter((item) => item.Id === Id);
        assignedNames.push(obj[0]?.Name);
      });
      setStakeHoldersNames(assignedNames);
      setShowBackDrop(false);
    }
  }, [budgetOpportunities && assignedToList]);

  // const checkEmptyBudget = () => {
  //   budgetOpportunities.forEach((item) => {
  //     console.log("validation :", item.budget)
  //     if (item.budget === 0) {
  //       setStopPropagtion(false)
  //     }
  //   })

  // }

  const checkEmptyBudget = useMemo(() => {
    return budgetOpportunities.reduce((val, item) => {
      val.push(parseInt(item.budget));
      return val;
    }, []);
  }, [budgetOpportunities]);

  const containsZero = (array) => !array.includes(0);

  const updateBudget = (value: Number, index: any) => {
    // console.log("Budget Updated Values", value, index);
    let currentData = [...budgetOpportunities];
    currentData[index].budget = value;
    // console.log("Updated List", currentData);
    setBudgetOpportunities(currentData);
  };

  const totalBudget = useMemo(() => {
    return budgetOpportunities.reduce((val, item) => {
      return val + parseFloat(item.budget);
    }, 0);
  }, [budgetOpportunities]);

  const onSubmitHandler = useCallback(async (values: any) => {
    console.log(values, "values", values[0].budget, values[0].id);
  }, []);

  const handleBudgetNext = () => {
    if (containsZero(checkEmptyBudget)) {
      let updateData = [];
      budgetOpportunities.forEach((item) => {
        updateData.push({
          oppId: item.salesforceOpportunityId,
          budget: parseInt(item.budget),
        });
      });
      axiosHelper({
        url: "salesforce/updateBudgetData",
        method: "POST",
        JSONData: { updateData },
        urlOverride: false,
      })
        .then((res) => {
          if (globalProgress < 60) {
            dispatch(setGlobalProgress(60));
          }
          navigate("/emailTemplateSetup");
        })
        .catch((err) => {
          console.log("Error while submitting Budget Data", err);
        });
    } else {
      setShowSnackBar(true);
    }
  };
  const tableBody = () => {
    return (
      stakeHolderNames.length > 0 &&
      budgetOpportunities.map((d, index) => {
        return (
          <tr
            style={{ textAlign: "left" }}
            // style={{ alignItems: "center" }}
          >
            <td
              // style={{ display: "flex", justifyContent: "center" }}
              style={{ padding: "18px", paddingLeft: "30px" }}
            >
              <Text
                fontWeight={"400"}
                color="#050505"
                textAlign={"justify"}
                fontSize="14px"
              >
                {" "}
                {d.name}{" "}
              </Text>
            </td>
            <td
              style={{ padding: "18px", paddingLeft: "30px" }}
              // style={{ display: "flex", justifyContent: "center" }}
            >
              <ImageTag
                title={stakeHolderNames[index]}
                width="32px"
                height="32px"
                image={d?.image || Image}
              />
            </td>
            <td style={{ padding: "18px", paddingLeft: "30px" }}>
              <Tag
                color="#2247C9"
                bgColor="rgba(34, 71, 201, 0.1)"
                text={d.stage.key}
              />
            </td>
            <td
              style={{ padding: "18px", paddingLeft: "30px" }}
              // style={{ display: "flex", justifyContent: "flex-start", marginLeft:"-20px" }}
            >
              <Text
                fontWeight={"400"}
                color="#050505"
                textAlign={"justify"}
                fontSize="14px"
              >
                {" "}
                {/* {d.value}{" "} */}
                {`$ ${d.value}`}
              </Text>
            </td>
            <td
              style={{ padding: "18px", paddingLeft: "30px" }}
              // style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Text
                fontWeight={"400"}
                color="#050505"
                textAlign={"justify"}
                fontSize="14px"
              >
                {" "}
                {d.stakeholders.length}{" "}
              </Text>
            </td>
            <td style={{ padding: "18px", paddingLeft: "30px" }}>
              <Formik
                initialValues={{
                  budget: parseFloat(d.budget).toFixed(2),
                }}
                onSubmit={onSubmitHandler}
                enableReinitialize
              >
                {({ values, errors, initialValues, submitForm }) => {
                  // console.log(errors, "error", values, initialValues);
                  return (
                    <Form>
                      <EditField
                        name="budget"
                        width="45px"
                        height="20px"
                        fillColor="rgb(0, 148, 227)"
                        onBlur={() => updateBudget(values.budget, index)}
                        onChange={() => updateBudget(values.budget, index)}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </td>
          </tr>
        );
      })
    );
  };

  const tableFooter = () => {
    return (
      stakeHolderNames.length > 0 && (
        <Box
          background="#FFFFFF"
          padding="12px 48px"
          borderTop="1px solid rgba(0, 0, 0, 0.1)"
        >
          <Flex
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Text
              fontWeight="400"
              color="#050505"
              textAlign="justify"
              fontSize="14px"
            >
              {" "}
              Total budget: ${totalBudget?.toFixed(2)}{" "}
            </Text>
          </Flex>
        </Box>
      )
    );
  };
  return (
    <>
      <SnackBar
        iconName={"info"}
        subHeading={"Recommended budget cannot be empty!"}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        message=""
        timeout={3000}
        anchor={"top-right"}
        type={"success"}
        bgColorr="#F44545"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
        open={showBackDrop}
      >
        <MemoRevenoidLoader />
      </Backdrop>
      <Box
        background="#F7F7F7"
        padding="32px 42px"
        overflow="auto"
        height="100vh"
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          marginBottom="20px"
        >
          <Flex flexDirection="column" gap="10px">
            <Flex alignItems="baseline" gap="10px">
              <Text
                id="budgetsetup.title"
                fontSize="24px"
                color="#050505"
                fontWeight="400"
              />
              <Text fontSize="14px" color="#050505" fontWeight="200">
                {"("}
                {budgetOpportunities.length}
                {" opportunities selected)"}
              </Text>
            </Flex>
            <Info locale="budgetsetup.infomessage" color="#1FA1FF" />
          </Flex>
          <Flex alignItems="baseline">
            <Text fontSize="12px" fontWeight="400">
              Having troubles?
            </Text>
            <Anchor text="Get Help" link="" id={stonlyId} />
          </Flex>
        </Flex>
        <Table
          bodyHeight={
            budgetOpportunities?.length > 3 ? "400px" : " max-content"
          }
          caption=""
          width="100%"
          titles={tableTitle}
          rows={tableBody}
          borderLess
          footer={tableFooter}
        />
        <Flex
          flexDirection="row"
          justifyContent="flex-end"
          gap="16px"
          mt="54px"
        >
          <Button
            iconAlign="left"
            iconName="arrowLeft"
            variant="secondary"
            title="previous.step"
            onClick={() => {
              navigate("/opportunityLayout");
            }}
          />
          <Button
            iconAlign="right"
            iconName="arrowRight"
            title="next"
            onClick={handleBudgetNext}
          />
        </Flex>
      </Box>
    </>
  );
};
