import * as React from "react";

function AddIcon(props) {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 20 20" fill="#fff" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02943 14.9706 1 10 1C5.02943 1 1 5.02943 1 10C1 14.9706 5.02943 19 10 19Z" stroke="#0094E3" stroke-width="1.5" stroke-linejoin="round" />
      <path d="M10 6.40625V13.6063" stroke="#0094E3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.40039 10H13.6004" stroke="#0094E3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
export default AddIcon;
