import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { useEffect, useState } from "react";
import IntegrationList from "./components/IntegrationList";

function OtherIntegrationsRightSections() {

    

    return (
        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"} >
            <IntegrationList></IntegrationList>
        </Flex>
    );
}

export default OtherIntegrationsRightSections;
