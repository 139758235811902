import { IconButton } from "@mui/material";

const AIIconButton = () => {
  return (
    <IconButton>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M6.49854 15.9998L4.8617 11.6367L0.498657 9.99989L4.8617 8.36304L6.49854 4L8.13539 8.36304L12.4984 9.99989L8.13539 11.6367L6.49854 15.9998Z"
          fill="black"
        />
        <path
          d="M6.49854 15.9998L4.8617 11.6367L0.498657 9.99989L4.8617 8.36304L6.49854 4L8.13539 8.36304L12.4984 9.99989L8.13539 11.6367L6.49854 15.9998Z"
          fill="url(#paint0_linear_22178_10129)"
        />
        <path
          d="M2.74995 5.4999L2.0134 3.4865L0 2.74995L2.0134 2.0134L2.74995 0L3.4865 2.0134L5.49989 2.74995L3.4865 3.4865L2.74995 5.4999Z"
          fill="black"
        />
        <path
          d="M2.74995 5.4999L2.0134 3.4865L0 2.74995L2.0134 2.0134L2.74995 0L3.4865 2.0134L5.49989 2.74995L3.4865 3.4865L2.74995 5.4999Z"
          fill="url(#paint1_linear_22178_10129)"
        />
        <path
          d="M12.4981 7.99987L11.5259 5.4721L8.99817 4.49993L11.5259 3.52776L12.4981 1L13.4703 3.52776L15.998 4.49993L13.4703 5.4721L12.4981 7.99987Z"
          fill="black"
        />
        <path
          d="M12.4981 7.99987L11.5259 5.4721L8.99817 4.49993L11.5259 3.52776L12.4981 1L13.4703 3.52776L15.998 4.49993L13.4703 5.4721L12.4981 7.99987Z"
          fill="url(#paint2_linear_22178_10129)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_22178_10129"
            x1="0.498657"
            y1="9.99989"
            x2="12.4984"
            y2="9.99989"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#8D68F6" />
            <stop offset="1" stop-color="#0094E3" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_22178_10129"
            x1="0"
            y1="2.74995"
            x2="5.49989"
            y2="2.74995"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#8D68F6" />
            <stop offset="1" stop-color="#0094E3" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_22178_10129"
            x1="8.99817"
            y1="4.49993"
            x2="15.998"
            y2="4.49993"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#8D68F6" />
            <stop offset="1" stop-color="#0094E3" />
          </linearGradient>
        </defs>
      </svg>
    </IconButton>
  );
};

export default AIIconButton;