import * as React from "react";

function AutomatedSequenceIcon(props) {
    return (
       
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M7.81133 4.77098L5.1926 6.23747L6.65909 3.61874L5.1926 1L7.81133 2.46649L10.4301 1L8.96358 3.61874L10.4301 6.23747L7.81133 4.77098ZM20.3813 15.0364L23 13.5699L21.5335 16.1887L23 18.8074L20.3813 17.3409L17.7625 18.8074L19.229 16.1887L17.7625 13.5699L20.3813 15.0364ZM23 1L21.5335 3.61874L23 6.23747L20.3813 4.77098L17.7625 6.23747L19.229 3.61874L17.7625 1L20.3813 2.46649L23 1ZM13.9287 12.292L16.4846 9.7361L14.2639 7.51542L11.708 10.0713L13.9287 12.292ZM15.0076 6.54125L17.4588 8.99238C17.8673 9.37996 17.8673 10.0608 17.4588 10.4693L5.2345 22.6936C4.82597 23.1021 4.1451 23.1021 3.75753 22.6936L1.30639 20.2425C0.897869 19.8549 0.897869 19.174 1.30639 18.7655L13.5307 6.54125C13.9392 6.13272 14.62 6.13272 15.0076 6.54125Z" fill="white"/>
</svg>


    );
}

export default AutomatedSequenceIcon;
