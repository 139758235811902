import React, { useState } from "react";
import { axiosHelperCore } from "utils";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Backdrop } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import AeReturnActivityTag from "containers/AeReturnActivityHistory/AeReturnActivityTag";
import InfoIcon from "assets/icons/InfoIcon";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";


export default function GongForm({ integration, setConnectedIntegrationList }) {
    const [defaultQuery, setDefaultQuery] = useState(integration?.fields?.defaultQuery);
    const [startDate, setStartDate] = useState(integration?.fields?.fromDate);
    const [endDate, setEndDate] = useState(integration?.fields?.toDate);
    const [syncedOn, setSyncedOn] = useState(integration?.fields?.syncedOn);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    const currentDate = new Date();

    const [selectedStartYear, setStartSelectedYear] = useState(startDate ? startDate.split("-")[0] : (currentDate.getFullYear() - 1) + "");
    const [selectedStartMonth, setStartSelectedMonth] = useState(startDate ? parseInt(startDate.split("-")[1]) : (currentDate.getMonth() + 1) + "");
    const [selectedStartDay, setStartSelectedDay] = useState(startDate ? parseInt(startDate.split("-")[2]) : currentDate.getDate() + "");

    const [selectedEndYear, setEndSelectedYear] = useState(endDate ? endDate.split("-")[0] : currentDate.getFullYear() + "");
    const [selectedEndMonth, setEndSelectedMonth] = useState(endDate ? parseInt(endDate.split("-")[1]) : (currentDate.getMonth() + 1) + "");
    const [selectedEndDay, setEndSelectedDay] = useState(endDate ? parseInt(endDate.split("-")[2]) : currentDate.getDate() + "");


    const saveGongSettings = async () => {
        try {
            setIsSubmitted(true);
            const startDate = selectedStartYear + "-" + selectedStartMonth.toString().padStart(2, '0') + "-" + selectedStartDay.toString().padStart(2, '0');
            const endDate = selectedEndYear + "-" + selectedEndMonth.toString().padStart(2, '0') + "-" + selectedEndDay.toString().padStart(2, '0');
            setStartDate(startDate);
            setEndDate(endDate);

            if (!defaultQuery?.trim())
                return;

            if ((new Date(startDate)) > (new Date(endDate)))
                return;

            setLoading(true);
            const result = await axiosHelperCore({
                url: 'gong/saveGongSettings',
                method: 'POST',
                JSONData: { defaultQuery: defaultQuery, startDate: startDate, endDate: endDate }
            });

            setConnectedIntegrationList(result?.data?.account?.otherIntegrations?.connections);
            setSyncedOn(result?.data?.account?.otherIntegrations?.connections?.find(c => c.type === "gong")?.fields?.syncedOn);
            setSnackbarMessage('Gong sync up is in progress..');
            setSnackbarType('success');
            setShowSnackBar(true);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const getDateList = () => {
        const options = [];
        for (let i = 1; i <= 31; i++) {
            options.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }
        return options;
    };

    const generateMonthOptions = () => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        return months.map((month, index) => (
            <option key={index} value={index + 1}>
                {month}
            </option>
        ));
    };

    const generateYearOptions = () => {
        const options = [];
        for (let year = 2010; year <= 2030; year++) {
            options.push(
                <option key={year} value={year}>
                    {year}
                </option>
            );
        }
        return options;
    };

    return (
        <>
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}>
                    <MemoRevenoidLoader />
            </Backdrop>}
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            <div style={{height:"10px"}}></div>
            <hr></hr>
            <div style={{ marginTop: "20px" }}>
                <div style={{ display: "flex", marginBottom: "5px", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ color: "#57585C", fontSize: "14px", marginBottom: "2px" }}><b>Default Query</b></div>
                    <div style={{ color: "#57585C", fontSize: "14px", marginBottom: "5px" }}>
                        {syncedOn && <div style={{ border: "1px solid #3B71CA", color: "#fff", background: "#3B71CA", borderRadius: "8px", padding: "5px", fontSize: "12px" }}>{integration?.fields?.status}</div>}
                    </div>
                </div>
                <div style={{ color: "#3B71CA", fontSize: "13px", marginBottom:"5px" }}>*A preliminary set of insights will be generated with this query for the call transcripts available</div>
                <div style={{ marginTop: "0" }}>
                    <textarea
                        disabled={syncedOn}
                        style={{

                            borderRadius: "4px",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            width: "100%",
                            fontWeight: "400",
                            fontSize: "14px",
                            padding: "12px 8px",
                        }}
                        defaultValue={integration?.fields?.defaultQuery}
                        onChange={(evt) => setDefaultQuery(evt?.target?.value)}

                    />
                    {!defaultQuery?.trim() && isSubmitted && <div style={{ color: "red", fontSize: "12px" }}>Defaut query cannot be blank</div>}
                </div>
                <div style={{ color: "#3B71CA", fontSize: "13px" }}>
                    *Transcript of calls made within this date range is captured from Gong and used for generating insights
                </div>
                <div style={{ height: "10px" }}></div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ color: "#9F9F9F", fontSize: "12px" }}>From Date:</div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <select style={{fontSize:"13px"}}  defaultValue={selectedStartDay} disabled={syncedOn}
                                    onChange={(evt) => setStartSelectedDay(evt?.target?.value)}>
                                    {getDateList()}
                                </select>
                            </div>
                            <div>
                                <select style={{fontSize:"13px"}} defaultValue={selectedStartMonth} disabled={syncedOn}
                                    onChange={(evt) => setStartSelectedMonth(evt?.target?.value)}>
                                    {generateMonthOptions()}
                                </select>
                            </div>
                            <div>
                                <select style={{fontSize:"13px"}} defaultValue={selectedStartYear} disabled={syncedOn}
                                    onChange={(evt) => setStartSelectedYear(evt?.target?.value)}>
                                    {generateYearOptions()}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ color: "#9F9F9F", fontSize: "12px" }}>To Date:</div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <select style={{fontSize:"13px"}} defaultValue={selectedEndDay} disabled={syncedOn}
                                    onChange={(evt) => setEndSelectedDay(evt?.target?.value)}>
                                    {getDateList()}
                                </select>
                            </div>
                            <div>
                                <select style={{fontSize:"13px"}} defaultValue={selectedEndMonth} disabled={syncedOn}
                                    onChange={(evt) => setEndSelectedMonth(evt?.target?.value)}>
                                    {generateMonthOptions()}
                                </select>
                            </div>
                            <div>
                                <select style={{fontSize:"13px"}} defaultValue={selectedEndYear} disabled={syncedOn}
                                    onChange={(evt) => setEndSelectedYear(evt?.target?.value)}>
                                    {generateYearOptions()}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "right", width: "30%" }}>
                        {!syncedOn && <div>
                            <Button
                                size="xs"
                                padding="12px 32px"
                                borderRadius={"12px"}
                                onClick={saveGongSettings}
                            >
                                Sync
                            </Button>
                        </div>}
                        {syncedOn && <div>
                            <Button
                                size="xs"
                                disabled
                                padding="12px 32px"
                                borderRadius={"12px"}
                            >
                                Sync
                            </Button>
                        </div>}
                    </div>
                </div>
                {((new Date(startDate)) > (new Date(endDate))) && isSubmitted && <div style={{ color: "red", fontSize: "12px" }}>Start date cannot be greater than end date</div>}
                <div style={{ height: "15px" }}></div>


            </div>

            <div style={{ background: "#DD015B", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", padding: "10px", borderRadius: "8px", textAlign: "center", fontSize: "12px", marginTop: "5px" }}>
                <InfoIcon width="16px" height="16px"></InfoIcon>&nbsp;This is a one-time setup and cannot be updated after clicking the Sync button.
            </div>
        </>
    )

}