import { useEffect, useState } from "react";
import apiService from "services/apiService";
import { useAppSelector } from "hooks";

export const useSalesforceFields = (collectionType) => {
    const [salesforceFields, setSalesforceFields] = useState([]);
    const profileData = useAppSelector((state) => state.profileState.profileData);
    useEffect(() => {
        if (profileData?.userId) {
            const postData = { userId: profileData.userId, "collection": collectionType }
            apiService.post("salesforce/getSalesforceFieldsFromCollection", postData).then(
                (data) => {
                    setSalesforceFields(data?.fields || []);
                });
        }
    }, [profileData]);

    return salesforceFields;
}

export const useSaveSalesforceSettings = () => {
    const saveFormdata = async (formData) => {
        const organizationId = localStorage.getItem("organizationId");
        apiService.post("salesforce/saveSalesforceFields", { ...formData, organizationId }).then(
            (data) => {
                const fields = data?.result?.connector?.connections.find(c => c.type === "salesforce")?.fields;
            });
    }
    return { saveFormdata }
}

export const useSavedSalesforceFields = (intialSalesforceFields) => {
    const [salesforceFields, setSalesforceFields] = useState(intialSalesforceFields);
    useEffect(() => {
        const organizationId = localStorage.getItem("organizationId");
        apiService.post("salesforce/getSalesforceFields", { organizationId }).then(
            (fieldData) => {
                const { dataSource, tagName, tagField, callPrep, linkedinUrl, accountScore, accountSummary,prospectScore } = fieldData?.data?.salesForceField;
                setSalesforceFields({
                    dataSource: dataSource,
                    tagName: tagName || "evabot",
                    tagField: { label: tagField?.label, value: tagField?.name },
                    callPrep: { label: callPrep?.label, value: callPrep?.name },
                    linkedinUrl: { label: linkedinUrl?.label, value: linkedinUrl?.name },
                    accountScore: { label: accountScore, value: accountScore },
                    accountSummary: { label: accountSummary, value: accountSummary },
                    prospectScore: { label: prospectScore, value: prospectScore }
                })
            });
    }, []);

    const changeSalesforceFields = (newValue) => {
        setSalesforceFields(newValue);
    }

    return { salesforceFields, changeSalesforceFields };
}
