import * as React from "react";

function MemberListingIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.77734 12H23.1107" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.77734 19.7812H23.1107" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.77734 4.21875H23.1107" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.33312 17.5547H0.888672V21.9991H5.33312V17.5547Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
            <path d="M5.33312 9.78125H0.888672V14.2257H5.33312V9.78125Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
            <path d="M5.33312 2H0.888672V6.44444H5.33312V2Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
        </svg>

    );
}

export default MemberListingIcon;
