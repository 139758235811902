import * as React from "react";

  
  function AdminReportIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.46499 5.50731V12.5788C3.46499 17.8588 8.14216 20.3972 11.8989 22.4372L11.9991 22.4908L12.1007 22.4372C15.8575 20.3972 20.5346 17.8588 20.5346 12.5788V5.50731L11.9991 1.4866L3.46497 5.50731H3.46499ZM11.9991 24.0014C11.8833 24.0014 11.7676 23.9718 11.6617 23.9153L11.2254 23.6781C7.35441 21.576 2.05322 18.6988 2.05322 12.5788V5.05976C2.05322 4.78589 2.21135 4.53741 2.4584 4.42024L11.6998 0.0677625C11.889 -0.0225875 12.1106 -0.0225875 12.2998 0.0677625L21.5412 4.42026C21.7883 4.53744 21.9464 4.78591 21.9464 5.05979V12.5788C21.9464 18.6989 16.6438 21.576 12.7742 23.6781L12.3379 23.9153C12.232 23.9718 12.1163 24.0014 11.9991 24.0014ZM17.8386 17.1558H13.9209L12.776 16.0123V14.9647H11.3162V13.505H10.2701L9.53882 12.7737C9.51482 12.7751 9.49082 12.7751 9.46682 12.7751C7.26589 12.7751 5.47576 10.9849 5.47576 8.78401C5.47576 6.58307 7.26589 4.79154 9.46682 4.79154C11.6678 4.79154 13.4579 6.58307 13.4579 8.78401C13.4579 8.80801 13.4579 8.83201 13.4565 8.8546L17.8386 13.2381L17.8386 17.1558ZM14.5054 15.744H16.4268V13.8226L11.984 9.37695L12.0334 8.91107C12.0461 7.36236 10.8885 6.20331 9.46682 6.20331C8.04517 6.20331 6.88752 7.36236 6.88752 8.78401C6.88752 10.2071 8.04517 11.3633 9.46682 11.3633L10.0569 11.297L10.8546 12.0932H12.728V13.5529H14.1878V15.4278L14.5054 15.744ZM10.5879 8.82439C10.5879 9.40886 10.1135 9.88321 9.52908 9.88321C8.94461 9.88321 8.47026 9.40886 8.47026 8.82439C8.47026 8.23991 8.94461 7.76556 9.52908 7.76556C10.1135 7.76556 10.5879 8.23991 10.5879 8.82439Z" fill="white"/>
        </svg>
    );
  }

export default AdminReportIcon;
