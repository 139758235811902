import * as React from "react";

  
  function ManageUseCaseIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M20.5556 14.4375H3.44444C2.09441 14.4375 1 15.5319 1 16.8819V20.5486C1 21.8986 2.09441 22.9931 3.44444 22.9931H20.5556C21.9056 22.9931 23 21.8986 23 20.5486V16.8819C23 15.5319 21.9056 14.4375 20.5556 14.4375Z" stroke="white" stroke-width="1.5"/>
        <path d="M9.55556 1.60938H3.44444C2.09441 1.60938 1 2.70379 1 4.05382V7.72049C1 9.07049 2.09441 10.1649 3.44444 10.1649H9.55556" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M18.1115 10.7778C20.8116 10.7778 23.0004 8.58895 23.0004 5.88889C23.0004 3.18883 20.8116 1 18.1115 1C15.4115 1 13.2227 3.18883 13.2227 5.88889C13.2227 8.58895 15.4115 10.7778 18.1115 10.7778Z" stroke="white" stroke-width="1.5"/>
        <path d="M18.1107 7.72917C19.1232 7.72917 19.944 6.90836 19.944 5.89583C19.944 4.88331 19.1232 4.0625 18.1107 4.0625C17.0982 4.0625 16.2773 4.88331 16.2773 5.89583C16.2773 6.90836 17.0982 7.72917 18.1107 7.72917Z" fill="white"/>
        </svg>
    );
  }

export default ManageUseCaseIcon;
