import React from 'react';
import { Box, Typography } from '@mui/material';

const NoRecordFound: React.FC = () => {
  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgcolor="#FFFFFF"
        width="380px"
        height="270px"
        justifyContent="center"
        borderRadius="12px"
        gap="24px"
      >
        <Typography
          fontWeight={500}
          fontSize="20px"
          textAlign="center"
          color="#050505"
        >
          No record found
        </Typography>
      </Box>
    </Box>
  );
};

export default NoRecordFound;
