import { Box, CircularProgress, InputAdornment, TextField, styled } from '@mui/material'
import React, { useState } from 'react'

const KeywordTagManager = ({
    MAX_LENGTH = 3,
    name, loading, defaultValue, onSubmit, placeholder }) => {
    const [value, setValue] = useState('')
    const [errorText, setErrorText] = useState('')

    const handleSave = async () => {
        setErrorText('')
        const words = value
            .split(',')
            .map(x => x.trim())
            .reduce((prevValue, currentValue) => {
                var pattern = /^[A-Za-z0-9,\s]*$/;
                if (currentValue.trim()?.length) {
                    currentValue = currentValue.charAt(0).toUpperCase() + currentValue.slice(1);
                }

                if (currentValue?.replace(/\s/g, '').length > 50) {
                    return {
                        ...prevValue,
                        success: false,
                        message: `"${currentValue}" ${name} is too long. Up to 50 characters are allowed`
                    }
                }

                if (!isNaN(currentValue)) {
                    return {
                        ...prevValue,
                        success: false,
                        message: 'Numeric not allowed'
                    }
                }

                if (!pattern.test(currentValue)) {
                    return {
                        ...prevValue,
                        success: false,
                        message: 'Special characters not allowed'
                    }
                }
                if (prevValue.words.map(x => x?.toLowerCase()).includes(currentValue?.toLowerCase())) {
                    return {
                        ...prevValue,
                        success: false,
                        message: `"${currentValue}" already exists`
                    }
                }

                if (!currentValue.trim()) {
                    return prevValue
                }

                return { ...prevValue, words: [...prevValue.words, currentValue.trim()] }
            },
                {
                    success: true,
                    words: defaultValue || [],
                    message: ''
                })

        if (words?.words?.length > MAX_LENGTH) {
            setErrorText(`${name} should not be more than ${MAX_LENGTH}`)
            return;
        }
        // words.words

        if (words.success) {
            await onSubmit(words.words)
            setValue('')
        } else {
            setErrorText(words.message)
        }
    }



    return (
        <>
            <Container>
                <Box>
                    <TextField
                        value={value}
                        disabled={defaultValue?.length >= MAX_LENGTH}
                        placeholder={placeholder}
                        onChange={e => setValue(e.target.value)} size="small"
                        variant="outlined"
                        InputProps={{
                            endAdornment: loading ?
                                <InputAdornment position="end">
                                    <CircularProgress size={16} />
                                </InputAdornment> :
                                <></>
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (
                                    defaultValue?.length >= MAX_LENGTH || !value?.trim() || loading) {
                                    // handle disable case
                                } else {
                                    handleSave()
                                }
                            }
                        }}
                        style={{
                            backgroundColor:
                                (defaultValue?.length >= MAX_LENGTH) ? '#EEF1FB' : '#fff',
                            fontSize: '12px',
                            width : '100%'
                        }}
                        inputProps={{
                            style: {
                                fontSize: '12px'
                            }
                        }}
                    />
                    {errorText && <Box sx={{
                        paddingLeft: '5px',
                        color: '#DD015B',
                        backgroundColor: '#FFEEF5',
                        fontSize: '10px',
                        fontWeight: 300,
                        // height: '21px',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '3px'
                    }}>
                        {errorText}
                    </Box>}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        color: '#9F9F9F',
                        fontSize: '10px',
                        marginTop: '4px'
                    }}>
                        (Up to {MAX_LENGTH} {name} allowed. {defaultValue?.length || 0}/{MAX_LENGTH} saved)
                    </Box>
                </Box>

                {/* <SaveButton
                    disabled={defaultValue?.length >= MAX_LENGTH || !value?.trim() || loading}
                    onClick={handleSave}
                >
                    Save
                </SaveButton> */}

            </Container>
        </>
    )
}


const Container = styled(Box)({
    alignItems: 'center',
    marginTop: '10px'
})

// const SaveButton = styled(Button)({
//     textTransform: 'capitalize',
//     marginTop: '-18px'
// })

export default KeywordTagManager