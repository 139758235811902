import React, { FC, useCallback, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { useNavigate } from "react-router-dom";
import { aeForgotPasswordData } from "./store/AeFormReducer";
import { axiosHelper, axiosHelperCore } from "utils";
import { useAppDispatch } from "hooks";

const AeForgotPasswordRightSectionSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .email("aereturn.profile.email.invalid")
    .required("aereturn.profile.email.valid"),
});

const defaultValues = {
  emailAddress: "",
};

interface AeForgotPasswordRightSectionProps {
  type?: "reset" | "";
}

const AeForgotPasswordRightSection: FC<AeForgotPasswordRightSectionProps> = ({
  type,
}) => {
  const dispatch = useAppDispatch();

  const [isLoading, toggleIsLoading] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarError = (message) => {
    setShowSnackBar(true);
    setSnackbarMessage(message);
  };

  const onSubmitHandler = useCallback(
    async (values: any) => {
      dispatch(aeForgotPasswordData(values));
      console.log(values);
      toggleIsLoading(true);
      try {
        const result:any = await axiosHelperCore({
          url: "rapport/user/evaGenerateForgotPasswordLink",
          method: "POST",
          JSONData: {
            email: values.emailAddress,
          },
          urlOverride: false,
        });
        toggleIsLoading(false);
        console.log("resultresultresult",result);
        if (result?.data?.success) {
          navigate("/checkEmail", { state: type });
        }
        else
        {
          let result1:any = result;
          handleSnackbarError(result?.data?.error || result1?.response?.statusText || result1?.response?.data);
        }
      } catch (err) {
        console.log("errerrerrerrerrerrerr1",err)
        //toggleIsLoading(false);
        console.log(err.response?.data);
       // handleSnackbarError(err.response?.data);
      }
    },
    [dispatch]
  );

  const navigate = useNavigate();

  return (
    <>
      <SnackBar
        iconName={"info"}
        message={"Login Error"}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        subHeading={snackbarMessage}
        timeout={4000}
        anchor={"top-right"}
        type="error"
      />
      <Grid>
        <Text variant="pageHeadingRegular" color={"#050505"} fontWeight="500">
          Please enter your registered
        </Text>
        <Text variant="pageHeadingRegular" color={"#050505"} fontWeight="500">
          {" "}
          email address
        </Text>
        <Formik
          initialValues={defaultValues}
          onSubmit={onSubmitHandler}
          enableReinitialize
          validationSchema={AeForgotPasswordRightSectionSchema}
        >
          {({ dirty, isValid, submitForm }) => {
            return (
              <Form>
                <Grid width={"300px"} gridRowGap="5px">
                  <Text variant="bodyRegular" mt={"24px"} color="#050505">
                    Email address
                  </Text>
                  <FormikInput
                    width={"100%"}
                    name="emailAddress"
                    placeholder="example@revenoid.com"
                  />

                  <Flex justifyContent={"space-between"} alignItems="center">
                    {type === "reset" ? (
                      <div />
                    ) : (
                      <Flex
                        cursor="pointer"
                        onClick={() => navigate("/aeLogin1")}
                      >
                        <Icon name="arrowLeft" />{" "}
                        <Text ml="10px" color={"#0E0B30"} variant="subTitle">
                          Back to login
                        </Text>
                      </Flex>
                    )}
                    <Box mr="-30px">
                      <Button
                        loading={isLoading}
                        padding="13px 31px"
                        iconName="arrowRight"
                        iconAlign="right"
                        borderRadius={"118px"}
                        type="submit"
                        disabled={!dirty || !isValid}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Flex>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </>
  );
};

export default AeForgotPasswordRightSection;
