import axios from "axios";
import { usersToEnableActivityHistory } from "../constants";
import { HmacSHA512 } from "crypto-js";
import { getCookie } from "./cookie";

const getBaseURL = (): string => {
  const env = process.env.REACT_APP_ENV;
  if (env) {
    switch (env.trim()) {
      case "dev":
        return "https://rapport_backend.stage.evabot.com/";
      case "stage":
        return "https://rapport_backend.stage.evabot.com/";
      case "pre-prod":
        return "https://rapport_backend.preprod.evabot.com/";
      case "prod":
        return "https://api.rapport.evabot.com/";
      default:
        return "https://rapport_backend.stage.evabot.com/";
    }
  } else return "https://rapport_backend.stage.evabot.com/";
};



const getCoreURL = (): string => {
  const env = process.env.REACT_APP_ENV;
  if (env) {
    switch (env.trim()) {
      case "dev":
        return "https://core.evabot.com/";
      case "stage":
        return "https://core.stage.evabot.com/";
      case "pre-prod":
        return "https://core.preprod.evabot.com/";
      case "prod":
        return "https://core.evabot.com/";
      default:
        return "https://core.evabot.com/";
    }
  } else return "https://core.evabot.com/";
};


export const REQUEST_URL = getBaseURL();
export const CORE_API_URL = getCoreURL();

interface axiosWrapperParams {
  url?: string;
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  JSONData?: object;
  urlOverride?: boolean;
  headerProps?: any;
}

export const axiosHelper = async (props: axiosWrapperParams) =>
  axios({
    url: props.urlOverride ? props.url : `${REQUEST_URL}${props.url}`,
    method: props.method,
    data: props.JSONData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      ...props.headerProps,
    },
  });

export const axiosHelperCore = async (props: axiosWrapperParams) => {
  try {
    const result = await axios({
      url: props.urlOverride ? props.url : `${CORE_API_URL}${props.url}`,
      method: props.method,
      data: props.JSONData,
      headers: {
        Authorization: `Bearer ${getCookie("token")}`,
        ...props.headerProps,
      },
    });
    return result;
  }
  catch (err) {
    if (err?.response?.status == 429) {
      return err;
    }
    localStorage.removeItem("Authorization");
    localStorage.removeItem("organizationId");
    //clearAuthenticationCookies();
    window.location.href = "/aeLogin1";
    const channel = new BroadcastChannel('user-logout');
    channel.postMessage('logout');
  }
}

export const axiosHelperHmac = async (url, method = "GET", JSONData = {}) => {
  let payload = "";
  let hMac = "";
  let tnounce;
  if (method === "POST") {
    tnounce = new Date().getTime();
    payload = `${tnounce}.${JSON.stringify(JSONData)}`;
    hMac = HmacSHA512(payload, process.env.REACT_APP_HMAC_SECRET_KEY);
  }
  const loginData = JSON.parse(localStorage.getItem("userData"));
  return await axios({
    url,
    method,
    data: JSONData,
    headers:
      method === "POST"
        ? {
          "X-Authorization": loginData?.jwtToken,
          "Content-Type": "application/json",
          "X-Signature": hMac,
          "X-AccessKey": process.env.REACT_APP_HMAC_ACCESS_KEY,
          "X-AccessToken": localStorage.getItem("Authorization"),
          "X-Timestamp": tnounce,
          "X-MemberId": loginData?.userId,
        }
        : {},
  });
};

export const capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const fieldMappingSalesforceNameMapper = (
  values,
  allSalesforceFields
) => {
  let finalObj = {};
  for (const [key, value] of Object.entries(values)) {
    let realValue = allSalesforceFields.find((x) => x.label === value)?.name;
    finalObj[key] = realValue;
  }
  return finalObj;
};

export const checkActivityHistoryEnabled = (loggedInMember) => {
  let nameToCheck = `${loggedInMember.firstName} ${loggedInMember.lastName}`
    .toLowerCase()
    .trim();
  if (usersToEnableActivityHistory.includes(nameToCheck)) {
    return true;
  }
  return false;
};
