import React from 'react';

interface PreviewButtonProps {
    onClick?: (e?: any) => any | void;
}

const PreviewButton: React.FC<PreviewButtonProps> = ({ onClick }) => {
    return (
        <button
            style={{
                color: '#0094E3',
                display: 'flex',
                padding: '2px 4px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                borderRadius: '4px',
                border: '0.6px solid var(--Colours-Eva-EvaBlue, #0094E3)',
                background: '#FFF',
                cursor: 'pointer' 
            }}
            onClick={onClick}
        >
            Preview
        </button>
    );
}

export default PreviewButton;
