import React, { FC, useState, useEffect , useRef } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { useAppSelector, useAppDispatch } from "hooks";
import { setSelectedNudge } from "store/globalStateReducer";
import { useNavigate } from "react-router-dom";
import { axiosHelper } from "utils";
import { Backdrop } from "@mui/material";
import { format, differenceInDays , differenceInMinutes } from "date-fns";
import { useParams } from "react-router-dom";
import {  setSelectedNudge as sn  } from "store/globalStateReducer";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { setSelectedNudgeForUnlike } from "../AEReturnDashboard/store/aeDashboardReducer";
import { AeReturnUnlikeModal } from "./../../components/AeReturnUnlikeModal/AeReturnUnlikeModal";
import BirthdayCard from "containers/AEReturnDashboard/StakeholderProfileComps/BirthdayCard";
import { getCoffeeString, getAlcoholString, getOtherC1String, getCuisineString, capitalizeString, getC4MappedString } from "utils/preferenceMapper";
import SendTreatModal from "../AEReturnDashboard/SendTreatModal";
import SendSportsModal from "../AEReturnDashboard/SendSportsModal";
import parse from 'html-react-parser';
import TimeOutline from "../AEReturnDashboard/TimeOutline";
import { getDateString } from "utils/constants";
import CompanyDomainIcon from "containers/AeReturnStakeholderDashboard/CompanyDomainIcon";
import TimerIcon from "../AEReturnDashboard/BirthdayNudge/TimerIcon";
import { Timer } from "components/Timer/Timer";
import Carousel from "./Carousel/Carousel";
import AccessDeniedModal from "components/AccessDeniedModal/AccessDeniedModal";
import { insertActivityIntoActivityHistory } from "utils/activityHistoryHelper";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

interface StakeholderProfileMobileViewProps {
    searchQuery: any;
  }

  const StakeholderProfileMobileView: FC<StakeholderProfileMobileViewProps> = ({ searchQuery }) => {
    const loggedInMember = useAppSelector(
      (state) => state.profileState.profileData
    );
    const [hideAccessDeniedModal, setHideAccessDeniedModal] = useState(true);
    const [hover, setHover] = useState(false);
    const [ showMobileSnackBar , setShowMobileSnackbar ] = useState(false);
    const [ nudgeFeedBack , setNudgeFeedBack ] = useState(null);
    const feedRef = useRef();
    const [ showSportsModal , setShowSportsModal ] = useState(false);
    const [copiedIds , setCopiedIds ] = useState([]);
    const [showTreatModal, setShowTreatModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { stakeholderId } = useParams();
  // const selectedNudge = useAppSelector((state) => state.globalState.selectedNudge);
  const [weather, setWeather] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarState, setSnackbarState] = useState('success');
  const [userInfo, setUserInfo] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [ nudges , setNudges ] = useState([]);
  const [thumsUpIds, setThumsUpIds] = useState([]);
  const [thumsDownIds, setThumsDownIds] = useState([]);
  const [hide, setHide] = useState(true);
  const [ personName , setPersonName  ] = useState("");
  const [sendTreatOrder, setSendTreatOrder] = useState(null);
  const [fangearOrder, setFangearOrder] = useState(null);
  const [ openhowwork , setOpenhowwork ] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const getDomainName = (url) => {
    try {
      return new URL(url).hostname.replace("www.", "");
    }
    catch(err) {
      console.log(url, err);
      return '';
    }
  }

  const getHeadlineStr = (nudge) => {
    let text = nudge?.nudgeHeadline;
    
    try {
      let substrings = nudge?.recHighlight;
      substrings = substrings.filter((sub) => sub.trim() != "");
      substrings.forEach((substr, i) => {        
        let pattern = new RegExp(substr, "gi");

        text = text.replace(
          pattern,
          `<span style="font-family:'Inter-Bold'" >${capitalizeString(
            substr
          )}</span>`
        );
        console.log("text", text);
      });

      return (
        <Text
          color={"#050505"}
          fontWeight={"300"}
          fontSize={"20px"}
          lineHeight={"140%"}
        >
          {parse(text)}
        </Text>
      );
    } catch (err) {
      return (
        <Text
          color={"#050505"}
          fontWeight={"300"}
          fontSize={"20px"}
          lineHeight={"140%"}
        >
          {text}
        </Text>
      );
    }
    }
  const fetchWeatherAPI = async () => {
    try {
      const result = await axiosHelper({
        url: `salesforce/getWeather/${stakeholderId}`,
      });

      if (result.data == 0) {
        // displayError('Unable to fetch Weather!')
      } else {
       
        setCurrentSlide( result.data.length - 8 );
        setWeather(result.data);
      }
    } catch (err) {
      // displayError('Error while fetching weather details.')
    }
  };
  const fetchCurrentOrderAPI = async () => {
    try {
      const promises = [
        axiosHelper({
          url: `orders/getActiveOrder/treat/${stakeholderId}`,
        }),
        axiosHelper({
          url: `orders/getActiveOrder/fanGear/${stakeholderId}`,
        }),
      ];
      const result = await Promise.all(promises);
      let treat = result[0];
      let fanGear = result[1];

      if (treat.data.success) {
        console.log("setSendTreatOrder is set");
        setSendTreatOrder(treat.data.order);
      } else if (
        !treat.data.success &&
        treat.data.error === "ORDER_NOT_FOUND"
      ) {
        setSendTreatOrder(null);
      }
      if (fanGear.data.success) {
        setFangearOrder(fanGear.data.order);
      } else if (
        !fanGear.data.success &&
        fanGear.data.error === "ORDER_NOT_FOUND"
      ) {
        setFangearOrder(null);
      }
    } catch (err) {
      console.log(err);
      // displayError('Error while fetching weather details.')
    }
  };
  const fetchstakeholderInfoAPI = async () => {
    try {
      const result = await axiosHelper({
        url: `salesforce/getStakeholderInfoForStakeholderProfilePage/${stakeholderId}`,
      });

      setUserInfo(result?.data);
   
    } catch (err) {
      // displayError('Error while fetching weather details.')
    }
  };
  const fetchNudgesAPI = async () => {
    try {
        const result = await axiosHelper({
            url: `salesforce/getFilteredNudgeData/${stakeholderId}`,
          });
          let data = result.data.data;
        // console.log("nudgesss",result?.data?.data);
        setNudges(data);  
        setPersonName(result.data.name);
    } catch(err) {

    }
  }

  const cancelOrder = async (orderType) => {
    try {
      const result = await axiosHelper({
        url: `orders/cancelOrder?orderType=${orderType}&stakeholderId=${stakeholderId}`,
        method: "DELETE",
      });
      if (result.data.success) {
        setSnackbarState("success");
        setSnackbarMessage("Order is successfully cancelled.");
        setShowSnackBar(true);
        fetchCurrentOrderAPI();
      } else if (result.data.error === "CANCEL_TIME_LIMIT_EXCEEDED") {
        setSnackbarState("error");
        setSnackbarMessage(
          "This order cannot be cancelled after 30 minutes from ordering."
        );
        setShowSnackBar(true);
      } else if (result.data.error === "ORDER_NOT_FOUND") {
        setSnackbarState("error");
        setSnackbarMessage("Order not found.");
        setShowSnackBar(true);
      }
    } catch (err) {
      setSnackbarState("error");
      setSnackbarMessage("There was a problem cancelling this order.");
      setShowSnackBar(true);
    }
  };
  const verifyAccessForUser = async () => {
    axiosHelper({
      url: `salesforce/verifyStakeholderPageUser/${stakeholderId}`,
    })
      .then((result) => {
        if (result.data.success) {
          Promise.all([
            fetchWeatherAPI(),
            fetchstakeholderInfoAPI(),
            fetchNudgesAPI(),
            fetchCurrentOrderAPI(),
          ]).then((result) => {
            setShowBackDrop(false);
          });
        } else {
          setShowBackDrop(false);
          setHideAccessDeniedModal(!hideAccessDeniedModal);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowBackDrop(false);
        setHideAccessDeniedModal(!hideAccessDeniedModal);
      });
  };

  useEffect(() => {
    console.log("selectedNudge.stakeholderId", stakeholderId);
    if (stakeholderId) {
      setShowBackDrop(true);
      verifyAccessForUser();
    }
  }, [stakeholderId]);
  // useEffect(() => {
  //   console.log("selectedNudge.stakeholderId", stakeholderId);
  //   if (stakeholderId) {
  //     setShowBackDrop(true);
  //     Promise.all([fetchWeatherAPI(), fetchstakeholderInfoAPI(), fetchNudgesAPI(), fetchCurrentOrderAPI()]).then(
  //       (result) => {
  //         setShowBackDrop(false);
  //       }
  //     );
  //   }
  // }, [stakeholderId]);
  const getNudgeIcon = (nudge, isHover?: boolean) => {
    if ( nudge?.nudgeHeadlinePayload?.type === "podcastEpisode" ) {
      return <Icon  size="32px" name={  isHover ? "nudgePodcastHover" : "nudgePodcast"} />
    } else if ( nudge?.nudgeHeadlinePayload?.type === "books" )  {
     return <Icon  size="32px" name={ isHover ? "nudgeBookHover"  : "nudgeBook" } />
    }  else if ( nudge?.nudgeHeadlinePayload?.type === "movies" ) {
    return <Icon  size="32px" name={ isHover ? "nudgeMovieHover"  : "nudgeMovie" } />
    } else if (nudge?.nudgeHeadlinePayload?.type === "birthdayWish") {
      return <Icon name={isHover ? "nudgeBirthdayHover" : "nudgeBirthday"} size="32px" />;
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesKids") {
      return (
        <Icon
        size="32px"
          name={
            isHover ? "nudgeGooglePlacesKidsHover" : "nudgeGooglePlacesKids"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesPets") {
      return (
        <Icon
        size="32px"
          name={
            isHover ? "nudgeGooglePlacesPetsHover" : "nudgeGooglePlacesPets"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesCafe") {
      return (
        <Icon
        size="32px"
          name={
            isHover ? "nudgeGooglePlacesCafeHover" : "nudgeGooglePlacesCafe"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesRestaurant") {
      return (
        <Icon
        size="32px"
          name={
            isHover
              ? "nudgeGooglePlacesRestaurantHover"
              : "nudgeGooglePlacesRestaurant"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesOutdoor") {
      return (
        <Icon
        size="32px"
          name={
            isHover
              ? "nudgeGooglePlacesOutdoorHover"
              : "nudgeGooglePlacesOutdoor"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "weatherAlert") {
      return (
        <Icon size="32px" name={isHover ? "nudgeWeatherAlertHover" : "nudgeWeatherAlert"} />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "weatherPatternChange") {
      return (
        <Icon
        size="32px"
          name={
            isHover
              ? "nudgeWeatherPatternChangeHover"
              : "nudgeWeatherPatternChange"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "espnNews") {
      return <Icon size="32px" name={isHover ? "nudgeEspnNewsHover" : "nudgeEspnNews"} />;
    } else if (nudge?.nudgeHeadlinePayload?.type === "espnScore") {
      return <Icon size="32px" name={isHover ? "nudgeEspnScoreHover" : "nudgeEspnScore"} />;
    } else if (nudge?.nudgeHeadlinePayload?.type === "espnSchedule") {
      return (
        <Icon size="32px" name={isHover ? "nudgeEspnScheduleHover" : "nudgeEspnSchedule"} />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "spotifyMusic") {
      return (
        <Icon size="32px" name={isHover ? "nudgeSpotifyMusicHover" : "nudgeSpotifyMusic"} />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "ticketMasterConcert") {
      return (
        <Icon
        size="32px"
          name={isHover ? "nudgeConcertTicketsHover" : "nudgeConcertTickets"}
        />
      );
    }
  };
   const undoNudgeAction = async (nudgeId, type) => {
    axiosHelper({
      url: `salesforce/updateNudgeVisibility/${nudgeId}/${false}`,
      method: 'PATCH'
    });
    if (type === "up") {
      let updatedThumsUpIds = thumsUpIds.filter((id) => id !== nudgeId);
      setThumsUpIds(updatedThumsUpIds);
    } else if (type === "down") {
      let updatedThumsDownIds = thumsDownIds.filter((id) => id !== nudgeId);
      setThumsDownIds(updatedThumsDownIds);
    }
  };
  const getWeatherIcon = (main) => {
    if ( main == "Thunderstorm" ) {
      return <Icon name="stakeholderThunderStorm" />
    } else if (main == "Drizzle") {
     return <Icon name="stakeholderDrizzle" />
    } else if ( main == "Rain" ) {
        return <Icon name="stakeholderRain" />
    } else if ( main == "snow" ) {
        return <Icon name="stakeholderSnow" />
    } else if ( main == "Clear" ) {
      return <Icon name="stakeholderClear" />
    } else if ( main == "Clouds" ) {
        return <Icon name="stakeholderCloudy" />
    } else {
        return <Icon name="stakeholderAtmosphere" />
    }
  }
   const updateNudgeFeedback = async (nudge, feedback, comment) => {
    console.log(nudge);
    const result = await axiosHelper({
      url: "salesforce/updateNudgeFeedback",
      method: "POST",
      JSONData: {
        stakeholderName: nudge.stakeholderName,
        stakeholderEmail: nudge.email,
        nudgeContext: nudge.context,
        nudgeHeadline: nudge.nudgeHeadline,
        nudgeTypeUniqueId: nudge.uniqueId,
        nudgeId: nudge.nudgeId,
        feedback: feedback,
        comment: comment,
      },
      urlOverride: false,
    });
    if (result.data.success) {
      if (feedback === "up") {
        if (
          !thumsUpIds.includes(nudge?.nudgeId) &&
          !thumsDownIds.includes(nudge?.nudgeId)
        ) {
          setThumsUpIds([nudge?.nudgeId, ...thumsUpIds]);
        } else if (
          !thumsUpIds.includes(nudge?.nudgeId) &&
          thumsDownIds.includes(nudge?.nudgeId)
        ) {
          let updatedthumsDownIds = thumsDownIds.filter(
            (id) => id !== nudge?.nudgeId
          );
          setThumsUpIds([nudge?.nudgeId, ...thumsUpIds]);
          setThumsDownIds(updatedthumsDownIds);
        }
      } else if (feedback === "down") {
        if (
          !thumsDownIds.includes(nudge?.nudgeId) &&
          !thumsUpIds.includes(nudge?.nudgeId)
        ) {
          setThumsDownIds([nudge?.nudgeId, ...thumsDownIds]);
        } else if (
          !thumsDownIds.includes(nudge?.nudgeId) &&
          thumsUpIds.includes(nudge?.nudgeId)
        ) {
          let updatedthumsUpIds = thumsUpIds.filter(
            (id) => id !== nudge?.nudgeId
          );
          setThumsDownIds([nudge?.nudgeId, ...thumsDownIds]);
          setThumsUpIds(updatedthumsUpIds);
        }
      }

      // setShowSnackBar(true);
    }
    console.log(result.data);
  };
  const modalHandler = () => {
    setHide(true);
  };
  const getScheduledTypeText = (type) => {
    if (type == "treatTea") {
      return "Treat is scheduled";
    } else if (type == "treatLiquor") {
      return "Alcohol is scheduled";
    } else if (type == "treatPizza") {
      return "Meal is scheduled";
    } else if (type == "treatGift") {
      return "Gift is scheduled";
    } else if (type == "treatRose") {
      return "Flower is scheduled";
    } else if (type == "treatCake") {
      return "Cake is scheduled";
    }
  };
  return (
    <>
    <div style={{ position:  "fixed" , display: openhowwork ? "block" : "none", top:  0 , left:  0 , backgroundColor:  "rgba(0,0,0,0.8)" , zIndex:  1 , height : "100vh" , width : "100vw" }} >
    
    </div>
    <div style={{ position : "fixed" , display: openhowwork ? "block" : "none", bottom : 0 , right : 0 , height: "40%", width:"100%", zIndex:2 , background :"#fff" , padding : "15px" , boxSizing:  "border-box" , borderRadius : "12px 12px 0px 0px" }} >
    <Flex
                flexDirection={"column"}
                gridRowGap={"12px"}
              >
               <Flex flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} >
               <Flex flexDirection={"row"} gridColumnGap={"8px"} alignItems={"center"} >
               <Icon name="birthdayOrderBulb" fill="white" />
               <Text color={"#050505"} fontSize={"16px"} fontWeight={"500"} lineHeight={"28px"} > How it works? </Text>
               </Flex>
               <Icon name="closeIcon" fill="black" cursor="pointer" onClick={ () => setOpenhowwork(false) } />
                </Flex> 
               

                <Flex
                  flexDirection={"column"}
                  gridRowGap={"30px"}
                >
                  <Flex
                    flexDirection={"row"}
                    gridColumnGap={"10px"}
                    alignItems={"center"}
                  >
                    <Icon name="stepOne" />
                    <Flex
                      flexDirection={"column"}
                      gridRowGap={"10px"}
                    >
                      <Text
                        color={"#000000"}
                        fontWeight={"500"}
                        fontSize={"12px"}
                      >
                        {" "}
                        Immediately places a cake order
                      </Text>
                     
                    </Flex>
                  </Flex>
                  <Flex
                    flexDirection={"row"}
                    gridColumnGap={"10px"}
                    alignItems={"center"}
                  >
                    <Icon name="stepTwo" />
                    <Flex
                      flexDirection={"column"}
                      gridRowGap={"10px"}
                    >
                      <Text
                        color={"#000000"}
                        fontWeight={"500"}
                        fontSize={"12px"}
                      >
                        {" "}
                        Our concierge team will process the order{" "}
                      </Text>
                      
                    </Flex>
                  </Flex>
                  <Flex
                    flexDirection={"row"}
                    gridColumnGap={"10px"}
                    alignItems={"center"}
                  >
                    <Icon name="stepThree" />
                    <Flex
                      flexDirection={"column"}
                      gridRowGap={"10px"}
                    >
                      <Text
                        color={"#000000"}
                        fontWeight={"500"}
                        fontSize={"12px"}
                      >
                        {" "}
                        The cake is delivered on their birthday{" "}
                      </Text>
                      
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
      </div>
    <div style={{ overflowY : "scroll" , height:  "100%", position : "relative" }} ref={feedRef}  >
    <AccessDeniedModal
          setHideModal={() => {
            setHideAccessDeniedModal(!hideAccessDeniedModal);
          }}
          hideModal={hideAccessDeniedModal}
        />
      {
      userInfo && userInfo.preference && 
      userInfo.preference.sportsTeam && 
      userInfo.preference.sportsTeam.length>0 &&
        <SendSportsModal
          name={personName}
          stakeholderId={stakeholderId}
          stakeholderName={userInfo.name}
          showSportsModal={showSportsModal}
          setShowSportsModal={setShowSportsModal}
          cancelOrder={cancelOrder}
          fetchCurrentOrderAPI={fetchCurrentOrderAPI}
        />
      }
     <SendTreatModal
        name={personName}
        stakeholderName={userInfo?.name}
        sendTreatOrder={sendTreatOrder}
        setShowTreatModal={setShowTreatModal}
        showTreatModal={showTreatModal}
        stakeholderId={stakeholderId}
        cancelOrder={cancelOrder}
        fetchCurrentOrderAPI={fetchCurrentOrderAPI}
      />
     {
        //@ts-ignore
        <AeReturnUnlikeModal
          
          screenSize={screenSize}
          closeModal={() => setHide(true)}
          width={screenSize.dynamicWidth > 576 ? "450px" : "100vw"}
            height={screenSize.dynamicWidth > 576 ? "max-content" : "100vh"}
          hideModal={hide}
          setHideModal={modalHandler}
          action={true}
          updateNudgeFeedback={updateNudgeFeedback}
        ></AeReturnUnlikeModal>
      }
       <SnackBar
        iconName={"info"}
        message={snackbarState === 'success' ? "Great!" : "Oops"}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        subHeading={snackbarMessage}
        timeout={4000}
        anchor={"top-right"}
        type={snackbarState}
        // bgColorr="#F44545"
      />
      <SnackBar
        iconName={"stakeholderDone"}
        bgColorr={"#24B300"}
        closeText="Undo"
        closeFunc={ () => {
          console.log("undo clicked");
          // undoNudgeAction(  );
          // undoNudgeAction(nudge?.nudgeId,"up");
          undoNudgeAction(nudgeFeedBack?.nudge?.nudgeId, nudgeFeedBack?.action );
          setShowMobileSnackbar(false);
        } }
        // subHeading={"Email sent successfully to Kianna."}
        setShowSnackBar={setShowMobileSnackbar}
        show={showMobileSnackBar}
        message="Thank you for your feedback"
        timeout={5000}
        anchor={"bottom-left"}
        type={"success"}
        // bgColorr="red"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
        open={showBackDrop}
      >
        <MemoRevenoidLoader />
      </Backdrop>
     
      <Box
        // overflow="auto"
        // height="100vh"
        height={"100%"}
        marginBottom="80px"
        // paddingBottom={"140px"}
      >
        <Flex
          flexDirection={"column"}
          paddingLeft={"16px"}
          paddingRight={"16px"}
          gridRowGap={"16px"}
          alignItems={"flex-start"}
          height={"100%"}
        >
<Flex
            flexDirection={"column"}
            // alignItems={"center"}
            gridRowGap={"16px"}
            width={"100%"}
            marginTop={"18px"}
          >
            <Flex
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              gridColumnGap={"12px"}
            >
              <Box
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(setSelectedNudge(null));
                  navigate("/aeReturnDashboard");
                }}
              >
                <Icon name="arrowLeft" fill="#050505" />
              </Box>
              {userInfo && userInfo.name && <Text
                color={"#050505"}
                fontWeight={"400"}
                fontSize={"14px"}
                lineHeight={"19.6px"}
              >
                {/* {userInfo.name.split(" ")[0]}'s profile */}
                Go back 
              </Text>}
            </Flex>
          </Flex>
          {userInfo &&
              userInfo.birthday &&
              userInfo.name &&
              differenceInDays(
                new Date(`${userInfo.birthday}/2023`),
                new Date()
              ) <= 15 &&
              differenceInDays(
                new Date(`${userInfo.birthday}/2023`),
                new Date()
              ) >= 0 && (
                <Box width={"100%"}>
                  <BirthdayCard
                    stakeholderId={stakeholderId}
                    stakeholderName={userInfo.name}
                    setOpenhowwork={setOpenhowwork}
                    mobile={true}
                    birthday={userInfo.birthday}
                    name={userInfo.name.split(" ").length>1 ? userInfo.name.split(" ")[0] : userInfo.name}
                    loadEmail={() => {}}
                    days={differenceInDays(
                      new Date(`${userInfo.birthday}/2023`),
                      new Date()
                    )+1}
                    setShowTreatModal={setShowTreatModal}
                  />
                </Box>
              )}
          <Box width={"100%"}>
          <Box
              style={{ boxSizing: "border-box" }}
              background="#FFFFFF"
              borderRadius="8px"
              padding={ (!sendTreatOrder && !fangearOrder) ? "16px 16px" : ""}
              width="100%"
            >
                 <Flex
                flexDirection={"column"}
                alignItems={"flex-start"}
                gridRowGap={"16px"}
                padding={ ( sendTreatOrder || fangearOrder ) ? "18px":  ""}
                  borderBottom={ ( sendTreatOrder || fangearOrder ) ? "1px solid #E4E4E4":  ""}
              >
                <Flex
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"flex-start"}
                >
                    <Box
                      borderRadius="36px"
                      background="#E6F6FF"
                      height="32px"
                      width="32px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {userInfo && userInfo.name && <Text fontSize="12px" fontWeight="400">
                        {userInfo.name.trim()
                      .split(" ")
                      .map((t) => t[0].toUpperCase())
                      .join("")}
                      </Text>
                      }
                    </Box>
                    {userInfo && userInfo.name && <Text
                      alignSelf={"center"}
                      color={"#050505"}
                      fontSize={"14px"}
                      fontWeight={"400"}
                    >
                      {" "}
                      {userInfo.name}{" "}
                    </Text>}

                  </Flex>
                  <Flex flexDirection={"column"} gridRowGap={"4px"} alignItems={"flex-start"}  >
                    <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"8px"} >
                    <Icon name="stakeholderMail" fill={"black"} size="8" />
                      <Text
                        color={"#57585C"}
                        fontSize={"12px"}
                        fontWeight={"400"}
                      >
                        {" "}
                        Email{" "}
                      </Text>
                    </Flex>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"14px"}
                    >
                      {" "}
                      {userInfo && userInfo?.email}{" "}
                    </Text>
                  </Flex>
                  <Flex flexDirection={"column"} gridRowGap={"4px"} alignItems={"flex-start"}  >
                    <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"8px"} >
                    <Icon name="stakeholderLocation" fill={"black"} size="8" />
                      <Text
                        color={"#57585C"}
                        fontSize={"12px"}
                        fontWeight={"400"}
                      >
                        {" "}
                        Location{" "}
                      </Text>
                    </Flex>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"14px"}
                    >
                      {" "}
                      {userInfo &&
                        userInfo?.address &&
                        `${userInfo?.address?.city}, ${userInfo?.address?.state}, ${userInfo?.address?.country}`}
                    </Text>
                  </Flex>
              </Flex>
              { ( sendTreatOrder || fangearOrder ) && (
                <Flex borderBottom={"1px solid #E4E4E4"} padding={"18px"}  alignItems={"flex-start"} gridRowGap={"12px"} flexDirection={"column"}>
                 { sendTreatOrder && (
                  <Flex flexDirection={'column'} gap={'8px'}>
                  <Flex
                    flexDirection={"row"}
                    alignItems={"center"}
                    gridColumnGap={"4px"}
                  >
                    <Icon name="doubleTick" stroke={"#24B300"} />
                    <Text
                      color={"#24B300"}
                      fontWeight={"400"}
                      fontSize={"14px"}
                    >
                      {" "}
                      {getScheduledTypeText(sendTreatOrder.orderDetails?.orderContent)}
                    </Text>
                  </Flex>
                  <Flex gap="8px">
                    <Text
                      color={"#DD015B"}
                      cursor="pointer"
                      fontSize={'14px'}
                      onClick={() => {
                        cancelOrder('treat');
                      }}
                    >
                      Cancel
                    </Text>
                    <Flex gridGap={"4px"}>
                      <TimerIcon />
                      <Timer timerValue={(30 - differenceInMinutes(new Date(), new Date(sendTreatOrder?.orderCreatedDate)))*60}/>
                    </Flex>
                  </Flex>
                </Flex>
                 ) }
                 { fangearOrder && (
                  <Flex flexDirection={'column'} gap={'8px'}>
                  <Flex
                    flexDirection={"row"}
                    alignItems={"center"}
                    gridColumnGap={"4px"}
                  >
                    <Icon name="doubleTick" stroke={"#24B300"} />
                    <Text
                      color={"#24B300"}
                      fontWeight={"400"}
                      fontSize={"14px"}
                    >
                      {" "}
                      Fan gear scheduled!{" "}
                    </Text>
                  </Flex>
                  <Flex gap="8px">
                    <Text
                      color={"#DD015B"}
                      cursor="pointer"
                      fontSize={'14px'}
                      onClick={() => {
                        cancelOrder('fanGear');
                      }}
                    >
                      Cancel
                    </Text>
                    <Flex gridGap={"4px"}>
                      <TimerIcon />
                      <Timer timerValue={(30 - differenceInMinutes(new Date(), new Date(fangearOrder?.orderCreatedDate)))*60}/>
                    </Flex>
                  </Flex>
                </Flex>
                 ) }
                </Flex>
              ) }
                </Box>
            </Box>
            <Box width={"100%"}>
            <Box
                style={{ boxSizing: "border-box" }}
                background="#FFFFFF"
                borderRadius="8px"
                width="100%"
              >
                 <Flex
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                  gridColumnGap={"8px"}
                  alignItems={"center"}
                  padding={"18px"}
                  borderBottom={"1px solid #E4E4E4"}
                >
<Icon name="stakeholderThermometer" size={"8"} fill={"#000000"} />
                    <Text
                      color={"#111827"}
                      fontWeight={"400"}
                      fontSize={"14px"}
                    >
                      {" "}
                      Local weather{" "}
                    </Text>
                </Flex>
                <div style={{ padding : "18px" , width : "100%" , boxSizing : "border-box" , borderBottom : "1px solid #E4E4E4" }}  >
                       { weather.length > 0 && <Carousel getWeatherIcon={getWeatherIcon} 
                      
                      items={weather}
                      perPage={2}
                      startIndex={currentSlide} />}
                    
                    </div>
                {/* <Flex borderBottom={"1px solid #E4E4E4"} padding={"18px"} overflowX={"scroll"} alignItems={"center"} gridColumnGap={"35px"} flexDirection={"row"}>
                { weather && weather.length > 0 && weather.map( ( entry , id ) => {
                     return (
                      <Flex flexDirection={"column"} gridRowGap={"6px"} alignItems={"center"}  minWidth={"95px"}  >
                        { getWeatherIcon(entry.weather[0].main ) } 
                        <Flex flexDirection={"row"} alignItems={"center"} >
                        <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            display={"inline-block"}
                            fontSize={"14px"}
                            lineHeight={"17px"}
                          >
                            {" "}
                            {parseInt(entry.temp.max)}° {" "}
                          </Text>
                          <Text
                            color={"#6B7280"}
                            lineHeight={"17px"}
                            display={"inline-block"}
                            fontSize={"14px"}
                            fontWeight={"400"}
                          >
                            {" "}
                         
                            / {parseInt(entry.temp.min)}°{" "}
                          </Text>
                          </Flex>
                         <Text textAlign={"center"} fontSize={"11px"} fontWeight={"400"} color={"#9F9F9F"} lineHeight={"140%"} >  { capitalizeString(entry?.weather[0]?.description) }  </Text>
                         { id == 0 ? (
                          <Box borderRadius={"4px"} bg={"#0094E3"} width={"42px"} height={"21px"} >
                           <Flex justifyContent={"center"} alignItems={"center"} width={"100%"} height={"100%"} >
                             <Text color={"#fff"} fontSize={"12px"} fontWeight={"400"} lineHeight={"140%"} >Now</Text>
                           </Flex>
                          </Box>
                          
                         ) : (
                          <Text fontSize={"12px"} fontWeight={"400"} color={"#111827"} lineHeight={"140%"} > {format(entry.dt * 1000, "iii")} </Text> 
                         ) }
                        </Flex>
                     )
                  } ) }
                </Flex> */}
                <Flex flexDirection={"row"} alignItems={"center"} padding={"18px"} justifyContent={"flex-start"} >
                {userInfo && userInfo.address && userInfo.address?.pincode &&
                      <Box
                        alignSelf={"flex-start"}
                        cursor="pointer"
                        onClick={() =>
                          {window.open(
                            `https://weather.com/weather/tenday/l/${userInfo?.address?.pincode}`,
                            "_blank"
                        )}}
                      >
                        <Tag
                          text={"Source: weather.com"}
                          color="#0094E3"
                          bgColor="#F2FBFF"
                        />
                      </Box>}
                </Flex>
                </Box>
            </Box>
            <Box width={"100%"}>
              <Box
                style={{ boxSizing: "border-box" }}
                background="#FFFFFF"
                borderRadius="8px"
                width="100%"
              >
<Flex
                  padding={"18px"}
                  flexDirection={"row"}
                  gridColumnGap={"16px"}
                  borderBottom={"1px solid #E4E4E4"}
                  alignItems={"center"}
                >
                  <Icon name="stakeholderTongue" fill={"#000000"} />
                  <Text color={"#111827"} fontWeight={"400"} fontSize={"14px"}>
                    {" "}
                    Preferences{" "}
                  </Text>
                </Flex>
                <Flex
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                  padding={"18px"}
                  gridRowGap={"24px"}
                >
                    
                    {userInfo && userInfo.preference && 
                  userInfo.preference.c1 && userInfo.preference.c1.length>0 &&
                  <Flex
                    flexDirection={"row"}
                    width={"100%"}
                   
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderCoffee" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >Coffee/Tea:</Text>  {userInfo.preference.c1
                        .map((entry) => getCoffeeString(entry))
                        .filter((x) => x)
                        .join(", ")}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                  userInfo.preference.c3 && userInfo.preference.c3.length>0 &&
                    <Flex
                    width={"100%"}
                    
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderWine" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >Alcoholic beverages:</Text>  {userInfo.preference.c3
                        .map((x) => getAlcoholString(x))
                        .filter((x) => x)
                        .join(", ")}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                    userInfo.preference.c1 && userInfo.preference.c1.length>0 &&
                    userInfo.preference.c1
                    .map(x => getOtherC1String(x)).filter(y => y && y.length>0).length>0 &&
                    <Flex
                    width={"100%"}
                  
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderSoda" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                     
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >Nothing/Other:</Text> {userInfo.preference.c1
                        .map((entry) => getOtherC1String(entry))
                        .filter((x) => x)
                        .join(", ")}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                    userInfo.preference.cuisine && userInfo.preference.cuisine.length>0 && 
                    <Flex
                    width={"100%"}
                   
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderCuisine" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >Favourite cuisines/dishes: </Text> {userInfo.preference.cuisine
                        .map((entry) => getCuisineString(entry))
                        .filter((x) => x)
                        .join(", ")}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                    userInfo.preference.allergy && userInfo.preference.allergy.length>0 &&
                    <Flex
                    width={"100%"}
                   
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderDiet" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >Dietary restrictions: </Text>  {userInfo.preference.allergy
                        .map((entry) => capitalizeString(entry))
                        .filter((x) => x)
                        .join(", ")}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                    userInfo.preference.music && userInfo.preference.music.length>0 &&
                    <Flex
                    width={"100%"}
                   
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderMusic" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                     
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >I can’t live without my music: </Text>  {userInfo.preference.music
                        .map((entry) => capitalizeString(entry))
                        .filter((x) => x)
                        .join(", ")}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                    userInfo.preference.sportsTeam && userInfo.preference.sportsTeam.length>0 &&
                    <Flex
                    width={"100%"}
                   
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderSports" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >I follow sports religiously: </Text>  {userInfo.preference.sportsTeam
                        .map((entry) => capitalizeString(entry))
                        .filter((x) => x)
                        .join(", ")}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                    userInfo.preference.books && userInfo.preference.books.length>0 &&
                    <Flex
                    width={"100%"}
                    
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderBook" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >I like to read: </Text>  {userInfo.preference.books
                        .map((entry) => capitalizeString(entry))
                        .filter((x) => x)
                        .join(", ")}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                    userInfo.preference.movies && userInfo.preference.movies.length>0 &&
                    <Flex
                    width={"100%"}
                    
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderMonitor" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >I like to watch TV shows and movies: </Text> {userInfo.preference.movies
                        .map((entry) => capitalizeString(entry))
                        .filter((x) => x)
                        .join(", ")}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                    userInfo.preference.podcasts && userInfo.preference.podcasts.length>0 &&
                    <Flex
                    width={"100%"}
                    
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderMic" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >I like to listen to podcasts: </Text> {userInfo.preference.podcasts
                        .map((entry) => capitalizeString(entry))
                        .filter((x) => x)
                        .join(", ")}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                    userInfo.preference.c4 && userInfo.preference.c4.length>0 &&
                    userInfo.preference.c4
                    .map(entry => getC4MappedString(entry, 'outdoor'))
                    .filter(x => x && x.length>0).length>0 &&
                    <Flex
                    width={"100%"}
                    
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderOuting" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >Outdoor activities: </Text>  {userInfo.preference.c4.map((entry) => (
                        <>{getC4MappedString(entry, "outdoor")} </>
                      ))}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                    userInfo.preference.indoor && userInfo.preference.indoor.length>0 &&
                    <Flex
                    width={"100%"}
                    
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderIndoor" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >Indoor activities: </Text>  {userInfo.preference.indoor
                        .map((entry) => capitalizeString(entry))
                        .filter((x) => x)
                        .join(", ")}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                    userInfo.preference.c4 && userInfo.preference.c4.length>0 &&
                    userInfo.preference.c4
                    .map(entry => getC4MappedString(entry, 'pets'))
                    .filter(x => x && x.length>0).length>0 &&
                    <Flex
                    width={"100%"}
                    
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderPet" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      
                    >
                      {" "}
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >Spending time with my pet: </Text> {userInfo.preference.c4.map((entry) => (
                        <>{getC4MappedString(entry, "pets")} </>
                      ))}
                    </Text>
                  </Flex>}
                  {userInfo && userInfo.preference && 
                    userInfo.preference.c4 && userInfo.preference.c4.length>0 &&
                    userInfo.preference.c4
                    .map(entry => getC4MappedString(entry, 'kids'))
                    .filter(x => x && x.length>0).length>0 &&
                    <Flex
                    width={"100%"}
                    
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderKid" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      
                    >
                      <Text fontFamily={"Inter-Bold"} display={"inline-block"}  fontWeight={"300"}
                      fontSize={"12px"}
                      lineHeight={"140%"} >Spending time with my kids: </Text>  {userInfo.preference.c4.map((entry) => (
                        <>{getC4MappedString(entry, "kids")} </>
                      ))}
                    </Text>
                  </Flex>}
                    </Flex>
                </Box>
                </Box>
                <Text
              color={"#050505"}
              fontWeight={"400"}
              fontSize={"20px"}
              lineHeight={"140%"}
              marginTop={"16px"}
            >
              {" "}
              Nudge feed{" "}
            </Text>
            { nudges.length>0 && nudges.map( (nudge , ind) => {
                return !thumsDownIds.includes(nudge?.nudgeId) ? (
                  <Box width={"100%"}
                    paddingBottom={ (nudges.length - 1 == ind) ? "120px":  "0px" }
                    
                  boxShadow={hover && "0px 0px 15px 1px rgba(0, 0, 0, 0.07)"}
                  >
                  <Box
                                  style={{ boxSizing: "border-box" }}
                                  background="#FFFFFF"
                                  borderTopLeftRadius="8px"
                                  borderTopRightRadius="8px"
                                  borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                                  padding="12px"
                                  width="100%"
                                >
                                  <Flex
                  // marginTop={"12px"}
                  
                  borderBottom="1px dashed rgba(0, 0, 0, 0.1)"
                  marginBottom="16px"
                  paddingBottom={"12px"}
                  // justifyContent="space-between"
                  justifyContent={"flex-start"}
                  gridColumnGap={"10px"}
                  padding="0px 0px 12px 0px"
                  // height="16px"
                  alignItems="center"
                >
                    <Flex flexDirection={"column"} gridRowGap={"4px"} >
                    <Text
                      color="#0094E3"
                      fontSize="14px"
                      fontWeight="300"
                      
                    //   paddingRight="10px"
                      cursor="pointer"
                      onClick={() => {
                        dispatch( sn(nudge) );
                        navigate(`/aeReturnDashboard/stakeholder/${nudge?.stakeholderId}`);
                      }}
                    >
                      {" "}
                      {nudge?.stakeholderName}{" "}
                    </Text>
                    <Text color={"#57585C"} fontSize={"11px"} fontWeight={"400"} lineHeight={"160%"} >
                      { nudge?.email }
                    </Text>
                    </Flex>
                   
                    {/* {hover && (
              <Flex>
                <Flex
                  color="#DFDFDF"
                  flexDirection={"column"}
                  width={"100%"}
                  border="1px solid rgba(0, 0, 0, 0.1)"
                />
                <Text
                  ml="10px"
                  color="#57585C"
                  fontSize={"14px"}
                  fontWeight={300}
                >
                  {nudge?.email}
                </Text>
              </Flex>
            )} */}
                </Flex>
                                   {/* <Flex
                                    borderBottom="1px dashed rgba(0, 0, 0, 0.1)"
                                    marginBottom="16px"
                                    // justifyContent="space-between"
                                    justifyContent={"flex-start"}
                                    gridColumnGap={"10px"}
                                    padding="0px 0px 12px 0px"
                                    height="16px"
                                    alignItems="center"
                                  >
                                      <Text
                                        color="#0094E3"
                                        fontSize="14px"
                                        fontWeight="300"
                                        
                                      //   paddingRight="10px"
                                        cursor="pointer"
                                        onClick={() => {
                                          dispatch( sn(nudge) );
                                          navigate(`/aeReturnDashboard/stakeholder/${nudge?.stakeholderId}`);
                                          
                                        }}
                                      >
                                        {" "}
                                        {nudge?.stakeholderName}{" "}
                                      </Text>
                                      {hover && (
                                <Flex>
                                  <Flex
                                    color="#DFDFDF"
                                    flexDirection={"column"}
                                    width={"100%"}
                                    border="1px solid rgba(0, 0, 0, 0.1)"
                                  />
                                  <Text
                                    ml="10px"
                                    color="#57585C"
                                    fontSize={"14px"}
                                    fontWeight={300}
                                  >
                                    {nudge?.email}
                                  </Text>
                                </Flex>
                              )}
                                  </Flex> */}
                                  <Flex flexDirection={"column"} gridRowGap={"8px"}  >
                                   <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"12px"} >
                                   {/* { getNudgeIcon(nudge) } */}
                                   {getNudgeIcon(nudge, hover)}
                                   <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"8px"} >
                                   {/* <Icon name="nudgeIndexFinger" /> */}
                                   <Text color={"#57585C"} fontWeight={"300"}  fontSize={"12px"}> { nudge?.context } </Text>
                                   </Flex>
                                   </Flex>
                                   { getHeadlineStr(nudge) }
                                   <Flex alignItems={"center"} justifyContent={"space-between"} >
                                    <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"10px"} > 
                                   {nudge?.nudgeCategory == "weather" &&
                                            nudge?.crawlerData?.weatherUrl && (
                                              <Box
                                                alignSelf={"flex-start"}
                                                cursor="pointer"
                                                onClick={() =>
                                                  {
                                                    insertActivityIntoActivityHistory(
                                                      nudge,
                                                      {},
                                                      loggedInMember,
                                                      "source"
                                                    );
                                                   
                                                    window.open(
                                                    nudge?.crawlerData?.weatherUrl,
                                                    "_blank"
                                                  )}
                                                }
                                              >
                                                <Tag
                                                  
                                                  text={`Source: ${getDomainName(nudge?.crawlerData?.weatherUrl)}`}
                                                  color="#0094E3"
                                                  bgColor="#F2FBFF"
                                                />
                                              </Box>
                                            )}
                                           {nudge?.nudgeCategory == "googlePlaces" &&
                                            nudge?.crawlerData?.maps_url && (
                                              <Box
                                                alignSelf={"flex-start"}
                                                cursor="pointer"
                                                onClick={() =>
                                                  {
                                                    insertActivityIntoActivityHistory(
                                                      nudge,
                                                      {},
                                                      loggedInMember,
                                                      "source"
                                                    );
                                                 
                                                    window.open(
                                                    nudge?.crawlerData?.maps_url,
                                                    "_blank"
                                                  )}
                                                }
                                              >
                                                <Tag
                                                  text={`Source: ${getDomainName(nudge?.crawlerData?.maps_url)}`}
                                                  color="#0094E3"
                                                  bgColor="#F2FBFF"
                                                />
                                              </Box>
                                            )}
                                             {nudge?.nudgeCategory == "news" &&
                                            nudge?.crawlerData?.url && (
                                              <Box
                                              alignSelf={"flex-start"}
                                                cursor="pointer"
                                                onClick={() =>
                                                  {
                                                    insertActivityIntoActivityHistory(
                                                      nudge,
                                                      {},
                                                      loggedInMember,
                                                      "source"
                                                    );
                                                   
                                                    window.open(
                                                    nudge?.crawlerData?.url,
                                                    "_blank"
                                                  )}
                                                }
                                              >
                                                <Tag
                                                  text={`Source: ${getDomainName(nudge?.crawlerData?.url)}`}
                                                  color="#0094E3"
                                                  bgColor="#F2FBFF"
                                                />
                                              </Box>
                                            )}
                                          {nudge?.nudgeCategory == "music" &&
                                            nudge?.crawlerData?.link && (
                                              <Box
                                              alignSelf={"flex-start"}
                                                cursor="pointer"
                                                onClick={() =>
                                                  {
                                                    insertActivityIntoActivityHistory(
                                                      nudge,
                                                      {},
                                                      loggedInMember,
                                                      "source"
                                                    );
                                                   
                                                    window.open(
                                                    nudge?.crawlerData?.link,
                                                    "_blank"
                                                  )}
                                                }
                                              >
                                                <Tag
                                                  text={`Source: ${getDomainName(nudge?.crawlerData?.link)}`}
                                                  color="#0094E3"
                                                  bgColor="#F2FBFF"
                                                />
                                              </Box>
                                            )}
                                          {nudge?.nudgeCategory == "concertTickets" &&
                                            nudge?.crawlerData?.eventTickets && (
                                              <Box
                                              alignSelf={"flex-start"}
                                                cursor="pointer"
                                                onClick={() =>
                                                  {
                                                    insertActivityIntoActivityHistory(
                                                      nudge,
                                                      {},
                                                      loggedInMember,
                                                      "source"
                                                    );
                                                   
                                                    window.open(
                                                    nudge?.crawlerData?.eventTickets,
                                                    "_blank"
                                                  )}
                                                }
                                              >
                                                <Tag
                                                  text={`Source: ${getDomainName(nudge?.crawlerData?.eventTickets)}`}
                                                  color="#0094E3"
                                                  bgColor="#F2FBFF"
                                                />
                                              </Box>
                                            )}
                                             {nudge?.nudgeCategory == "sports" &&(
                                            <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"4px"} >
                                                {nudge?.crawlerData?.schedule_url && nudge?.uniqueId.indexOf('30')>-1 && (
                                              <Box
                                              alignSelf={"flex-start"}
                                                cursor="pointer"
                                                onClick={() =>
                                                  {
                                                    insertActivityIntoActivityHistory(
                                                      nudge,
                                                      {},
                                                      loggedInMember,
                                                      "source"
                                                    );
                                                 
                                                    window.open(
                                                    nudge?.crawlerData?.schedule_url,
                                                    "_blank"
                                                  )}
                                                }
                                              >
                                                <Tag
                                                  text={`Source: ${getDomainName(nudge?.crawlerData?.schedule_url)}`}
                                                  color="#0094E3"
                                                  bgColor="#F2FBFF"
                                                />
                                              </Box>
                                            )}
                                              {nudge?.crawlerData?.news_url && nudge?.uniqueId.indexOf('16')>-1 && (
                                              <Box
                                              alignSelf={"flex-start"}
                                                cursor="pointer"
                                                onClick={() =>
                                                  {
                                                    insertActivityIntoActivityHistory(
                                                      nudge,
                                                      {},
                                                      loggedInMember,
                                                      "source"
                                                    );
                                                 
                                                    window.open(
                                                    nudge?.crawlerData?.news_url,
                                                    "_blank"
                                                  )}
                                                }
                                              >
                                                <Tag
                                                  text={`Source: ${getDomainName(nudge?.crawlerData?.news_url)}`}
                                                  color="#0094E3"
                                                  bgColor="#F2FBFF"
                                                />
                                              </Box>
                                            )}
                                            {nudge?.crawlerData?.game_id_url && nudge?.uniqueId.indexOf('17')>-1 && (
                                              <Box
                                              alignSelf={"flex-start"}
                                                cursor="pointer"
                                                onClick={() =>
                                                  {
                                                    insertActivityIntoActivityHistory(
                                                      nudge,
                                                      {},
                                                      loggedInMember,
                                                      "source"
                                                    );
                                               
                                                    window.open(
                                                    nudge?.crawlerData?.game_id_url,
                                                    "_blank"
                                                  )}
                                                }
                                              >
                                                <Tag
                                                  text={`Source: ${getDomainName(nudge?.crawlerData?.game_id_url)}`}
                                                  color="#0094E3"
                                                  bgColor="#F2FBFF"
                                                />
                                              </Box>
                                            )}
                  
                                            </Flex>
                                             )
                                              
                                            }
                                            <Flex alignItems={"center"}>
                                    <TimeOutline />
                                    <Text
                                      color="#9F9F9F"
                                      fontSize={"10px"}
                                      fontWeight={400}
                                      ml="5px"
                                    >
                                      Added {getDateString(nudge?.time)}
                                    </Text>
                                  </Flex>
                                  </Flex>
                                  { !copiedIds.includes(nudge?.uniqueId) ? <ToolTip
                                                          
                                                          color="#EEEEEE"
                                                          bgColor="#0094E3"
                                                          boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
                                                          borderRadius="8px"
                                                          direction="top-start"
                                                          title={
                                                            <>
                                                            <Text padding={"12px 16px"} fontSize={"14px"} fontWeight={"400"}> Description and the source link will be copied </Text>
                                                            </>
                                                          }
                                                        >
                          <Box background={"rgba(221, 1, 91, 0.05)"} borderRadius={"8px"} padding={"8px"} cursor="pointer" onClick={ () => {
                              setCopiedIds([...copiedIds , nudge?.uniqueId]);
                              let content = nudge?.nudgeHeadline;
                              let links = [];
                              if ( nudge?.nudgeCategory == "movies" && nudge?.crawlerData?.url ) {
                                links.push(nudge?.crawlerData?.url);
                                                  } else if ( nudge?.nudgeCategory == "books" && nudge?.crawlerData?.link ) {
                              links.push(nudge?.crawlerData?.link );
                                                  } else if ( nudge?.nudgeCategory == "podcasts" && nudge?.crawlerData?.link ) {
                                                    links.push(nudge?.crawlerData?.link);
                                                  } else if ( nudge?.nudgeCategory == "googlePlaces" &&
                              nudge?.crawlerData?.maps_url ) {
                                  links.push(nudge?.crawlerData?.maps_url);
                              } else if ( nudge?.nudgeCategory == "news" &&
                              nudge?.crawlerData?.url ) {
                               links.push(nudge?.crawlerData?.url);
                              } else if ( nudge?.nudgeCategory == "music" &&
                              nudge?.crawlerData?.link  ) {
                               links.push(nudge?.crawlerData?.link);
                              } else if ( nudge?.nudgeCategory == "concertTickets" &&
                              nudge?.crawlerData?.eventTickets ) {
                             links.push(nudge?.crawlerData?.eventTickets);
                              } else if ( nudge?.nudgeCategory == "sports" && nudge?.crawlerData?.news_url && nudge?.uniqueId.indexOf('16')>-1 ) {
                               links.push(nudge?.crawlerData?.news_url);
                              } else if ( nudge?.nudgeCategory == "sports" && nudge?.crawlerData?.game_id_url && nudge?.uniqueId.indexOf('17')>-1 ) {
                              links.push(nudge?.crawlerData?.game_id_url);
                              } else if ( nudge?.nudgeCategory == "weather" && nudge?.crawlerData?.weatherUrl  ) {
                                links.push(nudge?.crawlerData?.weatherUrl);
                              }
                              content = content + `\nSource: ${links.join(" ")}`
                              navigator.clipboard.writeText(content);
                              insertActivityIntoActivityHistory(
                                nudge,
                                {},
                                loggedInMember,
                                "copy"
                              );
                              setTimeout( () => {
                                let copiedId = copiedIds.filter( id => id != nudge?.uniqueId );
                                setCopiedIds(copiedId);
                              } , 1000 )
                          } } >                           
                          <Icon name="copy" size="16" fill={"#DD015B"} />
                          </Box>
                          </ToolTip> : (
                              <Box background={"#F4FFF1"} borderRadius={"8px"} padding={"8px"} >
                                  <Icon name="doubleTick" size="16" stroke="#24B300" />
                                  </Box>
                          )}
                                            </Flex>
                                  </Flex>
                                  </Box>
                                  <Box
                                  style={{ boxSizing: "border-box" }}
                                  background="#FFFFFF"
                                  borderBottomRightRadius="8px"
                                  borderBottomLeftRadius="8px"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  height="50px"
                                  width="100%"
                                  padding="8px 12px"
                                >
                                 <Flex flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} width="100%"  >
                                 <Flex alignItems={"center"}>
                            <CompanyDomainIcon />{" "}
                            <Text ml="10px" fontSize={"12px"} fontWeight={400} color="#0094E3">
                              {" "}
                              {/* {nudge?.accountName} */}
                              { nudge?.email && nudge?.email.split("@").length == 2 &&  nudge?.email.split("@")[1] }
                            </Text>
                          </Flex>
                                  <Flex alignItems={"center"} gridRowGap={"10px"} >
                                 { thumsUpIds.includes( nudge?.nudgeId ) ? (
                                  <>
                                  </>
                                //   <Flex flexDirection={"row"} gridColumnGap={"8px"} alignItems={"center"} >
                                //    <Icon name="stakeholderDone" fill={"#1CB43D"}  />
                                //    <Text color={"#24B300"} fontWeight={"400"} fontSize={"12px"} > Thank you for your feedback </Text>
                                //    <Button variant="undo" onClick={ () => {
                                //   undoNudgeAction(nudge?.nudgeId,"up");
                                //  } } > Undo  </Button>
                                //   </Flex>
                                  ) : (
                  <Flex gap="8px">
                                   
                                     <Box height={"25px"}  borderRadius={"4px"} cursor={"pointer"} border={"1px solid rgba(0, 0, 0, 0.1)"}   onClick={() => {
                                        setSelectedNudge(nudge);
                                        // updateNudgeFeedback(nudge, "up", "");
                                        insertActivityIntoActivityHistory(
                                          nudge,
                                          {},
                                          loggedInMember,
                                          "like"
                                        );
                                      
                                        updateNudgeFeedback(nudge, "up", "").then( res => {
                                          setNudgeFeedBack({ nudge : nudge , action : "up" });
                                          setShowMobileSnackbar(true);
                                       } ).catch( err => {
                                         console.log("error",err);
                                       } )
                                      }} >
                                      <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"4px"} padding={"4px 8px"} >
                                        {/* <Text color={"#9F9F9F"} lineHeight={"140%"} fontWeight={"400"} fontSize={"12px"} > I </Text> */}
                                        <Icon name="heart" fill="#FF005D" />
                                        {/* <Text color={"#9F9F9F"} lineHeight={"140%"} fontWeight={"400"} fontSize={"12px"} > this </Text> */}
                                      </Flex>
                                     </Box>
                                     <Box height={"25px"}  borderRadius={"4px"} cursor={"pointer"} border={"1px solid rgba(0, 0, 0, 0.1)"}   onClick={() => {
                                         dispatch(setSelectedNudgeForUnlike(nudge));
                                         setHide(!hide);
                                      }} >
                                      <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"4px"} padding={"4px 8px"} >
                                        
                                        <Icon name="thumbsDown" fill={"#9F9F9F"} />
                                       
                                      </Flex>
                                     </Box>
                                  
                                  </Flex>
                                  ) }
                                   {/* { !copiedIds.includes(nudge?.uniqueId) ? <ToolTip
                                                          
                                                          color="#EEEEEE"
                                                          bgColor="#0094E3"
                                                          boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
                                                          borderRadius="8px"
                                                          direction="top-start"
                                                          title={
                                                            <>
                                                            <Text padding={"12px 16px"} fontSize={"14px"} fontWeight={"400"}> Description and the source link will be copied </Text>
                                                            </>
                                                          }
                                                        >
                          <Box background={"rgba(221, 1, 91, 0.05)"} borderRadius={"8px"} padding={"8px"} cursor="pointer" onClick={ () => {
                              setCopiedIds([...copiedIds , nudge?.uniqueId]);
                              let content = nudge?.nudgeHeadline;
                              let links = [];
                              if ( nudge?.nudgeCategory == "googlePlaces" &&
                              nudge?.crawlerData?.maps_url ) {
                                  links.push(nudge?.crawlerData?.maps_url);
                              } else if ( nudge?.nudgeCategory == "news" &&
                              nudge?.crawlerData?.url ) {
                               links.push(nudge?.crawlerData?.url);
                              } else if ( nudge?.nudgeCategory == "music" &&
                              nudge?.crawlerData?.link  ) {
                               links.push(nudge?.crawlerData?.link);
                              } else if ( nudge?.nudgeCategory == "concertTickets" &&
                              nudge?.crawlerData?.eventTickets ) {
                             links.push(nudge?.crawlerData?.eventTickets);
                              } else if ( nudge?.nudgeCategory == "sports" && nudge?.crawlerData?.news_url && nudge?.uniqueId.indexOf('16')>-1 ) {
                               links.push(nudge?.crawlerData?.news_url);
                              } else if ( nudge?.nudgeCategory == "sports" && nudge?.crawlerData?.game_id_url && nudge?.uniqueId.indexOf('17')>-1 ) {
                              links.push(nudge?.crawlerData?.game_id_url);
                              } else if ( nudge?.nudgeCategory == "weather" && nudge?.crawlerData?.weatherUrl  ) {
                                links.push(nudge?.crawlerData?.weatherUrl);
                              }
                              content = content + `\nSource: ${links.join(" ")}`
                              navigator.clipboard.writeText(content);
                              setTimeout( () => {
                                let copiedId = copiedIds.filter( id => id != nudge?.uniqueId );
                                setCopiedIds(copiedId);
                              } , 1000 )
                          } } >                           
                          <Icon name="copy" size="16" fill={"#DD015B"} />
                          </Box>
                          </ToolTip> : (
                              <Box background={"#F4FFF1"} borderRadius={"8px"} padding={"8px"} >
                                  <Icon name="doubleTick" size="16" stroke="#24B300" />
                                  </Box>
                          )} */}
                          </Flex>
                          </Flex>
                  
                                </Box>
                  </Box>
                      ): (
                        <Box width={"100%"}>
                        <Box
                               style={{ boxSizing: "border-box" }}
                               background="#FFFFFF"
                               borderTopLeftRadius="8px"
                               borderTopRightRadius="8px"
                               borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                               padding="20px 12px"
                               width="100%"
                             >
                             <Flex  flexDirection={"row"} gridColumnGap={"40px"} justifyContent={"space-betwen"} alignItems={"center"} >
                              <Text color={"#050505"} lineHeight={"150%"} fontWeight={"300"} fontSize={"14px"} > We won't recommend this nudge to you again </Text>
                              <Button variant="undo" onClick={ () => {
                               undoNudgeAction(nudge?.nudgeId,"down");
                              } } > Undo  </Button>
                             </Flex>
                             </Box>
                       </Box>
                      )
              } ) }
                
        </Flex>
        </Box>
    
      { (!sendTreatOrder || (userInfo && userInfo.preference && 
                  userInfo.preference.sportsTeam && 
                  userInfo.preference.sportsTeam.length>0 && !fangearOrder)) && <div style={{ position : "fixed" , bottom : 0 , right : 0 , height: "66px", width:"100%", zIndex:1 , background :"#fff" , padding : "10px" , boxSizing:  "border-box" }} >
        <Flex flexDirection={"row"} width={"100%"} justifyContent={"center"} alignItems={"center"} gridColumnGap={"8px"} >
        {!sendTreatOrder && <Button
                    fullSize={fangearOrder}
                    alignSelf={"flex-end"}
                    variant="primary"
                    onClick={() => {
                      setShowTreatModal(true)
                    }}
                  >
                    {" "}
                    Send treat/gift{" "}
                  </Button>}
                  {
                  userInfo && userInfo.preference && 
                  userInfo.preference.sportsTeam && 
                  userInfo.preference.sportsTeam.length>0 && !fangearOrder &&
                  <Button fullSize={sendTreatOrder} variant="primary" alignSelf={"flex-end"} onClick={ () => {
                    setShowSportsModal(true);
                  } } > Send a fan gear </Button>}
        </Flex>
    
    </div>}
        </div>
        </>
  );
};
export default StakeholderProfileMobileView;  