import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import DataRepositoryContent from "./DataRepositoryContent";

function DataRepositoryRightSection() {
    return (
        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
            <DataRepositoryContent></DataRepositoryContent>
        </Flex>
    )
}

export default DataRepositoryRightSection;