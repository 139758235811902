import React from 'react';

const InfoIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <g clipPath="url(#clip0_19316_49022)">
        <path
          d="M8.10019 16.1992C3.62656 16.1992 0.000194867 12.572 0.000195258 8.09922C0.000195649 3.6264 3.62657 -0.000783112 8.1002 -0.000782721C12.5738 -0.00078233 16.2002 3.6264 16.2002 8.09922C16.2002 12.572 12.5738 16.1992 8.10019 16.1992ZM8.09858 5.66598C8.20694 5.66941 8.31489 5.65103 8.41601 5.61192C8.51713 5.57282 8.60937 5.5138 8.68723 5.43836C8.7651 5.36291 8.82701 5.27259 8.8693 5.17276C8.91158 5.07292 8.93336 4.96561 8.93336 4.85719C8.93336 4.74877 8.91158 4.64146 8.8693 4.54162C8.82701 4.44179 8.7651 4.35147 8.68723 4.27603C8.60937 4.20059 8.51713 4.14156 8.41601 4.10246C8.31489 4.06336 8.20694 4.04498 8.09858 4.04841C7.8885 4.05506 7.68926 4.14318 7.54301 4.29413C7.39676 4.44508 7.31498 4.64701 7.31498 4.85719C7.31498 5.06737 7.39676 5.2693 7.54301 5.42025C7.68926 5.5712 7.8885 5.65933 8.09858 5.66598ZM8.1002 12.1492C8.29871 12.1492 8.4903 12.0762 8.63858 11.9442C8.78686 11.8122 8.8815 11.6304 8.90453 11.4332L8.9102 11.3392L8.90858 7.28841L8.90291 7.19364C8.87949 6.99677 8.78468 6.81532 8.63644 6.68367C8.48821 6.55202 8.29683 6.47931 8.09858 6.47931C7.90032 6.47931 7.70894 6.55202 7.56071 6.68367C7.41247 6.81532 7.31766 6.99677 7.29425 7.19364L7.28858 7.28841L7.29019 11.3392L7.29587 11.434C7.31908 11.631 7.41379 11.8127 7.56205 11.9445C7.71031 12.0764 7.9018 12.1492 8.1002 12.1492Z"
          fill="#0094E3"
        />
      </g>
      <defs>
        <clipPath id="clip0_19316_49022">
          <rect
            width="16.2"
            height="16.2"
            fill="white"
            transform="translate(16.2002 16.1992) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoIcon;
