import React, { FC, useCallback, useState, useEffect } from "react";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { useAppDispatch, useAppSelector } from "hooks";
import { axiosHelper } from "utils";
import { useNavigate } from "react-router-dom";
import { aeLoginFormData } from "./store/aeLoginFormReducer";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { setLoggedInState, setMenuItems } from "store/globalStateReducer";
import userService from "services/userService";
import menuDetails from "utils/menuBuilder";
import { getCookie } from "utils/cookie";

const AESignupSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .email("aereturn.profile.email.invalid")
    .required("aereturn.profile.email.valid"),
  password: yup
    .string()
    .required("aereturn.profile.password.valid")
    .min(4, "aereturn.profile.password.min"),
});

const defaultValues = {
  emailAddress: "",
  password: "",
};

interface AeLoginFormProps {
  handleSnackbarError: any;
}

const AELoginForm: FC<AeLoginFormProps> = ({ handleSnackbarError }) => {

  const checkCookie = getCookie("evaAuthToken");
  const menuItems = useAppSelector((state) => state.globalState.menuItems);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (menuItems && menuItems.length > 0) {
      const navigationPath = localStorage.getItem("navigationPath");
      localStorage.removeItem("navigationPath");
      navigate(navigationPath ? navigationPath : menuItems[0]?.route);
    }
  }, [menuItems])

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, toggleIsLoading] = useState(false);

  const setLoginCookie = (token: string, name: string) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + (30 * 24 * 60 * 60 * 1000));
    const cookie = `${name}=${token};expires=${expires.toUTCString()};path=/;`;
    document.cookie = cookie;
  }

  const onSubmitHandler = useCallback(
    async (values: any) => {
      try {
        dispatch(aeLoginFormData(values));
        // email, password
        toggleIsLoading(true);
        const emailAddress = values.emailAddress.toLowerCase().trim();
        const password = values.password;//Buffer.from(values.password).toString("base64");
        const hashedPassword = await encryptPassword(password);

        // const result = await axiosHelper({
        //   url: "rapport/user/evaLogin",
        //   method: "POST",
        //   JSONData: {
        //     email: emailAddress,
        //     password: decodedPassword,
        //   },
        // });

        if (checkCookie) {
          validateTokenAndLogin(checkCookie)
        }

        const result = await userService.evaLogin({ email: emailAddress, password: btoa(String.fromCharCode.apply(null, new Uint8Array(hashedPassword.encryptedPassword))), hash: btoa(String.fromCharCode.apply(null, new Uint8Array(hashedPassword.iv))), encryptedKey: btoa(String.fromCharCode.apply(null, new Uint8Array(hashedPassword.encryptionKey))) })
        toggleIsLoading(false);
        if (result?.success) {
          localStorage.setItem("Authorization", result.token);
          localStorage.setItem("organizationId", result.organizationId);
          //setLoginCookie(emailAddress);
          setLoginCookie(result.token, "token");
          setLoginCookie(result.token, "evaAuthToken");
          // localStorage.setItem("loggedIn", "true");
          // const loggedInEvent = new Event("loggedInEvent");
          // window.dispatchEvent(loggedInEvent);
          dispatch(setLoggedInState(true));

        } else {
          toggleIsLoading(false);
          handleSnackbarError(result?.data);
        }
      } catch (err) {
        toggleIsLoading(false);
        console.log(err.response?.data);
        handleSnackbarError(err.response?.data);
      }
    },
    [dispatch, handleSnackbarError, navigate]
  );
  const encryptPassword = async (password: string) => {
    try {
      const encoder = new TextEncoder();
      const encryptionKey = await crypto.subtle.generateKey({ name: 'AES-CBC', length: 256 }, true, ['encrypt', 'decrypt']
      );
      const exportedKey = await crypto.subtle.exportKey('raw', encryptionKey);
      const keyArray = Array.from(new Uint8Array(exportedKey));
      const iv = crypto.getRandomValues(new Uint8Array(16));  // Initialization vector
      const encryptedData = await crypto.subtle.encrypt({ name: 'AES-CBC', iv: iv }, encryptionKey, encoder.encode(password));

      return {
        iv: Array.from(iv),
        encryptedPassword: Array.from(new Uint8Array(encryptedData)),
        encryptionKey: keyArray
      };
    } catch (error) {
      console.error('Encryption error:', error);
      throw error; // Rethrow the error for further handling if needed
    }
  };

  const validateTokenAndLogin = async (value) => {
    try {
      toggleIsLoading(true);
      const result = await userService.validateTokenAndLogin({ token: value });
      if (result?.success) {
        localStorage.setItem("Authorization", result.token);
        localStorage.setItem("organizationId", result.organizationId);
        setLoginCookie(result.token, "evaAuthToken");
        setLoginCookie(result.token, "token");
        dispatch(setLoggedInState(true));
        const firstMenuValue = result?.navigateOption?.[0];
        const menuMatch = menuDetails.find(menu => menu.dbValue === firstMenuValue);
      } else {
        toggleIsLoading(false);
        handleSnackbarError(result?.response?.data);
      }
    } catch (error) {
      toggleIsLoading(false);
      console.log(error.response?.data);
      handleSnackbarError(error.response?.data);
    }

  }

  useEffect(() => {
    if (checkCookie) {
      validateTokenAndLogin(checkCookie)
    }
  }, [checkCookie])




  return (
    <>
      <Formik
        initialValues={defaultValues}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validationSchema={AESignupSchema}
      >
        {({ dirty, isValid, submitForm }) => {
          return (
            <Form>
              <Grid width={screenSize.dynamicWidth > 576 ? "300px" : "100%"}>
                <div style={{ height: "110px" }}>
                  <Text mb={"5px"}
                    color={screenSize.dynamicWidth > 576 ? "white" : "#050505"}
                    variant="bodyRegular"
                  >
                    Email address
                  </Text>
                  <FormikInput
                    width={screenSize.dynamicWidth > 576 ? "100%" : ""}
                    name="emailAddress"
                    placeholder="e.g. example@revenoid.com"
                  />
                </div>
                <div style={{ height: "105px" }}>
                  <Text mb={"5px"}
                    color={screenSize.dynamicWidth > 576 ? "white" : "#050505"}
                    variant="bodyRegular"
                  >
                    Password
                  </Text>
                  <FormikInput
                    // width={"100%"}
                    width={screenSize.dynamicWidth > 576 ? "100%" : ""}
                    name="password"
                    placeholder="aereturn.login.password.placeholder"
                    type={"password"}
                  />
                </div>

                <Text
                  width={"108%"}
                  color={"#B1B1B1"}
                  variant="bodyRegular"
                  cursor="pointer"
                  textAlign={"right"}
                  onClick={() => {
                    navigate("/forgotPassword");
                  }}
                >
                  Forgot password?
                </Text>

                <Box
                  width={"108%"}
                  justifyContent={"center"}
                  style={{ boxSizing: "border-box" }}
                  mt={screenSize.dynamicWidth > 576 ? "14px" : "32px"}
                >
                  <Button
                    width={"108%"}
                    fullSize
                    loading={isLoading}
                    variant="primary"
                    borderRadius={
                      screenSize.dynamicWidth > 576 ? "118px" : "8px"
                    }
                    // fontSize="14px"
                    fontSize={screenSize.dynamicWidth > 576 ? "14px" : "16px"}
                    type="submit"
                    disabled={!dirty || !isValid}
                    iconName="arrowRight"
                    iconAlign="right"
                  >
                    Log In to Revenoid
                  </Button>
                </Box>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AELoginForm;
