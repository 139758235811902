import React, { FC, useEffect } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setGlobalStateRole } from "store/globalStateReducer";
import { axiosHelper, axiosHelperCore } from "utils";
import { setLoggedInState, setMenuItems } from "store/globalStateReducer";



const RedirectScreen = ({ userMenuAccess }) => {


  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let code = searchParams.get('code');
  let state = searchParams.get('state');
  let scope = searchParams.get('scope');
  const location = useLocation();
  const dispatch = useAppDispatch();

  const menuItems = useAppSelector((state) => state.globalState.menuItems);

  const setLoginCookie = (token: string, name: string) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + (30 * 24 * 60 * 60 * 1000));
    const cookie = `${name}=${token};expires=${expires.toUTCString()};path=/;`;
    document.cookie = cookie;
  }


  useEffect(() => {

    const hasAuthorization = localStorage.getItem("Authorization");
    if (hasAuthorization) {
      const matchingMenuItem = menuItems[0]
      if (matchingMenuItem) {
        const navigationPath = localStorage.getItem("navigationPath");
        localStorage.removeItem("navigationPath");
        navigate(navigationPath ? navigationPath : matchingMenuItem.route);
      }
    } else if (!hasAuthorization) {
      navigate('/aeLogin1');
    }
  }, [userMenuAccess, menuItems]);


  const fetchAccessToken = async () => {
    const result = await axiosHelper({
      url: `salesforce/getAccessToken/${code}/${state}`,
      method: 'GET',
      JSONData: {},
      urlOverride: false,
    });
    if (result.data.success) {
      localStorage.setItem("Authorization", result.data.data);
      let decodedState = JSON.parse(decodeURIComponent(state));
      dispatch(setLoggedInState(true));

      if (decodedState.journeyName === 'ae') {
        // navigate('/aeReturnDashboard');
        window.location.reload();
      }
      else {
        navigate("/fieldMappingSection");
      }
    }
  }




  const fetchGoogleAccessToken = async () => {
    try {
      console.log('google access token');
      const result = await axiosHelper({
        url: `google/callback?code=${code}`
      });
      if (result.data.success) {
        let decodedState = JSON.parse(decodeURIComponent(state));
        localStorage.setItem("Authorization", result.data.token);
        setLoginCookie(result.data.token, "evaAuthToken");
        dispatch(setLoggedInState(true));
        if (decodedState.journeyName === 'ae') {
          //navigate('/aeReturnDashboard');
          window.location.reload();
        }
        else {
          navigate("/fieldMappingSection");
        }
      }
      console.log(result.data);
    }
    catch (err) {
      navigate("/aeLogin1", { state: 'GOOGLE_UNAUTHORIZED' });
    }
  }

  useEffect(() => {
    if (state && state !== '') {
      console.log('obtained state-', state);
      dispatch(setGlobalStateRole(JSON.parse(decodeURIComponent(state))));
    }
  }, [state, dispatch])

  useEffect(() => {
    if (location?.pathname === "/admin2") {
      console.log('admin2journey disp');
      dispatch(setGlobalStateRole({ journeyName: "admin", journeyPath: "2" }));
    }
  }, [dispatch, location?.pathname]);

  useEffect(() => {
    console.log(code, scope);
    if (code && code !== '' && scope) {
      fetchGoogleAccessToken();
    }
    else if (code && code !== '' && !scope) {
      console.log('access token call', code);
      fetchAccessToken();
    }
    else if (!code && !scope) {
      dispatch(setGlobalStateRole({ journeyName: "ae", journeyPath: "1" }));
      if (!localStorage.getItem("Authorization")) {
        navigate('/aeLogin1');
      }

    }
  }, [code, scope])

  return (
    <></>
  )

}


export default RedirectScreen;