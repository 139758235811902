import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button'
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex'
import { Icon } from '@evabot/eva-react-core-library/dist/atoms/Icon/Icon'
import React, { FC, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { RightSideModal } from "@evabot/eva-react-core-library/dist/molecules/RightSideModal/RightSideModal";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { AEMailCard } from "@evabot/eva-react-core-library/dist/molecules/AEMailCard/AEMailCard"
import MemoRevenoidLoader from 'components/RevenoidLoader/RevenoidLoader';

interface OpportunityFooterProps { }

const OpportunityTableFooter: FC<OpportunityFooterProps> = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(true);
    const rightModalHandler = () => {
        setShow(!show);
    }
    return (
        <>
            <RightSideModal open={!show} onClose={rightModalHandler}>
                <Box overflowY="scroll" height="100vh">
                    <Flex
                        borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                        justifyContent="space-between"
                        marginTop="-15px"
                    >
                        <Text
                            id="start.building.rapport"
                            fontWeight="400"
                            fontSize="24px"
                            margin="25px 30px 25px 30px"
                        />
                        <Flex alignItems="center" marginRight="30px" gap="7px">
                            <Text id="email.send" fontWeight="400" fontSize="14px" />
                            <MemoRevenoidLoader />
                        </Flex>
                    </Flex>
                    <Flex
                        justifyContent="center"
                        margin="20px 40px"
                        padding="20px 30px"
                        gap="20px"
                        alignItems="center"
                        background="#F1FBFF"
                        borderRadius="8px"
                    >
                        <Icon name="aeEmail" />
                        <Flex flexDirection="column">
                            <Text id="work.email" fontSize="14px" fontWeight="400" />
                            <Text
                                id="demo.email"
                                fontSize="14px"
                                fontWeight="400"
                                color="#57585C"
                            />
                        </Flex>
                    </Flex>
                    <Flex
                        flexDirection="column"
                        alignItems="center"
                        gap="15px"
                        margin="0px 10px 80px 10px"
                        onClick={()=>navigate("/sendOpportunity1")}
                    >
                        <AEMailCard
                            name="Jordyn Bator"
                            designation="VP"
                            emailBody="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet."
                            emailTitle="Email subject 1"
                            image="https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
                        />
                        <AEMailCard
                            name="Kianna Westervelt"
                            designation="VP"
                            emailBody="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet."
                            emailTitle="Email subject 2"
                            image="https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
                        />
                        <AEMailCard
                            name="Jaxson Dokidis"
                            designation="VP"
                            emailBody="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet."
                            emailTitle="Email subject 3"
                            image="https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"

                        />
                    </Flex>
                </Box>
            </RightSideModal>

            <Flex
                justifyContent={"space-between"}
                width="100%" borderTop="1px solid rgba(0, 0, 0, 0.1)"
                padding="24px 0px 0px 0px"
                alignItems="center"
            >
                <Flex gap="10px" alignItems="center">
                    <Icon name="lightBulb" />
                    <Flex flexDirection="column" gap="10px">
                        <Text variant="imputLabel">
                            0/5 first email sent
                        </Text>
                        <Text fontSize="12px" fontWeight="500" color="#57585C">
                            Send first email to as many stakeholders as possible to increase the chances of closing the deal
                        </Text>
                    </Flex>

                </Flex>
                <Button
                    onClick={() => setShow(!show)}
                    size="m"
                    padding="12px"
                    variant="primary"
                    iconName="arrowRight"
                    iconAlign="right"
                    paddingBottom="12px"
                    paddingLeft="32px"
                    paddingRight="32px"
                    paddingTop="12px"
                >
                    Next step: Send first email
                </Button>
            </Flex>
        </>
    )
}

export default OpportunityTableFooter