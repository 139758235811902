import React, { useEffect, useState } from "react";
import EmailTemplateTableBody from "./EmailTemplateTableBody";
import EmailTemplateTableHeader from "./EmailTemplateTableHeader";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import userService from "services/userService";
import { Backdrop } from "@mui/material";
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import { useAppSelector } from "hooks";
import { useDispatch } from "react-redux";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import AddTemplateForm from "./component/AddTemplateForm";
import emailTemplateService from "services/emailTemplateService";
import EditTemplateForm from "./component/EditTemplateForm";
import { setEditFormData, setEditFormVisibility } from "./store/emailTemplateReducer";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

function EmailTemplateTable() {
    const [loading, setLoading] = useState(false);
    const [filteredEmailTemplateList, setFilteredEmailTemplateList] = useState([]);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [isAddTemplateOpen, setAddTemplateOpen] = useState(false);
    const dispatch = useDispatch();

    const userOrganizationId = useAppSelector(
        (state) => state.profileState.profileData.organizationId
    );

    const isEditFormVisible = useAppSelector(
        (state) => state.emailTemplateState.editFormVisible
    );

    const filterData = (data) => {
        setFilteredEmailTemplateList(data);
    }

    const handleAddTemplateButtonClick = () => {
        setAddTemplateOpen(true);
    };

    const handleAddTemplateClose = () => {
        setAddTemplateOpen(false);
    };

    const handleAddTemplate = (values, isTestEmail) => {

        emailTemplateService.addEmailTemplate(values).then((response) => {
            if (isTestEmail) {
                let templateId = response?.data?.templateId;
                emailTemplateService.sendEmail({ templateId: templateId }).then((response2) => { 
                    setShowSnackBar(true);
                    setSnackbarMessage('Email sent!');
                    setSnackbarType("success");
                    handleAddTemplateClose();
                    dispatch(setEditFormData(response.data));
                    dispatch(setEditFormVisibility(true));
                });
            }
            if (response?.success === true && !isTestEmail) {
                setShowSnackBar(true);
                setSnackbarMessage('Template has been added');
                setSnackbarType("success");
            }
            else {
                setShowSnackBar(true);
                setSnackbarMessage('Error adding template');
                setSnackbarType("error");
            }
           !isTestEmail &&  handleAddTemplateClose();
        })
    }

    useEffect(() => {
        fetchEmailTemplateList();
    }, [isEditFormVisible, isAddTemplateOpen])

    const fetchEmailTemplateList = async () => {
        try {
            setLoading(true);
            emailTemplateService.getAllEmailTemplateList()
                .then((response) => {
                    setFilteredEmailTemplateList(response?.data);
                    setLoading(false);
                });
        } catch (error) {
            console.error("Error fetching report:", error);
            setLoading(false);
        }

    };

    useEffect(() => {
        fetchEmailTemplateList();
    }, [userOrganizationId])


    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            <Box>
                {loading && <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                   <MemoRevenoidLoader />
                </Backdrop>}
                {!isEditFormVisible && (
                    <>
                        {!isAddTemplateOpen && (
                            <>
                                <Flex justifyContent={"space-between"} alignItems="center" marginBottom={"20px"}>
                                    <Flex>
                                        <Text fontWeight="400" fontSize="24px">Email Templates</Text>
                                    </Flex>
                                    <Flex>
                                        <Button
                                            variant="primary"
                                            iconName="add"
                                            size="sm"
                                            padding={"12px"}
                                            onClick={handleAddTemplateButtonClick}
                                        >
                                            Add a new template
                                        </Button>
                                    </Flex>
                                </Flex>
                                <EmailTemplateTableHeader filterDataFunc={filterData} />
                                <EmailTemplateTableBody data={filteredEmailTemplateList} fetchData={fetchEmailTemplateList} />
                            </>
                        )}
                        {isAddTemplateOpen && <AddTemplateForm onClose={handleAddTemplateClose} handleTemplate={handleAddTemplate} templateData={filteredEmailTemplateList} />}
                    </>
                )}
                {isEditFormVisible && <EditTemplateForm templateData={filteredEmailTemplateList} />}

            </Box>
        </>
    );
}

export default EmailTemplateTable;
