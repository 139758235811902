import * as React from "react";

function EvaLogo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="133" height="40" viewBox="0 0 133 40" fill="none">
        <path d="M11.3371 27.554C11.2524 27.6994 11.2003 27.8656 11.1906 28.0429L7.07413 28.8669L7.53869 23.7523L11.3371 27.554Z" fill="#BD99FF"/>
        <path d="M12.0101 27.0389C11.913 27.0653 11.8212 27.1042 11.7366 27.1539L7.80248 23.2163L11.5648 21.5427C11.6811 21.6799 11.8348 21.7844 12.0101 21.8405V27.0389Z" fill="#BD99FF"/>
        <path d="M12.8607 27.1439C12.7725 27.0942 12.6767 27.0561 12.5755 27.0315V21.8493C12.7591 21.7968 12.9205 21.6917 13.0424 21.5514L16.394 23.0389C16.3906 23.0708 16.3888 23.1031 16.3888 23.1359C16.3888 23.2773 16.4214 23.4111 16.4795 23.5302L12.8607 27.1439Z" fill="#BD99FF"/>
        <path d="M17.2894 22.2354C17.2987 22.2354 17.308 22.2355 17.3173 22.2358L18.3094 19.5938C18.197 19.5144 18.1 19.4146 18.024 19.2997L13.2799 20.8788C13.2803 20.8904 13.2805 20.902 13.2805 20.9136C13.2805 20.9548 13.278 20.9953 13.273 21.0351L16.6284 22.5243C16.7929 22.3466 17.0281 22.2354 17.2894 22.2354Z" fill="#BD99FF"/>
        <path d="M17.8484 22.4298C17.9818 22.5355 18.0845 22.6783 18.141 22.8424H22.7039L19.5018 19.6448C19.3384 19.7404 19.1482 19.7952 18.9452 19.7952C18.9096 19.7952 18.8744 19.7935 18.8396 19.7902L17.8484 22.4298Z" fill="#BD99FF"/>
        <path d="M18.1481 23.4079C18.0691 23.6578 17.8839 23.8606 17.6455 23.9633L18.0633 28.5532L22.7474 23.4079H18.1481Z" fill="#BD99FF"/>
        <path d="M17.0822 24.0125C17.009 23.9953 16.9393 23.9691 16.8742 23.9352L13.2674 27.5369C13.359 27.6877 13.4151 27.8619 13.4244 28.0483L17.5241 28.8671L17.0822 24.0125Z" fill="#BD99FF"/>
        <path d="M12.5755 29.1754C12.8951 29.0978 13.1606 28.8846 13.3059 28.6012L17.2315 29.3852L12.5755 33.5697V29.1754Z" fill="#BD99FF"/>
        <path d="M17.8437 18.7638L13.0965 20.3439C12.9703 20.1695 12.7878 20.0386 12.5755 19.9779V15.308L17.8806 18.3983C17.855 18.4916 17.8413 18.5898 17.8413 18.6913C17.8413 18.7156 17.8421 18.7398 17.8437 18.7638Z" fill="#BD99FF"/>
        <path d="M12.0101 15.3086V19.9867C11.8074 20.0516 11.6336 20.1812 11.513 20.3512L7.0255 18.8541C7.03164 18.8007 7.0348 18.7464 7.0348 18.6913C7.0348 18.5385 7.01047 18.3914 6.96552 18.2538L12.0101 15.3086Z" fill="#BD99FF"/>
        <path d="M6.84867 19.3912L11.3345 20.8877C11.3343 20.8963 11.3342 20.905 11.3342 20.9136C11.3342 20.9508 11.3363 20.9876 11.3403 21.0237L7.47118 22.7448L6.39375 19.8692C6.5807 19.7492 6.73714 19.585 6.84867 19.3912Z" fill="#BD99FF"/>
        <path d="M5.65493 20.0857C5.37295 20.0857 5.11073 20.0002 4.89225 19.8535L1.90594 22.8424H6.90392L5.86503 20.0696C5.79652 20.0802 5.72636 20.0857 5.65493 20.0857Z" fill="#BD99FF"/>
        <path d="M4.49666 19.4494C4.38381 19.2738 4.30905 19.0708 4.28418 18.8525L0 18.4604L1.34966 22.5992L4.49666 19.4494Z" fill="#BD99FF"/>
        <path d="M0.0151562 17.894L4.33331 18.2892C4.41429 18.017 4.57532 17.7799 4.78876 17.6058L2.31292 12.6753L0.0151562 17.894Z" fill="#BD99FF"/>
        <path d="M11.7831 14.7863L6.68464 17.7631C6.57983 17.6444 6.45523 17.544 6.31609 17.4671L7.98446 11.9275L11.7831 14.7863Z" fill="#BD99FF"/>
        <path d="M5.65493 17.2969C5.69547 17.2969 5.7356 17.2987 5.77524 17.3021L7.43795 11.7813L2.7332 12.2522L5.291 17.3459C5.40694 17.314 5.52896 17.2969 5.65493 17.2969Z" fill="#BD99FF"/>
        <path d="M18.1655 17.9099L12.8034 14.7863L16.6115 11.9269L18.3691 17.7495C18.295 17.7949 18.2267 17.8488 18.1655 17.9099Z" fill="#BD99FF"/>
        <path d="M18.911 17.5879L17.1582 11.7812L21.8749 12.2523L19.1774 17.6119C19.1025 17.5958 19.0249 17.5874 18.9452 17.5874C18.9338 17.5874 18.9223 17.5876 18.911 17.5879Z" fill="#BD99FF"/>
        <path d="M19.9835 18.3154C19.9207 18.1421 19.8158 17.9891 19.6812 17.8685L22.2956 12.674L24.5992 17.894L19.9835 18.3154Z" fill="#BD99FF"/>
        <path d="M19.9009 19.2442C19.9653 19.1332 20.0109 19.0098 20.0333 18.8786L24.6144 18.4604L23.2614 22.6L19.9009 19.2442Z" fill="#BD99FF"/>
        <path d="M38.2552 14.7332H36.6938C36.6776 15.7741 36.6613 16.815 36.645 17.8559C36.645 18.8968 36.645 19.9377 36.645 20.9786H38.06C39.3286 20.9786 40.2313 20.6696 40.768 20.0515C41.321 19.4335 41.5974 18.6772 41.5974 17.7827C41.5974 15.7497 40.4834 14.7332 38.2552 14.7332ZM31.4243 14.6356V13.489H38.7675C40.6704 13.489 42.1342 13.8549 43.1588 14.5868C44.1997 15.3187 44.7201 16.3352 44.7201 17.6363C44.7201 18.2218 44.5738 18.7911 44.281 19.344C44.0045 19.897 43.5573 20.3768 42.9392 20.7834C42.3212 21.19 41.508 21.4746 40.4996 21.6373C41.5243 21.7999 42.2805 22.1577 42.7685 22.7107C43.2727 23.2637 43.6386 24.0688 43.8663 25.1259L44.6226 28.3218L46.5011 28.517V29.6636C46.0945 29.7775 45.7367 29.8506 45.4276 29.8832C45.1349 29.932 44.7527 29.9564 44.281 29.9564C43.3865 29.9564 42.6871 29.81 42.183 29.5172C41.695 29.2245 41.386 28.704 41.2559 27.9559L40.5484 24.5648C40.3695 23.654 40.0605 23.036 39.6214 22.7107C39.1985 22.3692 38.5561 22.1984 37.6941 22.1984H36.645C36.645 23.2881 36.645 24.3371 36.645 25.3455C36.6613 26.3376 36.6776 27.3216 36.6938 28.2974L38.7675 28.517V29.6636H31.4243V28.517L33.4735 28.2974C33.5061 27.2891 33.5223 26.2644 33.5223 25.2235C33.5223 24.1826 33.5223 23.1417 33.5223 22.1008V21.0518C33.5223 20.0271 33.5223 19.0025 33.5223 17.9779C33.5223 16.937 33.5061 15.8961 33.4735 14.8552L31.4243 14.6356Z" fill="white"/>
        <path d="M53.0476 18.8073C52.6573 18.8073 52.2669 18.9374 51.8766 19.1977C51.5025 19.4416 51.1772 19.8482 50.9008 20.4175C50.6405 20.9867 50.4779 21.7593 50.4128 22.7351H53.7551C54.3406 22.7351 54.7309 22.6131 54.9261 22.3692C55.1213 22.1252 55.2189 21.7837 55.2189 21.3445C55.2189 20.5964 55.0074 19.9865 54.5846 19.5148C54.1617 19.0432 53.6494 18.8073 53.0476 18.8073ZM53.0476 30.0052C51.9091 30.0052 50.9089 29.7612 50.0469 29.2733C49.1849 28.7854 48.5099 28.086 48.022 27.1752C47.5341 26.2482 47.2901 25.1503 47.2901 23.8817C47.2901 22.5806 47.5666 21.4665 48.1196 20.5395C48.6888 19.5961 49.4207 18.8805 50.3153 18.3926C51.226 17.8884 52.1937 17.6363 53.2184 17.6363C54.178 17.6363 55.0156 17.8396 55.7312 18.2462C56.4468 18.6528 56.9998 19.2221 57.3901 19.954C57.7967 20.6858 58 21.5478 58 22.5399C58 23.0604 57.9512 23.5077 57.8536 23.8817H50.3884C50.4535 25.2804 50.8194 26.3295 51.4863 27.0288C52.1694 27.7282 53.0232 28.0779 54.0479 28.0779C54.7635 28.0779 55.3652 27.9396 55.8532 27.6631C56.3574 27.3704 56.8128 26.9963 57.2194 26.5409L57.9268 27.1752C57.4552 28.0697 56.8046 28.7691 55.9751 29.2733C55.1619 29.7612 54.1861 30.0052 53.0476 30.0052Z" fill="white"/>
        <path d="M66.5579 19.0025V17.9779H71.1932V19.0025L69.827 19.2953L65.826 29.7612H64.3867L59.971 19.2221L58.7024 19.0025V17.9779H65.1185V19.0025L63.2401 19.2953L65.9236 26.3701L68.3388 19.3197L66.5579 19.0025Z" fill="white"/>
        <path d="M77.4437 18.8073C77.0534 18.8073 76.6631 18.9374 76.2727 19.1977C75.8987 19.4416 75.5734 19.8482 75.2969 20.4175C75.0367 20.9867 74.874 21.7593 74.809 22.7351H78.1512C78.7367 22.7351 79.1271 22.6131 79.3222 22.3692C79.5174 22.1252 79.615 21.7837 79.615 21.3445C79.615 20.5964 79.4036 19.9865 78.9807 19.5148C78.5578 19.0432 78.0455 18.8073 77.4437 18.8073ZM77.4437 30.0052C76.3052 30.0052 75.305 29.7612 74.443 29.2733C73.581 28.7854 72.9061 28.086 72.4181 27.1752C71.9302 26.2482 71.6863 25.1503 71.6863 23.8817C71.6863 22.5806 71.9627 21.4665 72.5157 20.5395C73.085 19.5961 73.8168 18.8805 74.7114 18.3926C75.6222 17.8884 76.5899 17.6363 77.6145 17.6363C78.5741 17.6363 79.4117 17.8396 80.1273 18.2462C80.8429 18.6528 81.3959 19.2221 81.7862 19.954C82.1928 20.6858 82.3961 21.5478 82.3961 22.5399C82.3961 23.0604 82.3474 23.5077 82.2498 23.8817H74.7846C74.8496 25.2804 75.2156 26.3295 75.8824 27.0288C76.5655 27.7282 77.4193 28.0779 78.444 28.0779C79.1596 28.0779 79.7614 27.9396 80.2493 27.6631C80.7535 27.3704 81.2089 26.9963 81.6155 26.5409L82.323 27.1752C81.8513 28.0697 81.2007 28.7691 80.3713 29.2733C79.5581 29.7612 78.5822 30.0052 77.4437 30.0052Z" fill="white"/>
        <path d="M84.1685 29.6636V28.6634L85.7542 28.3462C85.7705 27.7444 85.7786 27.0939 85.7786 26.3945C85.7786 25.6789 85.7786 25.0609 85.7786 24.5404V23.2474C85.7786 22.5806 85.7705 22.052 85.7542 21.6617C85.7542 21.2551 85.738 20.8078 85.7054 20.3199L83.9977 20.1003V19.1733L88.0719 17.6363L88.4622 17.8803L88.633 19.7588C89.8853 18.3438 91.2678 17.6363 92.7803 17.6363C93.8375 17.6363 94.6507 17.986 95.2199 18.6854C95.8054 19.3847 96.0982 20.4907 96.0982 22.0032V24.5404C96.0982 25.0934 96.0982 25.7196 96.0982 26.4189C96.0982 27.1183 96.1063 27.7688 96.1226 28.3706L97.6108 28.6634V29.6636H91.5849V28.6634L93.1463 28.3462C93.1625 27.7444 93.1707 27.102 93.1707 26.4189C93.1707 25.7196 93.1707 25.0934 93.1707 24.5404V22.2228C93.1707 21.2632 93.0405 20.6045 92.7803 20.2467C92.5201 19.8726 92.081 19.6856 91.4629 19.6856C90.6009 19.6856 89.6901 20.0841 88.7306 20.881V24.5404C88.7306 25.0771 88.7306 25.6952 88.7306 26.3945C88.7306 27.0939 88.7387 27.7526 88.755 28.3706L90.1943 28.6634V29.6636H84.1685Z" fill="white"/>
        <path d="M105.035 30.0052C103.929 30.0052 102.929 29.7612 102.034 29.2733C101.156 28.7854 100.457 28.0779 99.9364 27.1508C99.4159 26.2238 99.1557 25.1178 99.1557 23.8329C99.1557 22.5318 99.4241 21.4177 99.9608 20.4907C100.514 19.5636 101.238 18.8561 102.132 18.3682C103.027 17.8803 103.994 17.6363 105.035 17.6363C106.076 17.6363 107.044 17.8803 107.938 18.3682C108.833 18.8561 109.557 19.5636 110.11 20.4907C110.663 21.4015 110.939 22.5155 110.939 23.8329C110.939 25.1341 110.671 26.2482 110.134 27.1752C109.614 28.086 108.906 28.7854 108.012 29.2733C107.133 29.7612 106.141 30.0052 105.035 30.0052ZM105.035 28.8097C105.913 28.8097 106.58 28.4031 107.036 27.5899C107.507 26.7767 107.743 25.5325 107.743 23.8573C107.743 20.5069 106.84 18.8317 105.035 18.8317C103.246 18.8317 102.352 20.5069 102.352 23.8573C102.352 25.5325 102.579 26.7767 103.035 27.5899C103.49 28.4031 104.157 28.8097 105.035 28.8097Z" fill="white"/>
        <path d="M112.614 29.6636V28.6634L114.224 28.3462C114.24 27.7444 114.248 27.0939 114.248 26.3945C114.248 25.6789 114.248 25.0609 114.248 24.5404V23.3938C114.248 22.727 114.24 22.174 114.224 21.7349C114.224 21.2795 114.207 20.8078 114.175 20.3199L112.443 20.1003V19.1733L116.834 17.6363L117.224 17.8803L117.151 21.3201V24.5404C117.151 25.0609 117.151 25.6789 117.151 26.3945C117.151 27.0939 117.159 27.7526 117.176 28.3706L118.664 28.6634V29.6636H112.614ZM115.688 15.3675C115.167 15.3675 114.728 15.2048 114.37 14.8796C114.012 14.5543 113.833 14.1395 113.833 13.6354C113.833 13.1149 114.012 12.692 114.37 12.3668C114.728 12.0415 115.167 11.8788 115.688 11.8788C116.208 11.8788 116.639 12.0415 116.981 12.3668C117.338 12.692 117.517 13.1149 117.517 13.6354C117.517 14.1395 117.338 14.5543 116.981 14.8796C116.639 15.2048 116.208 15.3675 115.688 15.3675Z" fill="white"/>
        <path d="M123.339 23.8817C123.339 25.3618 123.616 26.4677 124.169 27.1996C124.738 27.9152 125.453 28.273 126.315 28.273C126.738 28.273 127.121 28.1917 127.462 28.0291C127.82 27.8502 128.153 27.5981 128.462 27.2728V20.2711C127.747 19.6531 127.039 19.344 126.34 19.344C125.527 19.344 124.819 19.71 124.217 20.4419C123.632 21.1738 123.339 22.3204 123.339 23.8817ZM128.755 29.932L128.56 28.395C128.105 28.9155 127.6 29.3139 127.047 29.5904C126.494 29.8669 125.86 30.0052 125.144 30.0052C124.185 30.0052 123.331 29.7612 122.583 29.2733C121.851 28.7854 121.274 28.086 120.851 27.1752C120.428 26.2644 120.216 25.1829 120.216 23.9305C120.216 22.6457 120.452 21.5316 120.924 20.5883C121.412 19.6449 122.054 18.9212 122.851 18.417C123.664 17.8965 124.551 17.6363 125.51 17.6363C126.128 17.6363 126.681 17.7502 127.169 17.9779C127.674 18.2056 128.145 18.5796 128.584 19.1001V14.0745L126.681 13.8305V12.8791L131.146 11.7812L131.512 12.0008L131.414 15.4407V28.395L133 28.6634V29.6636L128.755 29.932Z" fill="white"/>
        </svg>

  );
}

export default EvaLogo;
