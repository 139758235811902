import Button from 'components/Elements/Button';
import React, { useEffect, useState } from 'react';
import { axiosHelperCore } from "utils";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { TextField, Backdrop } from "@mui/material";
import { useAppSelector } from 'hooks';
import MemoRevenoidLoader from 'components/RevenoidLoader/RevenoidLoader';

const SalesloftForm = ({ isDualMode, mode, isFieldAdded, setIsFieldsAdded, setShowForm, setSalesloftText }) => {

    const [loading, setLoading] = useState(false);
    const [accountFieldData, setAccountFieldData] = useState({ accountScoreField: "", accountSummaryField: "" });
    const [prospectFieldData, setProspectFieldData] = useState({ tagField: "evabot", callPrepField: "", prospectScore: "" });
    const [IsError, setIsError] = useState(false);

    const orgId = useAppSelector(
        (state) => state.profileState.profileData.organizationId
    );

    const validateFields = () => {
        const fields = [
            accountFieldData?.accountScoreField,
            accountFieldData?.accountSummaryField,
            prospectFieldData?.tagField,
            prospectFieldData?.callPrepField,
            prospectFieldData?.prospectScore
        ];

        const normalizedFields = fields.map(field =>
            (field !== undefined && field !== null && field.trim() !== '') ? field.trim().toLowerCase() : field
        );

        const filteredFields = normalizedFields.filter(field => field !== undefined && field !== null && field !== '');

        const uniqueFields = new Set(filteredFields);

        if (uniqueFields.size !== filteredFields.length) {
            return false;
        }

        return true;
    }

    const cancel = () => {
        setIsFieldsAdded(true);
        setShowForm(false);
    }
    const getSalesloftData = async () => {
        setLoading(true);
        try {
            const result = await axiosHelperCore({
                url: `salesloft/getData`,
                method: "POST",
                JSONData: { organizationId: orgId }
            });

            console.log("resultresultresult", result);

            if (result?.data?.salesloftData) {
                const salesloftData = result?.data?.salesloftData;
                setAccountFieldData({ accountScoreField: salesloftData?.accountScore, accountSummaryField: salesloftData?.accountSummary });
                setProspectFieldData({ tagField: salesloftData?.tag || "evabot", callPrepField: salesloftData?.callPrep, prospectScore: salesloftData?.prospectScore });
                const salesloftText = salesloftData?.callPrep ? "Connected" : "Connection Pending";
                setSalesloftText(salesloftText);
            }
        } catch (error) {
            console.error("Error fetching outreach field data:", error);
        } finally {
            setLoading(false);
        }
    };

    const saveSalesloftData = async () => {
        if (!validateFields()) {
            setIsError(true);
            const div = document.getElementById('scrollableDiv');
            div.scrollTop = 0;
            return;
        }
        setLoading(true);
        try {
            await axiosHelperCore({
                url: `salesloft/saveData`,
                method: "POST",
                JSONData: { accountData: accountFieldData, prospectData: prospectFieldData }
            });
            setIsFieldsAdded(true);
            setShowForm(false);
            setSalesloftText("Connected");
        } catch (error) {
            console.error("Error saving outreach field data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (orgId) {
            getSalesloftData();
        }
    }, [orgId]);

    useEffect(() => {
        setShowForm(true);
    }, []);

    if (isDualMode && !mode)
        return (<>  </>)

    return (
        <>
            {loading && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <MemoRevenoidLoader />
                </Backdrop>
            )}
            <div id='scrollableDiv' className="text-left" style={{ width: "90%", margin: "0 auto", maxHeight: "40vh", scrollbarWidth: "none", overflowY: "auto" }} >


                {mode === "write" && (<div>
                    {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                    <div>

                        <div>Prospect Fields</div>
                        <div style={{ padding: "10px" }}>

                            <div>
                                <div className='text-sm text-[#57585C] mt-1 mb-1'>Call Prep</div>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                    value={prospectFieldData.callPrepField || ''}
                                    onChange={(e) => setProspectFieldData({ ...prospectFieldData, callPrepField: e.target.value })}
                                    variant="outlined"
                                    inputProps={{ style: { padding: '10px', margin: 0 } }}
                                />
                            </div>
                            <div>
                                <div className='text-sm text-[#57585C] mt-1 mb-1'>Prospect Account Score</div>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                    value={prospectFieldData.prospectScore || ''}
                                    onChange={(e) => setProspectFieldData({ ...prospectFieldData, prospectScore: e.target.value })}
                                    variant="outlined"
                                    inputProps={{ style: { padding: '10px', margin: 0 } }}
                                />
                            </div>
                        </div>

                        <div>Account Fields</div>
                        <div style={{ padding: "10px" }}>
                            <div>
                                <div className='text-sm text-[#57585C] mt-1 mb-1'>Account Score</div>
                                <TextField
                                    margin="normal"
                                    style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                    fullWidth
                                    value={accountFieldData.accountScoreField || ''}
                                    onChange={(e) => setAccountFieldData({ ...accountFieldData, accountScoreField: e.target.value })}
                                    variant="outlined"
                                    inputProps={{ style: { padding: '10px', margin: 0 } }}
                                />
                            </div>
                            <div>
                                <div className='text-sm text-[#57585C] mt-1 mb-1'>Account Summary</div>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                    value={accountFieldData.accountSummaryField || ''}
                                    onChange={(e) => setAccountFieldData({ ...accountFieldData, accountSummaryField: e.target.value })}
                                    variant="outlined"
                                    inputProps={{ style: { padding: '10px', margin: 0 } }}
                                />
                            </div>
                        </div>
                    </div>
                </div>)}
                {
                    (!mode || mode === "all") && (<div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div >
                            <div className='mb-2'>Prospect Fields</div>
                            <div style={{ padding: "10px" }}>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Tag</div>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={prospectFieldData.tagField || ''}
                                        onChange={(e) => setProspectFieldData({ ...prospectFieldData, tagField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Call Prep</div>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={prospectFieldData.callPrepField || ''}
                                        onChange={(e) => setProspectFieldData({ ...prospectFieldData, callPrepField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Prospect Account Score</div>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={prospectFieldData.prospectScore || ''}
                                        onChange={(e) => setProspectFieldData({ ...prospectFieldData, prospectScore: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="mt-3">
                            <div className='mb-2'>Account Fields</div>
                            <div style={{ padding: "10px" }}>
                               
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Account Score</div>
                                    <TextField
                                        margin="normal"
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        fullWidth
                                        value={accountFieldData.accountScoreField || ''}
                                        onChange={(e) => setAccountFieldData({ ...accountFieldData, accountScoreField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Account Summary</div>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={accountFieldData.accountSummaryField || ''}
                                        onChange={(e) => setAccountFieldData({ ...accountFieldData, accountSummaryField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                }

                {
                    (mode === "read") && (<div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div >
                            <div className='mb-2'>Prospect Fields</div>
                            <div style={{ padding: "10px" }}>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Tag</div>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={prospectFieldData.tagField || ''}
                                        onChange={(e) => setProspectFieldData({ ...prospectFieldData, tagField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                }




                <div className="mt-3 text-center flex flex-wrap space-x-2 justify-center">
                    <Button variant={"salesforce"} text={isFieldAdded ? "Save" : "Save"} onClick={saveSalesloftData} />
                    <Button variant={"secondary"} text={"Cancel"} onClick={cancel} />
                </div>
            </div>
        </>
    );
};

export default SalesloftForm;
