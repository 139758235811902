import * as React from 'react';

function TwentyFourSevenLogo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="12" viewBox="0 0 60 12" fill="none">
            <g clip-path="url(#clip0_210_15402)">
            <path d="M38.6209 3.05689H34.0684V1.16406H40.9548V2.97339L37.0227 10.8602H34.5925L38.6209 3.05689Z" fill="white"/>
            <path d="M14.4277 8.69211L18.247 5.30542C18.4537 5.119 18.6491 4.92071 18.8322 4.71159C19.0232 4.5006 19.1272 4.22668 19.1237 3.94379C19.1292 3.79587 19.0989 3.64878 19.0351 3.5148C18.9713 3.38083 18.876 3.26386 18.757 3.17367C18.5154 2.98639 18.2157 2.88723 17.9086 2.89299C17.7398 2.88451 17.5713 2.91203 17.4143 2.97368C17.2573 3.03532 17.1157 3.12965 16.999 3.25022C16.7831 3.49433 16.6572 3.80336 16.6417 4.12704L14.5594 3.97627C14.5743 3.52246 14.6831 3.07647 14.879 2.66567C15.0536 2.30766 15.3045 1.99114 15.6146 1.73781C15.9317 1.4861 16.2959 1.2985 16.6864 1.18573C17.1212 1.05894 17.5727 0.996408 18.0261 1.00016C18.4501 0.997173 18.872 1.05979 19.2764 1.18573C19.6486 1.29941 19.9938 1.48555 20.2918 1.73317C20.5842 1.98227 20.8149 2.2941 20.9663 2.64479C21.1333 3.04943 21.2133 3.48386 21.2014 3.9206C21.2057 4.20481 21.1741 4.48844 21.1073 4.76495C21.0483 5.00753 20.9575 5.24147 20.8371 5.46084C20.7225 5.66467 20.5854 5.85528 20.4281 6.02915C20.2659 6.20746 20.0941 6.37704 19.9134 6.53716L16.9285 9.06093H21.2907V10.8703H14.4277V8.68979V8.69211Z" fill="white"/>
            <path d="M39.2041 10.2626C39.2039 10.1742 39.2215 10.0867 39.2558 10.0051C39.2881 9.92621 39.3361 9.85444 39.3968 9.79403C39.4583 9.73435 39.5309 9.68708 39.6107 9.65486C39.7782 9.58901 39.965 9.58901 40.1325 9.65486C40.2123 9.68708 40.2849 9.73435 40.3464 9.79403C40.4071 9.85444 40.4551 9.92621 40.4874 10.0051C40.5558 10.1701 40.5558 10.3551 40.4874 10.5201C40.4553 10.5991 40.4074 10.671 40.3464 10.7312C40.2851 10.7911 40.2124 10.8385 40.1325 10.8704C39.965 10.9362 39.7782 10.9362 39.6107 10.8704C39.5308 10.8385 39.458 10.7911 39.3968 10.7312C39.3358 10.671 39.2878 10.5991 39.2558 10.5201C39.2215 10.4385 39.2039 10.351 39.2041 10.2626Z" fill="white"/>
            <path d="M44.9347 10.2604H44.9136C44.7517 10.5155 44.5123 10.7135 44.2296 10.8264C43.7301 11.0264 43.1767 11.0534 42.6596 10.903C42.4532 10.8426 42.2602 10.7442 42.0909 10.613C41.9267 10.485 41.7934 10.3226 41.7007 10.1375C41.6013 9.93198 41.5522 9.70633 41.5573 9.47869C41.5498 9.22966 41.6047 8.98264 41.7172 8.7596C41.8206 8.56736 41.9678 8.40141 42.1473 8.27479C42.3362 8.14243 42.5458 8.04136 42.7677 7.97556C43.0042 7.90338 43.2464 7.85062 43.4916 7.81782C43.7403 7.78453 43.9906 7.7644 44.2414 7.75751C44.4921 7.75751 44.7232 7.75751 44.9347 7.75751C44.9396 7.63526 44.9156 7.51356 44.8646 7.40204C44.8136 7.29052 44.7371 7.19224 44.641 7.11497C44.4453 6.95847 44.1994 6.87619 43.9476 6.883C43.707 6.8801 43.4693 6.93419 43.2543 7.04074C43.0417 7.1482 42.8519 7.29462 42.6949 7.47219L41.8746 6.64176C42.1643 6.37731 42.507 6.1758 42.8806 6.05025C43.2636 5.91849 43.6665 5.85182 44.0722 5.85308C44.4507 5.84133 44.8283 5.89793 45.1862 6.02009C45.4623 6.1175 45.7061 6.28755 45.8913 6.51186C46.071 6.73935 46.1956 7.00435 46.2556 7.28662C46.3284 7.64471 46.3631 8.00933 46.359 8.37454V10.8774H44.9488V10.2604H44.9347ZM44.554 8.72016C44.4388 8.72016 44.2931 8.72016 44.1192 8.73408C43.9488 8.74362 43.78 8.77242 43.6162 8.81991C43.4655 8.86198 43.3254 8.93467 43.2049 9.03332C43.1483 9.08209 43.1036 9.14294 43.0743 9.21125C43.045 9.27957 43.0318 9.35357 43.0357 9.42766C43.0318 9.51036 43.0518 9.59245 43.0934 9.66434C43.135 9.73622 43.1965 9.79493 43.2707 9.8336C43.4207 9.91949 43.5909 9.96508 43.7643 9.96582C43.9114 9.96632 44.0578 9.94601 44.1991 9.9055C44.3329 9.86854 44.4599 9.81058 44.5751 9.73385C44.6866 9.6619 44.7792 9.56492 44.8454 9.45085C44.9125 9.32601 44.9457 9.18617 44.9418 9.04492V8.72016H44.561H44.554Z" fill="white"/>
            <path d="M47.2119 4.40742C47.2121 4.26256 47.2485 4.11999 47.3179 3.99233C47.3872 3.86466 47.4874 3.75582 47.6095 3.67546C47.7316 3.59509 47.8719 3.54566 48.018 3.53154C48.1641 3.51742 48.3114 3.53905 48.447 3.59452C48.5826 3.64998 48.7022 3.73757 48.7954 3.84954C48.8885 3.96151 48.9522 4.0944 48.9809 4.23646C49.0096 4.37852 49.0024 4.52538 48.9599 4.66403C48.9174 4.80268 48.8409 4.92886 48.7373 5.0314C48.6124 5.15496 48.4531 5.23914 48.2797 5.27326C48.1062 5.30739 47.9264 5.28993 47.7631 5.2231C47.5997 5.15627 47.4601 5.04308 47.362 4.89786C47.2639 4.75265 47.2116 4.58196 47.2119 4.40742ZM47.3365 5.95694H48.8759V10.8816H47.3365V5.95926V5.95694Z" fill="white"/>
            <path d="M26.5969 8.92096H22.2441V7.19515L25.3184 1.16406H28.5971V7.19515H29.8615V8.92328H28.5971V10.8695H26.5969V8.92096ZM26.5382 3.48603H26.4442L24.5757 7.19747H26.5969L26.5382 3.48603Z" fill="white"/>
            <path d="M30.5215 1.16406H32.7731V10.8579H30.5215V10.2037H31.7907V1.8182H30.5215V1.16406Z" fill="white"/>
            <path d="M13.2526 1.8182H11.9834V10.2037H13.2526V10.8579H11.001V1.16406H13.2526V1.8182Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_210_15402">
            <rect width="38" height="10" fill="white" transform="translate(11 1)"/>
            </clipPath>
            </defs>
        </svg>

    )
}

const TwentyFourSevenLogoIcon = React.memo(TwentyFourSevenLogo);
export default TwentyFourSevenLogoIcon;