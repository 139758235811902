import * as React from "react";

  
  function ManagePersonaIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0485 12L19.0486 13.2845C19.6338 13.4914 20.1689 13.804 20.6311 14.1996L21.7447 13.5566L23.4114 16.4434L22.2985 17.0859C22.3533 17.3823 22.3819 17.6878 22.3819 18C22.3819 18.3122 22.3533 18.6177 22.2985 18.9141L23.4114 19.5566L21.7447 22.4434L20.6311 21.8004C20.1689 22.196 19.6338 22.5086 19.0486 22.7155L19.0485 24H15.7152L15.7152 22.7155C15.13 22.5086 14.5948 22.196 14.1327 21.8005L13.019 22.4434L11.3524 19.5566L12.4652 18.9141C12.4105 18.6177 12.3819 18.3122 12.3819 18C12.3819 17.6878 12.4105 17.3823 12.4652 17.0859L11.3524 16.4434L13.019 13.5566L14.1326 14.1996C14.5948 13.804 15.13 13.4914 15.7152 13.2845L15.7152 12H19.0485ZM11.0884 10.8C12.1816 10.8 13.2098 11.0864 14.1059 11.5902C11.7798 12.7837 10.1884 15.206 10.1884 18C10.1884 19.3114 10.539 20.5409 11.1516 21.6L2.87068 21.6C1.6102 21.6 0.588379 20.5782 0.588379 19.3177V17.28C0.588379 13.7907 3.26972 10.9455 6.62869 10.8054L6.88838 10.8H11.0884ZM17.3819 16C16.2773 16 15.3819 16.8954 15.3819 18C15.3819 19.1046 16.2773 20 17.3819 20C18.4864 20 19.3819 19.1046 19.3819 18C19.3819 16.8954 18.4864 16 17.3819 16ZM8.98838 0C11.308 0 13.1884 1.8804 13.1884 4.2C13.1884 6.44228 11.4312 8.27415 9.21882 8.39379L8.98838 8.4C6.66878 8.4 4.78838 6.5196 4.78838 4.2C4.78838 1.95772 6.54551 0.125848 8.75794 0.00621464L8.98838 0Z" fill="white"/>
        </svg>
    );
  }

export default ManagePersonaIcon;
