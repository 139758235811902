import { useState } from "react";
import { Typography, Box } from "@mui/material";

interface SmartTextProps {
  text: string;
  length: number;
  color?: string;
  style?: any;
}

const SmartText = ({
  text = "",
  length = 20,
  color,
  style,
}: SmartTextProps) => {
  const [showLess, setShowLess] = useState(true);
  if (text && text?.length < length) {
    return (
      <Box>
        <Typography
          color={color || "#57585C"}
          fontSize={"13px"}
          fontFamily={'"Inter"'}
          sx={{ textOverflow: "ellipsis", overflow: "hidden", ...style }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: showLess ? `${text?.slice(0, length)}` : text,
            }}
          />
        </Typography>
        {!showLess && (
          <Typography
            color={"#0094E3"}
            onClick={() => setShowLess(!showLess)}
            component="span"
            fontSize={"13px"}
            sx={{ cursor: "pointer" }}
          >
            &nbsp; show less
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <>
      <Typography
        component="span"
        color={color || "#57585C"}
        style={{
          textOverflow: "ellipsis",
          fontFamily: '"Inter"',
          fontSize: "13px",
          overflow: "hidden",
          ...style,
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: showLess ? `${text?.slice(0, length)}...` : text,
          }}
        />
        <Typography
          color={"#0094E3"}
          fontSize={"13px"}
          fontWeight={600}
          onClick={() => setShowLess(!showLess)}
          component="span"
          style={style}
          sx={{ cursor: "pointer" }}
        >
          &nbsp; {showLess ? "view more" : ""}
        </Typography>

        {!showLess && (
          <Typography
            color={"#0094E3"}
            onClick={() => setShowLess(!showLess)}
            component="span"
            fontSize={"13px"}
            sx={{ cursor: "pointer" }}
          >
            {" "}
            &nbsp;{showLess ? "" : "show less"}{" "}
          </Typography>
        )}
      </Typography>
    </>
  );
};

export default SmartText;
