import React,{ FC, useState, useEffect, useRef } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { useAppSelector, useAppDispatch } from "hooks";
import { setSelectedNudge } from "store/globalStateReducer";
import { useNavigate } from "react-router-dom";
import { axiosHelper } from "utils";
import { Backdrop } from "@mui/material";
import {
  format,
  differenceInDays,
  differenceInSeconds,
  subMonths,
  getUnixTime,
} from "date-fns";
import { useParams } from "react-router-dom";
import { setSelectedNudge as sn } from "store/globalStateReducer";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { setSelectedNudgeForUnlike } from "../AEReturnDashboard/store/aeDashboardReducer";
import { AeReturnUnlikeModal } from "./../../components/AeReturnUnlikeModal/AeReturnUnlikeModal";
import BirthdayCard from "containers/AEReturnDashboard/StakeholderProfileComps/BirthdayCard";
import {
  getCoffeeString,
  getAlcoholString,
  getOtherC1String,
  getCuisineString,
  capitalizeString,
  getC4MappedString,
} from "utils/preferenceMapper";
import SendTreatModal from "../AEReturnDashboard/SendTreatModal";
import SendSportsModal from "../AEReturnDashboard/SendSportsModal";
import TimerIcon from "../AEReturnDashboard/BirthdayNudge/TimerIcon";
import { Timer } from "components/Timer/Timer";
import StakeholderProfileNudgeCard from "./StakeholderProfileNudgeCard";
import BirthdayIcon from "assets/icons/BirthdayIcon";
import PhoneIcon from "assets/icons/PhoneIcon.png";
import parse from "html-react-parser";
import AccessDeniedModal from "components/AccessDeniedModal/AccessDeniedModal";

import Carousel from "./Carousel/Carousel";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";
interface StakeholderProfileProps {
  searchQuery: any;
}

const StakeholderProfile: FC<StakeholderProfileProps> = ({ searchQuery }) => {
  const feedRef = useRef();
  const [showSportsModal, setShowSportsModal] = useState(false);
  const [copiedIds, setCopiedIds] = useState([]);
  const [showTreatModal, setShowTreatModal] = useState(false);
  const [hideAccessDeniedModal, setHideAccessDeniedModal] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { stakeholderId } = useParams();
  const loggedInMember = useAppSelector(
    (state) => state?.profileState?.profileData
  );
  const [weather, setWeather] = useState([]);

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarState, setSnackbarState] = useState("success");

  const [userInfo, setUserInfo] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [nudges, setNudges] = useState([]);
  const [thumsUpIds, setThumsUpIds] = useState([]);
  const [thumsDownIds, setThumsDownIds] = useState([]);
  const [hide, setHide] = useState(true);
  const [personName, setPersonName] = useState("");
  const [sendTreatOrder, setSendTreatOrder] = useState(null);
  const [fangearOrder, setFangearOrder] = useState(null);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const getHeadlineStr = (nudge) => {
    let text = nudge?.nudgeHeadline;

    let substrings = nudge?.recHighlight;
    substrings = substrings.filter((sub) => sub.trim() != "");
    substrings.forEach((substr, i) => {
      console.log("substring", substr);
      substr = substr.replace(/[^\w\s]/gi, "");
      let pattern = new RegExp(substr, "gi");

      text = text.replace(
        pattern,
        `<span style="font-family:'Inter-Bold'" > ${capitalizeString(
          substr
        )} </span>`
      );
    });

    return (
      <Text
        color={"#050505"}
        fontWeight={"300"}
        fontSize={"20px"}
        lineHeight={"140%"}
      >
        {parse(text)}
      </Text>
    );
  };
  const fetchWeatherAPI = async () => {
    try {
      const result = await axiosHelper({
        url: `salesforce/getWeather/${stakeholderId}`,
      });

      if (result.data == 0) {
        // displayError('Unable to fetch Weather!')
      } else {
        setCurrentSlide(result.data.length - 8);
        setWeather(result.data);
      }
    } catch (err) {
      // displayError('Error while fetching weather details.')
    }
  };
  const fetchCurrentOrderAPI = async () => {
    try {
      const promises = [
        axiosHelper({
          url: `orders/getActiveOrder/treat/${stakeholderId}`,
        }),
        axiosHelper({
          url: `orders/getActiveOrder/fanGear/${stakeholderId}`,
        }),
      ];
      const result = await Promise.all(promises);
      let treat = result[0];
      let fanGear = result[1];

      if (treat.data.success) {
        console.log("setSendTreatOrder is set");
        setSendTreatOrder(treat.data.order);
      } else if (
        !treat.data.success &&
        treat.data.error === "ORDER_NOT_FOUND"
      ) {
        setSendTreatOrder(null);
      }
      if (fanGear.data.success) {
        setFangearOrder(fanGear.data.order);
      } else if (
        !fanGear.data.success &&
        fanGear.data.error === "ORDER_NOT_FOUND"
      ) {
        setFangearOrder(null);
      }
    } catch (err) {
      console.log(err);
      // displayError('Error while fetching weather details.')
    }
  };
  const fetchstakeholderInfoAPI = async () => {
    try {
      const result = await axiosHelper({
        url: `salesforce/getStakeholderInfoForStakeholderProfilePage/${stakeholderId}`,
      });
      // console.log("user info",result?.data);
      setUserInfo(result?.data);
    } catch (err) {
      // displayError('Error while fetching weather details.')
    }
  };

  const cancelOrder = async (orderType) => {
    try {
      const result = await axiosHelper({
        url: `orders/cancelOrder?orderType=${orderType}&stakeholderId=${stakeholderId}`,
        method: "DELETE",
      });
      if (result.data.success) {
        setSnackbarState("success");
        setSnackbarMessage("Order is successfully cancelled.");
        setShowSnackBar(true);
        fetchCurrentOrderAPI();
      } else if (result.data.error === "CANCEL_TIME_LIMIT_EXCEEDED") {
        setSnackbarState("error");
        setSnackbarMessage(
          "This order cannot be cancelled after 30 minutes from ordering."
        );
        setShowSnackBar(true);
      } else if (result.data.error === "ORDER_NOT_FOUND") {
        setSnackbarState("error");
        setSnackbarMessage("Order not found.");
        setShowSnackBar(true);
      }
    } catch (err) {
      setSnackbarState("error");
      setSnackbarMessage("There was a problem cancelling this order.");
      setShowSnackBar(true);
    }
  };

  const fetchNudgesAPI = async () => {
    try {
      const result = await axiosHelper({
        url: `salesforce/getFilteredNudgeData/${stakeholderId}`,
      });
      let data = result.data.data;
      // console.log("nudgesss",result?.data?.data);
      setNudges(data);
      setPersonName(result.data.name);
    } catch (err) {}
  };

  const verifyAccessForUser = async () => {
    axiosHelper({
      url: `salesforce/verifyStakeholderPageUser/${stakeholderId}`,
    })
      .then((result) => {
        if (result.data.success) {
          Promise.all([
            fetchWeatherAPI(),
            fetchstakeholderInfoAPI(),
            fetchNudgesAPI(),
            fetchCurrentOrderAPI(),
          ]).then((result) => {
            setShowBackDrop(false);
          });
        } else {
          setShowBackDrop(false);
          setHideAccessDeniedModal(!hideAccessDeniedModal);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowBackDrop(false);
        setHideAccessDeniedModal(!hideAccessDeniedModal);
      });
  };

  useEffect(() => {
    console.log("selectedNudge.stakeholderId", stakeholderId);
    if (stakeholderId) {
      setShowBackDrop(true);
      verifyAccessForUser();
    }
  }, [stakeholderId]);
  const getNudgeIcon = (nudge, isHover?: boolean) => {
    if (nudge?.nudgeHeadlinePayload?.type === "podcastEpisode") {
      return <Icon name={isHover ? "nudgePodcastHover" : "nudgePodcast"} />;
    } else if (nudge?.nudgeHeadlinePayload?.type === "books") {
      return <Icon name={isHover ? "nudgeBookHover" : "nudgeBook"} />;
    } else if (nudge?.nudgeHeadlinePayload?.type === "movies") {
      return <Icon name={isHover ? "nudgeMovieHover" : "nudgeMovie"} />;
    } else if (nudge?.nudgeHeadlinePayload?.type === "birthdayWish") {
      return <Icon name={isHover ? "nudgeBirthdayHover" : "nudgeBirthday"} />;
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesKids") {
      return (
        <Icon
          name={
            isHover ? "nudgeGooglePlacesKidsHover" : "nudgeGooglePlacesKids"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesPets") {
      return (
        <Icon
          name={
            isHover ? "nudgeGooglePlacesPetsHover" : "nudgeGooglePlacesPets"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesCafe") {
      return (
        <Icon
          name={
            isHover ? "nudgeGooglePlacesCafeHover" : "nudgeGooglePlacesCafe"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesRestaurant") {
      return (
        <Icon
          name={
            isHover
              ? "nudgeGooglePlacesRestaurantHover"
              : "nudgeGooglePlacesRestaurant"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesOutdoor") {
      return (
        <Icon
          name={
            isHover
              ? "nudgeGooglePlacesOutdoorHover"
              : "nudgeGooglePlacesOutdoor"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "weatherAlert") {
      return (
        <Icon name={isHover ? "nudgeWeatherAlertHover" : "nudgeWeatherAlert"} />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "weatherPatternChange") {
      return (
        <Icon
          name={
            isHover
              ? "nudgeWeatherPatternChangeHover"
              : "nudgeWeatherPatternChange"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "espnNews") {
      return <Icon name={isHover ? "nudgeSportNewsHover" : "nudgeSportNews"} />;
    } else if (nudge?.nudgeHeadlinePayload?.type === "espnScore") {
      return (
        <Icon name={isHover ? "nudgeSportsScoreHover" : "nudgeSportsScore"} />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "espnSchedule") {
      return (
        <Icon
          name={isHover ? "nudgeSportsScheduleHover" : "nudgeSportsSchedule"}
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "spotifyMusic") {
      return (
        <Icon name={isHover ? "nudgeSpotifyMusicHover" : "nudgeSpotifyMusic"} />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "ticketMasterConcert") {
      return (
        <Icon
          name={isHover ? "nudgeConcertTicketsHover" : "nudgeConcertTickets"}
        />
      );
    }
  };
  const undoNudgeAction = async (nudgeId, type) => {
    axiosHelper({
      url: `salesforce/updateNudgeVisibility/${nudgeId}/${false}`,
      method: "PATCH",
    });
    if (type === "up") {
      let updatedThumsUpIds = thumsUpIds.filter((id) => id !== nudgeId);
      setThumsUpIds(updatedThumsUpIds);
    } else if (type === "down") {
      let updatedThumsDownIds = thumsDownIds.filter((id) => id !== nudgeId);
      setThumsDownIds(updatedThumsDownIds);
    }
  };
  const getWeatherIcon = (main) => {
    if (main == "Thunderstorm") {
      return <Icon name="stakeholderThunderStorm" />;
    } else if (main == "Drizzle") {
      return <Icon name="stakeholderDrizzle" />;
    } else if (main == "Rain") {
      return <Icon name="stakeholderRain" />;
    } else if (main == "snow") {
      return <Icon name="stakeholderSnow" />;
    } else if (main == "Clear") {
      return <Icon name="stakeholderClear" />;
    } else if (main == "Clouds") {
      return <Icon name="stakeholderCloudy" />;
    } else {
      return <Icon name="stakeholderAtmosphere" />;
    }
  };
  const updateNudgeFeedback = async (nudge, feedback, comment) => {
    console.log(nudge);
    const result = await axiosHelper({
      url: "salesforce/updateNudgeFeedback",
      method: "POST",
      JSONData: {
        stakeholderName: nudge.stakeholderName,
        stakeholderEmail: nudge.email,
        nudgeContext: nudge.context,
        nudgeHeadline: nudge.nudgeHeadline,
        nudgeTypeUniqueId: nudge.uniqueId,
        nudgeId: nudge.nudgeId,
        feedback: feedback,
        comment: comment,
      },
      urlOverride: false,
    });
    if (result.data.success) {
      if (feedback === "up") {
        if (
          !thumsUpIds.includes(nudge?.nudgeId) &&
          !thumsDownIds.includes(nudge?.nudgeId)
        ) {
          setThumsUpIds([nudge?.nudgeId, ...thumsUpIds]);
        } else if (
          !thumsUpIds.includes(nudge?.nudgeId) &&
          thumsDownIds.includes(nudge?.nudgeId)
        ) {
          let updatedthumsDownIds = thumsDownIds.filter(
            (id) => id !== nudge?.nudgeId
          );
          setThumsUpIds([nudge?.nudgeId, ...thumsUpIds]);
          setThumsDownIds(updatedthumsDownIds);
        }
      } else if (feedback === "down") {
        if (
          !thumsDownIds.includes(nudge?.nudgeId) &&
          !thumsUpIds.includes(nudge?.nudgeId)
        ) {
          setThumsDownIds([nudge?.nudgeId, ...thumsDownIds]);
        } else if (
          !thumsDownIds.includes(nudge?.nudgeId) &&
          thumsUpIds.includes(nudge?.nudgeId)
        ) {
          let updatedthumsUpIds = thumsUpIds.filter(
            (id) => id !== nudge?.nudgeId
          );
          setThumsDownIds([nudge?.nudgeId, ...thumsDownIds]);
          setThumsUpIds(updatedthumsUpIds);
        }
      }
      setSnackbarMessage("Thank you for sharing the feedback");
      setShowSnackBar(true);
    }
    console.log(result.data);
  };
  const modalHandler = () => {
    setHide(true);
  };
  const getScheduledTypeText = (type) => {
    if (type == "treatTea") {
      return "Treat is scheduled";
    } else if (type == "treatLiquor") {
      return "Alcohol is scheduled";
    } else if (type == "treatPizza") {
      return "Meal is scheduled";
    } else if (type == "treatGift") {
      return "Gift is scheduled";
    } else if (type == "treatRose") {
      return "Flower is scheduled";
    } else if (type == "treatCake") {
      return "Cake is scheduled";
    }
  };
  const handlePrevClick = () => {
    if (currentSlide > 0) {
      const newCurrentIndex =
        currentSlide - (currentSlide % 8 === 0 ? 8 : currentSlide % 8);
      setCurrentSlide(Math.max(newCurrentIndex, 0));
    }
  };

  const handleNextClick = () => {
    if (currentSlide < weather.length - 8) {
      setCurrentSlide(currentSlide + 8);
    }
  };
  return (
    <div
      style={{ overflowY: "scroll", height: "100%", position: "relative" }}
      ref={feedRef}
    >
      <Flex
        flexDirection={"column"}
        // overflowY={"scroll"}
      >
        <AccessDeniedModal
          setHideModal={() => {
            setHideAccessDeniedModal(!hideAccessDeniedModal);
          }}
          hideModal={hideAccessDeniedModal}
        />
        {userInfo &&
          userInfo.preference &&
          userInfo.preference.sportsTeam &&
          userInfo.preference.sportsTeam.length > 0 && (
            <SendSportsModal
              name={personName}
              stakeholderName={userInfo?.name}
              stakeholderId={stakeholderId}
              showSportsModal={showSportsModal}
              setShowSportsModal={setShowSportsModal}
              cancelOrder={cancelOrder}
              fetchCurrentOrderAPI={fetchCurrentOrderAPI}
            />
          )}
        <SendTreatModal
          name={personName}
          stakeholderName={userInfo?.name}
          setShowTreatModal={setShowTreatModal}
          sendTreatOrder={sendTreatOrder}
          showTreatModal={showTreatModal}
          stakeholderId={stakeholderId}
          cancelOrder={cancelOrder}
          fetchCurrentOrderAPI={fetchCurrentOrderAPI}
        />
        {
          //@ts-ignore
          <AeReturnUnlikeModal
            screenSize={screenSize}
            closeModal={() => setHide(true)}
            width="450px"
            height="max-content"
            hideModal={hide}
            setHideModal={modalHandler}
            action={true}
            updateNudgeFeedback={updateNudgeFeedback}
          ></AeReturnUnlikeModal>
        }
        <SnackBar
          iconName={"info"}
          message={snackbarState === "success" ? "Great!" : "Oops"}
          setShowSnackBar={setShowSnackBar}
          show={showSnackBar}
          subHeading={snackbarMessage}
          timeout={4000}
          anchor={"top-right"}
          type={snackbarState}
          bgColorr={snackbarState === "success" && "#24B300"}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
          open={showBackDrop}
        >
         <MemoRevenoidLoader />
        </Backdrop>
        <Box
          // overflow="auto"
          height="100vh"
          marginBottom="40px"
        >
          <Flex
            flexDirection={"column"}
            paddingLeft={"70px"}
            paddingRight={"70px"}
            gridRowGap={"24px"}
            alignItems={"flex-start"}
            height={"100%"}
          >
            <Flex
              flexDirection={"column"}
              // alignItems={"center"}
              gridRowGap={"16px"}
              width={"100%"}
              marginTop={"24px"}
            >
              <Flex
                flexDirection={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                // gridColumnGap={"20px"}
                gridColumnGap={"12px"}
              >
                <Box
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setSelectedNudge(null));
                    navigate("/aeReturnStakeholders");
                  }}
                >
                  <Icon name="arrowLeft" fill="#050505" />
                </Box>
                {userInfo && userInfo.name && (
                  <Text
                    color={"#050505"}
                    fontWeight={"400"}
                    // fontSize={"16px"}
                    fontSize={"20px"}
                    // lineHeight={"140%"}
                    lineHeight={"28px"}
                  >
                    {/* {userInfo.name.split(" ")[0]}'s profile */}
                    Back to nudge feed
                  </Text>
                )}
              </Flex>
            </Flex>
            {userInfo &&
              userInfo.birthday &&
              userInfo.name &&
              differenceInDays(
                new Date(`${userInfo.birthday}/2023`),
                new Date()
              ) <= 15 &&
              differenceInDays(
                new Date(`${userInfo.birthday}/2023`),
                new Date()
              ) >= 0 && (
                <Box width={"100%"}>
                  <BirthdayCard
                    stakeholderId={stakeholderId}
                    stakeholderName={userInfo.name}
                    birthday={userInfo.birthday}
                    name={
                      userInfo.name.split(" ").length > 1
                        ? userInfo.name.split(" ")[0]
                        : userInfo.name
                    }
                    loadEmail={() => {}}
                    days={
                      differenceInDays(
                        new Date(`${userInfo.birthday}/2023`),
                        new Date()
                      ) + 1
                    }
                    setShowTreatModal={setShowTreatModal}
                  />
                </Box>
              )}
            <Box width={"100%"}>
              <Box
                style={{ boxSizing: "border-box" }}
                background="#FFFFFF"
                borderRadius="12px"
                padding="32px 29px"
                width="100%"
              >
                <Flex
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Flex
                    width={"70%"}
                    flexDirection={"row"}
                    gridColumnGap={
                      userInfo && userInfo.birthday ? "24px" : "32px"
                    }
                    alignItems={"center"}
                  >
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"16px"}
                      alignItems={"center"}
                      paddingRight={"20px"}
                      borderRight={"1px solid rgba(0, 0, 0, 0.1)"}
                    >
                      <Box
                        borderRadius="36px"
                        background="#E6F6FF"
                        height="62px"
                        width="62px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {userInfo && userInfo.name && (
                          <Text fontSize="15px" fontWeight="400">
                            {userInfo.name
                              .trim()
                              .split(" ")
                              .map((t) => t[0].toUpperCase())
                              .join("")}
                          </Text>
                        )}
                      </Box>
                      <Flex
                        flexDirection={"column"}
                        gap="8px"
                        alignContent={"start"}
                      >
                        {userInfo && userInfo.name && (
                          <Text
                            alignSelf={"start"}
                            color={"#050505"}
                            // fontSize={"20px"}
                            fontSize={
                              userInfo && userInfo.birthday ? "17px" : "20px"
                            }
                            fontWeight={"400"}
                          >
                            {" "}
                            {userInfo.name}{" "}
                          </Text>
                        )}
                        {userInfo && userInfo.email && (
                          <Text
                            alignSelf={"start"}
                            color={"#9F9F9F"}
                            // fontSize={"20px"}
                            fontSize="12px"
                            fontWeight={"200"}
                          >
                            {" "}
                            {userInfo && userInfo?.email}{" "}
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                    {/* <Flex
                      flexDirection={"column"}
                      gridRowGap={"4px"}
                      width={userInfo && userInfo.birthday ? "150px" : "auto"}
                    >
                      <Flex
                        flexDirection={"row"}
                        alignItems={"center"}
                        gridColumnGap={"12px"}
                      >
                        <Icon name="stakeholderMail" fill={"black"} />
                        <Text
                          color={"#57585C"}
                          fontSize={"14px"}
                          fontWeight={"400"}
                        >
                          {" "}
                          Email{" "}
                        </Text>
                      </Flex>
                      <Text
                        color={"#050505"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                      >
                        {" "}
                        {userInfo && userInfo?.email}{" "}
                      </Text>
                    </Flex> */}
                    <Flex
                      flexDirection={"column"}
                      gridRowGap={"4px"}
                      // width={userInfo && userInfo.birthday ? "150px" : "auto"}
                    >
                      <Flex
                        flexDirection={"row"}
                        alignItems={"center"}
                        gridColumnGap={"12px"}
                      >
                        <Icon name="stakeholderLocation" fill={"black"} />
                        <Text
                          color={"#57585C"}
                          fontSize={"14px"}
                          fontWeight={"400"}
                        >
                          {" "}
                          Location{" "}
                        </Text>
                      </Flex>
                      <Text
                        color={"#050505"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                        //   width={"204px"}
                      >
                        {" "}
                        {/* {selectedNudge?.payload?.address}{" "} */}
                        {userInfo &&
                          userInfo?.address &&
                          `${userInfo?.address?.city}, ${userInfo?.address?.state}, ${userInfo?.address?.country}`}
                      </Text>
                    </Flex>
                    {userInfo && userInfo.birthday && (
                      <Flex
                        flexDirection={"column"}
                        gridRowGap={"4px"}
                        width={userInfo && userInfo.birthday ? "150px" : "auto"}
                      >
                        <Flex
                          flexDirection={"row"}
                          alignItems={"center"}
                          gridColumnGap={"12px"}
                        >
                          <BirthdayIcon />
                          <Text
                            color={"#57585C"}
                            fontSize={"14px"}
                            fontWeight={"400"}
                          >
                            {" "}
                            Birthday{" "}
                          </Text>
                        </Flex>
                        <Text
                          color={"#050505"}
                          fontWeight={"400"}
                          fontSize={"12px"}
                          //   width={"204px"}
                        >
                          {" "}
                          {/* {selectedNudge?.payload?.address}{" "} */}
                          {format(new Date(userInfo.birthday), "do MMMM")}
                        </Text>
                      </Flex>
                    )}
                    {userInfo &&
                      userInfo?.phone &&
                      (loggedInMember?.email?.split("@")[1] === "redica.com" ||
                        loggedInMember?.email?.split("@")[1] ===
                          "evabot.ai") && (
                        <Flex
                          flexDirection={"column"}
                          gridRowGap={"4px"}
                          width={userInfo && userInfo?.phone ? "150px" : "auto"}
                        >
                          <Flex
                            flexDirection={"row"}
                            alignItems={"center"}
                            gridColumnGap={"12px"}
                          >
                            <img src={PhoneIcon} alt="" />
                            <Text
                              color={"#050505"}
                              fontWeight={"400"}
                              fontSize={"12px"}
                              textWrap="nowrap"
                            >
                              {userInfo?.phone?.split('"')?.join(" ")}
                            </Text>
                          </Flex>
                        </Flex>
                      )}
                  </Flex>
                  <Flex
                    width={"30%"}
                    justifyContent={"flex-end"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    gridColumnGap={"20px"}
                  >
                    {!sendTreatOrder ? (
                      <Button
                        alignSelf={"flex-end"}
                        variant="primary"
                        onClick={() => {
                          setShowTreatModal(true);
                        }}
                      >
                        {" "}
                        Send treat/gift{" "}
                      </Button>
                    ) : (
                      <Flex flexDirection={"column"} gap={"8px"}>
                        <Flex
                          flexDirection={"row"}
                          alignItems={"center"}
                          gridColumnGap={"4px"}
                        >
                          <Icon name="doubleTick" stroke={"#24B300"} />
                          <Text
                            color={"#24B300"}
                            fontWeight={"400"}
                            fontSize={"14px"}
                          >
                            {" "}
                            {getScheduledTypeText(
                              sendTreatOrder.orderDetails?.orderContent
                            )}
                          </Text>
                        </Flex>
                        <Flex gap="8px">
                          <Text
                            color={"#DD015B"}
                            cursor="pointer"
                            fontSize={"14px"}
                            onClick={() => {
                              cancelOrder("treat");
                            }}
                          >
                            Cancel
                          </Text>
                          <Flex gridGap={"4px"}>
                            <TimerIcon />
                            <Timer
                              timerValue={
                                1800 -
                                differenceInSeconds(
                                  new Date(),
                                  new Date(sendTreatOrder?.orderCreatedDate)
                                )
                              }
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.sportsTeam &&
                      userInfo.preference.sportsTeam.length > 0 && (
                        <>
                          {!fangearOrder ? (
                            <Button
                              variant="primary"
                              alignSelf={"flex-end"}
                              onClick={() => {
                                setShowSportsModal(true);
                              }}
                            >
                              {" "}
                              Send fan gear{" "}
                            </Button>
                          ) : (
                            <Flex flexDirection={"column"} gap={"8px"}>
                              <Flex
                                flexDirection={"row"}
                                alignItems={"center"}
                                gridColumnGap={"4px"}
                              >
                                <Icon name="doubleTick" stroke={"#24B300"} />
                                <Text
                                  color={"#24B300"}
                                  fontWeight={"400"}
                                  fontSize={"14px"}
                                >
                                  {" "}
                                  Fan gear is scheduled!{" "}
                                </Text>
                              </Flex>
                              <Flex gap="8px">
                                <Text
                                  color={"#DD015B"}
                                  cursor="pointer"
                                  fontSize={"14px"}
                                  onClick={() => {
                                    cancelOrder("fanGear");
                                  }}
                                >
                                  Cancel
                                </Text>
                                <Flex gridGap={"4px"}>
                                  <TimerIcon />
                                  <Timer
                                    timerValue={
                                      1800 -
                                      differenceInSeconds(
                                        new Date(),
                                        new Date(fangearOrder?.orderCreatedDate)
                                      )
                                    }
                                  />
                                </Flex>
                              </Flex>
                            </Flex>
                          )}
                        </>
                      )}
                  </Flex>
                </Flex>
              </Box>
            </Box>

            <Flex flexDirection={"column"} gridRowGap={"16px"} width={"100%"}>
              <Box width={"100%"}>
                <Box
                  style={{ boxSizing: "border-box" }}
                  background="#FFFFFF"
                  borderRadius="12px"
                  width="100%"
                >
                  <Flex
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    padding={"25px"}
                    borderBottom={"1px solid #E4E4E4"}
                  >
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"12px"}
                      alignItems={"center"}
                    >
                      <Icon name="stakeholderThermometer" fill={"#000000"} />
                      <Text
                        color={"#111827"}
                        fontWeight={"500"}
                        fontSize={"16px"}
                      >
                        {" "}
                        Local weather{" "}
                      </Text>
                      {/* <Text
                        color={"#57585C"}
                        fontSize={"14px"}
                        fontWeight={"400"}
                      >
                        {" "}
                        Next 7 days forecast{" "}
                      </Text> */}
                      {userInfo &&
                        userInfo.address &&
                        userInfo.address?.pincode && (
                          <Box
                            alignSelf={"flex-start"}
                            cursor="pointer"
                            onClick={() =>
                              window.open(
                                `https://weather.com/weather/tenday/l/${userInfo?.address?.pincode}`,
                                "_blank"
                              )
                            }
                          >
                            <Tag
                              text={"Source: weather.com"}
                              color="#0094E3"
                              bgColor="#F2FBFF"
                            />
                          </Box>
                        )}
                    </Flex>
                  </Flex>
                  <div
                    style={{
                      padding: "30px",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    {weather.length > 0 && (
                      <Carousel
                        getWeatherIcon={getWeatherIcon}
                        items={weather}
                        perPage={8}
                        startIndex={currentSlide}
                      />
                    )}
                  </div>
                </Box>
              </Box>
              <Box width={"100%"}>
                <Box
                  style={{ boxSizing: "border-box" }}
                  background="#FFFFFF"
                  borderRadius="12px"
                  width="100%"
                >
                  <Flex
                    padding={"25px"}
                    flexDirection={"row"}
                    gridColumnGap={"16px"}
                    borderBottom={"1px solid #E4E4E4"}
                    alignItems={"center"}
                  >
                    <Icon name="stakeholderTongue" fill={"#000000"} />
                    <Text
                      color={"#111827"}
                      fontWeight={"500"}
                      fontSize={"16px"}
                    >
                      {" "}
                      Preferences{" "}
                    </Text>
                  </Flex>
                  <Flex
                    flexDirection={"row"}
                    alignItems={"center"}
                    padding={"25px"}
                    flexWrap={"wrap"}
                    gap={"40px"}
                  >
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.c1 &&
                      userInfo.preference.c1.length > 0 && (
                        <Flex
                          flexDirection={"row"}
                          width={"20%"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderCoffee" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              marginRight="4px"
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              Coffee/Tea:{" "}
                            </Text>
                            {userInfo.preference.c1
                              .map((entry) => getCoffeeString(entry))
                              .filter((x) => x)
                              .join(", ")}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.c3 &&
                      userInfo.preference.c3.length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderWine" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              marginRight="2px"
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              Alcoholic beverages:
                            </Text>{" "}
                            {userInfo.preference.c3
                              .map((x) => getAlcoholString(x))
                              .filter((x) => x)
                              .join(", ")}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.c1 &&
                      userInfo.preference.c1.length > 0 &&
                      userInfo.preference.c1
                        .map((x) => getOtherC1String(x))
                        .filter((y) => y && y.length > 0).length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderSoda" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              marginRight="4px"
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              Nothing/Other:
                            </Text>
                            {userInfo.preference.c1
                              .map((entry) => getOtherC1String(entry))
                              .filter((x) => x)
                              .join(", ")}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.cuisine &&
                      userInfo.preference.cuisine.length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderCuisine" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              marginRight="4px"
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              Favourite cuisines/dishes:{" "}
                            </Text>
                            {userInfo.preference.cuisine
                              .map((entry) => getCuisineString(entry))
                              .filter((x) => x)
                              .join(", ")}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.allergy &&
                      userInfo.preference.allergy.length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderDiet" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              marginRight="4px"
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              Dietary restrictions:{" "}
                            </Text>
                            {userInfo.preference.allergy
                              .map((entry) => capitalizeString(entry))
                              .filter((x) => x)
                              .join(", ")}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.music &&
                      userInfo.preference.music.length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderMusic" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              marginRight="4px"
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              I can’t live without my music:{" "}
                            </Text>
                            {userInfo.preference.music
                              .map((entry) => capitalizeString(entry))
                              .filter((x) => x)
                              .join(", ")}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.sportsTeam &&
                      userInfo.preference.sportsTeam.length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderSports" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              marginRight="4px"
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              I follow sports religiously:{" "}
                            </Text>
                            {userInfo.preference.sportsTeam
                              .map((entry) => capitalizeString(entry))
                              .filter((x) => x)
                              .join(", ")}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.books &&
                      userInfo.preference.books.length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderBook" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              marginRight="4px"
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              I like to read:{" "}
                            </Text>
                            {userInfo.preference.books
                              .map((entry) => capitalizeString(entry))
                              .filter((x) => x)
                              .join(", ")}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.movies &&
                      userInfo.preference.movies.length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderMonitor" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              marginRight="4px"
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              I like to watch TV shows and movies:{" "}
                            </Text>
                            {userInfo.preference.movies
                              .map((entry) => capitalizeString(entry))
                              .filter((x) => x)
                              .join(", ")}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.podcasts &&
                      userInfo.preference.podcasts.length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderMic" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              marginRight="4px"
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              I like to listen to podcasts:{" "}
                            </Text>
                            {userInfo.preference.podcasts
                              .map((entry) => capitalizeString(entry))
                              .filter((x) => x)
                              .join(", ")}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.c4 &&
                      userInfo.preference.c4.length > 0 &&
                      userInfo.preference.c4
                        .map((entry) => getC4MappedString(entry, "outdoor"))
                        .filter((x) => x && x.length > 0).length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderOuting" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              marginRight="4px"
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              Outdoor activities:{" "}
                            </Text>
                            {userInfo.preference.c4.map((entry) => (
                              <>{getC4MappedString(entry, "outdoor")} </>
                            ))}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.indoor &&
                      userInfo.preference.indoor.length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderIndoor" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              marginRight="4px"
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              Indoor activities:{" "}
                            </Text>
                            {userInfo.preference.indoor
                              .map((entry) => capitalizeString(entry))
                              .filter((x) => x)
                              .join(", ")}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.c4 &&
                      userInfo.preference.c4.length > 0 &&
                      userInfo.preference.c4
                        .map((entry) => getC4MappedString(entry, "pets"))
                        .filter((x) => x && x.length > 0).length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderPet" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            {" "}
                            <Text
                              fontFamily={"Inter-Bold"}
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              Spending time with my pet:{" "}
                            </Text>
                            {userInfo.preference.c4.map((entry) => (
                              <>{getC4MappedString(entry, "pets")} </>
                            ))}
                          </Text>
                        </Flex>
                      )}
                    {userInfo &&
                      userInfo.preference &&
                      userInfo.preference.c4 &&
                      userInfo.preference.c4.length > 0 &&
                      userInfo.preference.c4
                        .map((entry) => getC4MappedString(entry, "kids"))
                        .filter((x) => x && x.length > 0).length > 0 && (
                        <Flex
                          width={"20%"}
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Box
                            borderRadius={"12px"}
                            background={"#EEF1FB"}
                            padding={"14px 12px"}
                          >
                            <Icon name="stakeholderKid" />
                          </Box>
                          <Text
                            color={"#050505"}
                            fontWeight={"400"}
                            fontSize={"12px"}
                            lineHeight={"140%"}
                            width={"171px"}
                          >
                            <Text
                              fontFamily={"Inter-Bold"}
                              display={"inline-block"}
                              fontWeight={"300"}
                              fontSize={"12px"}
                              lineHeight={"140%"}
                            >
                              Spending time with my kids:{" "}
                            </Text>
                            {userInfo.preference.c4.map((entry) => (
                              <>{getC4MappedString(entry, "kids")} </>
                            ))}
                          </Text>
                        </Flex>
                      )}
                    {/* <Flex
                    flexDirection={"row"}
                    gridColumnGap={"8px"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={"12px"}
                      background={"#EEF1FB"}
                      padding={"14px 12px"}
                    >
                      <Icon name="stakeholderHobby" />
                    </Box>
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                      width={"171px"}
                    >
                      {" "}
                      Hobbies: Capturing photos and videos{" "}
                    </Text>
                  </Flex> */}
                  </Flex>
                </Box>
              </Box>
              { nudges.length > 0 && <Text
                color={"#050505"}
                fontWeight={"400"}
                fontSize={"20px"}
                lineHeight={"140%"}
                marginTop={"16px"}
              >
                {" "}
                Nudge feed{" "}
              </Text>}
              {nudges.length > 0 &&
                nudges.map((nudge) => {
                  return !thumsDownIds.includes(nudge?.nudgeId) ? (
                    <StakeholderProfileNudgeCard
                      feedRef={feedRef}
                      nudge={nudge}
                      getNudgeIcon={getNudgeIcon}
                      thumsUpIds={thumsUpIds}
                      undoNudgeAction={undoNudgeAction}
                      updateNudgeFeedback={updateNudgeFeedback}
                      hide={hide}
                      setHide={setHide}
                    />
                  ) : (
                    <Box width={"100%"}>
                      <Box
                        style={{ boxSizing: "border-box" }}
                        background="#FFFFFF"
                        borderTopLeftRadius="12px"
                        borderTopRightRadius="12px"
                        borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                        padding="40px"
                        width="100%"
                      >
                        <Flex
                          flexDirection={"row"}
                          gridColumnGap={"52px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Text
                            color={"#9F9F9F"}
                            lineHeight={"140%"}
                            fontWeight={"500"}
                            fontSize={"16px"}
                          >
                            {" "}
                            We won't recommend this nudge to you again{" "}
                          </Text>
                          <Button
                            variant="undo"
                            onClick={() => {
                              undoNudgeAction(nudge?.nudgeId, "down");
                            }}
                          >
                            {" "}
                            Undo{" "}
                          </Button>
                        </Flex>
                      </Box>
                    </Box>
                  );
                })}
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <div style={{ position: "fixed", bottom: 20, right: 20 }}>
        {feedRef && feedRef.current && (
          <Box
            height={"32px"}
            cursor={"pointer"}
            width={"32px"}
            borderRadius={"8px"}
            border={"1px solid rgba(0, 0, 0, 0.1)"}
            background={"#fff"}
            onClick={() => {
              // console.log("cliekdd");
              // @ts-ignore
              feedRef.current.scroll({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              height={"100%"}
              width={"100%"}
            >
              <Icon name="up" fill="black" />
            </Flex>
          </Box>
        )}
      </div>
    </div>
  );
};
export default StakeholderProfile;
