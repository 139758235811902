import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MemberListingTableHeader from './MemberListingTableHeader';
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import { useDispatch } from 'react-redux';
import { setMemberListingData, setTabViewMemberData, setTagViewVisibility } from './store/memberListingReducer';
import { styled } from '@mui/material/styles';
import FrameworkAndContextTable from 'containers/FrameworkAndContext/FrameworkAndContextTable';
import { useAppSelector } from 'hooks';
import MemberListingTableBody from './MemberListingTableBody';
import userService from 'services/userService';
import { Backdrop } from "@mui/material";
import { Icon } from '@evabot/eva-react-core-library/dist/atoms/Icon/Icon';
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex';
import { ModalWrapper } from '@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper';
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import AddMemberForm from './component/AddMemberForm';
import randomstring from 'randomstring';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import GeneralInfo from './component/GeneralInfo';
import Settings from './component/Settings';
import MemoRevenoidLoader from 'components/RevenoidLoader/RevenoidLoader';

const StyledTab = styled(Tab)(({ theme }) => ({
    '&.Mui-selected': {
        color: '#0094E3 !important',
    },
    backgroundColor: 'transparent !important',
}));

const TabView = () => {
    const [tabValue, setTabValue] = useState('1');
    const [memberData, setMemberData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hideModal, setHideModal] = useState(true);
    const [showSnackBarModal, setShowSnackBarModal] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [AdminMemberList, setAdminMemberList] = useState([]);
    const [crmMessage, setCrmMessage] = useState("");
    const [accountSequenceAutomation, setAccountSequenceAutomation] = useState(false);
    const [generalInfoUserdata, setGeneralInfoUserData] = useState({});

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        fetchReport();
        getUserInfoApi();
    };

    const tabViewPresentUserData = useAppSelector(
        (state) => state.memberListingState?.tabViewState?.isTabViewMemberData
    );

    const memberListingData = useAppSelector((state) => state.memberListingState.memberListingData)

    const dispatch = useDispatch();

    const handleBack = () => {
        dispatch(setTagViewVisibility(false));
        const userEmail = "";
        const integration = [];
        dispatch(setTabViewMemberData({userEmail, integration}));
    };

    const fetchAdminMemberList = async () => {
        try {
            userService.fetchAdminMemberList()
                .then((response) => {
                    const adminMemberList = response?.data
                        .filter(item => item.organizationId)
                        .map(({ organizationId, firstName, lastName, email }) => ({
                            value: organizationId + "$$$" + email,
                            label: `${firstName} ${lastName} <${email}>`
                        }));
                    setAdminMemberList(adminMemberList);
                })
        }
        catch (error) {
            console.error("Error fetching company list", error);
        }
    };

    const handleAddMember = (value, selectedCompanyId) => {
        let userId = "";
        const userIdList = memberListingData.filter(item => item.organizationId === orgId && (item.userType === 'admin' || item.userType === 'superadmin'));
        if(userIdList.length > 0){
            userId = userIdList[0].userId;
        }
        try {
            console.log(value);
            const generatedPassword = randomstring.generate({
                length: 10,
                charset: 'alphanumeric',
            });

            const newValue = {
                password: generatedPassword,
                ...({ organizationId: orgId, adminUserId : userId }),
                ...value
            }

            newValue.menu = value.menu.map(item => item.value);

            newValue.email = newValue?.email?.toLowerCase();

            userService.addUser(newValue)
                .then((response) => {
                    if (response?.message) {                        
                        setSnackbarMessage('Member Already Exist');
                        setSnackbarType('error');
                        setShowSnackBarModal(true);
                    } else if (response?.success === true && response?.user?.identityContact?.email) {
                        setCrmMessage(response?.CRMmessage);
                        setSnackbarMessage('Member has been added successfully.');
                        setShowSnackBar(true);
                        setSnackbarType('success');
                        setShowDetailsModal(true);
                        fetchReport();
                        fetchAdminMemberList();
                    } 
                    else {
                        setSnackbarMessage('Error adding Member. Please try again later');
                        setShowSnackBar(true);
                        setSnackbarType('error');
                        setHideModal(true);
                    }
                })
        } catch (error) {
            console.error("Error adding member", error)
        }
    }

    const fetchReport = async () => {
        try {
            setLoading(true);
            const obj = { "organizationId": orgId };
            userService.getAllMemberByOrgId(obj)
                .then((response) => {
                    // const filterNonDeletedData = response?.data.filter(data => data.isDeleted === false);
                    setMemberData(response?.data);
                    setAccountSequenceAutomation(response?.accountSequenceEnabled);
                    dispatch(setMemberListingData(response?.data));
                    setLoading(false);
                })
        } catch (error) {
            console.error("Error fetching report:", error);
            setLoading(false);
        }

    };

    const orgId = useAppSelector(
        (state) => state.memberListingState?.tabViewState?.isOrgId
    );

    const getUserInfoApi = async(userId = "") => {
        setLoading(true);
        try {
            const obj = {userId : tabViewPresentUserData?.userId, organizationId : orgId};
            userService.getUserInfoData(obj)
                .then((response) => {
                    setGeneralInfoUserData(response);
                })
        }
        catch (error) {
            console.error("Error fetching company list", error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchReport();
    }, [orgId]);

    useEffect(() => {
        if(tabViewPresentUserData?.userId && orgId){
            getUserInfoApi();
        }
    }, [tabViewPresentUserData?.userId, orgId])

    return (
        <div>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}
            >
                <MemoRevenoidLoader />
            </Backdrop>}
            <Flex justifyContent={"space-between"}>
                <Flex>
                    <Text><span style={{ "fontWeight": "600" }}>Selected Member</span> : {tabViewPresentUserData.userEmail}</Text>
                </Flex>
                <Flex>
                    {tabValue !== '3' && <Flex>
                        <Flex>
                            <Button size='m' justifyContent={"right"} marginRight={"10px"} fontSize={"16px"} padding={"10px"} onClick={handleBack} variant="secondary" marginBottom={2} iconName="arrowLeft">
                                Back
                            </Button>
                        </Flex>
                        <Flex>
                            <Button
                                size="m"
                                variant="primary"
                                borderRadius={"15px"}
                                fontSize={"16px"}
                                padding={"10px"}
                                onClick={() => { setHideModal(false); fetchAdminMemberList(); }}
                                iconName='add'
                            >
                                Add Member
                            </Button>
                        </Flex>
                    </Flex>
                    }
                    {tabValue === '3' && <Flex>
                        <Button size='m' justifyContent={"right"} marginRight={"10px"} fontSize={"16px"} padding={"10px"} onClick={handleBack} variant="secondary" right={"220%"} marginBottom={2} iconName="arrowLeft">
                            Back
                        </Button>
                    </Flex>}

                    {/* {tabValue !== '1' && (<Flex>
                        <Flex>
                            <Button
                                size="m"
                                variant="primary"
                                borderRadius={"15px"}
                                fontSize={"16px"}
                                padding={"10px"}
                                onClick={() => { setHideModal(false); fetchAdminMemberList(); }}
                                iconName='add'
                            >
                                Add Member
                            </Button>
                        </Flex>
                    </Flex>)} */}
                </Flex>

            </Flex>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="inherit"
                indicatorColor="primary"
                aria-label="secondary tabs example"
            >

                <StyledTab value="1" label="General Info" />                    
                <StyledTab value="2" label="Team Members" />
                <StyledTab value="3" label="Framework/Context" />
                <StyledTab value="4" label="Settings" />
            </Tabs>
            {tabValue === '1' && (
                <>
                    {Object.keys(generalInfoUserdata).length > 0 ? (
                        <GeneralInfo integration={tabViewPresentUserData.integration} refetchData={fetchReport} userInfoData={generalInfoUserdata} userInfo={getUserInfoApi} />
                    ) : (
                        <Text>Loading General Info...</Text>
                    )}
                </>
            )}
            {tabValue === '2' && (
                <>
                    <MemberListingTableHeader />
                    <MemberListingTableBody data={memberData} refetch={fetchReport} accountSequenceEnabled={accountSequenceAutomation} />
                </>
            )}
            {tabValue === '3' && (
                <>
                    <FrameworkAndContextTable tabView={true} />
                </>
            )}
            {tabValue === '4' && (
                <>
                    <Settings  userData={tabViewPresentUserData}/>
                </>
            )}

            <ModalWrapper
                width="max-content"
                height={"auto"}
                overflow={"scroll"}
                hideModal={hideModal}
                setHideModal={() => {
                    setHideModal(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="2%"
            >
                <SnackBar
                    iconName={"info"}
                    message={snackbarMessage}
                    setShowSnackBar={setShowSnackBarModal}
                    show={showSnackBarModal}
                    timeout={4000}
                    anchor={"top-right"}
                    type={snackbarType}
                />
                {showDetailsModal ?
                (
                    <>
                        <Flex flexDirection="column" alignItems="center">
                            <Text fontWeight="600" fontSize="16px" marginBottom={3}>Use Token for login</Text>
                            <Button onClick={() => { setShowDetailsModal(false); setHideModal(true); }}>Close</Button>
                            {crmMessage && <Text fontSize="12px" marginTop={3} fontStyle={"italic"} display={"inherit"}> 
                                <Icon name={"info"} size="50px" style={{paddingRight : "10px"}}></Icon>
                                {crmMessage}
                            </Text>}
                        </Flex>
                    </>
                ) : 
                (<>                
                <Text fontWeight="400" fontSize="24px" marginBottom={4}>Add Member</Text>
                <AddMemberForm
                    onSubmit={handleAddMember}
                    handleClose={() => { setHideModal(true); }}
                    adminMemberList={AdminMemberList}
                    tabView="true"
                />
                </>)
                }
            </ModalWrapper>
        </div>
    );
};

export default TabView;
