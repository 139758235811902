import React, { useState, useEffect } from 'react';
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ToggleButton } from '@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton';
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { TextField, MenuItem, Select, FormControl, InputLabel, Autocomplete, InputAdornment, Tooltip, IconButton } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { axiosHelperCore } from 'utils';
import { useAppSelector } from 'hooks';
import { Link } from 'react-router-dom';

const SavedStepForm = ({ errorMessage, isSubmitted, step, frameworkList, defaultEmail, defaultLinkedin, toneList, wordCountList, contextList, dropDownList, setGlobalUsedCustomValues, onChange, isDisabled, autoPersonaSelectionSettingStep }) => {
    const selectedClass = "bg-[#0094E3] px-3 py-2 border rounded-[30px] border-gray-200 text-[#fff] text-[13px]";
    const notSelectedClass = "bg-[white] px-3 py-2 border rounded-[30px] border-gray-200 text-[#000] text-[13px]";

    const inputDisabled: React.CSSProperties = !isDisabled
        ? {}
        : {
            pointerEvents: 'none' as 'none',
            opacity: 0.6,
        };

    const [formData, setFormData] = useState({
        emailSubject: step?.type.includes('email') ? step.field1 : '',
        emailBody: (step?.type.includes('email') || step?.type.includes('followUp')) ? step.field2 : '',
        linkedinSubject: step?.type.includes('linkedinInMail') ? step.field1 : '',
        linkedinBody: step?.type.includes('linkedin') ? step.field2 : '',
        callprepBody: step?.type.includes('call') ? step.field2 : '',
        selectedFramework: step.frameworkId || '',
        selectedTone: step.toneId || '',
        selectedWordLimit: step.wordId || '',
        selectedContext: step.contextId || '',
        type: step?.type || '',
    });

    const [isDeleteConfirmationShow, setIsDeleteConfirmationShow] = useState(false);
    const [isActivated, setIsActivated] = useState(step?.activated);
    const loggedInUserDetail = useAppSelector((state) => state.profileState.profileData);

    const sectionDisabled: React.CSSProperties = isActivated
        ? {}
        : {
            pointerEvents: 'none' as 'none',
            opacity: 0.6,
        };

    const onDeleteSetting = async () => {
        const result: any = await axiosHelperCore({
            url: `rapport/sequence/updateSequenceActivateSetting`,
            method: "POST",
            JSONData: { userId: loggedInUserDetail?.userId, sequenceId: step?.sequenceId, stepId: step?.stepId, activated: !isActivated, activityType: "stepEdit" }
        });

        if (result?.data?.data) {
            setIsActivated(!isActivated);
        }
        setIsDeleteConfirmationShow(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        const oldValue = formData[name];

        setGlobalUsedCustomValues(prevValues => {
            const filteredValues = prevValues.filter(val => val !== oldValue);
            return [...filteredValues, value].filter(val => val);
        });

        setFormData({ ...formData, [name]: value });
    };

    const handleSelect = (type, value) => {
        let updatedFormData = {
            ...formData,
            [type]: value,
        };

        if (type === 'selectedFramework' && !value) {
            const matchedTone = toneList.find(tone => tone?.value === defaultEmail?.tone);
            updatedFormData = {
                ...updatedFormData,
                selectedTone: matchedTone?.toneId,
            }
        }

        if (type === 'selectedFramework' && value) {
            updatedFormData = {
                ...updatedFormData,
                selectedTone: '',
            }
        }

        // Automatically select tone & word limit for LinkedIn types
        if (type === 'type') {
            if (value === 'linkedinDirectMessage') {
                updatedFormData = {
                    ...updatedFormData,
                    selectedFramework: '',
                    selectedTone: toneList.find(tone => tone?.value === defaultLinkedin?.linkedinDirectMessage?.tone)?.toneId || '',
                    selectedWordLimit: wordCountList.find(wordLimit => wordLimit?.value === defaultLinkedin?.linkedinDirectMessage?.wordLimit)?.wordId || '',
                };
            } else if (value === 'linkedinConnectionRequest') {
                updatedFormData = {
                    ...updatedFormData,
                    selectedFramework: '',
                    selectedTone: toneList.find(tone => tone?.value === defaultLinkedin?.linkedinConnectionRequest?.tone)?.toneId || '',
                    selectedWordLimit: wordCountList.find(wordLimit => wordLimit?.value === defaultLinkedin?.linkedinConnectionRequest?.wordLimit)?.wordId || '',
                };
            }
            // else if (value === 'linkedinInMail') {
            //     updatedFormData = {
            //         ...updatedFormData,
            //         selectedFramework: '',
            //         selectedTone: toneList.find(tone => tone?.value === defaultLinkedin?.linkedinDirectMessage?.tone)?.toneId || '',
            //         selectedWordLimit: wordCountList.find(wordLimit => wordLimit?.value === defaultLinkedin?.linkedinDirectMessage?.wordLimit)?.wordId || '',
            //     };
            // }
        }

        setFormData(updatedFormData);
    };

    useEffect(() => {
        onChange(step.stepId, step.sequenceId, {
            ...formData,
            stepName: step.stepName,
            stepType: step.stepType,
            crmStepId: step.crmStepId,
            orderBy: step.orderBy,
            isActive: isActivated,
        });
    }, [formData, isActivated]);

    const getStepType = (type) => {
        if (type.startsWith("followUp")) {
            const [baseType, ...rest] = type.split('-');
            const StepBaseType = "Follow Up";

            const StepRest = rest.join(' ');

            return `${StepBaseType} ${StepRest}`.trim();
        }

        return null;
    };

    const StepType = getStepType(formData.type);
    let combinedStepName = step.stepName;

    if (StepType) {
        combinedStepName = step.stepName.replace(/\)$/, ` : ${StepType})`);
    }

    return (
        <div id={"id_" + step.stepId} className="saved-step-form mb-6 border bg-white shadow-lg rounded-lg p-6" style={inputDisabled}>
            {errorMessage && isSubmitted && errorMessage.split("$$$")[0] === "id_" + step.stepId && <div className="text-red-500 text-[12px]">{errorMessage.split("$$$")[1]}</div>}
            <div className='flex justify-between'>
                <div className="text-xl text-[#050505] font-medium">{combinedStepName}</div>
                <Tooltip title={"Disabling this step will remove it from the sequence and prevent output generation"} placement="top-start" disableHoverListener={!isActivated} arrow>
                    <div>
                        <ToggleButton
                            value={isActivated}
                            name='isActive'
                            variant="tertiary"
                            onChange={(value) => { setIsDeleteConfirmationShow(true) }}
                        />
                    </div>
                </Tooltip>
            </div>
            {!isDisabled && <div className='mt-5' style={sectionDisabled}>
                <FormControl margin="normal" style={{ width: "150px" }}>
                    <InputLabel id="select-type-label">Select Type</InputLabel>
                    <Select
                        labelId="select-type-label"
                        label="Select Type"
                        value={formData.type}
                        style={{ width: "200px" }}
                        onChange={(e) => handleSelect('type', e.target.value)}
                    >
                        {dropDownList?.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                            //   disabled={usedEmailOptions.includes(option.value)}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="flex items-start input-fields col my-1" style={{ alignItems: "flex-start", gap: '1rem' }}>
                    {formData?.type?.includes('email') && (
                        <>
                            <div className='w-1/2'>
                                <TextField
                                    label="Email Subject Custom Field"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="emailSubject"
                                    value={formData.emailSubject}
                                    onChange={handleChange}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Custom field name should be unique.">
                                                    <IconButton>
                                                        <InfoIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {!formData.emailSubject?.trim() && isActivated && isSubmitted && <div className="text-red-500 text-[12px]">subject is required</div>}
                            </div>
                            <div className='w-1/2'>
                                <TextField
                                    label="Email Body Custom Field"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="emailBody"
                                    value={formData.emailBody}
                                    onChange={handleChange}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Custom field name should be unique.">
                                                    <IconButton>
                                                        <InfoIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {!formData.emailBody?.trim() && isSubmitted && isActivated && <div className="text-red-500 text-[12px]">email body is required</div>}
                            </div>
                        </>
                    )}
                    {formData?.type?.includes('linkedinInMail') && (
                        <>
                            <div className='w-1/2'>
                                <TextField
                                    label="Linkedin Subject Custom Field"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="linkedinSubject"
                                    value={formData.linkedinSubject}
                                    onChange={handleChange}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Custom field name should be unique.">
                                                    <IconButton>
                                                        <InfoIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {!formData.linkedinSubject?.trim() && isSubmitted && isActivated && <div className="text-red-500 text-[12px]">linkedin subject is required</div>}
                            </div>
                            <div className='w-1/2'>
                                <TextField
                                    label="Linkedin Body Custom Field"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="linkedinBody"
                                    value={formData.linkedinBody}
                                    onChange={handleChange}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Custom field name should be unique.">
                                                    <IconButton>
                                                        <InfoIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {!formData.linkedinBody?.trim() && isSubmitted && isActivated && <div className="text-red-500 text-[12px]">linkedin body is required</div>}
                            </div>
                        </>
                    )}
                    {(formData?.type.includes('linkedinDirectMessage') || formData?.type.includes('linkedinConnectionRequest')) && (
                        <div style={{ width: "100%" }}>
                            <TextField
                                label={'LinkedIn Custom Field'}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="linkedinBody"
                                value={formData.linkedinBody}
                                onChange={handleChange}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Custom field name should be unique.">
                                                <IconButton>
                                                    <InfoIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {!formData.linkedinBody?.trim() && isSubmitted && isActivated && <div className="text-red-500 text-[12px]">linkedin body is required</div>}
                        </div>
                    )}
                    {formData?.type.includes('followUp') && (
                        <>
                            <div style={{ width: "100%" }}>
                                <TextField
                                    label="Follow Up Email Body Custom Field"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="emailBody"
                                    value={formData.emailBody}
                                    onChange={handleChange}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Custom field name should be unique.">
                                                    <IconButton>
                                                        <InfoIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {!formData.emailBody?.trim() && isSubmitted && isActivated && <div className="text-red-500 text-[12px]">follow up email body is required</div>}
                            </div>
                        </>
                    )}
                    {(formData?.type.includes('call')) && (
                        <div style={{ width: "100%" }}>
                            <TextField
                                label={'Call Custom Field'}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="callprepBody"
                                value={formData.callprepBody}
                                onChange={handleChange}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Custom field name should be unique.">
                                                <IconButton>
                                                    <InfoIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {!formData.callprepBody?.trim() && isSubmitted && isActivated && <div className="text-red-500 text-[12px]">call field is required</div>}
                        </div>
                    )}
                </div>
                {<div className="dropdowns my-4">
                    {(formData?.type?.includes('email') || formData?.type.includes('followUp') || formData?.type?.includes('linkedinInMail') || formData?.type?.includes('call')) &&
                        <div className="py-2">
                            {/* <div className="py-1 text-[16px]">Select framework</div> */}
                            <div className="flex gap-4 cursor-pointer flex-wrap">
                                {/* {frameworkList.map((framework) => (
                        <div
                            key={framework.frameworkId}
                            className={formData.selectedFramework === framework.frameworkId ? selectedClass : notSelectedClass}
                            onClick={() => handleSelect('selectedFramework', framework.frameworkId)}
                        >
                            {framework.name}
                        </div>
                        ))} */}
                                <Tooltip placement='bottom-end' title={(formData.selectedFramework && !(frameworkList.find(fw => fw.frameworkId === formData.selectedFramework)?.isPublic) && !(loggedInUserDetail?.userId === frameworkList.find(fw => fw.frameworkId === formData.selectedFramework)?.createdBy?.userId)) ? "Framework is private" : ""}>
                                    <Autocomplete
                                        options={formData?.type.includes('call') ? frameworkList.filter(fw => (fw.type === "callprep") && (fw?.isPublic === undefined || fw?.isPublic)) : frameworkList.filter(fw => (fw.type === "email" || fw.frameworkId == "") && (fw?.isPublic === undefined || fw?.isPublic))}
                                        getOptionLabel={(option) => option.name}
                                        // renderOption={(props, option) => {
                                        //     const isPublic = option?.isPublic === undefined || option?.isPublic === true;
                                        //     return (
                                        //       <li {...props}>
                                        //         {option.name}{" "}
                                        //         <span style={{ color: isPublic ? "green" : "red", marginLeft: "8px" }}>
                                        //           {isPublic ? "(Public)" : "(Private)"}
                                        //         </span>
                                        //       </li>
                                        //     );
                                        // }}
                                        renderInput={(params) => <TextField {...params} label="Select framework (public only)" variant="outlined" />}
                                        value={frameworkList.find(fw => fw.frameworkId === formData?.selectedFramework && fw.frameworkId != "") || (formData?.type.includes('call') ? frameworkList.find(fw => fw.name === "DEFAULT") : frameworkList.find(fw => fw.frameworkId === ""))}
                                        onChange={(event, newValue) => {
                                            if (formData?.type.includes('call'))
                                                handleSelect('selectedFramework', newValue ? newValue.frameworkId : frameworkList.find(fw => fw.name === "DEFAULT")?.frameworkId);
                                            else
                                                handleSelect('selectedFramework', newValue ? newValue.frameworkId : '');
                                        }}
                                        style={{ width: "50%" }}
                                    />
                                </Tooltip>
                            </div>
                            <div className='font-normal' style={{ fontSize: "12px", color: "#57585C", display: 'flex', alignItems: 'center', gap: '5px', marginTop: "10px" }}>
                                Want to add a custom framework?<Link to="/frameworkandcontext" style={{ color: "#0094E3", textDecoration: "underline" }}>click here</Link>
                            </div>

                            {!formData?.type.includes('call') && formData?.selectedFramework && frameworkList.find(fw => fw.frameworkId === formData?.selectedFramework) && <div style={{ fontSize: "10px", backgroundColor: "#DCF9FF", color: "#57585C", display: 'flex', alignItems: 'center', gap: '10px', marginTop: "10px", border: "1px solid #0094E3", padding: '5px', borderRadius: '5px' }}>
                                {(step?.type.includes('email') || step?.type?.includes('followUp') || step?.type === 'linkedinInMail' || formData?.type.includes('linkedinInMail') || formData?.type.includes('call')) && frameworkList.find(fw => fw.frameworkId === formData.selectedFramework)?.framework}
                            </div>}

                            {formData?.type.includes('call') && frameworkList.find(fw => fw.frameworkId === formData?.selectedFramework) && <div style={{ fontSize: "10px", backgroundColor: "#DCF9FF", color: "#57585C", display: 'flex', alignItems: 'center', gap: '10px', marginTop: "10px", border: "1px solid #0094E3", padding: '5px', borderRadius: '5px' }}>
                                {frameworkList.find(fw => fw.frameworkId === formData.selectedFramework)?.framework || frameworkList.find(fw => fw.name === "DEFAULT")?.framework}
                            </div>}
                        </div>}

                    {!formData?.selectedFramework && !formData?.type.includes('followUp') && !formData?.type.includes('call') && <div className="py-2">
                        <div className="py-2 text-[16px]">Select email tone</div>
                        <div className="flex gap-4 cursor-pointer">
                            {toneList?.map((tone) => (
                                <div
                                    key={tone.toneId}
                                    className={formData.selectedTone === tone.toneId ? selectedClass : notSelectedClass}
                                    onClick={() => handleSelect('selectedTone', tone.toneId)}
                                >
                                    {tone.name}
                                </div>
                            ))}
                        </div>
                    </div>}

                    {!(formData?.type === 'linkedinConnectionRequest') && !formData?.type.includes('followUp') && !formData?.type.includes('call') && <div className="py-2">
                        <div className="py-2 text-[16px]">Select email word limit</div>
                        <div className="flex gap-4 cursor-pointer">
                            {wordCountList.map((wordCount) => (
                                <div
                                    key={wordCount.wordId}
                                    className={formData.selectedWordLimit === wordCount.wordId ? selectedClass : notSelectedClass}
                                    onClick={() => handleSelect('selectedWordLimit', wordCount.wordId)}
                                >
                                    {wordCount.name}
                                </div>
                            ))}
                        </div>
                    </div>}

                    {/* <div className="py-2">
                        <div className="py-1 text-[16px]">Select context</div>
                        <div className="flex gap-4 cursor-pointer flex-wrap">
                        {contextList.map((context) => (
                            <div
                            key={context.contextId}
                            className={formData.selectedContext === context.contextId ? selectedClass : notSelectedClass}
                            onClick={() => handleSelect('selectedContext', context.contextId)}
                            >
                            {context.name}
                            </div>
                        ))}
                        </div>
                    </div> */}
                </div>}
                {!autoPersonaSelectionSettingStep &&<div className="dropdowns my-4">
                    {(formData.type.includes('followUp') || formData.type.includes('email')) && <Autocomplete
                        options={contextList.filter(item => item.type === 'email' && (item?.isPublic === undefined || item?.isPublic))}
                        getOptionLabel={(option) => option.name}
                        // renderOption={(props, option) => {
                        //     const isPublic = option?.isPublic === undefined || option?.isPublic === true;
                        //     return (
                        //         <li {...props}>
                        //         {option.name}{" "}
                        //         <span style={{ color: isPublic ? "green" : "red", marginLeft: "8px" }}>
                        //             {isPublic ? "(Public)" : "(Private)"}
                        //         </span>
                        //         </li>
                        //     );
                        // }}
                        renderInput={(params) => <TextField {...params} label="Select Context (public only)" variant="outlined" />}
                        value={contextList.filter(item => item.type === 'email' || item.contextId === "")?.find(fw => fw.contextId === formData.selectedContext) || null}
                        onChange={(e, newValue) => {
                            handleSelect('selectedContext', newValue ? newValue.contextId : '');
                        }}
                        style={{ width: "50%", marginTop: '20px' }}
                    />}

                    {formData.type.includes('linkedin') && <Autocomplete
                        options={contextList.filter(item => item.type === 'linkedin' && (item?.isPublic === undefined || item?.isPublic))}
                        getOptionLabel={(option) => option.name}
                        // renderOption={(props, option) => {
                        //     const isPublic = option?.isPublic === undefined || option?.isPublic === true;
                        //     return (
                        //         <li {...props}>
                        //         {option.name}{" "}
                        //         <span style={{ color: isPublic ? "green" : "red", marginLeft: "8px" }}>
                        //             {isPublic ? "(Public)" : "(Private)"}
                        //         </span>
                        //         </li>
                        //     );
                        // }}
                        renderInput={(params) => <TextField {...params} label="Select Context (public only)" variant="outlined" />}
                        value={contextList.filter(item => item.type === 'linkedin' || item.contextId === "")?.find(fw => fw.contextId === formData.selectedContext) || null}
                        onChange={(e, newValue) => {
                            handleSelect('selectedContext', newValue ? newValue.contextId : '');
                        }}
                        style={{ width: "50%", marginTop: '20px' }}
                    />}
                    {formData.type.includes('call') && <Autocomplete
                        options={contextList.filter(item => item.type === 'callprep' && (item?.isPublic === undefined || item?.isPublic))}
                        getOptionLabel={(option) => option.name}
                        // renderOption={(props, option) => {
                        //     const isPublic = option?.isPublic === undefined || option?.isPublic === true;
                        //     return (
                        //         <li {...props}>
                        //         {option.name}{" "}
                        //         <span style={{ color: isPublic ? "green" : "red", marginLeft: "8px" }}>
                        //             {isPublic ? "(Public)" : "(Private)"}
                        //         </span>
                        //         </li>
                        //     );
                        // }}
                        renderInput={(params) => <TextField {...params} label="Select Context (public only)" variant="outlined" />}
                        value={contextList.filter(item => item.type === 'callprep' || item.contextId === "")?.find(fw => fw.contextId === formData.selectedContext) || null}
                        onChange={(e, newValue) => {
                            handleSelect('selectedContext', newValue ? newValue.contextId : '');
                        }}
                        style={{ width: "50%", marginTop: '20px' }}
                    />}
                    {contextList.find(cx => cx.contextId === formData?.selectedContext)?.context && <div style={{ fontSize: "10px", backgroundColor: "#DCF9FF", color: "#57585C", display: 'flex', alignItems: 'center', gap: '10px', marginTop: "10px", border: "1px solid #0094E3", padding: '5px', borderRadius: '5px' }}>
                        {contextList.find(cx => cx.contextId === formData.selectedContext)?.context}
                    </div>}
                </div>}
            </div>}

            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={!isDeleteConfirmationShow}
                setHideModal={() => {
                    setIsDeleteConfirmationShow(false);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="40px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text fontSize={"20px"} fontWeight={300}>
                        {isActivated ? "Are you sure you want to deactivate this Setting" : "Are you sure you want to activate this Setting"}
                    </Text>
                    <Flex mt="15px">
                        <Flex
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => { onDeleteSetting() }}
                        >
                            Yes
                        </Flex>
                        <Flex
                            ml="16px"
                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => { setIsDeleteConfirmationShow(false) }}
                        >
                            Cancel
                        </Flex>
                    </Flex>
                </Flex>
            </ModalWrapper>
        </div>
    );
};

export default SavedStepForm;
