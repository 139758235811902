export default function SalesforceImage() {
    return (
        <svg width="114" height="81" viewBox="0 0 114 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M47.4554 9.53415C51.1164 5.71993 56.2138 3.35365 61.852 3.35365C69.3473 3.35365 75.8848 7.53195 79.3672 13.7356C82.394 12.3842 85.743 11.6316 89.267 11.6316C102.784 11.6316 113.743 22.686 113.743 36.3215C113.743 49.9589 102.784 61.0133 89.267 61.0133C87.6166 61.0133 86.005 60.8462 84.4448 60.5326C81.3796 66.0004 75.5362 69.6965 68.8284 69.6965C66.0219 69.6965 63.3672 69.049 61.0011 67.8944C57.8942 75.2061 50.651 80.3338 42.2111 80.3338C33.4209 80.3338 25.929 74.7725 23.0542 66.9717C21.7978 67.237 20.4947 67.3771 19.1598 67.3771C8.69432 67.3771 0.211426 58.8056 0.211426 48.2316C0.211426 41.1435 4.02081 34.9563 9.68574 31.646C8.5194 28.9612 7.87194 26.0012 7.87194 22.8876C7.87194 10.727 17.745 0.867188 29.9224 0.867188C37.0717 0.867442 43.4258 4.2664 47.4554 9.53415Z" fill="#0F9BD7" />
            <path d="M16.654 42.0793C16.5822 42.2662 16.679 42.3046 16.7024 42.3365C16.916 42.4915 17.1311 42.6033 17.3498 42.7286C18.5078 43.3427 19.6023 43.5211 20.7455 43.5211C23.0749 43.5211 24.5215 42.283 24.5215 40.2889V40.2507C24.5215 38.4069 22.888 37.7363 21.3578 37.2523L21.1577 37.1889C20.0031 36.8136 19.0068 36.4897 19.0068 35.729V35.6905C19.0068 35.0398 19.5891 34.5609 20.4919 34.5609C21.4948 34.5609 22.6845 34.8946 23.4504 35.3186C23.4504 35.3186 23.6772 35.4637 23.759 35.2452C23.804 35.1266 24.191 34.0837 24.2328 33.9704C24.2761 33.8487 24.1994 33.7568 24.1192 33.7084C23.2431 33.1761 22.0335 32.8125 20.7821 32.8125L20.5502 32.814C18.4176 32.814 16.9295 34.1023 16.9295 35.9477V35.9861C16.9295 37.93 18.5714 38.5625 20.1097 39.0012L20.3567 39.078C21.478 39.4218 22.4442 39.7186 22.4442 40.5064V40.5466C22.4442 41.2674 21.8151 41.8046 20.804 41.8046C20.4102 41.8046 19.1573 41.798 17.8038 40.942C17.6403 40.8452 17.5469 40.7768 17.4201 40.7001C17.3534 40.6584 17.1864 40.5866 17.1131 40.8053L16.654 42.0793Z" fill="white" />
            <path d="M50.7482 42.0793C50.6764 42.2662 50.7732 42.3046 50.7966 42.3365C51.0102 42.4915 51.2254 42.6033 51.4441 42.7286C52.602 43.3427 53.6966 43.5211 54.8397 43.5211C57.1691 43.5211 58.6157 42.283 58.6157 40.2889V40.2507C58.6157 38.4069 56.9822 37.7363 55.452 37.2523L55.2519 37.1889C54.0973 36.8136 53.101 36.4897 53.101 35.729V35.6905C53.101 35.0398 53.6833 34.5609 54.5861 34.5609C55.589 34.5609 56.7788 34.8946 57.5446 35.3186C57.5446 35.3186 57.7715 35.4637 57.8532 35.2452C57.8983 35.1266 58.2853 34.0837 58.327 33.9704C58.3703 33.8487 58.2937 33.7568 58.2135 33.7084C57.3374 33.1761 56.1293 32.8125 54.8764 32.8125L54.6444 32.814C52.5119 32.814 51.0237 34.1023 51.0237 35.9477V35.9861C51.0237 37.93 52.6672 38.5625 54.204 39.0012L54.4509 39.078C55.5722 39.4218 56.54 39.7186 56.54 40.5064V40.5466C56.54 41.2674 55.9108 41.8046 54.898 41.8046C54.5042 41.8046 53.2528 41.798 51.898 40.942C51.7346 40.8452 51.6378 40.7801 51.5143 40.7001C51.4726 40.6734 51.2758 40.5968 51.2091 40.8053L50.7482 42.0793Z" fill="white" />
            <path d="M75.9656 36.0856C75.7754 35.4399 75.4768 34.8709 75.0814 34.3971C74.686 33.9215 74.1836 33.5396 73.5879 33.2626C72.9939 32.9856 72.2929 32.8438 71.5055 32.8438C70.7162 32.8438 70.0137 32.9856 69.4197 33.2626C68.8257 33.5396 68.3234 33.9217 67.928 34.3989C67.5308 34.8727 67.234 35.4417 67.0438 36.0859C66.8536 36.7282 66.7568 37.4307 66.7568 38.1749C66.7568 38.9191 66.8536 39.6233 67.0438 40.2639C67.234 40.9081 67.5311 41.4769 67.928 41.9525C68.3234 42.4281 68.8273 42.8084 69.4213 43.0788C70.0153 43.3492 70.7162 43.4859 71.5055 43.4859C72.2929 43.4859 72.9921 43.349 73.5879 43.0788C74.1818 42.8084 74.6842 42.4281 75.0814 41.9525C75.4768 41.4786 75.7754 40.9096 75.9656 40.2639C76.1558 39.6216 76.2525 38.9191 76.2525 38.1749C76.2525 37.4304 76.1558 36.728 75.9656 36.0856ZM74.0217 38.1746C74.0217 39.301 73.8114 40.1903 73.3977 40.8177C72.9872 41.4384 72.368 41.7419 71.5055 41.7419C70.6411 41.7419 70.0254 41.4384 69.6216 40.8177C69.2127 40.1903 69.006 39.301 69.006 38.1746C69.006 37.0501 69.213 36.1623 69.6201 35.5418C70.0257 34.9277 70.6413 34.629 71.5057 34.629C72.3683 34.629 72.9875 34.9277 73.3979 35.5418C73.8114 36.1623 74.0217 37.0501 74.0217 38.1746Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M91.9294 41.4327C91.866 41.2443 91.6807 41.3161 91.6807 41.3161C91.3986 41.4246 91.0984 41.5228 90.7763 41.573C90.4527 41.6247 90.0955 41.6496 89.7118 41.6496C88.7723 41.6496 88.0233 41.3693 87.4876 40.8171C86.9519 40.2631 86.65 39.3686 86.6533 38.159C86.6566 37.0594 86.9219 36.2319 87.399 35.5995C87.8728 34.9721 88.5954 34.6501 89.5565 34.6501C90.3575 34.6501 90.9698 34.7435 91.6107 34.9436C91.6107 34.9436 91.7642 35.0103 91.836 34.8102C92.0078 34.3364 92.1331 34.0011 92.3149 33.4822C92.3666 33.3338 92.2398 33.2719 92.1932 33.2536C91.9411 33.155 91.3471 32.9934 90.8968 32.9249C90.4779 32.8615 89.9858 32.8281 89.4384 32.8281C88.6224 32.8281 87.895 32.9682 87.2725 33.2452C86.65 33.5206 86.1245 33.9026 85.7056 34.3782C85.2886 34.8538 84.9714 35.4225 84.7613 36.0667C84.5495 36.7091 84.4443 37.413 84.4443 38.1605C84.4443 39.7724 84.8782 41.0755 85.7375 42.0285C86.5985 42.9878 87.8899 43.4734 89.5751 43.4734C90.5714 43.4734 91.5923 43.2715 92.3284 42.9827C92.3284 42.9827 92.4687 42.9143 92.4068 42.7508L91.9294 41.4327Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M101.876 35.7187C101.71 35.0947 101.302 34.4656 101.035 34.1786C100.611 33.7214 100.195 33.4026 99.7849 33.2259C99.2492 32.9957 98.6053 32.8438 97.8993 32.8438C97.0784 32.8438 96.3324 32.9823 95.7267 33.2659C95.121 33.5495 94.6121 33.9383 94.2116 34.4223C93.8129 34.9045 93.5124 35.4784 93.3205 36.1309C93.1285 36.7784 93.0317 37.4842 93.0317 38.2299C93.0317 38.9891 93.1318 39.6967 93.3304 40.3306C93.5305 40.973 93.8511 41.5354 94.2831 42.0044C94.717 42.4749 95.2725 42.842 95.9383 43.1007C96.6008 43.3561 97.4051 43.4895 98.3278 43.4862C100.228 43.4811 101.229 43.0574 101.642 42.8288C101.715 42.787 101.783 42.717 101.698 42.5136L101.266 41.3088C101.201 41.1303 101.019 41.1953 101.019 41.1953C100.547 41.3704 99.8798 41.6841 98.3181 41.6808C97.2969 41.6793 96.5409 41.3788 96.0671 40.9065C95.5816 40.4258 95.3412 39.7186 95.3013 38.719L101.88 38.7241C101.88 38.7241 102.054 38.7223 102.072 38.5522C102.079 38.4819 102.298 37.2003 101.876 35.7187ZM95.3316 37.0936C95.425 36.4678 95.5969 35.9474 95.864 35.5418C96.266 34.9262 96.8801 34.5857 97.7445 34.5857C98.6088 34.5857 99.1779 34.9262 99.5883 35.5418C99.8602 35.9489 99.9789 36.4894 100.025 37.0954L95.3316 37.0936Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M49.1742 35.7187C49.009 35.0947 48.6004 34.4656 48.3333 34.1786C47.9094 33.7214 47.4956 33.4026 47.0834 33.2259C46.5462 32.9957 45.9038 32.8438 45.1996 32.8438C44.377 32.8438 43.6313 32.9823 43.0255 33.2659C42.4198 33.5495 41.9109 33.9383 41.5104 34.4223C41.1117 34.9045 40.8113 35.4784 40.6193 36.1309C40.4273 36.7784 40.3306 37.4842 40.3306 38.2299C40.3306 38.9891 40.4306 39.6967 40.6292 40.3306C40.8293 40.973 41.1499 41.5354 41.5819 42.0044C42.0158 42.4749 42.5713 42.842 43.2371 43.1007C43.8996 43.3561 44.7039 43.4895 45.6266 43.4862C47.5272 43.4811 48.5283 43.0574 48.9405 42.8288C49.0138 42.787 49.0823 42.717 48.9973 42.5136L48.5652 41.3088C48.5 41.1303 48.3183 41.1953 48.3183 41.1953C47.846 41.3704 47.1786 41.6841 45.6169 41.6808C44.5975 41.6793 43.8398 41.3788 43.366 40.9065C42.8804 40.4258 42.6401 39.7186 42.6001 38.719L49.1793 38.7241C49.1793 38.7241 49.353 38.7223 49.3713 38.5522C49.3779 38.4819 49.5964 37.2003 49.1742 35.7187ZM42.6301 37.0936C42.7236 36.4678 42.8954 35.9474 43.1607 35.5418C43.5663 34.9262 44.1786 34.5857 45.043 34.5857C45.9074 34.5857 46.478 34.9262 46.8884 35.5418C47.1588 35.9489 47.2772 36.4894 47.324 37.0954L42.6301 37.0936Z" fill="white" />
            <path d="M31.0241 36.7747C30.757 36.7549 30.4118 36.7447 29.9981 36.7447C29.4341 36.7447 28.8885 36.8147 28.3762 36.9532C27.8622 37.0917 27.4001 37.3053 27.0029 37.589C26.6024 37.8759 26.2821 38.2413 26.0484 38.6751C25.8147 39.1089 25.6963 39.6194 25.6963 40.19C25.6963 40.7741 25.7963 41.2797 25.9983 41.6934C26.1984 42.1089 26.4871 42.456 26.856 42.723C27.2214 42.9901 27.6735 43.1854 28.196 43.3038C28.7133 43.4222 29.2989 43.4823 29.9398 43.4823C30.6155 43.4823 31.2864 43.4255 31.9387 43.3155C32.5843 43.2053 33.3754 43.0451 33.5956 42.9934C33.8143 42.9433 34.0562 42.8766 34.0562 42.8766C34.2197 42.8348 34.2064 42.6612 34.2064 42.6612L34.2031 36.6441C34.2031 35.3227 33.851 34.3448 33.1585 33.7376C32.4677 33.1336 31.4498 32.8281 30.1351 32.8281C29.6411 32.8281 28.8485 32.8948 28.3714 32.99C28.3714 32.99 26.9347 33.2686 26.344 33.7325C26.344 33.7325 26.2139 33.8127 26.2839 33.9945L26.7493 35.2458C26.8076 35.4078 26.9663 35.3525 26.9663 35.3525C26.9663 35.3525 27.0164 35.3327 27.0747 35.2975C28.3396 34.6101 29.9413 34.63 29.9413 34.63C30.6522 34.63 31.1993 34.7751 31.5682 35.0554C31.927 35.3324 32.1087 35.7461 32.1087 36.6238V36.9023C31.5448 36.8216 31.0241 36.7747 31.0241 36.7747ZM28.4063 41.3983C28.1476 41.1931 28.1127 41.1414 28.0274 41.008C27.8956 40.8061 27.8289 40.5192 27.8289 40.1521C27.8289 39.5746 28.0208 39.1576 28.4162 38.879C28.4129 38.879 28.9835 38.3851 30.325 38.4034C31.2678 38.4151 32.1121 38.5552 32.1121 38.5552V41.547C32.1121 41.547 31.2762 41.7255 30.3349 41.7823C28.997 41.864 28.4012 41.3968 28.4063 41.3983Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M84.0993 33.3887C84.1495 33.2402 84.0443 33.1684 84.0008 33.1519C83.889 33.1101 83.3284 32.9917 82.8978 32.9635C82.0701 32.9133 81.6113 33.0518 81.2009 33.2372C80.792 33.4208 80.3398 33.7194 80.0895 34.058L80.0878 33.2555C80.0878 33.1453 80.0093 33.0569 79.8991 33.0569H78.2121C78.1019 33.0569 78.0234 33.1453 78.0234 33.2555V43.0718C78.0234 43.1818 78.1118 43.2704 78.2236 43.2704H79.9523C80.0623 43.2704 80.1509 43.1818 80.1509 43.0718V38.1678C80.1509 37.5089 80.2242 36.8531 80.3696 36.4409C80.5114 36.032 80.7049 35.7066 80.9453 35.4731C81.1856 35.2394 81.4593 35.0759 81.7595 34.9843C82.0648 34.8908 82.4036 34.859 82.6404 34.859C82.9859 34.859 83.363 34.9491 83.363 34.9491C83.4916 34.9624 83.5616 34.884 83.6049 34.7704C83.7171 34.47 84.0392 33.5674 84.0993 33.3887Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M67.8714 28.8329C67.6611 28.768 67.4709 28.7245 67.2206 28.6794C66.967 28.6325 66.6666 28.6094 66.3262 28.6094C65.1364 28.6094 64.1987 28.9465 63.5395 29.6105C62.8855 30.2696 62.4399 31.2741 62.2164 32.5939L62.1362 33.0395H60.6426C60.6426 33.0395 60.4591 33.0329 60.4224 33.2315L60.1772 34.5997C60.1589 34.7298 60.2157 34.8115 60.3908 34.8115H61.8459L60.3692 43.0477C60.2541 43.7102 60.1222 44.2558 59.9753 44.6695C59.8317 45.0784 59.6899 45.3837 59.5165 45.6055C59.348 45.8191 59.1896 45.9792 58.9141 46.0711C58.6873 46.1462 58.4253 46.1811 58.1399 46.1811C57.9813 46.1811 57.7694 46.1544 57.6126 46.1228C57.4558 46.0928 57.374 46.0576 57.2572 46.0093C57.2572 46.0093 57.0869 45.9441 57.0186 46.1144C56.9652 46.2544 56.5782 47.3207 56.5313 47.4526C56.4845 47.5827 56.5496 47.6845 56.6332 47.7146C56.8267 47.7831 56.9702 47.8281 57.2338 47.8898C57.6009 47.9766 57.9095 47.9814 58.1982 47.9814C58.8039 47.9814 59.3561 47.8964 59.8134 47.7311C60.274 47.5641 60.6745 47.2739 61.0299 46.8836C61.4121 46.4596 61.6539 46.0159 61.8841 45.4102C62.1127 44.8111 62.3062 44.0669 62.4631 43.1992L63.9449 34.8113H66.1123C66.1123 34.8113 66.2959 34.8179 66.3325 34.6193L66.5777 33.2511C66.5945 33.121 66.5393 33.0392 66.3641 33.0392H64.2601C64.27 32.9924 64.3652 32.2515 64.6071 31.5559C64.7105 31.2573 64.9042 31.0187 65.0692 30.8535C65.2293 30.6915 65.4162 30.5764 65.6199 30.5097C65.8284 30.443 66.0655 30.4097 66.3257 30.4097C66.5243 30.4097 66.7177 30.4331 66.8662 30.4631C67.0699 30.5064 67.1498 30.5299 67.2033 30.5449C67.4169 30.6116 67.4452 30.5482 67.4887 30.4448L67.991 29.0633C68.0432 28.9146 67.9182 28.8513 67.8714 28.8329Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M38.4742 43.0634C38.4742 43.1736 38.3958 43.2637 38.2855 43.2637H36.5402C36.43 43.2637 36.3516 43.1736 36.3516 43.0634V29.0189C36.3516 28.9089 36.43 28.8203 36.5402 28.8203H38.2855C38.3958 28.8203 38.4742 28.9087 38.4742 29.0189V43.0634Z" fill="white" />
        </svg>
    )
}