import React, { useState } from 'react';
import { TextField, Box, Typography, Chip, Tooltip } from "@mui/material";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "assets/icons/DeleteIcon";

const PreviewDataContent = ({ previewData, setPreviewData, handleSubmitPreviewData, setIsPreviewPopupOpen }) => {
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [expandedTagRows, setExpandedTagRows] = useState(new Set());
  const MAX_TAGS_DISPLAY = 2;

    const toggleRowExpansion = (rowIndex) => {
        const newExpandedRows = new Set(expandedRows);
        if (expandedRows.has(rowIndex)) {
            newExpandedRows.delete(rowIndex);
        } else {
            newExpandedRows.add(rowIndex);
        }
        setExpandedRows(newExpandedRows);
    };

    const renderCellContent = (content, rowIndex) => {
        const words = content.split(" ");
        
        if (words.length > 20) {
            if (!expandedRows.has(rowIndex)) {
                const visibleWords = words.slice(0, 20).join(" ");
                return (
                    <>
                        {visibleWords}...
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                toggleRowExpansion(rowIndex);
                            }}
                            style={{ color: 'rgb(0, 148, 227)', textDecoration: 'underline', cursor: 'pointer', marginLeft: "3px"}}
                        >
                            View More
                        </a>
                    </>
                );
            } else {
                return (
                    <>
                        {content}
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                toggleRowExpansion(rowIndex);
                            }}
                            style={{ color: 'rgb(0, 148, 227)', textDecoration: 'underline', cursor: 'pointer', marginLeft: "3px" }}
                        >
                            View Less
                        </a>
                    </>
                );
            }
        }
        
        return content;
    };

    const toggleTagExpansion = (index) => {
        const newExpandedTagRows = new Set(expandedTagRows);
        if (expandedTagRows.has(index)) {
        newExpandedTagRows.delete(index);
        } else {
        newExpandedTagRows.add(index);
        }
        setExpandedTagRows(newExpandedTagRows);
    };

    const handleDeleteRow = (id) => {
        const updatedData = previewData.filter((_, index) => index !== id);
        setPreviewData(updatedData);
    };

  return (
    <Box>
        <Box marginBottom={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"} justifyItems={"center"} >
            <Typography variant="h6">
                Preview Data
            </Typography>
            <Button size="xs" variant="secondary" onClick={() => setIsPreviewPopupOpen(false)} iconName="arrowLeft">
                Back
            </Button>
        </Box>
        <Box sx={{ backgroundColor: 'white', borderRadius: 2, padding: 2, boxShadow: 1, paddingY: 2 , paddingBottom:"40px"}}>
            <Box display="flex" flexDirection="column" gap={2}>
                {previewData.map((row, rowIndex) => (
                    <Flex key={rowIndex} padding="10px" alignItems="center" justifyContent="space-between">
                        <Flex width={"5%"}>
                            <Box>{rowIndex + 1}</Box>
                        </Flex>
                        <Flex width={"80%"} justifyContent={"space-between"}>
                            <Flex padding={"0 5px"} textWrap="balance" width="20%" alignItems={"center"}>
                                <Box fontSize={"12px"}>{row.title}</Box>
                            </Flex>
                            <Flex padding={"0 5px"} textWrap="balance" width="30%" alignItems={"center"}>
                                <Box fontSize={"10px"}>
                                    {(expandedTagRows.has(rowIndex) ? row.tags : row.tags.slice(0, MAX_TAGS_DISPLAY)).map((tag, i) => (
                                        <Chip
                                            key={i}
                                            label={tag}
                                            style={{
                                                border: "1px solid rgb(0, 148, 227)",
                                                color: "rgb(0, 148, 227)",
                                                backgroundColor: "white",
                                                fontSize: "10px",
                                                margin: "2px",
                                            }}
                                        />
                                    ))}

                                    {row.tags.length > MAX_TAGS_DISPLAY && (
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                toggleTagExpansion(rowIndex);
                                            }}
                                            style={{ color: 'rgb(0, 148, 227)', textDecoration: 'underline', cursor: 'pointer', marginLeft: "3px" }}
                                        >
                                            {expandedTagRows.has(rowIndex) ? "Show Less" : "View More"}
                                        </a>
                                    )}
                                </Box>
                            </Flex>
                            <Flex padding={"0 5px"} textWrap="balance" width="30%" alignItems={"center"}>
                                <Box fontSize={"12px"}>{renderCellContent(row.rawData, rowIndex)}</Box>
                            </Flex>
                        </Flex>
                        <Flex width={"15%"} display={"flex"} justifyContent={"center"}>
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handleDeleteRow(rowIndex)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Flex>
                    </Flex>
                ))}
                <Box display={"flex"} gap={"10px"} justifyContent={"center"} marginTop={"20px"}>
                    <Button size="xs" variant="secondary" fontSize={"14px"} padding={"10px 10px"} onClick={() => setIsPreviewPopupOpen(false)}>Cancel</Button>
                    <Button size="xs" variant="primary" fontSize={"14px"} padding={"10px 10px"}  onClick={handleSubmitPreviewData} color="primary">
                        Submit
                    </Button>
                </Box>
            </Box>
        </Box>
    </Box>
  );
};

export default PreviewDataContent;