import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import ApolloSearchTable from "./ApolloSearchTable";

function EmailTemplateRightSection() {
    return (
        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
            <ApolloSearchTable></ApolloSearchTable>
        </Flex>
    )
}

export default EmailTemplateRightSection;