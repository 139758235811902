import React, { useState, useEffect } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { CORE_API_URL, axiosHelper } from "utils";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hooks";
import { checkUnauthorized, convertDate } from "utils/constants";
import { useNavigate } from "react-router";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { differenceInDays } from "date-fns";
import AeReturnAnalyticsTable from "./AeReturnAnalyticsTable";
import {
  setAeReturnAnalyticsData,
  setAeReturnAnalyticsDataLoading,
  setAnalyticsSnackbar,
  setAnalyticsPageNo,
  setAnalyticsDateRange,
  setSelectedRepNames,
} from "./store/aeReturnAnalyticsReducer";
import CalendarComponent from "./components/CalendarComponent";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Form, Formik } from "formik";
import { Dropdown } from "@evabot/eva-react-core-library/dist/atoms/Dropdown/Dropdown";
import DropArrowDown from "./DropArrowDown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import userService from "services/userService";
import Select from 'react-select';
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

interface AeReturnAnalyticsRightSectionProps {
  searchValues?: any;
}

const AeReturnAnalyticsRightSection: React.FC<
  AeReturnAnalyticsRightSectionProps
> = ({ searchValues }) => {
  const filteredValues = useAppSelector(
    (state) => state?.analyticsState?.selectedRepNames
  );
  const loggedInMember = useAppSelector(
    (state) => state.profileState.profileData
  );
  const [loading, setLoading] = useState(true);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [repNames, setRepNames] = useState([]);
  const [adminMemberList, setAdminMemberList] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(localStorage.getItem("organizationId"));
  const [filteredNames, setFilteredNames] = useState(filteredValues);
  const d1Present = new Date();
  const d2Last = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
  const dateValues = useAppSelector(
    (state) => state?.analyticsState?.dateRange
  );

  const allAnalyticsData = useAppSelector(
    (state) => state.analyticsState?.analyticsData
  );
  const allAnalyticsDataLoading = useAppSelector(
    (state) => state.analyticsState?.loading
  );
  const analyticsSnackbar = useAppSelector(
    (state) => state.analyticsState?.analyticsSnackbar
  );

  const isSuperAdmin = localStorage.getItem("superadminview");
  const journeyData = useAppSelector((state) => state.globalState.journey);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchAnalyticsData = async (fromDate, endDate, userData?: any[]) => {
    dispatch(setAeReturnAnalyticsDataLoading(true));
    setLoading(true);
    try {
      const result = await axiosHelper({
        url: CORE_API_URL + 'rapport/report/getWeeklyReport',
        method: "POST",
        JSONData: {
          userIds: userData?.map((data) => data?.userId) || [],
          startDate: fromDate,
          endDate: endDate,
          organizationId: loggedInMember.role === "superadmin" ? selectedOrgId : localStorage.getItem("organizationId"),
        },
        urlOverride: true,
      });
      if (result?.status === 200) {
        setAnalyticsData(result?.data);
        setRepNames(
          result?.data?.map((data) => `${data?.firstName} ${data?.lastName}`)
        );
        dispatch(setAeReturnAnalyticsData(result?.data));
        dispatch(setAeReturnAnalyticsDataLoading(false));
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      checkUnauthorized(err, journeyData, navigate);
      dispatch(setAeReturnAnalyticsDataLoading(false));
      setLoading(false);
    }
  };

  const fetchAdminMemberList = async () => {
    try {
      const response = await userService.fetchAdminMemberList();
      const adminMemberList = response.data.reduce((accumulator, { organizationId, firstName, lastName, email }) => {
        if (organizationId && !accumulator.map[organizationId]) {
          accumulator.map[organizationId] = true;
          accumulator.list.push({
            value: organizationId,
            label: `${firstName} ${lastName} <${email}>`
          });
        }
        return accumulator;
      }, { map: {}, list: [] }).list;

      setAdminMemberList(adminMemberList);
    } catch (error) {
      console.error("Error fetching admin member list", error);
    }
  };


  useEffect(() => {
    fetchAnalyticsData(convertDate(d2Last), convertDate(d1Present));
  }, [selectedOrgId]);

  useEffect(() => {
    fetchAdminMemberList();
    fetchAnalyticsData(convertDate(d2Last), convertDate(d1Present));
  }, []);

  useEffect(() => {
    if (searchValues?.length > 0) {
      //@ts-ignore
      dispatch(setAeReturnAnalyticsDataLoading(true));
      const filteredData = analyticsData?.filter((data) => {
        return (
          `${data?.firstName} ${data?.lastName}` === searchValues ||
          data?.email === searchValues
        );
      });
      setRepNames(
        filteredData?.map((data) => `${data?.firstName} ${data?.lastName}`)
      );

      setAnalyticsData(filteredData);
      //@ts-ignore
      dispatch(setAnalyticsPageNo(0));
      setTimeout(() => {
        //@ts-ignore
        dispatch(setAeReturnAnalyticsDataLoading(false));
      }, 1000);
    } else {
      setRepNames(
        allAnalyticsData?.map((data) => `${data?.firstName} ${data?.lastName}`)
      );
      setAnalyticsData(allAnalyticsData);
    }
  }, [searchValues]);

  const [date, setDate] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(
      setAnalyticsDateRange([
        new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
        new Date(),
      ])
    );
    setDate([new Date(Date.now() - 6 * 24 * 60 * 60 * 1000), new Date()]);
  }, []);


  return (
    <>
      <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
        <SnackBar
          iconName={"paperPlane"}
          setShowSnackBar={() => {
            dispatch(
              setAnalyticsSnackbar({
                //@ts-ignore
                show: false,
                message: "",
              })
            );
          }}
          show={analyticsSnackbar.show}
          message={analyticsSnackbar.message}
          timeout={2000}
          anchor={"top-right"}
          type={"success"}
          bgColorr="#24B300"
        />
        <>
          <Flex alignItems={"center"} className="report-filter">
            {/* <Text fontSize={"24px"} fontWeight={400} color="#050505">
              Weekly Usage View{" "}
            </Text> */}
            <Formik
              initialValues={{
                state: filteredNames,
                adminList: filteredNames,
              }}
              onSubmit={() => { }}
              enableReinitialize
            >
              {({ values, setFieldValue }) => {
                return (
                  <Form>
                    <Flex alignItems={"center"} gap="10px">
                      {isSuperAdmin === "yes" && loggedInMember.role === "superadmin" && (<><Text
                        color="#050505"
                        fontSize={"12px"}
                        fontWeight={400}
                        mr="5px"
                      >
                        Filter by:
                      </Text>
                        <Box width={"300px"} onClick={() => setOpenModal(false)}>

                          <Select
                            className="single-select"
                            placeholder="Admin List"
                            name="organizationName"
                            options={adminMemberList}
                            onChange={(organizationName) => {
                              setFieldValue('organizationName', organizationName ? organizationName.value : null);
                              setSelectedOrgId(organizationName.value);
                            }}
                            styles={{
                              control: (provided: Record<string, unknown>, state: any) => ({
                                ...provided,
                                width: "300px",
                                height: "48px"
                              }),
                            }}
                          />
                        </Box></>
                      )}

                      {(loggedInMember.role === "admin" || (!isSuperAdmin && loggedInMember.role === "superadmin")) && (<><Text
                        color="#050505"
                        fontSize={"12px"}
                        fontWeight={400}
                        mr="5px"
                      >
                        Filter by:
                      </Text>
                        <Box width={"300px"} onClick={() => setOpenModal(false)}>
                          <Dropdown
                            limitTags={1}
                            placeholder="Rep name: e.g. John Doe"
                            padding={"7.5px 0px !important"}
                            options={repNames}
                            name="state"
                            variantType="primary"
                            checkboxLabel
                            isMultiple
                            //@ts-ignore
                            onChange={(e, valueField) => {
                              // fetchAnalyticsData(
                              //   convertDate(dateValues[0]),
                              //   convertDate(dateValues[1]),
                              //   analyticsData?.filter((data) =>
                              //     valueField?.includes(
                              //       `${data?.firstName} ${data?.lastName}`
                              //     )
                              //   )
                              // );
                              // setFilteredNames(valueField);
                              // dispatch(setSelectedRepNames(valueField));
                              console.log("closedd change", valueField);
                              if (valueField?.length > 0) {
                                const filteredData = allAnalyticsData?.filter(
                                  (data) => {
                                    return valueField.includes(
                                      `${data?.firstName} ${data?.lastName}`
                                    );
                                  }
                                );
                                setAnalyticsData(filteredData);
                              } else {
                                setAnalyticsData(allAnalyticsData);
                              }
                            }}
                            onDropdownClose={() => {
                              // console.log("closedd", values?.state, values);
                              // if (values?.state?.length > 0) {
                              // }
                            }}
                            clear
                          />
                        </Box></>)}
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        px={"10px"}
                        width={"300px"}
                        background={"#FFFFFF"}
                        borderRadius={"6px"}
                        border="1px solid #cccccc"
                        height={"45.13px"}
                        position={"relative"}
                        cursor="pointer"
                        onClick={() => {
                          setOpenModal(!openModal);
                        }}
                      >
                        <Text
                          color="#57585C"
                          fontSize={"12px"}
                          fontWeight={600}
                        >
                          Date:{" "}
                          <Text as="span" fontWeight={400}>
                            {date?.length > 0
                              ? `${date[0]?.toDateString()?.split(" ")[2]} ${date[0]?.toDateString()?.split(" ")[1]
                              } - ${date[1]?.toDateString()?.split(" ")[2]} ${date[1]?.toDateString()?.split(" ")[1]
                              }`
                              : `${d1Present?.toDateString()?.split(" ")[2]} ${d1Present?.toDateString()?.split(" ")[1]
                              } - ${d2Last?.toDateString()?.split(" ")[2]} ${d2Last?.toDateString()?.split(" ")[1]
                              }`}
                          </Text>
                        </Text>
                        <ArrowDropDownIcon />
                      </Flex>
                      {openModal && date?.length > 0 && (
                        <CalendarComponent
                          date={date}
                          setDate={setDate}
                          setOpenModal={setOpenModal}
                          fetchAnalyticsData={fetchAnalyticsData}
                        />
                      )}
                    </Flex>
                  </Form>
                );
              }}
            </Formik>
          </Flex>

          {allAnalyticsDataLoading || loading ? (
           <MemoRevenoidLoader />
          ) : analyticsData?.length > 0 ? (
            <AeReturnAnalyticsTable analyticsData={analyticsData} />
          ) : (
            <Flex
              width="100%"
              height="100%"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Flex
                flexDirection={"column"}
                alignItems={"center"}
                backgroundColor={"#FFFFFF"}
                width={"380px"}
                height={"270px"}
                justifyContent={"center"}
                borderRadius={"12px"}
                gap="24px"
              >
                <Text
                  fontWeight={500}
                  fontSize={"20px"}
                  textAlign={"center"}
                  color="#050505"
                >
                  {searchValues?.length > 0
                    ? "You have no reports for this search"
                    : " You have no reports"}
                </Text>
              </Flex>
            </Flex>
          )}
        </>
      </Flex>
    </>
  );
};

export default AeReturnAnalyticsRightSection;
