import React, { FC, useEffect, useState } from "react";
import { SignupLayout } from "@evabot/eva-react-core-library/dist/templates/SignupLayout/SignupLayout";
import AESignupRightSection from "./AESignupRightSection";
import AESignupLeftSectionO1 from "./AESignupLeftSectionO1";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { setGlobalStateRole } from "store/globalStateReducer";
import AEMobileSignUp01 from "./AEMobileSignUp01";
const AESignupLayoutO1: FC = () => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setGlobalStateRole({ journeyName: 'ae', journeyPath: '1' }));
    localStorage.setItem("journeyPath", "1");
 

  }, [dispatch]);

  const params = useParams();
  const location = useLocation();

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [ssoScreen, setSsoScreen] = useState(false);

  const handleSnackbarError = (message) => {
    setShowSnackBar(true);
    setSnackbarMessage(message);
  }

  console.log(location, "search AE", params);

  return screenSize.dynamicWidth > 576 ? (
    <>
      {
        //@ts-ignore
        <SignupLayout
          as="div"
          left={30}
          right={70}
          leftChildren={<AESignupLeftSectionO1 handleSnackbarError={handleSnackbarError} handleSsoScreen={setSsoScreen} />}
          rightChildren={
            <AESignupRightSection
              showSnackBar={showSnackBar}
              setShowSnackBar={setShowSnackBar}
              snackbarMessage={snackbarMessage}
              showSsoLayout={ssoScreen}
            />
          }
        />
      }
    </>
  ) : (
    <AEMobileSignUp01 handleSnackbarError={handleSnackbarError} showSnackBar={showSnackBar}
      setShowSnackBar={setShowSnackBar}
      snackbarMessage={snackbarMessage} />
  )
};

export default AESignupLayoutO1;
