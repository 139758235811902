import { IconButton } from "@mui/material";

interface SendIconButtonProps {
  onClick?: () => void;
  type?: "submit";
  disabled?: boolean;
}

const SendIconButton = ({ onClick, type, disabled }: SendIconButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      type={type}
      color="primary"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="12"
        viewBox="0 0 15 12"
        fill="none"
      >
        <path
          d="M0.505859 11.6992C0.505859 11.9115 0.720217 12.0566 0.917277 11.9778L14.8095 6.42089C15.0609 6.32031 15.0609 5.96438 14.8095 5.8638L0.917276 0.306909C0.720217 0.228085 0.505859 0.373213 0.505859 0.585452V4.55142C0.505859 4.69958 0.614026 4.82556 0.760486 4.84797L9.22015 6.14234L0.760485 7.43672C0.614025 7.45913 0.505859 7.5851 0.505859 7.73327V11.6992Z"
          fill="black"
        />
        <path
          d="M0.505859 11.6992C0.505859 11.9115 0.720217 12.0566 0.917277 11.9778L14.8095 6.42089C15.0609 6.32031 15.0609 5.96438 14.8095 5.8638L0.917276 0.306909C0.720217 0.228085 0.505859 0.373213 0.505859 0.585452V4.55142C0.505859 4.69958 0.614026 4.82556 0.760486 4.84797L9.22015 6.14234L0.760485 7.43672C0.614025 7.45913 0.505859 7.5851 0.505859 7.73327V11.6992Z"
          fill="url(#paint0_linear_22178_10135)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_22178_10135"
            x1="0.505859"
            y1="6.14062"
            x2="15.5059"
            y2="6.14062"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#8D68F6" />
            <stop offset="1" stop-color="#0094E3" />
          </linearGradient>
        </defs>
      </svg>
    </IconButton>
  );
};

export default SendIconButton;
