import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex';
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import { Box } from '@evabot/eva-react-core-library/dist/atoms/Box/Box';
import { ToggleButton } from '@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton';
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Tag } from '@evabot/eva-react-core-library/dist/molecules/Tag/Tag';
import userService from 'services/userService';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Backdrop, Tooltip, Box as MuiBox, Checkbox, InputAdornment, TextField, Typography, IconButton, Popper, List, ListItem, Paper, ClickAwayListener } from "@mui/material";
import { ModalWrapper } from '@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper';
import * as yup from 'yup';
import { FormikTextArea } from '@evabot/eva-react-core-library/dist/atoms/FormikTextArea/FormikTextArea';
import EditButton from './component/EditButton';
import DeleteButton from './component/DeleteButton';
import EyeIcon from './component/EyeIcon';
import { useAppSelector } from "hooks";
import AIIconButton from './component/AIIconButton';
import { axiosHelperCore } from 'utils';
import CustomDataInfoBox from './component/CustomDataInfoBox';
import TagEditor from './component/TagEditor';
import { HighlightBox } from './component/style';
import InfoIcon from './component/InfoIcon';
import MemoRevenoidLoader from 'components/RevenoidLoader/RevenoidLoader';

const showMoreLessLinkStyle = {
    color: "rgb(0, 148, 227)",
    marginLeft: "10px",
};

const AddFrameworkAndContextSchema = yup.object().shape({
    name: yup.string().required("Name is required."),
    content: yup.string().required("Content is required."),
});

function FrameworkAndContextTableBodyField({ index, item, reFetch, contextWordLimit, dataRepositoryTagsList, dataRepositoryFeatureCheck }) {
    const [hideModal, setHideModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [isAIVisibilityEnabled, setIsAIVisibilityEnabled] = useState(item?.isExtensionVisibilityEnabled ?? true);
    const [hide, setHide] = useState(true);
    const [associtaedUserName, setAssociatedUserName] = useState([]);
    const [showMoreUserNames, setShowMoreUserNames] = useState(false);

    const [showMore, setShowMore] = useState(false);
    const maxLength = 50;


    const [query, setQuery] = useState('');
    const [mlCustomDataResponse, setMlCustomDataResponse] = useState('');
    const [filteredTags, setFilteredTags] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [hasSelectedTag, setHasSelectedTag] = useState(false);
    const [mlRepoIdsResponse, setMlRepoIdsResponse] = useState([]);
    const [isFullTextVisible, setIsFullTextVisible] = useState(false);
    const [contentHasValue, setContentHasValue] = useState(Boolean(item?.contextValue));
    const [usedTags, setUsedTags] = useState(new Set());
    const [isQueryModified, setIsQueryModified] = useState(false);
    const [originalQuery, setOriginalQuery] = useState('');
    const [isCustomDataGenerated, setIsCustomDataGenerated] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const inputRef = React.useRef(null);
    const [tagData, setTagData] = useState<{
        customData: string;
        repoIds: string[];
        query: string;
      }>({
        customData: "",
        repoIds: [],
        query: "",
    });

    const organizationUserData = useAppSelector(
        (state) => state.memberListingState.memberListingData
    );

    const loggedInUserOrgId = useAppSelector(
        (state) => state.profileState.profileData.organizationId
    )

    const userId = useAppSelector(
        (state) => state.profileState.profileData.userId
    );

    const content = item.type === "framework" ? item?.frameworkValue : item?.contextValue;
    const displayContent = showMore ? content : content?.slice(0, maxLength);

    const openEditFormpopup = (item) => {
        setEditItem(item)
        setHideModal(false);
    }

    const toggleAIVisibility = async (values) => {
        setIsAIVisibilityEnabled((prev) => !prev);

        setLoading(true);
        let dataObj = {};
        if (values?.type === "framework") {
            dataObj = { "userId": values?.userId, type: "framework", "frameworkId": values?.id, "isAIVisibilityEnabled": values?.isExtensionVisibilityEnabled !== undefined ? !values.isExtensionVisibilityEnabled : !isAIVisibilityEnabled };
        } else {
            dataObj = { "userId": values?.userId, type: "context", "contextId": values?.id, "isAIVisibilityEnabled": values?.isExtensionVisibilityEnabled !== undefined ? !values.isExtensionVisibilityEnabled : !isAIVisibilityEnabled };
        }

        try {
            const updateMember = await userService.updateAIVisibility(dataObj);
            if (updateMember?.success) {
                reFetch();
                setLoading(false);
            } else {
                setSnackbarMessage(updateMember?.result?.message || 'Error Changing AI Sequence Status');
                setShowSnackBar(true);
                setSnackbarType('error');
                setLoading(false);
            }
        } catch (error) {
            console.error("Error Changing AI Sequence Status", error);
            setLoading(false);
        }
    };

    const wordCount = (text) => {
        return text ? text.split(/\s+/).length : 0;
    };

    const handlePublicPrivateStatus = async (updatedStatus, id, type, userId) => {

        setLoading(true);
        let dataObj = {};
        if (type === "framework") {
            dataObj = { "userId": userId, "frameworkId": id, "contextId": null, "isPublic": updatedStatus, "type": type };
        } else {
            dataObj = { "userId": userId, "frameworkId": null, "contextId": id, "isPublic": updatedStatus, "type": type };
        }

        try {
            const updateMember = await userService.updateFrameworkOrContextStatus(dataObj);
            if (updateMember?.success) {
                reFetch();
                setLoading(false);
                setSnackbarMessage('Status Changed');
                setShowSnackBar(true);
                setSnackbarType('success');
            } else {
                setSnackbarMessage(updateMember?.result?.message || 'Error Changing Status');
                setShowSnackBar(true);
                setSnackbarType('error');
                setLoading(false);
            }
        } catch (error) {
            console.error("Error updating member", error);
            setLoading(false);
        }

    };

    const handleUpdateContextAndFramework = async (values, id) => {
        setLoading(true);
        const obj = { "id": values.id, isPublic: values.isPublic, "userId": values.userId, "name": values.name, "action": "update" };
        if (values.type === "framework") {
            obj["framework"] = values.content;
            obj["type"] = "framework";
            obj["frameworkId"] = id;
            try {
                const updateFramework = await userService.updateOrDeleteFramework(obj);
                updatingMessage(updateFramework);
            } catch (error) {
                console.error("Error updating member", error);
                setLoading(false);
            }
        } else {
            if (wordCount(values.content) > contextWordLimit) {
                setSnackbarMessage(`The content exceeds the word limit of ${contextWordLimit} words.`);
                setSnackbarType("error");
                setShowSnackBar(true);
                return;
            }
            obj["context"] = values.content;
            obj["type"] = "context";
            obj["contextId"] = id;

            if (query) {
                obj["query"] = query
            }

            if (mlRepoIdsResponse) {
                obj["repoIds"] = mlRepoIdsResponse
            }

            if (mlCustomDataResponse) {
                obj["customData"] = mlCustomDataResponse
            }

            try {
                const updateContext = await userService.updateOrDeleteContext(obj);
                if (updateContext) {
                    updatingMessage(updateContext);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error updating member", error);
                setLoading(false);
            }
        }
    }

    const deleteData = async (values) => {
        const obj = { "id": values.objectId, "userId": values.userId, "name": values.name, "action": "delete" };
        if (values.type === 'framework') {
            obj["framework"] = values.content;
            obj["frameworkId"] = values.id;
            obj["type"] = "framework";
            const updateFramework = await userService.updateOrDeleteFramework(obj);
            updatingMessage(updateFramework, true);
        } else {
            obj["context"] = values.content;
            obj["contextId"] = values.id;
            obj["type"] = "context";
            const updateContext = await userService.updateOrDeleteContext(obj);
            updatingMessage(updateContext, true);
        }
    }

    const updatingMessage = (msg, isDeleted = false) => {
        if (msg?.success) {
            reFetch();
            setLoading(false);
            setSnackbarMessage(isDeleted ? 'Remove Framework/Context' : 'Framework/Context Updated');
            setShowSnackBar(true);
            setSnackbarType('success');
            setHideModal(true);
        } else {
            setSnackbarMessage(msg?.result?.message || msg?.message);
            setShowSnackBar(true);
            setSnackbarType('error');
            setLoading(false);
        }
        setHide(true);
    }

    const deletedFrameworkAndContext = () => {

        const matchedSettingUsers = organizationUserData.filter(member =>
            (member.defaultSettings?.email?.contextId === item?.id) ||
            (member.defaultSettings?.callprep?.contextId === item?.id) ||
            (member.defaultSettings?.linkedin?.contextId === item?.id) ||
            (member.defaultSettings?.email?.frameworkId === item?.id) ||
            (member.defaultSettings?.callprep?.frameworkId === item?.id) ||
            (member.defaultSettings?.linkedin?.frameworkId === item?.id)
        );

        const settingUserNames = matchedSettingUsers.map(member => `${member.firstName} ${member.lastName}`);

        const uniqueUserNames = [...new Set(settingUserNames)];
        setAssociatedUserName(uniqueUserNames);

        setHide(false);
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const caretPosition = event.target.selectionStart;

        setQuery(inputValue);

        const lastAtSymbolIndex = inputValue.lastIndexOf('@', caretPosition - 1);
        if (
            lastAtSymbolIndex !== -1 &&
            inputValue.charAt(caretPosition - 1) !== ' ' &&
            !hasSelectedTag
        ) {
            setShowDropdown(true);
            setCursorPosition(caretPosition);
            const searchTerm = inputValue.slice(lastAtSymbolIndex + 1, caretPosition).toLowerCase();
            const matchedTags = dataRepositoryTagsList.filter((tag) =>
                tag.toLowerCase().includes(searchTerm)
            );
            setFilteredTags(matchedTags)
        } else {
            setShowDropdown(false);
        }
    };

    const handleTagSelect = (tag) => {
        const lastAtSymbolIndex = query.lastIndexOf('@', cursorPosition - 1);
        const newQuery =
          query.slice(0, lastAtSymbolIndex + 1) +
          `{${tag}}` +
          query.slice(cursorPosition);
    
        setQuery(newQuery);
        setShowDropdown(false);
        setHasSelectedTag(true);
        inputRef.current.focus();

        setUsedTags((prevUsedTags) => new Set(prevUsedTags).add(tag));
    };

    const handleSendQuery = async () => {
        try {
            setLoading(true);
            const obj = {
                payload: {
                    userId: item.userId,
                    query: query.replace(/@/g, ''),
                    tags: Array.from(usedTags)
                }
            };
            const response = await axiosHelperCore({
                url: `rapport/user/generateCustomData`,
                method: "POST",
                JSONData: obj
            });

            if (response) {
                setMlCustomDataResponse(response?.data?.result?.customData);
                setMlRepoIdsResponse(response?.data?.result?.repoIds);
                setIsQueryModified(false);
                setOriginalQuery(query);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error calling ML API:', error);
        }
    };

    const resetTagSelection = (inputValue, caretPosition) => {
        if (inputValue.charAt(caretPosition - 1) === '@') {
            setHasSelectedTag(false);
        }
    };

    const handleInputChangeWithReset = (event) => {
        handleInputChange(event);
        setIsQueryModified(true);
        resetTagSelection(event.target.value, event.target.selectionStart);
        setIsCustomDataGenerated(false);
    };

    const toggleTextVisibility = () => {
        setIsFullTextVisible(!isFullTextVisible);
    };

    const handleDescriptionChange = (event, setFieldValue) => {
        const value = event.target.value;
        setContentHasValue(value.length > 0);
    };

    const shouldShowCustomDataInfoBox = () => {
        return item?.type === 'context' && !contentHasValue && mlCustomDataResponse;
    };

    const closeModalAndRevertQuery = () => {
        setHideModal(true);
        setQuery(originalQuery);
        setIsQueryModified(false);
        setContentHasValue(true);
    };


    useEffect(() => {
        if (item.customData && item.query && item?.contextValue) {
            setMlCustomDataResponse(item?.customData);
            setQuery(item?.query);
            setContentHasValue(true);
            setOriginalQuery(item?.query);
        }

        if (!item.customData && !item.query && item?.contextValue) {
            setContentHasValue(true);
        }
    }, [item?.customData, item?.query, item?.contextValue]);

    useEffect(() => {
        if (!hideModal && editItem) {
            // Populate initial values for query and custom data with the item being edited
            setQuery(editItem.query || '');
            setMlCustomDataResponse(editItem.customData || '');
            setOriginalQuery(editItem.query || '');
            setMlRepoIdsResponse(editItem.repoIds || []);
        }
    }, [hideModal, editItem]);

    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}
            >
               <MemoRevenoidLoader />
            </Backdrop>}
            <Flex
                padding={"15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={index % 2 == 0 ? "#FFF" : "rgb(249, 249, 249)"}
                alignItems={"center"}
                borderRadius={index % 2 != 0 && "12px"}
                margin="0 16px"
            >
                <Flex padding={"0 5px"} textWrap="balance" width="5%" >
                    {index + 1}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="15%" gap={"15px"}>
                    <Flex width="80%">
                        {item?.type === "framework" ? item?.frameworkName : item?.contextName}
                    </Flex>
                    {item?.customData && (
                        <Flex width="20%">
                            <Tooltip title={item.customData || ''} placement='top' arrow>
                                <MuiBox
                                    component="span"
                                    sx={{
                                        position: 'absolute',
                                        top: '-5px',
                                        right: '-5px',
                                        padding: '2px 4px',
                                        backgroundColor: '#E8E9FD',
                                        border: '1px solid #8d68f6',
                                        borderRadius: '15px',
                                        fontSize: '10px',
                                        color: '#8d68f6',
                                        whiteSpace: 'nowrap',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: '#8d68f6',
                                            color: '#FFFFFF',
                                            transition: 'background-color 0.3s ease',
                                        },
                                    }}
                                >
                                    Custom
                                </MuiBox>
                            </Tooltip>
                        </Flex>
                    )}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="10%" flexDirection={"column"}>
                    <Tag
                        color={item.type == "framework" ? "#60C678" : "#0094E3"}
                        bgColor={item.type == "framework" ? "#EFF9F1" : "#DCF9FF"}
                        text={`${item.type} : ${item.linkedinOremail || 'N/A'}`}
                    />
                    {/* {item.linkedinOremail &&
                    <Tag
                        color={item.linkedinOremail == "email" ? "#DD015B" : "#B027E1"}
                        bgColor={item.linkedinOremail == "email" ? "rgba(221, 1, 91, 0.1)" : "rgba(176, 39, 225, 0.1)"}
                        text={item.linkedinOremail}
                    />} */}
                </Flex>
                <Flex padding={"0 5px"} width="5%">
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="20%">
                    <Text>
                        {displayContent}
                        {content?.length > maxLength && !showMore && (
                            '...'
                        )}
                        {content?.length > maxLength && (
                            <>
                                {showMore && (
                                    <span onClick={() => setShowMore(!showMore)} style={showMoreLessLinkStyle}>
                                        view less
                                    </span>
                                )}
                                {!showMore && (
                                    <span onClick={() => setShowMore(!showMore)} style={showMoreLessLinkStyle}>
                                        view more
                                    </span>
                                )}
                            </>
                        )}
                    </Text>
                </Flex>
                <Flex padding={"0 5px"} width="10%">
                    <Tooltip title={item?.isExtensionVisibilityEnabled ?? true ? "Can be visible at Extension" : "Not visible at Extension"} placement="bottom" arrow>
                        <div onClick={() => toggleAIVisibility(item)}>
                            <EyeIcon isAIVisibilityEnabled={item?.isExtensionVisibilityEnabled ?? true} />
                        </div>
                    </Tooltip>
                </Flex>
                <Flex padding={"0 5px"} width="10%" >
                    <ToggleButton
                        variant='tertiary'
                        value={item?.isPublic}
                        name='isActive'
                        onChange={(value) => handlePublicPrivateStatus(value, item?.id, item?.type, item.userId)}
                    />
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="15%" flexDirection={"column"}>
                    <Flex style={{ "textTransform": "capitalize" }} mb={"5px"}>
                        {item.firstName} {item.lastName}
                    </Flex>
                    <Flex>
                        {item.email}
                    </Flex>
                </Flex>
                <Flex padding={"0 5px"} width="10%" gap='10px'>
                    {/* <Button size='s' onClick={openEditFormpopup} variant="secondary">
                        Edit
                    </Button> */}
                    <EditButton onClick={() => openEditFormpopup(item)} />
                    <DeleteButton onClick={() => deletedFrameworkAndContext()} />

                </Flex>
            </Flex>


            <ModalWrapper
                width="600px"
                height="max-content"
                hideModal={hideModal}
                setHideModal={() => {
                    setHideModal(true);
                    setEditItem(null);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="30px"
            >

                <>
                    <SnackBar
                        iconName={"info"}
                        message={snackbarMessage}
                        setShowSnackBar={setShowSnackBar}
                        show={showSnackBar}
                        timeout={4000}
                        anchor={"top-right"}
                        type={snackbarType}
                    />
                    <Text fontWeight="400" fontSize="24px" marginBottom={4}>Update Framework/Context</Text>
                    <Formik
                        key={isModalOpen.toString()}
                        initialValues={{
                            name: item?.frameworkName || item?.contextName || '',
                            content: content || '',
                            userId: item?.userId,
                            id: item?.objectId,
                            type: item?.type,
                            isPublic: item?.isPublic,
                        }}
                        onSubmit={(values) => {
                            console.log('Submitting edited data:', values);
                            handleUpdateContextAndFramework(values, item?.id);
                        }}
                        validationSchema={AddFrameworkAndContextSchema}

                    >
                        {({ setFieldValue, values }) => (
                            <Form style={{ maxHeight: 600, overflowY: 'auto' }}>
                                <Flex flexDirection="column">
                                    <Flex padding={"5px 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Name</Text>
                                        <FormikInput
                                            name="name"
                                            fullSize
                                        // placeholder="Name"
                                        />
                                    </Flex>
                                    <Flex padding={"5px 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Description</Text>
                                        <FormikTextArea
                                            name="content"
                                            // placeholder="Content"
                                            width={"95%"}
                                            onChange={(event) => handleDescriptionChange(event, setFieldValue)}
                                        />
                                    </Flex>
                                    <Flex padding={"3px 10px"} flexDirection="row" alignItems={"center"} gap="5px">
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Make it visible to the team</Text>
                                        <ToggleButton
                                            variant='tertiary'
                                            value={values.isPublic}
                                            name='isActive'
                                            onChange={
                                                (value) => {
                                                    setFieldValue("isPublic", value);
                                                }
                                            }
                                        />
                                    </Flex>
                                    {dataRepositoryFeatureCheck && item?.type === 'context' &&
                                    (<>
                                        {shouldShowCustomDataInfoBox() && (
                                        <HighlightBox>
                                            <InfoIcon />
                                            <Typography variant="subtitle2" sx={{ color: "#FFF", mt: 0 }}>
                                            We have pulled your custom data. Please tell us how to use it
                                            in "Context details"
                                            </Typography>
                                        </HighlightBox>
                                        )}
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingLeft={"5px"} paddingTop={"10px"}>Add Custom Data</Text>
                                        <TagEditor
                                            query={query}
                                            setOriginalQuery={setOriginalQuery}
                                            originalQuery={originalQuery}
                                            customData={mlCustomDataResponse}
                                            setQuery={(value) => setQuery(value)}
                                            setCustomData={(value) => setMlCustomDataResponse(value)}
                                            onTagAdded={(value) => {
                                                setFieldValue("isPublic", true);
                                                setTagData(value);
                                            }}
                                            tagList={dataRepositoryTagsList}
                                        />
                                    </>)}
                                    <Flex marginRight={1} marginTop={3} padding={"5px 10px"}>
                                        <Flex justifyContent={"left"}>
                                            <Button disabled={!(query === originalQuery)} type="submit">Update</Button>
                                        </Flex>
                                        <Flex justifyContent={"right"}>
                                            <Button variant="secondary" type="button" onClick={closeModalAndRevertQuery} marginLeft={2}>
                                                Cancel
                                            </Button>
                                        </Flex>
                                    </Flex>

                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </>
            </ModalWrapper>

            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={hide}
                setHideModal={() => {
                    setHide(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="40px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    {associtaedUserName.length > 0 && <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                        <Text fontSize={"16px"} fontWeight={300} maxWidth={"auto"}>
                            {" "}
                            The context and framework are set as default settings by&nbsp;
                            {associtaedUserName.length > 3 ? (
                                <span style={{ fontWeight: "bold" }}>
                                    {associtaedUserName.slice(0, 3).join(', ')} {`and`}
                                    <a
                                        // href="#"
                                        style={showMoreLessLinkStyle}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        {`+${associtaedUserName.length - 3} more`}
                                    </a>
                                </span>
                            ) : (
                                <span style={{ fontWeight: "bold" }}>
                                    {associtaedUserName.join(", ")}
                                </span>
                            )}
                            <br />
                            <span>Please get in touch with them before making any changes.</span>
                        </Text>
                    </div>}
                    {associtaedUserName.length === 0 && <Text fontSize={"16px"} fontWeight={300}>Are you sure you want to remove this {item.type} ?</Text>}
                    {/* {showMoreUserNames && (
                        <ModalWrapper
                            width="max-content"
                            height="max-content"
                            hideModal={!showMoreUserNames}
                            setHideModal={() => setShowMoreUserNames(false)}
                            bgcolor={"rgba(0, 0, 0, 0.8)"}
                            padding="20px"
                        >
                            <Flex flexDirection={"column"} alignItems={"center"}>
                                <h3>Additional Users</h3>
                                <ul>
                                    {associtaedUserName.slice(1).map((name, idx) => (
                                        <li key={idx}>{name}</li>
                                    ))}
                                </ul>
                                <Button onClick={() => setShowMoreUserNames(false)}>Close</Button>
                            </Flex>
                        </ModalWrapper>
                    )} */}
                    <Flex mt="15px">
                        {associtaedUserName.length == 0 && <Flex
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => deleteData(item)}
                        >
                            Yes
                        </Flex>}
                        <Flex
                            ml="16px"
                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => setHide(true)}
                        >
                            Cancel
                        </Flex>
                    </Flex>
                </Flex>
            </ModalWrapper>
        </>
    );
}

export default FrameworkAndContextTableBodyField;
