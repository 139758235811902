import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import moment from 'moment';
import { ToggleButton } from '@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton';
import EditButton from "containers/FrameworkAndContext/component/EditButton";
import DeleteButton from "containers/FrameworkAndContext/component/DeleteButton";


export default function GongQuestionBody({ item, index, editQuestion, deleteQuestion, updateQuestionStatus }) {

    const date = moment(item?.createdAt);
    const formattedDate = date.format('DD/MM/YYYY');

    return (<div>
        <Flex
            padding={"10px 15px"}
            fontSize="12px"
            fontWeight={500}
            position="sticky"
            backgroundColor={index % 2 == 0 ? "#FFF" : "rgb(249, 249, 249)"}
            alignItems={"center"}
        >
            <Flex padding={"0 5px"} textWrap="balance" width="5%" >
                {index + 1}
            </Flex>

            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="40%" flexDirection={"column"}>
                {item?.question || ''}
            </Flex>
            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="10%">
                {item?.createdBy || ''}
            </Flex>
            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="10%" >
                {formattedDate}
            </Flex>
            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="20%" >
                <ToggleButton
                    variant='tertiary'
                    value={item?.activated}
                    name='isActive'
                    onChange={(value) => {
                        updateQuestionStatus(item.questionId, value)
                    }}
                />

            </Flex>
            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="20%" gap="5px" >
                <EditButton onClick={() => editQuestion(item)} />
                <DeleteButton onClick={() => deleteQuestion(item)} />
            </Flex>
        </Flex>
    </div>)
}